import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {NotificationService} from "../../../shared/services/notification.service";
import Swal from "sweetalert2";
import {FilterModel} from "../../../shared/models/filter.model";
import {RetiroSolicitudesModel} from "../../../shared/models/retiro-solicitudes.model";
import {MonedaModel} from "../../../shared/models/wallet-currency.model";
import {AppState} from "../../../shared/redux/general.reducers";
import {Store} from "@ngrx/store";
import * as CajeroAction from "../../redux/cajero.actions";
import {Actions, ofType} from "@ngrx/effects";
import {TranslateService} from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-retiros",
	templateUrl: "./retiros.component.html",
	styleUrls: ["./retiros.component.css"]
})
export class RetirosComponent implements OnInit, AfterViewInit, OnDestroy {

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	filter: FilterModel;
	selectedTab: number;
	retiroSolicitudesJugador: RetiroSolicitudesModel[];
	retiroSolicitudesAfiliado: RetiroSolicitudesModel[];
	cryptoCurrency: MonedaModel[];

	subscription = new Subscription();

	constructor(
		private notification: NotificationService,
		private store: Store<AppState>,
		private actions$: Actions,
		private translateService: TranslateService,
	) {
	}

	ngOnInit(): void {
		this.filter = new FilterModel();
		this.subscription.add(this.store.subscribe((state) => {
				this.cryptoCurrency = state.cajero.cryptoMonedas.value;
				this.retiroSolicitudesJugador = state.cajero.retiroSolicitudesJugador;
				this.retiroSolicitudesAfiliado = state.cajero.retiroSolicitudesAfiliado;
			})
		);

		this.subscription.add(this.actions$
			.pipe(ofType(CajeroAction.cancelarSolicitudRetiroCompletado))
			.subscribe(response => {
				const resp = response;
				this.onSearchForm();
				Swal.fire(this.notification.BuildSuccess(resp.data.message)).then();
			})
		)

		this.selectedTab = 0;
		this.onSearchForm();
	}

	ngAfterViewInit() {
		adjustSize();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON OPEN FILTER ()
	onOpenFilter() {
		// const modalRef = this.modalService.open(WFRetiroComponent);
		// modalRef.componentInstance.searchForm = this.searchForm;
		// modalRef.componentInstance.cryptoCurrency = this.cryptoCurrency;
		// modalRef.result.then(result => {
		// 	if (result) {
		// 		this.onSearchForm();
		// 	}
		// });
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET TAB ()
	onSetTab(tab) {
		this.selectedTab = tab;
		this.getAllCurrency();
		this.onSearchForm();

	}

	onSearchForm() {
		this.filter = new FilterModel()
		this.filter.filter = [];

		if (this.selectedTab == 0) {
			this.filter.filter.push({
				atribute: "tipo",
				operator: "=",
				value: 'JUGADOR'
			});
		} else {
			this.filter.filter.push({
				atribute: "tipo",
				operator: "=",
				value: 'AFILIADO'
			});
		}
		this.filter.filter.push({
			atribute: "estado",
			operator: "=",
			value: 'PENDIENTE'
		});
		this.filter.paginate = 1000;

		this.store.dispatch(CajeroAction.getSolicitudesRetiro({filter: this.filter}));
	}


	onCancelRetiro(retiro: RetiroSolicitudesModel) {
		const message = this.translateService.instant("IMPOERTE_SERA_DEVUELTO_CUANTA_JUGADOR");
		Swal.fire(this.notification.BuildQuestion(message,this.translateService.instant("CANCELAR_RETIRO") )).then(
			(result) => {
				if (result.value) {
					const param = {
						id: retiro.id
					};
					this.patchCancelRetiro(param);
				}
			}
		);
	}

	// API CALLS

	getAllCurrency() {
		this.store.dispatch(CajeroAction.getCryptoMonedas());
	}


	patchCancelRetiro(param) {
		this.store.dispatch(CajeroAction.cancelarSolicitudRetiro({param:param}));
	}


}
