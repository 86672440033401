import {Component, EventEmitter, Input, OnInit, AfterViewInit, OnDestroy} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../../../shared/services/notification.service";
import {MonedaModel} from "src/app/shared/models/wallet-currency.model";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as GamesAction from "../../../games/redux/games.actions";

import {fixAndroid} from "../../../shared/utils/general.util";

declare function adjustSize(): any;

@Component({
	selector: "app-filter-catalog",
	templateUrl: "./filter-catalog.component.html",
	styleUrls: ["./filter-catalog.component.css"]
})
export class FilterCatalogComponent implements OnInit, OnDestroy {

	searchForm: FormGroup;

	cryptoCurrency: MonedaModel[];
	subscription = new Subscription();
	monedaSeleccionadaNombre = "Todas";
	filterGames: { name: string, moneda_id: number };

	wActive: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private notificationService: NotificationService,
		private store: Store<AppState>,
		private formBuilder: FormBuilder,
	) {
	}

	ngOnInit() {
		this.subscription.add(this.store.subscribe((state) => {
			this.cryptoCurrency = state.cajero.cryptoMonedas.value;
			this.filterGames = state.games.filterGames;
		}));
		this.initFilter();
		setTimeout(() => {
			this.wActive = true;
		}, 0);
		setTimeout(() => {
			fixAndroid("wc-catalog");
		}, 250);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initFilter() {
		this.searchForm = this.formBuilder.group(
			{
				name: new FormControl(this.filterGames.name, []),
				moneda_id: new FormControl(this.filterGames.moneda_id, []),
			}
		);
		const encontrada = this.cryptoCurrency.find(x => x.id === this.filterGames.moneda_id);
		this.monedaSeleccionadaNombre = encontrada ? encontrada.siglas : "Todas";
	}

	setearNombreMoneda() {
		const id = this.searchForm.get("moneda_id").value;
		if (!isNaN(id)) {
			const encontrada = this.cryptoCurrency.find(x => x.id === id * 1);
			if (encontrada) {
				this.monedaSeleccionadaNombre = encontrada.siglas;
			}
		} else {
			this.monedaSeleccionadaNombre = "Todas";
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - NO CLICK ()
	noClick(event) {
		event.stopPropagation();
		event.preventDefault();
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE ()
	onCancel() {
		this.wActive = false;
		setTimeout(() => {
			adjustSize();
			this.activeModal.close(true);
		}, 250);
	}

	onRealTimeFilter() {
		const formModel = this.searchForm.value;
		this.store.dispatch(GamesAction.setFilterGames({name: formModel.name, moneda_id: formModel.moneda_id * 1}));
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close(true);
		}, 250);
	}

}
