import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {SessionServices} from "./session.services";
import {catchError, switchMap, take, timeout} from "rxjs/operators";
import {Router} from "@angular/router";
import {NotificationService} from "./notification.service";
import Swal from "sweetalert2";
import {PusherService} from "../../games/services/pusher.service";
import {PusherServiceNotif} from "../../nav/services/pusher-service-notif.service";
import * as GeneralAction from "../redux/general.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../redux/general.reducers";
import * as AuthAction from "../../authetication/redux/authentication.actions";
import {Actions, ofType} from "@ngrx/effects";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {IdiomaModel} from "../models/idioma.model";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	private isRefreshing = false;
	private accessToken = "";
	private lang: IdiomaModel;

	constructor(
		public sessionService: SessionServices,
		private router: Router,
		private notification: NotificationService,
		private pusherService: PusherService,
		private pusherServiceNotif: PusherServiceNotif,
		private store: Store<AppState>,
		private translateService: TranslateService,
		private actions$: Actions
	) {
		this.store.select((state: AppState) => state).subscribe(state => {
			this.accessToken = state.auth.token;
			this.isRefreshing = state.auth.is_refreshing;
			this.lang = state.general.selectedLang;
		});
		// translateService.onLangChange.subscribe((event: LangChangeEvent) => {
		// 	this.lang = event.lang;
		// });
	}

	/**
	 * Intercept all HTTP Calls, and if the request in Unauthenticated then send a Refresh Token API Call
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.accessToken && !this.checkForPublicURL(request.url)) {
			request = this.addToken(request, this.accessToken);
		}

		const requestResult = next.handle(request).pipe(timeout(180000), catchError(error => {
			this.store.dispatch(GeneralAction.hideLoader({
				httpRequestLoader: true,
				tipo: "error"
			}));

			if (error.name === "TimeoutError") {
				const messAlert = this.translateService.instant("CONECTION_EXPIRO");
				Swal.fire(this.notification.BuildError(messAlert)).then(() => {
				});
				return throwError(error);
			} else if (error instanceof HttpErrorResponse) {
				const httpErrorCode = error.status;
				let messAlert = error.error.message ? error.error.message.toUpperCase() : this.translateService.instant("PETICION_NO_PUEDE_SER_PRECESADA") + httpErrorCode;
				switch (httpErrorCode) {
					case 0:
					case 400:
					case 403:
					case 404:
					case 405:
					case 409:
					case 412:

						break;
					case 422:
					case 500:
						if (error.error.errors) {
							messAlert = this.convertErrorsToString(error.error.errors);
						}
						break;
					case 503:
						messAlert = this.translateService.instant("SERVICIO_NO_DISPONIBLE");
						break;
					case 401:
						if (error.error.message === "The refresh token is invalid.") {
							// Emitir la accion de finalizar session
							this.store.dispatch(AuthAction.logoutCompletado());
							this.pusherService.pusherDisconnect();
							this.pusherServiceNotif.pusherDisconnect();
						}
						return this.handle401Error(request, next);
						break;
				}

				const comparison = this.translateService.instant("NO_POSEE_SALDO_SUFICIENTE");
				if (messAlert === comparison.toLocaleUpperCase()) {
					this.router.navigate(["/cajero/wallet"]).then();
				}
				Swal.fire(this.notification.BuildError(messAlert)).then(() => {
				});
				return throwError(error);
			}
		}));

		return requestResult;
	}

	/**
	 * Convert Errors Array to a Single String with <br> at the end
	 */
	convertErrorsToString(errors) {
		let newError = "";
		for (const key in errors) {
			if (Object.prototype.hasOwnProperty.call(errors, key)) {
				newError += errors[key] + "<br>";
			}
		}
		return newError.substring(0, newError.length - 4).toUpperCase();
	}

	/**
	 * Add the Access Token to all HTTP Requests
	 */
	private addToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
				lang: this.lang.siglas
			}
		});
	}

	/**
	 * Handle HTTP Errors of type 401 for Unauthenticated
	 */
	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		const refreshTokenCompletado$ = this.actions$.pipe(
			ofType(AuthAction.refreshTokenCompletado),
			take(1),
			switchMap(data => {
				return next.handle(this.addToken(request, data.access_token));
			}));

		if (!this.isRefreshing) {
			this.store.dispatch(AuthAction.refreshToken());
		}
		// else {
		// 	//Emitir la accion de finalizar session
		// 	this.store.dispatch(AuthAction.logoutCompletado());
		// 	this.pusherService.pusherDisconnect();
		// 	this.pusherServiceNotif.pusherDisconnect();
		// }

		return refreshTokenCompletado$;
	}

	checkForPublicURL(url: string) {
		if (url.includes("frontend/act_jugadores/robot")) {
			return true;
		}
		if (url.includes("frontend/auth/login/refresh")) {
			return false;
		}
		if (url.includes("frontend/auth/login")) {
			return true;
		}
	}
}
