import { ResumenPeriodoAfiliadosModel } from "./resumen-periodo-afiliados.model";

export class AfiliadoModel {
	id: number;
	usuario: string;
	codigo_referencia_afiliacion: string;
	fecha_ultimo_jugador: string;
	adeudo: boolean;
	retiro: boolean;
	jugador_id: number;
	frecuencia: number;

	constructor(obj?: AfiliadoModelCompleteObject) {
		this.id = obj ? obj.id : 0;
		this.usuario = obj ? obj.usuario : '';
		this.codigo_referencia_afiliacion = obj ? obj.codigo_referencia_afiliacion : '';
		this.adeudo = obj ? obj.adeudo : false;
		this.fecha_ultimo_jugador = obj ? obj.fecha_ultimo_jugador : '';
		this.frecuencia = obj ? obj.frecuencia : 24;
		this.jugador_id = obj ? obj.jugador_id : 0;
		this.retiro = obj ? obj.retiro : false;
	}

}

export class AfiliadoModelCompleteObject extends AfiliadoModel {
	resumen_periodo_actual: ResumenPeriodoAfiliadosModel
}