import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {FilterModel} from "../../../shared/models/filter.model";
import {HistorialMovimientosModel} from "../../../shared/models/historial-movimientos.model";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";
import * as CuentaAction from "../../../cuenta/redux/cuenta.actions";
import {AppState} from "src/app/shared/redux/general.reducers";
import {Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";


declare function adjustSize(): any;

@Component({
	selector: "app-cta-mov",
	templateUrl: "./cta-mov.component.html",
	styleUrls: ["./cta-mov.component.css"]
})
export class CtaMovComponent implements OnInit, OnDestroy, AfterViewInit {

	movimientosHistorial: HistorialMovimientosModel[] = [];
	filter: FilterModel;
	subscription = new Subscription();
	recargar: boolean = true;
	@ViewChild('divContenerdor') divContenerdor: any;

	constructor(
		private store: Store<AppState>,
		private actions$: Actions,
	) {
	}

	ngOnInit(): void {

		this.filter = new FilterModel();
		this.movimientosHistorial = [];

		this.subscription.add(
			this.store.subscribe((state) => {
				this.filter = state.cuenta.movimientosHistorial.filter;
				this.movimientosHistorial = state.cuenta.movimientosHistorial.value;
			})
		)
		this.subscription = this.actions$
			.pipe(ofType(CuentaAction.getMovimientosCompletado))
			.subscribe(response => {
				this.recargar = true;
				if (this.divContenerdor) {
					if (this.filter.amount_last_page != 0) {
						setTimeout(
							() => {
								this.divContenerdor.nativeElement.scrollLeft = this.divContenerdor.nativeElement.scrollLeft + 200;
							}, 350
						);
					} else {
						this.divContenerdor.nativeElement.scrollTop = 20;
						this.divContenerdor.nativeElement.scrollLeft = 20;
					}
				}
			});
		this.initData();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	ngAfterViewInit() {
		adjustSize();
	}

	initData() {
		this.getCryptoCurrency();
		this.getMovimientosConcepto();
		this.getHistorialMovimientos("primero");
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SHOW TOOLTIP ()
	showTooltip(event, t) {
		event.stopPropagation();
		const tt = event.target as HTMLDivElement;
		tt.classList.add("active");
		setTimeout(() => {
			tt.classList.remove("active");
		}, t * 1000);
	}


	// API CALLS

	getHistorialMovimientos(tipo = "primero") {
		let nuevoFiltro = new FilterModel();
		if (tipo != "primero") {
			nuevoFiltro = {...this.filter};
		}
		if (tipo == "scroll+") {
			nuevoFiltro.page++;
		}
		if (tipo == "scroll-") {
			nuevoFiltro.page--;
		}
		if (this.filter.amount_last_page != 0 && this.filter.page > 2) {
			nuevoFiltro.page--;
		}
		if (this.recargar) {
			this.recargar = false;
			this.store.dispatch(CuentaAction.getMovimientos({filter: nuevoFiltro}));
		}
	}

	getMovimientosConcepto() {
		this.store.dispatch(CuentaAction.getConceptos());
	}

	getCryptoCurrency() {
		this.store.dispatch(CajeroAction.getCryptoMonedas())
	}

	onScroll() {
		if (this.filter.last_page != this.filter.page) {
			this.getHistorialMovimientos("scroll+");
		}
	}

	onScrollUp() {
		if (this.filter.page != 1) {
			this.getHistorialMovimientos("scroll-");
		}
	}

}
