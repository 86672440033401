import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {apiRoutes} from "../../api-routing";
import {environment} from "../../../environments/environment";
import {FilterModel} from "../../shared/models/filter.model";

@Injectable({
	providedIn: "root"
})
export class CajeroService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	// Final

	getWalletGeneral(param: any) {
		const url = this.buildURL(apiRoutes.r_frontend_wallet_general);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getCryptoCurrency(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_monedas_get + "?filter=[[\"crypto\",\"=\",\"1\"]]");
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	deleteWalletCurrency(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugador_monedas_delete.replace("{id}", param.id));
		const response = this.httpClient.delete(url, param.data);
		return response.pipe(map((data: any) => data));
	}

	postAddNewCurrency(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugador_monedas_post);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	// Final

	getAllCurrency(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_monedas_get + "?filter=[[\"crypto\",\"=\",\"1\"]]");
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	retirarCurrencyWallet(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_solicitud_retiros_get);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	createCurrencyHash(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugadores_create_hash);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	getAllCurrencyBase(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_monedas_get_all_crypto_moneda_base_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getCurrencyUtilities(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_monedas_utilidades_get.replace("{id}", param.id));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postCurrencyExchange(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_solicitud_exchangers_post);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	getSolicitudRetiros(filter: FilterModel): Observable<any> {
		const url = this.buildURL(this.formatFilters(filter, apiRoutes.r_frontend_solicitud_retiros_filter_all));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	patchCancelRetiro(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_solicitud_retiros_cancelacion.replace("{id}", param.id));
		const response = this.httpClient.patch(url, null);
		return response.pipe(map((data: any) => data));
	}

	getDocumentosJugador(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_documento_jugadores_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postDocumentosJugador(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_documento_jugadores_post);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	deleteDocumentosJugador(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_documento_jugadores_get + "/" + param.id);
		const response = this.httpClient.delete(url, param.data);
		return response.pipe(map((data: any) => data));
	}

	private formatFilters(flt: FilterModel, url) {
		url += `?paginate=` + flt.paginate + `&ordenado_por=created_at&direction=DESC&page=` + flt.page;
		let filter = ``;
		if (flt.filter.filter.length !== 0) {
			filter += `&filter=[`;
			let first = true;
			flt.filter.forEach(value => {
				if (first) {
					filter += `["${value.atribute}"`;
				} else {
					filter += `,["${value.atribute}"`;
				}
				filter += `,"${value.operator}"`;
				if (value.value === true) {
					filter += `,true]`;
				} else if (value.value === false) {
					filter += `,false]`;
				} else {
					filter += encodeURI(`,"${value.value}"]`);
				}
				first = false;
			});
			filter += `]`;

		}
		return url + filter;
	}


}
