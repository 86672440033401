import * as BonosActions from './bonos.actions';
import {Action, createReducer, on} from "@ngrx/store";
import {BonosJugadorAptoModel, BonosModel} from "../../shared/models/bonos.model";
import * as AuthenticationActions from "../../authetication/redux/authentication.actions";
import {BonosVisitanteModel} from "../../shared/models/bonos-visitante.model";
import {DataWithExpireTime, DataWithFilter} from 'src/app/shared/redux/general.reducers';
import {GeneralUtil} from 'src/app/shared/utils/general.util';
import * as WalletsActions from "../../cajero/redux/cajero.actions";
import {FilterModel} from "../../shared/models/filter.model";
import * as CuentaActions from "../../cuenta/redux/cuenta.actions";

export class BonoState {
	bonosJugadorAptos: BonosJugadorAptoModel[];
	bonosHome: DataWithExpireTime<BonosVisitanteModel>;
	bonosActivos: BonosModel[];
	bonosHistorial: DataWithFilter<BonosModel>;

	constructor() {
		this.bonosJugadorAptos = [];
		this.bonosHome = {value: [], expire: 0};
		this.bonosActivos = [];
		this.bonosHistorial = {
			value: [],
			filter: new FilterModel()
		};
	}
}

const BonosReducer = createReducer(
	new BonoState(),
	on(BonosActions.bonoJugadorAptoAuthCompletado, (state, {data}) => ({
		...state,
		bonosJugadorAptos: data.bonos_jugador,
	})),
	on(BonosActions.getBonosHomeCompletado, (state, {data}) => ({
		...state,
		bonosHome: GeneralUtil.setWithExpiry(data.data, 3600000)/* 60 minutos */,
	})),
	on(BonosActions.setBonoParaDeposito, (state, {data}) => ({
		...state,
	})),
	on(BonosActions.setBonoParaDepositoCompletado, (state, {data}) => ({
		...state,
	})),
	on(BonosActions.getBonosAptos, (state) => ({
		...state,
	})),
	on(BonosActions.getBonosAptosCompletado, (state, {data}) => ({
		...state,
		bonosJugadorAptos: data.data
	})),
	on(BonosActions.bonosLogoutCompletado, (state) => {
		return {...new BonoState(), bonosHome: state.bonosHome};
	}),
	on(BonosActions.getBonosActivosCompletado, (state, {data}) => ({
		...state,
		bonosActivos: data.data
	})),
	on(BonosActions.getBonosHistorialCompletado, (state, {data}) => {
		let nuevoArray = [];
		let nuevoFiltro = {...state.bonosHistorial.filter};

		nuevoArray = data.data;

		nuevoFiltro.total = data.total;
		nuevoFiltro.last_page = data.last_page;
		nuevoFiltro.page = data.current_page;
		nuevoFiltro.from = data.from;
		nuevoFiltro.to = data.to;

		return {
			...state,
			bonosHistorial: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(BonosActions.getBonosHistorial, (state, {filter}) => ({
		...state,
		bonosHistorial: GeneralUtil.setWithFilter(state.bonosHistorial.value, filter),
	})),
	// on(AuthenticationActions.register, state => ({...state, token: state.token, refresh_token: state.refresh_token, player: state.player})),
	// on(AuthenticationActions.refresh_token, state => ({...state, token: state.token, refresh_token: state.refresh_token})),
);

export function reducer(state: BonoState | undefined, action: Action) {
	return BonosReducer(state, action);
}
