<section class="wider tabbed" id="exchanger">
	<div class="section-header">
      <div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
			<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
      </div>
      
      <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
      <div class="hidden-landscape">
         <div class="section-title">
            <div class="txt-snor pr-1">{{'EXCHANGER'|translate}}</div>
            <img src="../../../../assets/images/icon-m-exchanger.svg" class="section-icon">
         </div>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>

   <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
   <div class="full-h pos-rel px-2 pb-1 hidden-landscape">

      <div class="bg-g1 pt-2 pb-15">
         <div class="flex-col">
            <div class="col col-3 flex-center">
               <img class="coin" *ngIf="selectedOrigenCrypto"
               src="../../../../assets/images/crypto/{{selectedOrigenCrypto ? selectedOrigenCrypto.moneda.siglas.toLowerCase() : 'btc'}}.svg"
               (error)="changeImg($event, selectedOrigenCrypto.moneda.imagen)">
            </div>
            <div class="col col-9 pr-1">
               <div class="sel2 faux-sel2 mb-03">
                  <input class="inp2 inp2-nor full-w mb-03" placeholder="{{'MONEDA' |translate}}" id="inp-from"
                  value="{{selectedOrigenCrypto ? selectedOrigenCrypto.moneda.nombre : null}}">
                  <select class="sel2 sel2-nor full-w" (change)="selectCurrencyOrigen($event)">
                     <option [selected]="selectedOrigenCrypto === null" [value]="null">{{'MONEDA' |translate}}
                     </option>
                     <option *ngFor="let curr of walletCurrency; let i = index;"
                           [selected]="selectedOrigenCrypto && selectedOrigenCrypto.id === curr.id"
                           [value]="curr.id"> {{curr.moneda.siglas.toUpperCase()}}
                        - {{curr.moneda.nombre}}</option>
                  </select>
               </div>
               <p class="fl txt-sm txt-g8">{{'DISPONIBLE' |translate}}:</p>
               <p class="fr txt-sm txt-w"><span class="txt-c2">
                  {{selectedOrigenCrypto ? selectedOrigenCrypto.moneda.siglas.toUpperCase() : ""}}
                  </span>
                  {{!selectedOrigenCrypto ? "-" : selectedOrigenCrypto.balance_real | number: "1.2-8"}}
               </p>
               <div class="cb mb-1"></div>
               <input type="text" class="inp2 inp2-nor full-w tar mb-03" placeholder="0.00000000"
                  placeholder="0.00000000" [imask]="numberIMask" [unmask]="true" [(ngModel)]="origenCryptoValue"
                  (keyup)="changeMonto()">
            </div>
         </div>
         <div class="col col-3"></div>
         <div class="col col-9 pr-1">
            <p class="fl txt-sm txt-g8">{{'EQUIV_A'}}:</p>
            <p class="fr txt-sm txt-g8">{{'USD'.toUpperCase()}}
               {{!origenCryptoValue ? "-" : calcUSD(selectedOrigenCrypto.cambio_moneda_base, origenCryptoValue) | number: "1.2-2"}}
            </p>
            <div class="cb"></div>
         </div>
         <div class="cb"></div>
      </div>

      <div class="exchanger-convert-icon">
         <img src="../../../../assets/images/icon-exch-ind.svg">
      </div>

      <div class="bg-g1 pt-2 pb-15 mb-2">
         <div class="flex-col">
            <div class="col col-3 flex-center">
               <img class="coin" *ngIf="destinyCryptoUtility"
               src="../../../../assets/images/crypto/{{destinyCryptoUtility ? destinyCryptoUtility.siglas.toLowerCase() : 'eth'}}.svg"
               (error)="changeImg($event, destinyCryptoUtility.imagen)">
            </div>
            <div class="col col-9 pr-1">
               <div class="sel2 faux-sel2 mb-03">
                  <input type="text" class="inp2 inp2-nor full-w mb-03" placeholder="Moneda" id="inp-to"
                  value="{{destinyCryptoUtility ? destinyCryptoUtility.siglas : null}}">
                  <select class="sel2 sel2-nor full-w" (change)="selectCurrencyDestiny($event)">
                     <option [selected]="destinyCryptoUtility === null" [value]="null">{{'MONEDA' |translate}}
                     </option>
                     <option *ngFor="let curr of utilityCurrency; let i = index;" [selected]="destinyCryptoUtility && destinyCryptoUtility.id === curr.id" [value]="curr.id"> {{curr.siglas.toUpperCase()}} - {{curr.nombre}}</option>
                  </select>
               </div>
               <p class="fl txt-sm txt-g8">{{'DISPONIBLE' |translate}}:</p>
               <p class="fr txt-sm txt-w"><span class="txt-c2">
                  {{destinyCryptoUtility ? destinyCryptoUtility.siglas.toUpperCase() : ""}} </span>
                  {{!selectedDestinyCrypto ? "-" : selectedDestinyCrypto.balance_real | number:"1.2-8"}}
               </p>
               <div class="cb mb-1"></div>
               <input type="text" class="inp2 inp2-nor full-w tar mb-03" placeholder="0.00000000" readonly [(ngModel)]="destinyCryptoValue" [imask]="numberIMaskTwo" [unmask]="true">
            </div>
         </div>
         <div class="col col-3"></div>
         <div class="col col-9 pr-1">
            <p class="fl txt-sm txt-g8">{{'EQUIV_A' |translate}}:</p>
            <p class="fr txt-sm txt-g8">{{'USD'.toUpperCase()}}
               {{!selectedDestinyCrypto ? "-" : calcUSD(selectedDestinyCrypto.cambio_moneda_base, destinyCryptoValue) | number: "1.2-2"}}
            </p>
            <div class="cb"></div>
         </div>
         <div class="cb"></div>
      </div>

      <div class="btn2-actions p-2">
         <button type="button" class="btn2 btn2-lg btn2-1st full-w bg-k5" (click)="exchangeCrypto()"
         [disabled]="!destinyCryptoUtility || destinyCryptoUtility.precio_jugador === 0 || destinyCryptoValue === '0'">{{'INTERCAMBIAR' |translate}}</button>
      </div>

   </div>
   <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

</section>
