<input type="text" autofocus="autofocus" style="display:none"/>

<div class="w-cover coverB" [class.active]="wActive==true" id="wc-catalog" (swipedown)="onCancel()">

	<div class="window w-filter" id="filter-catalog" (click)="$event.stopPropagation()">
		<form [formGroup]="searchForm" (ngSubmit)="onRealTimeFilter()">
			<!-- - - - - - - - - - - - - - - - - - LANDSCAPE -->
			<div class="hidden-portrait pt-25 px-4">
				<div class="px-1">
					<div class="col col-4">
						<div class="sel sel-1st">
							<select class="sel-nor full-w" formControlName="moneda_id" (change)="onRealTimeFilter()"
									(click)="$event.stopPropagation()">
								<option [value]="null">{{'MONEDAS' |translate}} ({{'TODAS' |translate}})</option>
								<option [value]="curr.id"
										*ngFor="let curr of cryptoCurrency">{{curr.siglas.toUpperCase()}}</option>
							</select>
						</div>
					</div>
					<div class="col col-8 pl-1">
						<input class="inp inp-nor inp-1st full-w" placeholder="{{'NOMBRE_JUEGO' |translate}}" type="text"
							   (click)="$event.stopPropagation()"
							   formControlName="name" (keyup)="onRealTimeFilter()">
					</div>
					<div class="cb"></div>
				</div>

				<div class="w-actions px-5">
					<div class="col col-3-1">
						<button (click)="onCancel()" class="btn btn-nor btn-rnd btn-g btn-icon full-w"
								type="button">
							{{'CERRAR' |translate}}
							<img src="../../../../assets/images/icon-b-close-c.svg">
						</button>
					</div>
					<div class="col col-4-1"></div>
					<div class="col col-3-1">
						<button (click)="onRealTimeFilter()" class="btn btn-nor btn-rnd btn-1st btn-icon full-w"
								type="button">
							{{'FILTRAR' |translate}}
							<img src="../../../../assets/images/icon-b-play-c.svg">
						</button>
					</div>
					<div class="cb"></div>
				</div>

				<button (click)="onCancel()" class="btn btn-w-close" type="button">
					<img src="../../../../assets/images/icon-b-close.svg">
				</button>
			</div>
			<!-- - - - - - - - - - - - - - - - - - / LANDSCAPE -->
			<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
			<div class="hidden-landscape">
				<div class="btn-window-close" (click)="onCancel()">
					<div class="btn-window-close-bar"></div>
				</div>
				<p class="w-title-cover txt-ssm txt-w tac">{{'FILTRAR' |translate}}</p>
				<div class="px-1">
					<div class="bg-g1 p-15 pb-2">
						<p class="txt-gc mb-05">{{'NOMBRE_JUEGO' |translate}}:</p>
						<input type="text" class="inp2 inp2-nor inp2-1st full-w mb-2" (click)="$event.stopPropagation()"
							   formControlName="name">

						<p class="txt-gc mb-05">{{'MONEDA_JUEGO' |translate}}:</p>
						<div class="sel2 faux-sel2">
							<input class="inp2 inp2-nor full-w mb-03" [value]="monedaSeleccionadaNombre" readonly>
							<select class="sel2 sel2-nor full-w" formControlName="moneda_id"
									(click)="$event.stopPropagation()" (change)="setearNombreMoneda()">
								<option>{{'MONEDAS' |translate}} ({{'TODAS' |translate}})</option>
								<option [value]="curr.id"
										*ngFor="let curr of cryptoCurrency">{{curr.siglas.toUpperCase()}}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="btn2-actions px-2 pb-2">
					<div class="col col-6 pr-07">
						<button type="button" class="btn2 btn2-lg btn2-g full-w"
								(click)="onCancel()">{{'CANCELAR' |translate}}
						</button>
					</div>
					<div class="col col-6 pl-07">
						<button type="submit" class="btn2 btn2-lg btn2-1st full-w">{{'FILTRAR' |translate}}
						</button>
					</div>
					<div class="cb"></div>
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

		</form>
	</div>

</div>
