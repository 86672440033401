import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AfiliadosRoutes } from "./afiliados.routing";
import { AfiliadosService } from "./services/afiliados.service";
import { SharedModule } from "../shared/shared.module";
import { AfiliadosComponent } from "./components/afiliados/afiliados.component";
import { CampaignsComponent } from "./components/campaigns/campaigns.component";
import { TraficoComponent } from "./components/trafico/trafico.component";
import { ComisionesComponent } from "./components/comisiones/comisiones.component";
import { WFComisionesComponent } from "./components/comisiones/batch-comisiones/w-f-comisiones/w-f-comisiones.component";
import { AfilBalancesRetirosComponent } from "./components/afil-balances-retiros/afil-balances-retiros.component";
import { WAddBalanceComponent } from "./components/afil-balances-retiros/w-add-balance/w-add-balance.component";
import { WAfilRetiroComponent } from "./components/afil-balances-retiros/w-afil-retiro/w-afil-retiro.component";
import { WFTraficoComponent } from "./components/trafico/w-f-trafico/w-f-trafico.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { WCampaignInfoComponent } from "./components/campaigns/w-campaign-info/w-campaign-info.component";
import { IMaskModule } from "angular-imask";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromAfiliadosReducer from "./redux/afiliados.reducers";
import { WNewLinkComponent } from "./components/campaigns/w-new-link/w-new-link.component";
import { WNewAccountComponent } from "./components/w-new-account/w-new-account.component";
import { AfiliadosEffects } from "./redux/afiliados.effects";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		RouterModule.forChild(AfiliadosRoutes),
		SharedModule,
		IMaskModule,
		InfiniteScrollModule,
		StoreModule.forFeature("afiliado", fromAfiliadosReducer.reducer),
		EffectsModule.forFeature([AfiliadosEffects]),
		TranslateModule
	],
	providers: [
		NgbActiveModal,
		AfiliadosService
	],
	declarations: [
		AfiliadosComponent,
		CampaignsComponent,
		TraficoComponent,
		ComisionesComponent,
		WFComisionesComponent,
		AfilBalancesRetirosComponent,
		WAddBalanceComponent,
		WAfilRetiroComponent,
		WFTraficoComponent,
		WNewLinkComponent,
		WCampaignInfoComponent,
		WNewAccountComponent
	],
	exports: [
		WCampaignInfoComponent,
		WFComisionesComponent,
		WAddBalanceComponent,
		WAfilRetiroComponent,
		WNewLinkComponent,
		WFTraficoComponent,
		WNewAccountComponent
	]
})

export class AfiliadosModule { }
