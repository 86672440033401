<img [src]="doc.url" *ngFor="let doc of allDocuments" style="display: none">

<section class="" id="verificacion">
	<div class="section-header">
      <div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
			<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
		</div>

		<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="section-title">
				<div class="txt-snor pr-1">{{'VERIFICACIONES' |translate}}</div>
				<img src="../../../../assets/images/icon-m-verificacion.svg" class="section-icon">
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>

	<div class="full-h">

		<div class="verificacion-container">

			<div class="col col-verif-L">
				<ul id="menu-verif">
					<li (click)="onSelect('datos')" [class.active]="selected=='datos'">
						<div class="pr-05 indicator {{vDatos}}">
							<!-- [class.verificado]="vDatos==true" -->
							<img src="../../../../../assets/images/icon-m-datos.svg">
						</div>
						<span class="hidden-landscape">{{'DATOS' |translate}}</span>
					</li>
					<li (click)="onSelect('tarjetas')" [class.active]="selected=='tarjetas'">
						<div class="pr-05 indicator {{vCard}}">
							<!-- [class.verificado]="vCard==true" -->
							<img src="../../../../../assets/images/icon-m-card.svg">
						</div>
						<span>{{'TARJETAS' |translate}}</span>
					</li>
					<li (click)="onSelect('id')" [class.active]="selected=='id'">
						<div class="pr-05 indicator {{vId}}">
							<!-- [class.verificado]="vId==true" -->
							<img src="../../../../../assets/images/icon-m-id.svg">
						</div>
						<span class="hidden-landscape">{{'ID' |translate}}</span>
					</li>
					<li (click)="onSelect('domicilio')" [class.active]="selected=='domicilio'">
						<div class="pr-05 indicator {{vRes}}">
							<!-- [class.verificado]="vRes==true" -->
							<img src="../../../../../assets/images/icon-m-residence2.svg">
						</div>
						<span class="hidden-landscape">{{'DOMICILIO' |translate}}</span>
					</li>
					<li (click)="onSelect('faxbackform')" [class.active]="selected=='faxbackform'">
						<div class="pr-05 indicator {{vFbf}}">
							<!-- [class.verificado]="vFbf==true" -->
							<img src="../../../../../assets/images/icon-m-fbform.svg">
						</div>
						<span class="hidden-landscape">{{'FORMULARIO' |translate}}</span>
					</li>
				</ul>
			</div>

			<div class="col col-verif-R" id="verificaciones">
				<ng-container *ngIf="selected === 'datos'">
					<app-verif-datos></app-verif-datos>
				</ng-container>
				<ng-container *ngIf="selected === 'tarjetas'">
					<app-verif-card></app-verif-card>
				</ng-container>
				<ng-container *ngIf="selected === 'id'">
					<app-verif-id></app-verif-id>
				</ng-container>
				<ng-container *ngIf="selected === 'domicilio'">
					<app-verif-residence ></app-verif-residence>
				</ng-container>
				<ng-container *ngIf="selected === 'faxbackform'">
					<app-verif-fbform></app-verif-fbform>
				</ng-container>
			</div>

		</div>


	</div>
	<!-- - - - - - - - - - - - - - - - - - - / LANDSCAPE -->
	<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
</section>
