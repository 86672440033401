import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NavigationRoutes} from "./navigation.routing";
import {NavigationService} from "./services/navigation.service";
import {SharedModule} from "../shared/shared.module";
import {ControlsComponent} from "./components/controls/controls.component";
import {MainmenuComponent} from "./components/mainmenu/mainmenu.component";
import {PusherServiceNotif} from "./services/pusher-service-notif.service";
import {ClickOutsideModule} from "ng-click-outside";
import {GamesModule} from "../games/games.module";
import {WidgetControlComponent} from "./components/widget-control/widget-control.component";
import {AngularDraggableModule} from "angular2-draggable";
import { WNotificacionesComponent } from './components/w-notificaciones/w-notificaciones.component';
import {PusherServiceActivacionCuenta} from "./services/pusher-service-activacion-cuenta.service";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		RouterModule.forChild(NavigationRoutes),
		SharedModule,
		ClickOutsideModule,
		GamesModule,
		AngularDraggableModule,
		TranslateModule
	],
	providers: [
		NgbActiveModal,
		NavigationService,
		PusherServiceNotif,
		PusherServiceActivacionCuenta
	],
	declarations: [
		ControlsComponent,
		MainmenuComponent,
		WidgetControlComponent,
		WNotificacionesComponent
	],
	exports: [
		ControlsComponent,
		WidgetControlComponent
	]
})
export class NavigationModule {
}
