<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover coverB" id="filtro-cta-games-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">
	<!-- -->

	<div class="window" id="filtro-cta-games">
		<form [formGroup]="searchForm" (ngSubmit)="onFilter($event)">

			<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
			<div class="hidden-landscape">
				<div class="btn-window-close" (click)="onCloseW()">
					<div class="btn-window-close-bar"></div>
				</div>
				<p class="w-title-cover txt-ssm txt-w tac">{{ 'FILTRAR_RESULTADOS' | translate }}</p>
				<div class="px-1">
					<div class="bg-g1 p-15 pb-2">
						<div class="col col-6 pr-07">
							<p class="txt-gc mb-05">{{ 'FECHA_DESDE' | translate }}:</p>
							<div class="pos-rel">
								<div class="date">
									<input class="inp2 inp2-nor full-w date-txt" placeholder="yyyy-mm-dd" type="text"
										readonly [value]="fechaInicio">
									<input (change)="setDate($event,'fecha_inicio')" class="inp2-nor full-w date-inp"
										type="date" formControlName="fecha_inicio">
								</div>
							</div>
						</div>
						<div class="col col-6 pl-07">
							<p class="txt-gc mb-05">{{ 'FECHA_HASTA' | translate }}:</p>
							<div class="pos-rel">
								<div class="date">
									<input class="inp2 inp2-nor full-w date-txt" placeholder="yyyy-mm-dd" type="text"
										readonly [value]="fechaFin">
									<input (change)="setDate($event,'fecha_fin')" class="inp2-nor full-w date-inp"
										type="date" formControlName="fecha_fin">
								</div>
							</div>
						</div>
						<div class="cb mb-2"></div>
						<div class="col col-6 pr-07">
							<p class="txt-gc mb-05">{{ 'MONEDA' | translate }}:</p>
							<div class="sel2">
								<select class="sel2 sel2-nor full-w" formControlName="moneda_id"
									(click)="$event.stopPropagation()">
									<option [value]="null">{{ 'TODAS' | translate }}</option>
									<option [value]="curr.id" *ngFor="let curr of cryptoCurrency">{{curr.siglas}}
									</option>
								</select>
							</div>
						</div>
						<div class="cb mb-2"></div>
						<p class="txt-gc mb-05">{{ 'CATEGORIA' | translate }}:</p>
						<div class="sel2">
							<select class="sel2 sel2-nor full-w" formControlName="clasificacion_visual_juego_id">
								<option [value]="null">{{ 'CATEGORIA' | translate }}</option>
								<option [value]="cat.id" *ngFor="let cat of categoriasVisuales">{{cat.nombre}}
								</option>
							</select>
						</div>
						<p class="txt-gc mb-05">{{ 'CONCEPTO' | translate }}:</p>
						<div class="sel2">
							<select class="sel2 sel2-nor full-w" formControlName="concepto_id">
								<option [value]="null">{{ 'CONCEPTO' | translate }}</option>
								<option [value]="9">{{ 'APUESTA_CASINO' | translate }}</option>
								<option [value]="10">{{ 'APUESTA_GANADORA_CASINO' | translate }}</option>
								<option [value]="11">{{ 'REENBOLSO_CASINO' | translate }}</option>
								<option [value]="32">{{ 'DEVOLUCION_APUESTA_CASINO' | translate }}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="btn2-actions px-2 pb-2">
					<div class="col col-6 pr-07">
						<button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCancel($event)">{{
							'CANCELAR' | translate }}
						</button>
					</div>
					<div class="col col-6 pl-07">
						<button type="submit" class="btn2 btn2-lg btn2-1st full-w">{{ 'FILTAR' | translate }}
						</button>
					</div>
					<div class="cb"></div>
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

		</form>
	</div>

</div>