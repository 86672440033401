<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover coverB" id="wallet-withdraw-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">
   <!-- -->

	<div class="window" id="wallet-withdraw">

		<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="btn-window-close" (click)="onCloseW()">
				<div class="btn-window-close-bar"></div>
			</div>
			<p class="w-title-cover txt-sm txt-w tac">{{'RETIRAR' |translate}}</p>

			<div class="px-1">
				<div class="bg-g1 p-15 mb-1">
               <p class="txt-lg txt-w">
                  <img class="coin-icon" src="../../../../assets/images/crypto/{{selectedWalletCurrency.moneda.siglas.toLowerCase()}}.svg" (error)="changeImg($event, selectedWalletCurrency.moneda.imagen)"> {{selectedWalletCurrency.moneda.nombre.toUpperCase()}} </p>
               <div class="cb mb-15"></div>
               <div class="fl txt-nor txt-g9">{{'DISPONIBLE_PARA_RETIRAR' |translate}}:</div>
               <div class="fr txt-nor"><span class="txt-c2"> {{selectedWalletCurrency.moneda.siglas.toUpperCase()}} </span> {{selectedWalletCurrency.balance_retirable | number: "1.2-8"}}</div>
               <div class="cb mb-05"></div>
               <div class="fl txt-nor txt-g9">{{'EQUIV_A' |translate}}:</div>
               <div class="fr txt-nor"><span class="txt-g9"> USD </span> {{selectedWalletCurrency.balance_retirable_equivalente | number: "1.2-2"}}</div>
               <div class="cb"></div>
            </div>

            <div class="bg-g1 p-15">
					<p class="txt-g9 mb-2">{{'INTRODUSCA_MONTO_DIRECCION_1' |translate}}<span class='txt-w'>{{'INTRODUSCA_MONTO_DIRECCION_2' |translate}}</span> {{'INTRODUSCA_MONTO_DIRECCION_3' |translate}}.</p>

					<p class="txt-gc mb-05">Monto a Retirar:</p>
					<div class="col col-btn2-11 pr-1">
						<input class="inp2 inp2-nor inp2-1st full-w tar" placeholder="0.000000" type="text"
							   [(ngModel)]="montoRetirar" (keyup)="changeMonto()" [imask]="numberIMask" [unmask]="true">
					</div>
					<div class="cb mb-1"></div>
					<p class="txt-gc mb-05">{{'DIRECCION' |translate}}:</p>
					<div class="col col-btn2-11 pr-1">
						<textarea class="ta2 ta2-nor full-w txt-w" placeholder="{{'HASH' |translate}}"
								  [(ngModel)]="walletHash"></textarea>
					</div>
					<div class="col col-btn2-1">
						<button type="button" class="btn2 btn2-4th btn2-lg btn2-lg-icon">
							<img (click)="onPasteHash($event)" src="../../../../assets/images/icon-paste.svg">
						</button>
					</div>
					<div class="cb"></div>
				</div>
			</div>

			<div class="w-actions px-2 pb-1">
				<div class="col col-6 pr-07">
					<button type="button" class="btn2 btn2-lg btn2-g full-w"
						(click)="onCancel($event)">{{'CANCELAR' |translate}}</button>
				</div>
				<div class="col col-6 pl-07">
					<button type="button" (click)="onWithdraw($event)" class="btn2 btn2-lg btn2-1st full-w">{{'CONTINUAR' |translate}}</button>
				</div>
				<div class="cb"></div>
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

	</div>
</div>
