<section class="wider tabbed" id="bonos">
	<div class="section-header">
      <div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
			<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
		</div>

      <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
      <div class="hidden-landscape">
         <div class="section-title">
            <div class="txt-snor pr-1">{{'MIS_BONOS' |translate}}</div>
            <img src="../../../../assets/images/icon-m-bonos.svg" class="section-icon">
         </div>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>

	<div class="full-h port-rel">

		<ul class="tabs">
			<li (click)="onSetTab(0)" [class.active]="selectedTab==0" class="tac">{{'BONOS_DISPONIBLES' |translate}}</li>
			<li (click)="onSetTab(1)" [class.active]="selectedTab==1" class="tac">{{'BONOS_ACTIVOS' |translate}}</li>
		</ul>

		<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TAB 0 - DISPONIBLES -->
		<ng-container *ngIf="selectedTab==0">
			<app-disponibles-bonos></app-disponibles-bonos>
		</ng-container>
		<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / TAB 0 - DISPONIBLES -->

		<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TAB 1 - ACTIVOS -->
		<ng-container *ngIf="selectedTab==1">
			<app-activos-bonos></app-activos-bonos>
		</ng-container>
		<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / TAB 1 - ACTIVOS -->

	</div>

</section>
