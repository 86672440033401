import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {apiRoutes} from "../../api-routing";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {FilterModel} from "../../shared/models/filter.model";

@Injectable({
	providedIn: "root"
})
export class MenuService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	getNotifications(filter: any): Observable<any> {
		const url = this.buildURL(filter && filter.length > 0 ? this.formatFilters(filter, apiRoutes.r_frontend_notifications_get) : apiRoutes.r_frontend_notifications_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	markAsReadNotification(id: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_notifications_mark_as_read.replace("{id}", id));
		const response = this.httpClient.patch(url, {id: id});
		return response.pipe(map((data: any) => data));
	}

	deleteNotification(id: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_notifications_delete.replace("{id}", id));
		const response = this.httpClient.delete(url);
		return response.pipe(map((data: any) => data));
	}



	private formatFilters(flt: FilterModel, url) {
		url += `?paginate=` + flt.paginate + `&ordenado_por=created_at&direction=DESC&page=` + flt.page;
		let filter = ``;
		if (flt.filter.filter.length !== 0) {
			filter += `&filter=[`;
			let first = true;
			flt.filter.forEach(value => {
				if (first) {
					filter += `["${value.atribute}"`;
				} else {
					filter += `,["${value.atribute}"`;
				}
				filter += `,"${value.operator}"`;
				if (value.value === true) {
					filter += `,true]`;
				} else if (value.value === false) {
					filter += `,false]`;
				} else {
					filter += encodeURI(`,"${value.value}"]`);
				}
				first = false;
			});
			filter += `]`;

		}
		return url + filter;
	}
}
