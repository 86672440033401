import {createAction, props} from '@ngrx/store';
import {FilterModel} from "../../shared/models/filter.model";

export const bonoJugadorAptoAuthCompletado = createAction('[Bonos] Bono Jugadores Aptos Auth Completado', props<{ data: any }>());
export const getBonosHome = createAction('[Bonos] Bono Home');
export const getBonosHomeCompletado = createAction('[Bonos] Bono Home Completado', props<{ data: any }>());
export const setBonoParaDeposito = createAction('[Bonos] Set Bono Para Deposito', props<{
	tipo: string,
	data: {
		bono_jugador_apto_id: number,
		moneda_id: number
	}
}>());
export const setBonoParaDepositoCompletado = createAction('[Bonos] Set Bono Para Deposito Completado', props<{ data: any }>());
export const getBonosAptos = createAction('[Bonos] Bono Aptos');
export const getBonosAptosCompletado = createAction('[Bonos] Bono Aptos Completado', props<{ data: any }>());
export const bonosLogoutCompletado = createAction('[Bonos] Logout Completado');
export const getBonosActivos = createAction('[Bonos] Get Bonos Activos');
export const getBonosActivosCompletado = createAction('[Bonos] Get Bonos Activos Completado', props<{ data: any }>());
export const getBonosHistorial = createAction('[Bonos] Get Bonos Historial', props<{ filter: FilterModel }>());
export const getBonosHistorialCompletado = createAction('[Bonos] Get Bonos Historial Completado', props<{ data: any }>());
export const activarBonos = createAction('[Bonos] Activar Bono', props<{ param: any }>());
export const activarBonosCompletado = createAction('[Bonos] Activar Bono Completado', props<{ data: any }>());
export const desactivarBonos = createAction('[Bonos] Desactivar Bono', props<{ param: any }>());
export const desactivarBonosCompletado = createAction('[Bonos] Desactivar Bono Completado', props<{ data: any }>());






