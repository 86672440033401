import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MonedaModel} from "../../../shared/models/wallet-currency.model";
import {FilterModel} from "../../../shared/models/filter.model";
import {ConceptoModel} from "../../../shared/models/concepto.model";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as CuentaAction from "../../redux/cuenta.actions";

import { fixAndroid } from '../../../shared/utils/general.util';
declare function adjustSize(): any;

@Component({
	selector: "app-w-f-cta-crypto",
	templateUrl: "./w-f-cta-crypto.component.html",
	styleUrls: ["./w-f-cta-crypto.component.css"]
})
export class WFCtaCryptoComponent implements OnInit, OnDestroy {

	filter: FilterModel;
	searchForm: FormGroup;
	cryptoCurrency: MonedaModel[];
	subscription = new Subscription();
	fechaInicio: string = '';
   fechaFin: string = '';

   wActive:boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private store: Store<AppState>,
		private formBuilder: FormBuilder,
	) {
	}


	ngOnInit(): void {
		this.subscription.add(
			this.store.subscribe((state) => {
				this.cryptoCurrency = state.cajero.cryptoMonedas.value;
				this.filter = state.cuenta.intercambioHistorial.filter;
			})
		)
      this.initFilter();
      setTimeout(() => { this.wActive = true; }, 0);
      setTimeout(() => { fixAndroid('w-cover-login'); }, 250);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initFilter() {
		this.searchForm = this.formBuilder.group(
			{
				estado: new FormControl('', []),
				moneda_origen_id: new FormControl(null, []),
				fecha_inicio: new FormControl('', []),
				fecha_fin: new FormControl('', []),
			}
		);

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SET DATE W ()
	setDate(event, tipo) {
		const dateInp = event.target as HTMLInputElement;
		const dateVal = dateInp.value;
		// const txtInp = dateInp.closest(".date").querySelector(".date-txt") as HTMLInputElement;
		// txtInp.value = dateVal;
		if (tipo == "fecha_inicio") {
			this.fechaInicio = dateVal;
		} else {
			this.fechaFin = dateVal;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close();
         adjustSize();
      }, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON FILTER ()
	onFilter(event) {
		event.stopPropagation();
		let nuevofilter = new FilterModel();
		const searchModel = this.searchForm.value;
		if (searchModel.fecha_inicio && searchModel.fecha_inicio != "null") {
			nuevofilter.filter.push({
				atribute: "created_at",
				operator: ">=",
				value: searchModel.fecha_inicio
			});
		}
		if (searchModel.fecha_fin && searchModel.fecha_fin != "null") {
			nuevofilter.filter.push({
				atribute: "created_at",
				operator: "<=",
				value: searchModel.fecha_fin
			});
		}
		if (searchModel.moneda_origen_id && searchModel.moneda_origen_id != "null") {
			nuevofilter.filter.push({
				atribute: "moneda_origen_id",
				operator: "=",
				value: searchModel.moneda_origen_id
			});
		}
		if (searchModel.estado && searchModel.estado != "null") {
			nuevofilter.filter.push({
				atribute: "estado",
				operator: "=",
				value: searchModel.estado
			});
		}
		this.store.dispatch(CuentaAction.getHistorialIntercambio({filter: nuevofilter}));
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close(true);
      }, 250);
	}


}
