import { AfterViewInit, Component, OnInit } from "@angular/core";
import { WAddBalanceComponent } from "./w-add-balance/w-add-balance.component";
import { WAfilRetiroComponent } from "./w-afil-retiro/w-afil-retiro.component";
import { BalanceAfiliadosModel } from "../../../shared/models/balance-afiliados.model";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import * as AfiliadosActions from '../../../afiliados/redux/afiliados.actions';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

declare function adjustSize(): any;

@Component({
	selector: "app-afil-balances-retiros",
	templateUrl: "./afil-balances-retiros.component.html",
	styleUrls: ["./afil-balances-retiros.component.css"]
})
export class AfilBalancesRetirosComponent implements OnInit, AfterViewInit {
	allBalances: BalanceAfiliadosModel[];
	balanceAtivo: BalanceAfiliadosModel;

	constructor(
		private store: Store<AppState>,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.allBalances = [];
		this.balanceAtivo = null;

		this.store.select((state) => state).subscribe(state => {
			this.allBalances = state.afiliado.balances;
			this.balanceAtivo = state.afiliado.balanceActivo;
		});

		this.store.dispatch(AfiliadosActions.afiliadosGetBalances());
	}

	ngAfterViewInit() {
		adjustSize();
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT BALANCE ()
	onSelectBalance(i) {
		if (this.allBalances && this.allBalances.length > i) {
			this.store.dispatch(AfiliadosActions.afiliadosSetBlanceActive({ activeBalance: this.allBalances[i] }));
		} else {
			this.store.dispatch(AfiliadosActions.afiliadosSetBlanceActive({ activeBalance: null }));
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON WHITDRAW ()
	onWithdraw() {
		this.modalService.open(WAfilRetiroComponent);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ADD BALANCE ()
	onAddBalance() {
		 this.modalService.open(WAddBalanceComponent);
	}

}
