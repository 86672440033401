<input type="text" autofocus="autofocus" style="display:none"/>

<div class="w-cover coverB" id="w-cover-new_msg" [class.active]="wActive==true" (swipedown)="onCloseW()">
   <!-- -->

	<div class="window pt-1" id="new-ticket">
		<form [formGroup]="notasForm" (submit)="onSendMsg($event)">

			<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
			<div class="hidden-lanscape">
				<div class="btn-window-close" (click)="onCloseW()">
					<div class="btn-window-close-bar"></div>
				</div>
				<p class="w-title-cover txt-ssm txt-w tac mb-1">{{'NUEVO_MENSAJE' |translate}}</p>

				<div class="px-1">
					<div class="p-15 bg-g1">
						<p class="txt-gc mb-05">{{'SU_MENSAJE' |translate}}:</p>
						<textarea class="ta2 ta2-xlg full-w txt-w bg-k5 mb-1" formControlName="mensaje"></textarea>

                     <div class="col col-icon-sm">
                        <input type="file" class="inp2 inp2-sm inputfile" id="file-inp" name="file-inp" (change)="handle($event)">
                        <button type="button" class="btn2 btn2-1st btn2-sm btn2-sm-icon mr-1" (click)="fireFile($event)">
                           <img src="../../../../assets/images/icon-file-upload.svg">
                        </button>
                     </div>
                     <div class="col col-icon-sm-r">
                        <input type="text" class="inp2 inp2-sm full-w file-inp-txt" id="file-inp-txt" readonly placeholder="{{'SELECCIONE_DOCUMENTO'|translate}}">
                     </div>
                     <div class="cb"></div>

						<!-- PROGRESS -->
						<div class="progress-c">
							<ng-container *ngIf="progress > 0">
								<div class="progress-container">
									<div class="progress progress-t0">
										<div class="progress-bar" role="progressbar" [style.width]="progress+'%'"
											 attr.aria-valuenow="{{progress}}" aria-valuemin="0"
											 aria-valuemax="100">
										</div>
									</div>
								</div>
							</ng-container>
						</div>
						<!-- / PROGRESS -->

					</div>
				</div>
				

				<div class="btn2-actions px-2 pb-2">
					<div class="col col-6 pr-07">
						<button type="button" class="btn2 btn2-lg btn2-g full-w"
								(click)="onCancel($event)">{{'CANCELAR' |translate}}
						</button>
					</div>
					<div class="col col-6 pl-07">
						<button type="button" class="btn2 btn2-lg btn2-1st full-w" (click)="onSendMsg($event)">{{'ENVIAR' |translate}}
						</button>
					</div>
					<div class="cb"></div>
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

		</form>
	</div>

</div>
