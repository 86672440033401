<!-- class="active" -->
<div id="main-menu-cover" [class.active]="myMenu.show_menu_component" (swipedown)="onToggleMenu()">

	<div id="main-menu">


		<!-- - - - - - - - - - - - - - - - LANDSCAPE -->
		<div class="col col-23-1 br-g1 p-1 hidden-portrait">
			<div class="pt-05">
				<img src="./assets/images/bitwild-logo-w.svg">
			</div>

			<div class="copy txt-g6">

				<div class="btn btn-sm btn-rnd btn-4th pos-rel">
					<img src="../../../../assets/images/icon-help.svg" alt="ingresar">
				</div>
				<!--
            <div class="fr tar">
               &copy; 2020 BitWild<br>
               Versión 1.0.0
            </div>
            -->
			</div>
		</div>
		<div class="col col-27-1 hidden-portrait">
			<ul class="menu1 bg-g3 pt-secure">

				<li (click)="onSetM2($event,'inicio')" [class.active]="menu=='inicio'">
					{{ afiliadosMode ? 'JUGADOR' : 'HOME' | translate }}
				</li>
				<li (click)="onSetM2($event,'cajero')" [class.active]="menu=='cajero'" *ngIf="!afiliadosMode">
					{{ 'CAJERO' | translate }}
				</li>
				<li (click)="onSetM2($event,'bonos')" [class.active]="menu=='bonos'" *ngIf="!afiliadosMode">
					{{ 'BONOS' | translate }}
				</li>
				<li (click)="onSetM2($event,'notificaciones')" [class.active]="menu=='notificaciones'"
					*ngIf="!afiliadosMode">
					{{ 'NOTIFICACIONES' | translate }}
					<span class="notificacions tac" *ngIf="readNotifs() != 0">{{readNotifs()}}</span>
					<!-- *ngIf="notifications.length > 0" -->
				</li>
				<li (click)="onSetM2($event,'cuenta')" [class.active]="menu=='cuenta'" *ngIf="!afiliadosMode">
					{{ 'MI_CUENTA' | translate }}
				</li>
				<li (click)="onSetM2($event,'afiliados')" [class.active]="menu=='afiliados'" *ngIf="playerLogged()">
					{{ 'AFILIADOS' | translate }}
				</li>
				<li (click)="logOutAction()" class="li-4th">
					{{ 'CERRAR_SESION' | translate }}
				</li>
			</ul>
		</div>
		<div class="col col-6 hidden-portrait">

			<!-- - - - - - - - - - - - - - - - NOTIFICACIONES -->
			<ul class="menu2 tickets" [style]="menu!=='notificaciones' ? 'display: none' : ''">
				<div class="tickets-container">

					<p class="txt-lg txt-c1 mb-15">{{ 'NOTIFICACIONES' | translate }}</p>

					<div class="tickets-list of-y">

						<!-- SI NO HAY TICKETS -->
						<!-- <div *ngIf="notifications.length == 0" class="ticket empty">No hay notificaciones</div> -->

						<!-- SI HAY TICKETS -->
						<ng-container>
							<!-- *ngIf="notifications.length > 0" -->
							<ng-container *ngFor="let notif of notificationes">
								<div class="ticket" [style]="!notif.leido ? '' : 'background: none'"
									(click)="marcarComoLeida($event, notif.id)">{{notif.descripcion}}</div>
							</ng-container>
						</ng-container>

					</div>

				</div>
			</ul>
			<!-- - - - - - - - - - - - - - - - NOTIFICACIONES -->

			<!-- - - - - - - - - - - - - - - - CAJERO -->
			<ul class="menu2" [style]="menu!=='cajero' ? 'display: none' : ''">
				<li (click)="onGoM1($event,'wallet')" [class.menu-selected]="subMenu === 'wallet'">
					<img class="m-icon" src="../../../../assets/images/icon-m-wallet.svg">
					{{ 'WALLET' | translate }}
				</li>
				<li (click)="onGoM1($event,'exchanger')" [class.menu-selected]="subMenu === 'exchanger'">
					<img class="m-icon" src="../../../../assets/images/icon-m-exchanger.svg">
					{{ 'EXCHANGER' | translate }}
				</li>
				<li (click)="onGoM1($event,'verificacion')" [class.menu-selected]="subMenu === 'verificacion'">
					<img class="m-icon" src="../../../../assets/images/icon-m-verificacion.svg">
					{{ 'VERIFICACIONES' | translate }}
				</li>
				<li (click)="onGoM1($event,'retiros')" [class.menu-selected]="subMenu === 'retiros'">
					<img class="m-icon" src="../../../../assets/images/icon-m-retiro.svg">
					{{ 'RETIROS' | translate }}
				</li>
			</ul>
			<!-- - - - - - - - - - - - - - - - / CAJERO -->
			<!-- - - - - - - - - - - - - - - - BONOS -->
			<ul class="menu2" [style]="menu!=='bonos' ? 'display: none' : ''">
				<li (click)="onGoM1($event,'mis_bonos')" [class.menu-selected]="subMenu === 'mis_bonos'">
					<img class="m-icon" src="../../../../assets/images/icon-m-bonos.svg">
					{{ 'MIS_BONOS' | translate }}
				</li>
				<li (click)="onGoM1($event,'historial')" [class.menu-selected]="subMenu === 'historial'">
					<img class="m-icon" src="../../../../assets/images/icon-m-bonos-hist.svg">
					{{ 'HISTORIAL_BONOS' | translate }}
				</li>
			</ul>
			<!-- - - - - - - - - - - - - - - - / BONOS -->
			<!-- - - - - - - - - - - - - - - - CUENTA -->
			<ul class="menu2" [style]="menu!=='cuenta' ? 'display: none' : ''" id="m2Cuenta">
				<li (click)="onGoM1($event,'datos')" [class.menu-selected]="subMenu === 'datos'">
					<img class="m-icon" src="../../../../assets/images/icon-m-mov-datos.svg">
					{{ 'MIS_DATOS' | translate }}
				</li>
				<li (click)="onGoM1($event,'movimientos')" [class.menu-selected]="subMenu === 'movimientos'">
					<img class="m-icon" src="../../../../assets/images/icon-m-movimiento.svg">
					{{ 'HISTORIAL_DE_MOVIMIENTOS' | translate }}
				</li>
				<li (click)="onGoM1($event,'juegos')" [class.menu-selected]="subMenu === 'juegos'">
					<img class="m-icon" src="../../../../assets/images/icon-m-mov-juegos-hist.svg">
					{{ 'HISTORIAL_DE_JUEGOS' | translate }}
				</li>
				<li (click)="onGoM1($event,'movimientos_cripto')"
					[class.menu-selected]="subMenu === 'movimientos_cripto'">
					<img class="m-icon" src="../../../../assets/images/icon-m-mov-crypto.svg">
					{{ 'MOVIMIENTOS_CRYPTO' | translate }}
				</li>
				<li (click)="onShowTerms($event, 'terminos')" [class.menu-selected]="subMenu === 'terminos'">
					<img class="m-icon" src="../../../../assets/images/icon-m-terms.svg">
					{{ 'TERMINOS_CONDICIONES' | translate }}
				</li>
			</ul>
			<!-- - - - - - - - - - - - - - - - / CUENTA -->
			<!-- - - - - - - - - - - - - - - - AFILIADOS -->
			<ul class="menu2" [style]="menu!=='afiliados' ? 'display: none' : ''">
				<!-- menu2-5 -->
				<!-- NO REGISTRADO -->
				<li (click)="onNewAfAccount($event,'crear cuenta')" [class.menu-selected]="subMenu === 'crear cuenta'"
					*ngIf="!afiliadosMode">
					<!-- *ngIf="!showAfiliados()" -->
					<img class="m-icon" src="../../../../assets/images/icon-m-af-reg.svg">
					{{ 'CREA_TU_CUENTA' | translate }}
				</li>
				<!-- REGISTRADO -->
				<li (click)="onGoM1($event,'campañas')" [class.menu-selected]="subMenu === 'campañas'"
					*ngIf="afiliadosMode">
					<!-- *ngIf="showAfiliados()" -->
					<img class="m-icon" src="../../../../assets/images/icon-m-mov-campaign.svg">
					{{ 'CAMPANNAS' | translate }}
				</li>
				<li (click)="onGoM1($event,'trafico')" [class.menu-selected]="subMenu === 'trafico'"
					*ngIf="afiliadosMode">
					<!-- *ngIf="showAfiliados()" -->
					<img class="m-icon" src="../../../../assets/images/icon-m-mov-traffic.svg">
					{{ 'CONTROL_TRAFICO' | translate }}
				</li>
				<li (click)="onGoM1($event,'comisiones')" [class.menu-selected]="subMenu === 'comisiones'"
					*ngIf="afiliadosMode">
					<!-- *ngIf="showAfiliados()" -->
					<img class="m-icon" src="../../../../assets/images/icon-m-mov-comision.svg">
					{{ 'MIS_COMISIONES' | translate }}
				</li>
				<li (click)="onGoM1($event,'balances_retiros')" [class.menu-selected]="subMenu === 'balances_retiros'"
					*ngIf="afiliadosMode">
					<!-- *ngIf="showAfiliados()" -->
					<img class="m-icon" src="../../../../assets/images/icon-m-mov-balance-retiro.svg">
					{{ 'BALANCE' | translate }} / {{ 'RETIROS' | translate }}
				</li>
			</ul>
			<!-- - - - - - - - - - - - - - - - / AFILIADOS -->
			<!-- - - - - - - - - - - - - - - - AYUDA -->
			<ul *ngIf="false" class="menu2">
				<li>
					<img class="m-icon" src="../../../../assets/images/icon-m-ticket.svg">
					{{ 'TICKETS' | translate }}
				</li>
				<li>
					<img class="m-icon" src="../../../../assets/images/icon-m-call.svg">
					{{ 'SOLICITAR_LLAMADA' | translate }}
				</li>
				<li>
					<img class="m-icon" src="../../../../assets/images/icon-m-chat.svg">
					{{ 'CHAT' | translate }}
				</li>
			</ul>
			<!-- - - - - - - - - - - - - - - - / AYUDA -->

		</div>
		<!-- - - - - - - - - - - - - - - - / LANDSCAPE -->

		<!-- - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="btn-window-close" (click)="onToggleMenu()">
				<div class="btn-window-close-bar"></div>
			</div>
			<div class="pl-15 pb-05">
				<div class="btn-m-help" (click)="onSetM2($event,'ayuda')">
					<img src="../../../../assets/images/icon-m-help.svg" *ngIf="menu!='ayuda'">
					<img src="../../../../assets/images/icon-m-help-up.svg" *ngIf="menu=='ayuda'">
				</div>
			</div>


			<ul class="menu2">
				<!-- - - - - - - - - - - - - - - - HOME -->
				<!-- - - - - - - - - - - - - - - - / HOME -->
				<ng-container *ngIf="menu=='inicio'">
					<li class="liLogo"><img src="../../../../assets/images/bitwild-logo-k.svg"></li>
				</ng-container>
				<!-- - - - - - - - - - - - - - - - CAJERO -->
				<ng-container *ngIf="menu=='cajero'">
					<li (click)="onGoM1($event,'wallet')" [class.menu-selected]="subMenu === 'wallet'">
						{{ 'WALLET' | translate }}
					</li>
					<li (click)="onGoM1($event,'exchanger')" [class.menu-selected]="subMenu === 'exchanger'">
						{{ 'EXCHANGER' | translate }}
					</li>
					<li (click)="onGoM1($event,'verificacion')" [class.menu-selected]="subMenu === 'verificacion'">
						{{ 'VERIFICACIONES' | translate }}
					</li>
					<li (click)="onGoM1($event,'retiros')" [class.menu-selected]="subMenu === 'retiros'">
						{{ 'RETIROS_CASE' | translate }}
					</li>
				</ng-container>
				<!-- - - - - - - - - - - - - - - - / CAJERO -->
				<!-- - - - - - - - - - - - - - - - BONOS -->
				<ng-container *ngIf="menu=='bonos'">
					<li (click)="onGoM1($event,'mis_bonos')" [class.menu-selected]="subMenu === 'mis_bonos'">
						{{ 'MIS_BONOS' | translate }}
					</li>
					<li (click)="onGoM1($event,'historial')" [class.menu-selected]="subMenu === 'historial'">
						{{ 'HISTORIAL_BONOS' | translate }}
					</li>
				</ng-container>
				<!-- - - - - - - - - - - - - - - - / BONOS -->
				<!-- - - - - - - - - - - - - - - - CUENTA -->
				<ng-container *ngIf="menu=='cuenta'">
					<li (click)="onGoM1($event,'datos')" [class.menu-selected]="subMenu === 'datos'">
						{{ 'MIS_DATOS' | translate }}
					</li>
					<li (click)="onGoM1($event,'movimientos')" [class.menu-selected]="subMenu === 'movimientos'">
						<div class="px-1 tac">{{ 'HISTORIAL_DE_MOVIMIENTOS' | translate }}</div>
					</li>
					<li (click)="onGoM1($event,'movimientos_cripto')"
						[class.menu-selected]="subMenu === 'movimientos_cripto'">
						{{ 'MOVIMIENTOS_CRYPTO' | translate }}
					</li>
					<li (click)="onGoM1($event,'juegos')" [class.menu-selected]="subMenu === 'juegos'">
						{{ 'HISTORIAL_DE_JUEGOS' | translate }}
					</li>
				</ng-container>
				<!-- - - - - - - - - - - - - - - - / CUENTA -->

				<!-- - - - - - - - - - - - - - - - AFILIADOS -->
				<ng-container *ngIf="menu=='afiliados'">
					<!-- NO REGISTRADO -->
					<li (click)="onNewAfAccount($event,'crear cuenta')"
						[class.menu-selected]="subMenu === 'crear cuenta'" *ngIf="!afiliadoAccount()">
						{{ 'CREA_TU_CUENTA' | translate }}
					</li>
					<!-- NO REGISTRADO -->
					<li (click)="onGoM1($event,'campannas')" [class.menu-selected]="subMenu === 'campannas'"
						*ngIf="afiliadoAccount()">
						{{ 'CAMPANNAS' | translate }}
					</li>
					<li (click)="onGoM1($event,'trafico')" [class.menu-selected]="subMenu === 'trafico'"
						*ngIf="afiliadoAccount()">
						{{ 'CONTROL_TRAFICO' | translate }}
					</li>
					<li (click)="onGoM1($event,'comisiones')" [class.menu-selected]="subMenu === 'comisiones'"
						*ngIf="afiliadoAccount()">
						{{ 'COMISIONES' | translate }}
					</li>
					<li (click)="onGoM1($event,'balances_retiros')"
						[class.menu-selected]="subMenu === 'balances_retiros'" *ngIf="afiliadoAccount()">
						{{ 'BALANCE_RETIROS' | translate }}
					</li>
				</ng-container>
				<!-- - - - - - - - - - - - - - - - / AFILIADOS -->

				<!-- - - - - - - - - - - - - - - - AYUDA -->
				<ng-container *ngIf="menu=='ayuda'">
					<li class="liHelp">
						<ul class="menu3">
							<li (click)="onOpenTickets($event)">
								<img src="../../../../assets/images/icon-m-ticket.svg">
								{{ 'TICKETS' | translate }}
							</li>
							<li (click)="onCallRequest($event)">
								<img src="../../../../assets/images/icon-m-call.svg">
								{{ 'SOLICITAR_LLAMADA' | translate }}
							</li>
							<li (click)="onHelpChat($event)">
								<img src="../../../../assets/images/icon-m-chat.svg">
								{{ 'CHAT' | translate }}
							</li>
						</ul>
					</li>
				</ng-container>
				<!-- - - - - - - - - - - - - - - - / AYUDA -->
				<li class="liLinks">
					<span *ngIf="menu=='cuenta'" (click)="onShowTerms($event, '')">
						{{ 'TERMINOS_CONDICIONES' | translate }}
					</span>
				</li>
			</ul>


			<ul class="menu1 p-1">
				<li (click)="onSetM2($event,'inicio')" [class.active]="menu=='inicio'" *ngIf="!afiliadosMode">
					<img src="../../../../assets/images/icon-home.svg">
					{{ 'HOME' | translate }}
				</li>
				<li (click)="onSetM2($event,'cajero')" [class.active]="menu=='cajero'" *ngIf="!afiliadosMode">
					<img src="../../../../assets/images/icon-m-wallet.svg">
					{{ 'CAJERO' | translate }}
				</li>
				<li (click)="onSetM2($event,'bonos')" [class.active]="menu=='bonos'" *ngIf="!afiliadosMode">
					<img src="../../../../assets/images/icon-m-bonos.svg">
					{{ 'BONOS' | translate }}
				</li>
				<li (click)="onSetM2($event,'logout')" [class.active]="logoutSelected==true">
					<img src="../../../../assets/images/icon-logout.svg">
					{{ 'CERRAR_SESION' | translate	}}
				</li>
				<li (click)="onSetM2($event,'cuenta')" [class.active]="menu=='cuenta'" *ngIf="!afiliadosMode">
					<img src="../../../../assets/images/icon-m-mov-datos.svg">
					{{ 'MI_CUENTA' | translate }}
				</li>
				<li (click)="onSetM2($event,'inicio')" [class.active]="menu=='inicio'" *ngIf="afiliadosMode">
					<img src="../../../../assets/images/icon-player.svg">
					{{ 'JUGADOR' | translate }}
				</li>
				<li (click)="onSetM2($event,'afiliados')" [class.active]="menu=='afiliados'" *ngIf="playerLogged()">
					<img src="../../../../assets/images/icon-m-mov-afiliacion.svg">
					{{ 'MODO_AFILIADO' | translate }}
				</li>
			</ul>

		</div>
		<!-- - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>

</div>