<div class="full" id="home-user" (window:resize)="thisOnResize()">

	<!-- - - - - - - - - - - - - - - - HOME HEADER -->
	<div class="home-header"></div>
	<!-- - - - - - - - - - - - - - - - / HOME HEADER -->

	<!-- - - - - - - - - - - - - - - - HOME USER CONTAINER PORTRAIT -->
	<div class="home-user-container hidden-landscape noScrlBr">

		<!-- PROMOCIONES -->
		<div class="py-1 bg-k" *ngIf="bonos && bonos.length > 0">
			<p class="txt-snor txt-gc tac">{{'PROMOCIONES' |translate}}</p>
			<div class="home-promos-container noScrlBr" id="hPromos" (scroll)="galScroll('#hPromos')">
				<!-- (swipe)="onSwipe($event,'#hPromos')" -->
				<!-- -->
				<div class="img-empty"></div>
				<!-- -->
				<img *ngFor="let item of bonos; let i = index;" [src]="item.bono.imagen" id="img{{i}}"
					(click)="onGoBonos($event)" class="img" [class.in]="hPromosZoomIndex == i">
				<!-- -->
				<div class="img-empty"></div>
				<!-- -->
			</div>
		</div>
		<!-- / PROMOCIONES -->
		<!-- LAST PLAYED -->
		<div class="py-1" *ngIf="masJugados.length > 0">
			<p class="txt-snor txt-gc tac">{{'ULTIMOS_JUGADOS' |translate}}</p>
			<div class="home-games-container noScrlBr" id="mostPlayed" (scroll)="gameScroll('#mostPlayed')">
				<!-- (swipe)="onSwipe($event,'#mostPlayed')" -->
				<!-- -->
				<!-- <div class="img-empty"></div> -->
				<div class="img-empty"></div>
				<!-- [class.in]="masJugadoZoomIndex % 2 != 0 ? masJugadoZoomIndex == i || masJugadoZoomIndex - 1 == i:masJugadoZoomIndex == i" -->
				<ng-container *ngFor="let item of masJugados; let i = index;">
					<div class="game" [class.in]="masJugadoZoomIndex == i" (click)="onSelectLastPlayed($event, i)">
						<img [src]="item.imagen_logo_base64" class="img" [class.in]="masJugadoZoomIndex == i">
						<!-- GAME DETAILS-->
						<div class="game-cover" *ngIf="lastPlayedActive === i"
							(click)="onUnselectLastPlayed($event, i)">
							<div class="b-game b-info" (click)="onShowGameInfo($event, item)">
								<img src="../../../../assets/images/icon-info-lg-circle.svg">
							</div>
							<div class="b-game b-favorite" (click)="onSelectFav($event, item)">
								<img *ngIf="!item.favorito" src="../../../../assets/images/icon-favorite-lg.svg">
								<img *ngIf="item.favorito" src="../../../../assets/images/icon-favorite-red.svg">
							</div>
							<div class="b-game b-play" (click)="onGoPlay($event, item)">
								<img src="../../../../assets/images/icon-play-lg.svg">
							</div>
						</div>
						<!-- / GAME DETAILS-->
					</div>
				</ng-container>
				<!-- -->
				<div class="img-empty"></div>
				<!-- <div class="img-empty"></div> -->
				<!-- -->
			</div>
		</div>
		<!-- / LAST PLAYED -->
		<!-- DESTACADOS -->
		<div class="py-1 bg-k mb-2" *ngIf="destacados.length > 0">
			<p class="txt-snor txt-gc tac">{{'DESTACADOS' |translate}}</p>
			<div class="home-games-container noScrlBr" id="featuredGames" (scroll)="gameScroll('#featuredGames')">
				<!-- (swipe)="onSwipe($event,'#featuredGames')" -->
				<!-- -->
				<!-- <div class="img-empty"></div> -->
				<div class="img-empty"></div>
				<!-- -->
				<hg-container *ngFor="let item of destacados; let i = index;">
					<div class="game" [class.in]="destacadosZoomIndex == i" (click)="onSelectDestacado($event, i)">
						<img [src]="item.imagen_logo_base64" class="img" [class.in]="destacadosZoomIndex == i">
						<!-- GAME DETAILS-->
						<div class="game-cover" *ngIf="destacadoActive === i" (click)="onUnselectDestacado($event)">
							<div class="b-game b-info" (click)="onShowGameInfo($event, item)">
								<img src="../../../../assets/images/icon-info-lg-circle.svg">
							</div>
							<div class="b-game b-favorite" (click)="onSelectFav($event, item)">
								<img *ngIf="!item.favorito" src="../../../../assets/images/icon-favorite-lg.svg">
								<img *ngIf="item.favorito" src="../../../../assets/images/icon-favorite-red.svg">
							</div>
							<div class="b-game b-play" (click)="onGoPlay($event, item)">
								<img src="../../../../assets/images/icon-play-lg.svg">
							</div>
						</div>
						<!-- / GAME DETAILS-->
					</div>
				</hg-container>
				<!-- -->
				<div class="img-empty"></div>
				<!-- <div class="img-empty"></div> -->
				<!-- -->
			</div>
		</div>
		<!-- / DESTACADOS -->
		<!-- CRYPTO -->
		<div class="px-1">
			<div class="bg-g1 p-1 pb-3 mb-2">
				<p class="txt-snor txt-g8 tac mb-1">{{'INTERCAMBIA_CRYPTO_NOSOTROS' |translate}}</p>
				<table class="tbl2 tbl2-striped striped-alt tbl2-nor">
					<tr>
						<th></th>
						<th class="col-45 txt-nor">
							<span class="txt-w">{{monedasExchange[0] ?
												monedasExchange[0].fuente_referencial_nombre : "-"}}</span>
						</th>
						<th class="col-5 px-2">
							<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild" class="bwild-table">
						</th>
					</tr>
					<ng-container *ngFor="let item of monedasExchange; let i = index;">
						<tr *ngIf="i < 8">
							<td class="col-3 pr-0">
								<img src="../../../../assets/images/crypto/{{item.moneda_origen_siglas.toLowerCase()}}.svg"
									(error)="changeImgUrl($event, item.moneda_origen_imagen)" class="hcu-coin">
								<span class="coin-name txt-c2">{{item.moneda_origen_siglas.toUpperCase()}}</span>
							</td>
							<td class="tac txt-gc">
								{{item.valor_referencial | number: "1.2-2"}}
							</td>
							<td class="tac">
								{{item.moneda_destino_precio | number: "1.2-2"}}
							</td>
						</tr>
					</ng-container>
				</table>
				<p class="txt-xnor txt-c2 tac py-2">{{'MEJORES_TARIFAS_NOSOTROS' |translate}}</p>
				<div>
					<div class="tac">
						<button type="button" class="btn2 btn2-nor btn2-nor-icon btn2-2nd"
							routerLink="/cajero/exchanger">
							<img src="../../../../assets/images/icon-m-exchanger.svg">
							{{'CAMBIAR_AHORA' |translate}}
						</button>
					</div>
				</div>
			</div>
			<!-- / CRYPTO -->

			<div class="pb-05" (click)="onShowCircuit()">
				<img src="../../../../assets/images/mr_worldwild/mrww-home-portrait.jpg">
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - / HOME USER CONTAINER PORTRAIT -->
      <div class="cb"></div>
      <app-footer-jugador></app-footer-jugador>

	</div>

	<app-circuito></app-circuito>

	<!-- GAME INFO PROTRAIT -->
	<div id="game-port-home-cover" *ngIf="juegoActivo" [class.active]="wActive==true"
		(swipedown)="onHideGameInfoPort()">
		<div id="game-port-home-info">
			<div class="btn-window-close" (click)="onHideGameInfoPort()">
				<div class="btn-window-close-bar"></div>
			</div>
			<div class="game-info">

				<div>
					<span class="fav-indicator mr-1">
						<img src="../../../../assets/images/icon-favorite-lg.svg"></span>
					<span class="ilb txt-nor game-info-title">{{juegoActivo.nombre_publico}}</span>
					<div class="cb mb-05"></div>
					<img [src]="juegoActivo.imagen_juego">
				</div>

				<div>
					<div class="col col-6 pr-05">
						<span class="info-txt txt-g8 tar">{{'CATEGORIA_JUEGO' |translate}}:</span>
						<span class="info-txt txt-g8 tar">{{'LINEAS' |translate}}:</span>
						<span class="info-txt txt-g8 tar">{{'MIN_EQUIV_A' |translate}}:</span>
						<span class="info-txt txt-g8 tar">{{'MAX_EQUIV_A' |translate}}:</span>
						<span class="info-txt txt-g8 tar">{{'JACKPOT_EQUIV_A' |translate}}:</span>
					</div>
					<div class="col col-6 pl-05">
						<span class="info-txt ellipsis">{{juegoActivo.categoria_juego.nombre}}</span>
						<!-- gameSelected.categoria_juego.nombre -->
						<span class="info-txt">{{juegoActivo.lineas}}</span>
						<!-- gameSelected.lineas -->
						<span class="info-txt"><span class="txt-c4-l"> {{'USD'}}
							</span>{{juegoActivo.apuesta_minima | number: '1.2-2'}}</span>
						<!-- gameSelected.apuesta_minima | number: '1.2-2' -->
						<span class="info-txt"><span class="txt-c4-l"> {{'USD'}}
							</span>{{juegoActivo.apuesta_maxima | number: '1.2-2'}}</span>
						<!-- gameSelected.apuesta_maxima | number: '1.2-2' -->
						<span class="info-txt"><span class="txt-c4-l"> {{'USD'}}
							</span>{{juegoActivo.jackpot | number: '1.2-2'}}</span>
						<!-- gameSelected.jackpot | number: '1.2-2' -->
					</div>
					<div class="cb"></div>
				</div>

				<div>
					<div class="info-coin" *ngFor="let item of juegoActivo.moneda_ids; let i = index;">
						<ng-container *ngIf="getSiglasMonedas(item) != ''">
							<img [src]="'../../../../assets/images/crypto/' + getSiglasMonedas(item) + '.svg'"><br>
							<span class="ilb pt-02">{{getSiglasMonedas(item).toUpperCase()}}</span>
						</ng-container>
					</div>
				</div>

			</div>

			<div class="px-15 py-2 bg-k">
				<div class="col col-6 pr-07">
					<button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onHideGameInfoPort()">
						{{'CERRAR' |translate}}
					</button>
				</div>
				<div class="col col-6 pl-07">
					<button type="button" class="btn2 btn2-lg btn2-1st full-w"
						(click)="onGoPlay($event, juegoActivo)">{{'JUGAR' |translate}}
					</button>
				</div>
				<div class="cb"></div>
			</div>

		</div>
	</div>
	<!-- / GAME INFO PROTRAIT -->
</div>
