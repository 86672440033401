import {Action, createReducer, on} from "@ngrx/store";
import {HistorialMovimientosModel} from "../../shared/models/historial-movimientos.model";
import * as CuentaActions from "../../cuenta/redux/cuenta.actions";
import {ConceptoModel} from "../../shared/models/concepto.model";
import {DataWithExpireTime, DataWithFilter} from "../../shared/redux/general.reducers";
import {GeneralUtil} from "../../shared/utils/general.util";
import {FilterModel} from "../../shared/models/filter.model";
import {HistorialJuegosModel} from "../../shared/models/historial-juegos.model";
import {HistorialIntercambioModel} from "../../shared/models/historial-intercambio.model";
import {HistorialTransaccionesModel} from "../../shared/models/historial-transacciones.model";


export class CuentaState {
	movimientosHistorial: DataWithFilter<HistorialMovimientosModel>;
	juegosHistorial: DataWithFilter<HistorialJuegosModel>;
	intercambioHistorial: DataWithFilter<HistorialIntercambioModel>;
	transaccionesCryptoHistorial: DataWithFilter<HistorialTransaccionesModel>;
	conceptos: DataWithExpireTime<ConceptoModel>;
	switchVistaCrypto: number;

	constructor() {
		this.movimientosHistorial = {
			value: [],
			filter: new FilterModel()
		};
		this.juegosHistorial = {
			value: [],
			filter: new FilterModel()
		};
		this.intercambioHistorial = {
			value: [],
			filter: new FilterModel()
		};
		this.transaccionesCryptoHistorial = {
			value: [],
			filter: new FilterModel()
		};
		this.conceptos = {value: [], expire: 0};
		this.switchVistaCrypto = 0;
	}
}

const CuentaReducer = createReducer(
	new CuentaState,
	on(CuentaActions.getMovimientosCompletado, (state, {data}) => {
		let nuevoArray = [...state.movimientosHistorial.value];
		let nuevoFiltro = {...state.movimientosHistorial.filter};

		nuevoFiltro.total = data.total;
		nuevoFiltro.last_page = data.last_page;
		nuevoFiltro.page = data.current_page;
		nuevoFiltro.from = data.from;
		nuevoFiltro.to = data.to;

		const elementosCargados = data.to - data.from + 1;
		if (data.last_page == data.current_page && elementosCargados <= 5) {
			nuevoArray = nuevoArray.concat(data.data);
			nuevoFiltro.amount_last_page = elementosCargados;
		} else {
			nuevoArray = data.data;
			nuevoFiltro.amount_last_page = 0;
		}
		return {
			...state,
			movimientosHistorial: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(CuentaActions.getMovimientos, (state, {filter}) => ({
		...state,
		movimientosHistorial: GeneralUtil.setWithFilter(state.movimientosHistorial.value, filter),
	})),
	on(CuentaActions.getHistorialJuegoCompletado, (state, {data}) => {
		let nuevoArray = [...state.juegosHistorial.value];
		let nuevoFiltro = {...state.juegosHistorial.filter};
		nuevoFiltro.total = data.total;
		nuevoFiltro.last_page = data.last_page;
		nuevoFiltro.page = data.current_page;
		nuevoFiltro.from = data.from;
		nuevoFiltro.to = data.to;

		const elementosCargados = data.to - data.from + 1;
		if (data.last_page == data.current_page && elementosCargados <= 5) {
			nuevoArray = nuevoArray.concat(data.data);
			nuevoFiltro.amount_last_page = elementosCargados;
		} else {
			nuevoArray = data.data;
			nuevoFiltro.amount_last_page = 0;
		}
		return {
			...state,
			juegosHistorial: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(CuentaActions.getHistorialJuego, (state, {filter}) => ({
		...state,
		juegosHistorial: GeneralUtil.setWithFilter(state.juegosHistorial.value, filter),
	})),
	on(CuentaActions.getHistorialIntercambioCompletado, (state, {data}) => {
		let nuevoArray = [...state.intercambioHistorial.value];
		let nuevoFiltro = {...state.intercambioHistorial.filter};

		nuevoFiltro.total = data.total;
		nuevoFiltro.last_page = data.last_page;
		nuevoFiltro.page = data.current_page;
		nuevoFiltro.from = data.from;
		nuevoFiltro.to = data.to;

		const elementosCargados = data.to - data.from + 1;
		if (data.last_page == data.current_page && elementosCargados <= 5) {
			nuevoArray = nuevoArray.concat(data.data);
			nuevoFiltro.amount_last_page = elementosCargados;
		} else {
			nuevoArray = data.data;
			nuevoFiltro.amount_last_page = 0;
		}

		return {
			...state,
			intercambioHistorial: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(CuentaActions.getHistorialIntercambio, (state, {filter}) => ({
		...state,
		intercambioHistorial: GeneralUtil.setWithFilter(state.intercambioHistorial.value, filter),
	})),
	on(CuentaActions.getHistorialTransaccionesCryptoCompletado, (state, {data}) => {
		let nuevoArray = [...state.transaccionesCryptoHistorial.value];
		let nuevoFiltro = {...state.transaccionesCryptoHistorial.filter};

		nuevoFiltro.total = data.total;
		nuevoFiltro.last_page = data.last_page;
		nuevoFiltro.page = data.current_page;
		nuevoFiltro.from = data.from;
		nuevoFiltro.to = data.to;

		const elementosCargados = data.to - data.from + 1;
		if (data.last_page == data.current_page && elementosCargados <= 5) {
			nuevoArray = nuevoArray.concat(data.data);
			nuevoFiltro.amount_last_page = elementosCargados;
		} else {
			nuevoArray = data.data;
			nuevoFiltro.amount_last_page = 0;
		}

		return {
			...state,
			transaccionesCryptoHistorial: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(CuentaActions.getHistorialTransaccionesCrypto, (state, {filter}) => ({
		...state,
		transaccionesCryptoHistorial: GeneralUtil.setWithFilter(state.transaccionesCryptoHistorial.value, filter),
	})),
	on(CuentaActions.getConceptosCompletado, (state, {data}) => ({
		...state,
		conceptos: GeneralUtil.setWithExpiry(data.data, 10000000),/* 3h */
	})),
	on(CuentaActions.changeSwitchVistaCrypto, (state, {position}) => ({
		...state,
		switchVistaCrypto: position
	}))
);

export function reducer(state: CuentaState | undefined, action: Action) {
	return CuentaReducer(state, action);
}
