<div class="w-cover coverB" id="add-coin-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">
   <!-- -->

	<div class="window pt-1" id="add-coin">

		<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape full-h">
			<div class="btn-window-close" (click)="onCloseW()">
				<div class="btn-window-close-bar"></div>
			</div>
         <p class="w-title-cover txt-sm txt-w tac mb-1">{{'SELECCIONE_MONEDA_AGREGAR' |translate}}</p>

         <div class="add-coin-container of-y2 px-15">

            <!-- COIN -->
            <ng-container *ngFor="let curr of cryptoCurrency; let i = index;">
               <div (click)="onSelectCoin($event,curr)" [class.disabled]="currencyAdded(curr.id)" [class.selected]="selectedCurr && selectedCurr.id === curr.id" class="coin">
                  <div class="coin-icon">
                     <img class="" src="../../../../assets/images/crypto/{{curr.siglas.toLowerCase()}}.svg" (error)="changeImg($event, curr.imagen)">
                  </div>
                  <div class="coin-desc">
                     <span class="txt-nor">
                        <span class="txt-c2">{{curr.siglas.toUpperCase()}}</span><br>
                        {{curr.nombre}}</span>
                  </div>
               </div>
            </ng-container>
            <!-- / COIN -->

         </div>

         <div class="p-2">
            <div class="col col-6 pr-07">
               <button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCloseW()">{{'CANCELAR' |translate}}</button>
            </div>
            <div class="col col-6 pl-07">
               <button type="button" class="btn2 btn2-lg btn2-1st full-w" (click)="onAddSelectedCoin($event)">{{'AGREGAR' |translate}}</button>
            </div>
            <div class="cb"></div>
         </div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->


	</div>

</div>
