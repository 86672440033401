import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
	selector: "[appBillingAddress]"
})
export class BillingAddressDirective {

	constructor(private _el: ElementRef) {
	}


	@HostListener("input", ["$event"]) onInputChange(event) {
		const initialValue = this._el.nativeElement.value;

		if (initialValue.length === 1) {
			this._el.nativeElement.value = initialValue.replace(/[^a-zA-Z0-9,#\/]*/g, "");
			if (initialValue !== this._el.nativeElement.value) {
				event.stopPropagation();
			}
		} else {
			this._el.nativeElement.value = initialValue.replace(/[^a-zA-Z0-9,#\/\s]*/g, "");
			if (initialValue !== this._el.nativeElement.value) {
				event.stopPropagation();
			}
		}
	}
}
