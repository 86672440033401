import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FilterModel} from "../../../shared/models/filter.model";
import {Subscription} from "rxjs";
import {HistorialJuegosModel} from "../../../shared/models/historial-juegos.model";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";
import {Store} from "@ngrx/store";
import {AppState} from "src/app/shared/redux/general.reducers";
import * as CuentaAction from "../../redux/cuenta.actions";
import * as GamesAction from "../../../games/redux/games.actions";
import {Actions, ofType} from "@ngrx/effects";

declare function adjustSize(): any;

@Component({
	selector: "app-cta-games",
	templateUrl: "./cta-games.component.html",
	styleUrls: ["./cta-games.component.css"]
})
export class CtaGamesComponent implements OnInit, OnDestroy, AfterViewInit {

	juegosHistorial: HistorialJuegosModel[];
	subscription = new Subscription();
	filter: FilterModel;
	recargar: boolean = true;
	@ViewChild('divContenerdor') divContenerdor: any;

	constructor(
		private store: Store<AppState>,
		private actions$: Actions,
	) {
	}

	ngOnInit(): void {
		this.filter = new FilterModel();
		this.juegosHistorial = [];
		this.subscription.add(
			this.store.subscribe((state) => {
				this.filter = state.cuenta.juegosHistorial.filter;
				this.juegosHistorial = state.cuenta.juegosHistorial.value;
			})
		)

		this.subscription = this.actions$
			.pipe(ofType(CuentaAction.getHistorialJuegoCompletado))
			.subscribe(response => {
				this.recargar = true;
				if (this.divContenerdor) {
					if (this.filter.amount_last_page != 0) {
						setTimeout(
							() => {
								this.divContenerdor.nativeElement.scrollLeft = this.divContenerdor.nativeElement.scrollLeft + 200;
							}, 350
						);
					} else {
						this.divContenerdor.nativeElement.scrollTop = 20;
						this.divContenerdor.nativeElement.scrollLeft = 20;
					}
				}
			});
		this.initData();

	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	ngAfterViewInit() {
		adjustSize();
	}


	initData() {
		this.getCryptoCurrency();
		this.getGamesCategories();
		this.getHistorialJuego();
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SHOW TOOLTIP ()
	showTooltip(event, t) {
		event.stopPropagation();
		const tt = event.target as HTMLDivElement;
		tt.classList.add("active");
		setTimeout(() => {
			tt.classList.remove("active");
		}, t * 1000);
	}

	onScroll() {
		if (this.filter.last_page != this.filter.page) {
			this.getHistorialJuego("scroll+");
		}
	}

	onScrollUp() {
		if (this.filter.page != 1) {
			this.getHistorialJuego("scroll-");
		}
	}


	// API CALLS


	getHistorialJuego(tipo = "primero") {
		let nuevoFiltro = new FilterModel();
		if (tipo != "primero") {
			nuevoFiltro = {...this.filter};
		}
		if (tipo == "scroll+") {
			nuevoFiltro.page++;
		}
		if (tipo == "scroll-") {
			nuevoFiltro.page--;
		}
		if (this.filter.amount_last_page != 0 && this.filter.page > 2) {
			nuevoFiltro.page--;
		}
		if (this.recargar) {
			this.recargar = false;
			this.store.dispatch(CuentaAction.getHistorialJuego({filter: nuevoFiltro}));
		}
	}

	getGamesCategories() {
		this.store.dispatch(GamesAction.getSoloClasificaciones());
	}

	getCryptoCurrency() {
		this.store.dispatch(CajeroAction.getCryptoMonedas())
	}
}
