import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {apiRoutes} from "../../api-routing";

@Injectable({
	providedIn: "root"
})
export class AuthenticationService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	postRegister(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugador_registro_crypto);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	getReSendEmail(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_login_reenviar_codigo_confirmacion + "?email=" + param.data.email);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postLoginLaravel(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_login);
		const response = this.httpClient.post(url, param.data);
		return response.pipe(map((data: any) => data));
	}

	postLoginFacebook(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_login_facebook);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	getLogOut(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_logout);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getUserData(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_obtener_usuario_logueado);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getUserDataFirstTime(param: any) {
		const url = this.buildURL(apiRoutes.r_frontend_obtener_usuario_logueado_primera_vez);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data.data));
	}

}
