<div class="full-h" id="contacto">
	<form class="full-h pos-rel" [formGroup]="accountForm">
		<!-- <div class="flex-col"> -->

		<div class="pt-1 mb-2">

			<p class="txt-c4-l mb-03">{{ 'TELEFONO_FIJO' | translate }}</p>
			<div class="col col-4">
				<div class="pos-rel">
					<div [class.disabled]="edit==false" class="sel sel-sm sel-1st">
						<select class="sel-sm full-w ff-cnd" formControlName="country_Fijo">
							<option disabled selected value="null">{{ 'PAIS' | translate }}</option>
							<option *ngFor="let country of countries" [value]="country.codigo_telefonico">
								+{{country.codigo_telefonico}} - {{country.tx_iso2}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col col-3 pl-1">
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w tac"
					placeholder="{{ 'AREA' | translate }}" type="text" formControlName="area_Fijo" appDecimalDirective
					[maxLength]="3">
			</div>
			<div class="col col-5 pl-1">
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w"
					placeholder="{{ 'NUMERO' | translate }}" type="text" formControlName="number_Fijo"
					appDecimalDirective [maxLength]="7">
			</div>
			<div class="cb mb-15"></div>
			<p class="txt-c4-l mb-03">{{ 'TELEFONO_CELULAR' | translate }}</p>
			<div class="col col-4">
				<div class="pos-rel">
					<div [class.disabled]="edit==false" class="sel sel-sm sel-1st">
						<select class="sel-sm full-w ff-cnd" formControlName="country_Mobile">
							<option disabled selected value="null">{{ 'PAIS' | translate }}</option>
							<option *ngFor="let country of countries" [value]="country.codigo_telefonico">
								+{{country.codigo_telefonico}} - {{country.tx_iso2}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col col-3 pl-1">
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w tac" placeholder="{{ 'AREA' | translate }}"
					type="text" formControlName="area_Mobile" appDecimalDirective [maxLength]="3">
			</div>
			<div class="col col-5 pl-1">
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w" placeholder="{{ 'NUMERO' | translate }}" type="text"
					formControlName="number_Mobile" appDecimalDirective [maxLength]="7">
			</div>
			<div class="cb"></div>
		</div>

		<div class="tar">
			<button (click)="onEdit()" *ngIf="edit==false" class="btn2 btn2-4th btn2-lg btn2-lg-icon" type="button">
				<img class="icon-full" src="../../../../assets/images/icon-b-edit-w.svg">
			</button>

			<ng-container *ngIf="edit==true">
				<button (click)="onSave()" class="btn2 btn2-1st btn2-1st-1st btn2-lg btn2-lg-icon mr-1"
					[disabled]="accountForm.invalid" [style]="accountForm.invalid ? 'background-color:gray':''"
					type="button">
					<img class="icon-full" src="../../../../assets/images/icon-b-save.svg">
				</button>
				<button (click)="onDiscard()" class="btn2 btn2-7th btn2-lg btn2-lg-icon" type="button">
					<img class="icon-full" src="../../../../assets/images/icon-b-discard.svg">
				</button>
			</ng-container>
		</div>

		<!-- </div> -->
		<div class="cb"></div>
	</form>
</div>