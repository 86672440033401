<div class="w-cover" id="w-cover-ww-terms">

	<div class="window pt-1" id="w-ww-terms">
      <div class="w-title mb-1">Términos y Condiciones</div>
		<div class="pl-2 pr-05 full-h">
<div class="container txt-gc taj full-h pl-2 pr-3">
			<ol>
            <li class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tortor ut ornare. Cras interdum nunc dictum massa varius commodo. Vivamus ultrices, eros maximus faucibus consequat, mauris est euismod mauris, quis mollis libero nisi ut nisl. Fusce ut libero risus. Nullam nec pharetra enim. Nam eget hendrerit erat. Integer consequat nisl massa, id tincidunt libero consequat sit amet. Cras ut feugiat eros. Sed quis nibh nisi. Sed eu diam porttitor, eleifend ex ac, imperdiet lacus. Donec lobortis tincidunt orci, ac consectetur libero lobortis a. Phasellus vitae aliquam elit, tristique faucibus libero. In porttitor, nibh vitae ultrices luctus, elit tellus cursus mauris, at aliquet risus nunc iaculis ipsum. Curabitur ante nisi, semper eu velit quis, porta vulputate tortor. Ut porttitor eleifend eleifend. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.</li>
            <li class="mb-1">Praesent eu tempus velit, vitae sodales odio. Integer egestas condimentum enim, fermentum commodo leo euismod a. Pellentesque fermentum neque pharetra mauris venenatis, vel finibus arcu dapibus. Vestibulum euismod porttitor elit, non lobortis mi pellentesque et. Nunc cursus, neque ac consequat malesuada, elit mi laoreet enim, sed feugiat nisi risus at sapien. Proin at quam vel arcu sollicitudin euismod. Aliquam nec dui consectetur, luctus lectus in, feugiat quam. Aliquam porta diam tellus, a ullamcorper sem hendrerit facilisis. Vestibulum hendrerit velit nulla, a tincidunt velit malesuada non. Curabitur ut efficitur dui. Cras viverra consequat hendrerit. Suspendisse pretium ante in ipsum scelerisque, eu facilisis sapien faucibus.</li>
            <li class="mb-1">Aliquam erat volutpat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus quis malesuada nunc. Donec pharetra erat risus, efficitur posuere ipsum ullamcorper et. Cras et blandit ex. Quisque molestie aliquam risus, et maximus velit viverra vel. Nam auctor, tortor sit amet convallis ultrices, magna orci mattis nulla, ut ornare libero nisl luctus orci. Sed sit amet euismod nunc.</li>
            <li class="mb-1">Quisque blandit dapibus sapien, sed blandit ex dapibus eu. Quisque orci erat, porta vel fringilla non, facilisis et nibh. Aliquam erat volutpat. Ut vitae blandit tellus. Proin dignissim lorem vel dui egestas, at ultrices felis egestas. Quisque in fringilla lacus, nec ultricies dui. Pellentesque congue urna nisl, at scelerisque nisl iaculis ut. In viverra, sem at blandit rutrum, sapien ipsum sodales massa, at laoreet augue libero ut enim.</li>
            <li class="mb-1">Suspendisse fermentum semper gravida. Suspendisse malesuada libero ac varius semper. Nullam vehicula ipsum et tincidunt pellentesque. Maecenas finibus nunc eget ipsum malesuada placerat. Aenean blandit lorem nec facilisis porta. Nunc quis justo sed nisl luctus pellentesque. Mauris commodo massa sit amet metus malesuada malesuada.</li>
            <li class="mb-1">Curabitur et vehicula massa. Praesent dignissim nunc et sollicitudin cursus. Praesent lacinia ligula vel magna aliquet, non faucibus sem semper. Integer tincidunt, mauris et molestie lacinia, ipsum massa euismod elit, non mattis lacus metus vel nunc. Donec iaculis accumsan bibendum. Nullam ligula dui, viverra vitae sapien id, faucibus tempus mauris. In commodo eleifend arcu, vitae hendrerit erat tincidunt eget. Nunc non elementum lorem.</li>
            <li>Pellentesque eget lacinia erat, vel fermentum tortor. Vestibulum cursus, arcu in imperdiet congue, lectus ante commodo ligula, eget posuere erat quam ac mi. Ut cursus sodales condimentum. Curabitur tempor non libero sit amet dignissim. Etiam eget sem eget nisi blandit imperdiet. Praesent sagittis tristique lorem, molestie maximus ipsum iaculis vel. Phasellus quis lacus rutrum, lobortis magna ac, volutpat nisi. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam fermentum commodo lacinia. Cras sodales ante tempus lacus elementum, et ornare orci commodo. Sed fringilla ipsum orci, sit amet convallis augue ornare fermentum.</li>
         </ol>
      </div>

		</div>

      <button (click)="onCloseW($event)" class="btn btn-w-close" type="button">
			<img src="../../../../assets/images/icon-b-close.svg">
		</button>
	</div>
</div>
