<div class="full-h" id="password">
	<form class="full-h pos-rel" [formGroup]="accountForm">

        <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
      <div class="formSection hidden-landscape">
         <p class="txt-sm txt-gc mb-03">Contraseña Actual</p>
         <input class="inp2 inp2-nor inp2-1st full-w tac mb-1" [style.border-color]="inputTouched('password') ? 'red' : ''" formControlName="password" type="password" value="password">
         <p class="txt-sm txt-gc mb-03">Nueva Contraseña</p>
         <input class="inp2 inp2-nor inp2-1st full-w tac mb-1" [style.border-color]="inputTouched('passwordNew') ? 'red' : ''" formControlName="passwordNew" type="password" value="password">
         <p class="txt-sm txt-gc mb-03">Repetir Contraseña</p>
         <input class="inp2 inp2-nor inp2-1st full-w tac mb-2" [style.border-color]="inputTouched('passwordNewConfirmation') ? 'red' : ''" formControlName="passwordNewConfirmation" type="password" value="password">

         <div class="tar">
            <div class="edit">
               <button type="button" class="btn2 btn2-1st btn2-1st-1st btn2-lg btn2-lg-icon mr-1" #save2 (click)="onSaveAccordion(save2)">
                  <img src="../../../../assets/images/icon-b-save-w.svg">
               </button>
               <button type="button" class="btn2 btn2-7th btn2-lg btn2-lg-icon" #cancel1 (click)="onCancelAccordion(cancel1)">
                  <img src="../../../../assets/images/icon-b-discard-w.svg">
               </button>
            </div>
            <div class="edit active">
               <button type="button" class="btn2 btn2-4th btn2-lg btn2-lg-icon" #editAcc1 (click)="onEditAccordion(editAcc1)">
                  <img src="../../../../assets/images/icon-b-edit-w.svg">
               </button>
            </div>
         </div>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</form>
</div>
