
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as AuthenticationActions from "../../authetication/redux/authentication.actions";
import {AppState} from "./general.reducers";


export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return localStorageSync({ keys: ['general', 'auth', 'bonos', 'games', 'cajero', 'afiliado', 'cuenta'], 'rehydrate': true })(reducer);
}

export function clearState(reducer) {
	return function (state, action) {
		return reducer(state, action);
	};
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, clearState];
