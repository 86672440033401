import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
	providedIn: "root"
})
export class FileUploadService {

	private api: string = environment.apiUrl;

	constructor(private http: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	addFile(params:any): Observable<any> {
		const formData: any = new FormData();
		formData.append("modelo", params.model);
		formData.append("_method", "POST");
		formData.append("file", params.file, params.filename);

		const url = this.buildURL(`/frontend/uploads`);

		return this.http.post(url, formData, {
			reportProgress: true,
			observe: "events"
		}).pipe(
			catchError(this.errorMgmt)
		);
	}

	errorMgmt(error: HttpErrorResponse) {
		let errorMessage = "";
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
			return throwError(errorMessage);
		} else {
			// Get server-side error
			// errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
			return throwError(error);
		}
	}
}
