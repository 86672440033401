import {createAction, props} from '@ngrx/store';
import { WalletGeneralModel } from 'src/app/shared/models/wallet-general.model';
import { FilterModel} from "../../shared/models/filter.model";

export const walletsAuthCompletado = createAction('[Cajero] Wallets Auth Completado', props<{ data: any }>());
export const getCryptoMonedas = createAction('[Cajero] Get Crypto Monedas');
export const getCryptoMonedasCompletado = createAction('[Cajero] Get Crypto Monedas Completado', props<{ data: any }>());
export const getHashDeposito = createAction('[Cajero] Get Hash Deposito', props<{ moneda_id: number }>());
export const getHashDepositoCompletado = createAction('[Cajero] Get Hash Deposito Completado', props<{ data: any }>());
export const getWallets = createAction('[Cajero] Get Wallets');
export const getWalletsCompletado = createAction('[Cajero] Get Wallets Completado', props<{ data: any }>());
export const getExchangerTableHome = createAction('[Cajero] Get Exchanger Table Home');
export const getExchangerTableHomeCompletado = createAction('[Cajero] Get Exchanger Table Home Completado', props<{ data: any }>());
export const cambioMonedaActiva = createAction('[Cajero] Cambio Moneda Activa', props<{ moneda_id: number }>());
export const cambioMonedaActivaCompletado = createAction('[Cajero] Cambio Moneda Activa Completado', props<{ data: any }>());
export const cajeroLogoutCompletado = createAction('[Cajero] Logout Completado');
export const getUtilidadesPorMoneda = createAction('[Cajero] Get utilidades Por Moneda', props<{ id: number }>());
export const getUtilidadesPorMonedaCompletado = createAction('[Cajero] Get utilidades Por Moneda Completado', props<{ data: any }>());
export const getAllCryptoCurrenciesInMonedaBase = createAction('[Cajero] Get all CryptoCurrencies in Moneda Base');
export const getAllCryptoCurrenciesInMonedaBaseCompletado = createAction('[Cajero]Get all CryptoCurrencies in Moneda Base Completado', props<{ data: any }>());
export const postExchanger = createAction('[Cajero] Post Exchanger', props<{ param: any }>());
export const postExchangerCompletado = createAction('[Cajero] Post Exchanger Completado', props<{ data: any }>());

export const addNewWallet = createAction('[Cajero] Add New Wallet', props<{ param: any }>());
export const addNewWalletCompletado = createAction('[Cajero] Add New Wallet Completado', props<{ data: any }>());
export const deleteWallet = createAction('[Cajero] Delete Wallet', props<{ param: any }>());
export const deleteWalletCompletado = createAction('[Cajero] Delete Wallet Completado', props<{ data: any }>());
export const addSolicitudRetiro = createAction('[Cajero] Add Solicitud de Retiro', props<{ param: any }>());
export const addSolicitudRetiroCompletado = createAction('[Cajero] Add Solicitud de Retiro Completado', props<{ data: any }>());

export const getSolicitudesRetiro = createAction('[Cajero] Get Solicitudes de Retiro', props<{ filter: FilterModel }>());
export const getSolicitudesRetiroCompletado = createAction('[Cajero] Get Solicitudes de Retiro Completado', props<{ data: any }>());

export const cancelarSolicitudRetiro = createAction('[Cajero] Cancelar Solicitudes de Retiro', props<{ param: any }>());
export const cancelarSolicitudRetiroCompletado = createAction('[Cajero] Cancelar Solicitudes de Retiro Completado', props<{ data: any }>());

export const pusherEventCambioBalance = createAction('[Cajero] Pusher Event Cambio de Balance', props<WalletGeneralModel>());

export const getDocumentoJugador = createAction('[Cajero] Get Documentos Jugador');
export const getDocumentoJugadorCompletado = createAction('[Cajero] Get Documentos Jugador Completado', props<{ data: any }>());

export const postDocumentoJugador = createAction('[Cajero] Post Documentos Jugador', props<{ params: any }>());
export const postDocumentoJugadorCompletado = createAction('[Cajero] Post Documentos Jugador Completado', props<{ data: any }>());

export const deleteDocumentoJugador = createAction('[Cajero] Delete Documentos Jugador', props<{ param: any }>());
export const deleteDocumentoJugadorCompletado = createAction('[Cajero] Delete Documentos Jugador Completado', props<{ data: any }>());


