import {Component, OnInit} from "@angular/core";

@Component({
	selector: "app-idiomas",
	templateUrl: "./idiomas.component.html",
	styleUrls: ["./idiomas.component.css"]
})
export class IdiomasComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}

}
