import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {MonedaModel, WalletCurrencyModel} from "../../../shared/models/wallet-currency.model";
import {SessionServices} from "../../../shared/services/session.services";
import {NotificationService} from "../../../shared/services/notification.service";

import Swal from "sweetalert2";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {Subscription} from "rxjs";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import * as CajeroAction from '../../../cajero/redux/cajero.actions';
import {Actions, ofType} from "@ngrx/effects";

declare function adjustSize(): any;

@Component({
	selector: "app-w-add-coin",
	templateUrl: "./w-add-coin.component.html",
	styleUrls: ["./w-add-coin.component.css"]
})
export class WAddCoinComponent implements OnInit, OnDestroy {

	walletCurrency: WalletGeneralModel[];
	cryptoCurrency: MonedaModel[];

	selectedCurr: MonedaModel;
   subscription = new Subscription();

   wActive:boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		public sessionService: SessionServices,
		private notification: NotificationService,
		private store: Store<AppState>,
		private actions$: Actions,
	) {
	}

	ngOnInit(): void {
		this.subscription.add(
			this.store.subscribe(state => {
				this.walletCurrency = state.cajero.wallets;
				this.cryptoCurrency = state.cajero.cryptoMonedas.value;
			})
      )
      setTimeout(() => { this.wActive = true; }, 0);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	currencyAdded(currID) {
		for (const curr of this.walletCurrency) {
			if (curr.moneda.id === currID) {
				return true;
			}
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close(false);
         adjustSize();
      }, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ADD THIS COIN ()
	onSelectCoin(event, curr) {
		event.stopPropagation();
		this.selectedCurr = curr;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
      event.stopPropagation();
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ADD SELECTED COIN ()
	onAddSelectedCoin(event) {
		event.stopPropagation();

		const param = {
			data: {
				jugador_id: this.sessionService.getPlayer().id,
				moneda_id: this.selectedCurr.id
			}
		};

      this.store.dispatch(CajeroAction.addNewWallet({param: param.data}));
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close(true);
      }, 250);
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

}
