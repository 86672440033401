import {Routes} from "@angular/router";
import {CtaCryptoComponent} from "./components/cta-crypto/cta-crypto.component";
import {CuentaComponent} from "./components/cuenta/cuenta.component";
import {CtaDatosComponent} from "./components/cta-datos/cta-datos.component";
import {CtaGamesComponent} from "./components/cta-games/cta-games.component";
import {CtaMovComponent} from "./components/cta-mov/cta-mov.component";
import {VerifiedGuard} from "../home/guards/verified.guard";

export const CuentaRoutes: Routes = [
	{
		path: "", component: CuentaComponent,
		children: [
			{path: "", redirectTo: "datos", pathMatch: "full"},
			{path: "datos", component: CtaDatosComponent,canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]},
			{
				path: "movimientos",
				component: CtaMovComponent,
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			},
			{
				path: "juegos",
				component: CtaGamesComponent,
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			},
			{
				path: "movimientos_cripto",
				component: CtaCryptoComponent,
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			}
		]
	}
];
