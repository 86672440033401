import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {JugadorModel} from "../../../../shared/models/user.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CuentaService} from "../../../services/cuenta.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/redux/general.reducers";
import {Subscription} from "rxjs";
import * as CuentaAction from "../../../redux/cuenta.actions";
import * as countriesJSON from "../../../../../assets/json/countries.json";
import {CountryModel} from "../../../../shared/models/country.model";

@Component({
	selector: "app-tab-datos-contacto",
	templateUrl: "./tab-datos-contacto.component.html",
	styleUrls: ["./tab-datos-contacto.component.css"]
})
export class TabDatosContactoComponent implements OnInit, OnDestroy {

	account: JugadorModel;

	accountForm: FormGroup;
	countries: CountryModel[];
	edit: boolean;

	emailRegex = /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-zA-Z]{2,9}$/;
	subscription = new Subscription();

	constructor(
		private cuentaService: CuentaService,
		private formBuilder: FormBuilder,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.edit = false;
		this.countries = countriesJSON.countries;
		this.subscription.add(this.store.subscribe((state) => {
			this.account = state.auth.player;
		}))
		this.initForm();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initForm() {
		const mobile = this.account.telefono_celular;
		const fijo = this.account.telefono_fijo;
		this.accountForm = this.formBuilder.group(
			{
				country_Mobile: new FormControl(mobile ? mobile.split("-")[0] : null, [Validators.required]),
				area_Mobile: new FormControl(mobile ? mobile.split("-")[1] : null, [Validators.required]),
				number_Mobile: new FormControl(mobile ? mobile.split("-")[2] : null, [Validators.required]),
				country_Fijo: new FormControl(fijo ? fijo.split("-")[0] : null, [Validators.required]),
				area_Fijo: new FormControl(fijo ? fijo.split("-")[1] : null, [Validators.required]),
				number_Fijo: new FormControl(fijo ? fijo.split("-")[2] : null, [Validators.required]),
			}
		);
		this.accountForm.disable();
	}

	inputTouched(formName: string) {
		return this.accountForm.controls[formName].invalid && (this.accountForm.controls[formName].dirty || this.accountForm.controls[formName].touched);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON EDIT ()
	onEdit() {
		this.edit = true;
		this.accountForm.enable();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SAVE ()

	onSave() {
		if (this.accountForm.valid) {
			const param = {
				telefono_celular: this.accountForm.value.country_Mobile + "-" + this.accountForm.value.area_Mobile + "-" + this.accountForm.value.number_Mobile,
				telefono_fijo: this.accountForm.value.country_Fijo + "-" + this.accountForm.value.area_Fijo + "-" + this.accountForm.value.number_Fijo,
				paso: 2
			};
			this.patchAccountDataCrypto(param);
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DISCARD ()
	onDiscard() {
		const mobile = this.account.telefono_celular;
		const fijo = this.account.telefono_fijo;
		this.accountForm.reset(
			{
				country_Mobile: {value: mobile ? mobile.split("-")[0] : null, disabled: true},
				area_Mobile: {value: mobile ? mobile.split("-")[1] : null, disabled: true},
				number_Mobile: {value: mobile ? mobile.split("-")[1] : null, disabled: true},
				country_Fijo: {value: fijo ? fijo.split("-")[0] : null, disabled: true},
				area_Fijo: {value: fijo ? fijo.split("-")[1] : null, disabled: true},
				number_Fijo: {value: fijo ? fijo.split("-")[2] : null, disabled: true}
			}
		);

		this.edit = false;
	}


	patchAccountDataCrypto(param) {
		this.store.dispatch(CuentaAction.patchJugadorFiat({params: param}));
		this.edit = false;
		this.accountForm.disable();
	}

}
