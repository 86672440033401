import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MonedaModel} from "../../../shared/models/wallet-currency.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {FilterModel} from "../../../shared/models/filter.model";
import {ConceptoModel} from "../../../shared/models/concepto.model";
import {Subscription} from "rxjs";
import * as CuentaAction from "../../redux/cuenta.actions";

import { fixAndroid } from '../../../shared/utils/general.util';
declare function adjustSize(): any;

@Component({
	selector: "app-w-f-cta-games",
	templateUrl: "./w-f-cta-games.component.html",
	styleUrls: ["./w-f-cta-games.component.css"]
})
export class WFCtaGamesComponent implements OnInit {

	searchForm: FormGroup;
	cryptoCurrency: MonedaModel[];
	categoriasVisuales: any[];
	filter: FilterModel;
	subscription = new Subscription();
	fechaInicio: string = '';
   fechaFin: string = '';

   wActive:boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private store: Store<AppState>,
		private formBuilder: FormBuilder,
	) {
	}

	ngOnInit(): void {
		this.subscription.add(
			this.store.subscribe((state) => {
				this.categoriasVisuales = state.games.clasificacionesVisuales.value;
				this.cryptoCurrency = state.cajero.cryptoMonedas.value;
				this.filter = state.cuenta.juegosHistorial.filter;
			})
		)
      this.initFilter();
      setTimeout(() => { this.wActive = true; }, 0);
      setTimeout(() => { fixAndroid('filtro-cta-games-cover'); }, 250);
	}

	initFilter() {
		let clasificacion_visual_juego_id = '';
		let moneda_id = null;
		let concepto_id = null;
		let fecha_inicio = '';
		let fecha_fin = '';
		for (var i = 0; i < this.filter.filter.length; i++) {
			if (this.filter.filter[i]['atribute'] == 'clasificacion_visual_juego_id') {
				clasificacion_visual_juego_id = this.filter.filter[i]['value'];
			}
			if (this.filter.filter[i]['atribute'] == 'moneda_id') {
				moneda_id = this.filter.filter[i]['value'];
			}
			if (this.filter.filter[i]['atribute'] == 'concepto_id') {
				concepto_id = this.filter.filter[i]['value'];
			}
			if (this.filter.filter[i]['atribute'] == 'created_at' && this.filter.filter[i]['operator'] == '>=') {
				fecha_inicio = this.filter.filter[i]['value'];
			}
			if (this.filter.filter[i]['atribute'] == 'created_at' && this.filter.filter[i]['operator'] == '<=') {
				fecha_fin = this.filter.filter[i]['value'];
			}
		}

		this.searchForm = this.formBuilder.group(
			{
				clasificacion_visual_juego_id: new FormControl(clasificacion_visual_juego_id, []),
				moneda_id: new FormControl(moneda_id, []),
				concepto_id: new FormControl(concepto_id, []),
				fecha_inicio: new FormControl(fecha_inicio, []),
				fecha_fin: new FormControl(fecha_fin, []),
			}
		);
		this.fechaInicio = fecha_inicio;
		this.fechaFin = fecha_fin;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SET DATE W ()
	setDate(event, tipo) {
		const dateInp = event.target as HTMLInputElement;
		const dateVal = dateInp.value;
		// const txtInp = dateInp.closest(".date").querySelector(".date-txt") as HTMLInputElement;
		// txtInp.value = dateVal;
		if(tipo == "fecha_inicio"){
			this.fechaInicio = dateVal;
		}else{
			this.fechaFin = dateVal;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close();
         adjustSize();
      }, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON FILTER ()
	onFilter(event) {
		event.stopPropagation();
		let nuevofilter = new FilterModel();
		const searchModel = this.searchForm.value;
		if (searchModel.fecha_inicio && searchModel.fecha_inicio != "null") {
			nuevofilter.filter.push({
				atribute: "created_at",
				operator: ">=",
				value: searchModel.fecha_inicio
			});
		}
		if (searchModel.fecha_fin  && searchModel.fecha_fin != "null") {
			nuevofilter.filter.push({
				atribute: "created_at",
				operator: "<=",
				value: searchModel.fecha_fin
			});
		}
		if (searchModel.moneda_id  && searchModel.moneda_id != "null") {
			nuevofilter.filter.push({
				atribute: "moneda_id",
				operator: "=",
				value: searchModel.moneda_id
			});
		}
		if (searchModel.concepto_id && searchModel.concepto_id != "null") {
			nuevofilter.filter.push({
				atribute: "concepto_id",
				operator: "=",
				value: searchModel.concepto_id
			});
		}
		if (searchModel.clasificacion_visual_juego_id && searchModel.clasificacion_visual_juego_id != "null") {
			nuevofilter.filter.push({
				atribute: "clasificacion_visual_juego_id",
				operator: "=",
				value: searchModel.clasificacion_visual_juego_id
			});
		}
		this.store.dispatch(CuentaAction.getHistorialJuego({filter: nuevofilter}));
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close(true);
      }, 250);
	}

}
