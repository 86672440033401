import {Routes} from "@angular/router";
import {AdminGuard} from "../shared/guards/admin-guard.service";
import {HelpTicketsComponent} from "./components/help-tickets/help-tickets.component";

export const HelpRoutes: Routes = [
	{
		path: "",
		redirectTo: "tickets",
		pathMatch: "full"
	},
	{
		path: "tickets",
		component: HelpTicketsComponent,
		canActivate: [AdminGuard],
		canLoad: [AdminGuard]
	},
	{
		path: "**",
		redirectTo: "tickets",
		pathMatch: "full"
	}
];
