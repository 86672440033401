<div id="home-afiliados-visitor" class="testb">
	<div class="home-afiliados-header p-1">
		<img src="../../../../assets/images/bitwild-partners-logo.svg" id="ha-logo">
	</div>

	<div class="ha-video-container">
		<div class="col ha-side-video">
		</div>
		<div class="col ha-center-video" (click)="unmuteVideo()">
			<video [muted]="vidMutted" onloadedmetadata="this.muted = true" preload="auto" playsinline autoplay
				oncanplay="this.play()" id="ha-main-video">
				<source src="../../../../assets/videos/squaretest.mp4" type="video/mp4">
			</video>
			<!--			<div id="control" class="muted" *ngIf="vidMutted"></div>-->
			<!--			<div id="control" *ngIf="!vidMutted"></div>-->
		</div>
		<div class="cb"></div>
	</div>

	<div class="p-1">
		<ng-container *ngIf="!itsLogged()">
			<div class="col col-6 pr-05">
				<button (click)="onLogIn()" class="btn2 btn2-nor btn2-nor-icon btn2-fb-afil full-w" type="button">
					{{'INGRESA' |translate}}
					<img src="../../../../assets/images/icon-b-log-c.svg" alt="ingresa a tu cuenta">
				</button>
			</div>
			<div class="col col-6 pl-05">
				<button (click)="onRegister()" class="btn2 btn2-nor btn2-nor-icon btn2-1st-afil full-w" type="button">
					{{'REGISTRATE' |translate}}
					<img src="../../../../assets/images/icon-b-log-c.svg" alt="regístrate">
				</button>
			</div>
		</ng-container>

		<ng-container *ngIf="itsLogged()">
			<div class="col col-6 pr-05">
				<button (click)="logOutAction()" class="btn2 btn2-nor btn2-nor-icon btn2-fb-afil full-w" type="button">
					{{'CERRAR_SESSION' |translate}}
					<img src="../../../../assets/images/icon-b-log-c.svg" alt="ingresa a tu cuenta">
				</button>
			</div>
			<div class="col col-6 pl-05">
				<button (click)="onIrALobby()" class="btn2 btn2-nor btn2-nor-icon btn2-1st-afil full-w" type="button">
					{{'IR_A_LOBBY' |translate}}
					<img src="../../../../assets/images/icon-b-log-c.svg" alt="regístrate">
				</button>
			</div>
		</ng-container>

		<div class="cb"></div>
	</div>

	<div class="ha-intro">
		<div class="content">
			<h2 class="txt-a3 mb-03"><strong>{{'RECIBE_INSTANTE' |translate}} <span class="txt-a1"> {{'HASTA'
						|translate}} </span></strong></h2>
			<h1 class="txt-a1 tac mb-07">{{'CINCUENTA_COMISION' |translate}}</h1>
			<p class="txt-g6 tar">{{'DE_TUS_JUGADORES' |translate}}.</p>
			<!-- <p class="txt-k2 mb-2">Sin esperas, sin engaños</p> -->
		</div>
		<div class="cb mb-1"></div>
		<div class="content">
			<div class="portfolio-coins">
				<div class="portfolio-coin tac" *ngFor="let item of arrayOne(16); let i = index;">
					<img src="../../../../assets/images/crypto/{{coinsA[i]}}.svg" class="portfolio-coin">
				</div>
				<div class="cb"></div>
			</div>
			<!-- <div class="txt-a1 tac py-2">
         Cobra en 16 Cryptomonedas en Tiempo Real
		</div> -->
			<p class="txt-a1 tac pt-2">{{'TIMEPO_REAL_CRYPTOMONEDAS' |translate}}.</p>
		</div>
	</div>

	<div class="pt-1">
		<img src="../../../../assets/images/top-afiliado-2.jpg">
	</div>

	<div class="ha-slider-container mb-2">
		<div id="ha-slider1-content" class="pos-0">
			<!-- SLIDE -->
			<div class="ha-slide">
				<div class="ha-slide-title tac pt-1">{{'PAGOS_16_CRIPTOS' |translate}}</div>
				<div class="hs-slide-image">
					<img src="../../../../assets/images/has-1.svg" alt="mano con criptomoneda">
				</div>
				<div class="hs-slide-caption">
					<p class="tac">{{'RETIROS_16_CRIPTOS' |translate}}.</p>
				</div>
				<div class="cb"></div>
			</div>
			<!-- / SLIDE -->
			<!-- SLIDE -->
			<div class="ha-slide">
				<div class="ha-slide-title tac pt-1">{{'RETIROS_MISMO_DIA' |translate}}</div>
				<div class="hs-slide-image">
					<img src="../../../../assets/images/has-2.svg" alt="atm de criptomonedas">
				</div>
				<div class="hs-slide-caption">
					<p class="tac">{{'RETIROS_GANANCIA_MISMO_DIA' |translate}}.</p>
				</div>
				<div class="cb"></div>
			</div>
			<!-- / SLIDE -->
			<!-- SLIDE -->
			<div class="ha-slide">
				<div class="ha-slide-title tac pt-1">{{'PANEL_CONTROL' |translate}}</div>
				<div class="hs-slide-image">
					<img src="../../../../assets/images/has-3.svg" alt="laptop con gráficos">
				</div>
				<div class="hs-slide-caption">
					<p class="tac">{{'REPORTES_ACT_TIEMPO_REAL' |translate}}.</p>
				</div>
				<div class="cb"></div>
			</div>
			<!-- / SLIDE -->
		</div>
		<!--
		<div class="ha-slider-control ha-slider-control-L" (click)="onSs1Prev()"></div>
      <div class="ha-slider-control ha-slider-control-R" (click)="onSs1Next()"></div>
      -->
	</div>

	<div class="py-2 pos-rel">
		<p class="txt-lg txt-bol txt-a1 tac">{{'TUTORIALES' |translate}}</p>
		<div class="ha-tutorial-control ha-tutorial-control-L" (click)="onSt1Prev()"></div>
		<div class="ha-tutorial-control ha-tutorial-control-R" (click)="onSt1Next()"></div>
	</div>

	<div class="pos-rel">
		<div class="ha-tutorial-container" (swipeleft)="onSt1Next()" (swiperight)="onSt1Prev()">
			<div id="ha-tutorial-content" class="pos-0">
				<!-- TUTORIAL -->
				<div class="ha-tutorial">
					<div class="col ha-side-video">
					</div>
					<div class="col ha-center-video">
						<video preload="auto" playsinline class="tutorial-video">
							<source src="../../../../assets/videos/squaretest.mp4" type="video/mp4">
						</video>
						<div class="pt-4 pb-1 tac video-title">
							<span class="txt-bol txt-a1">Tutorial 1 - </span><span
								class="txt-k">{{'CREO_COMPARTO_CAMPANNA' |translate}}</span>
						</div>
					</div>
					<div class="cb"></div>
				</div>
				<!-- /TUTORIAL -->
				<!-- TUTORIAL -->
				<div class="ha-tutorial">
					<div class="col ha-side-video">
					</div>
					<div class="col ha-center-video">
						<video preload="auto" playsinline class="tutorial-video">
							<source src="../../../../assets/videos/squaretest.mp4" type="video/mp4">
						</video>
						<div class="pt-4 pb-1 tac video-title">
							<span class="txt-bol txt-a1">{{'TUTORIAL_2' |translate}} - </span><span class="txt-k">
								{{'MONITOREO_GANACIAS' |translate}}</span>
						</div>
					</div>
					<div class="cb"></div>
				</div>
				<!-- /TUTORIAL -->
				<!-- TUTORIAL -->
				<div class="ha-tutorial">
					<div class="col ha-side-video">
					</div>
					<div class="col ha-center-video">
						<video preload="auto" playsinline class="tutorial-video">
							<source src="../../../../assets/videos/squaretest.mp4" type="video/mp4">
						</video>
						<div class="pt-4 pb-1 tac video-title">
							<span class="txt-bol txt-a1">{{'TUTORIAL_3' |translate}} - </span><span class="txt-k">
								{{'RETIRO_GANANCIAS' |translate}}</span>
						</div>
					</div>
					<div class="cb"></div>
				</div>
				<!-- /TUTORIAL -->
				<div class="cb"></div>
			</div>
			<div class="cb"></div>
		</div>
		<div class="dot-indicators">
			<div class="dot-indicator-container" *ngFor="let item of arrayOne(3); let i = index;">
				<div class="dot-indicator" [class.active]='st1==i'></div>
			</div>
		</div>
		<div class="cb"></div>
	</div>

	<div class="cb mb-1"></div>

	<div class="py-2 sellos tac">
		<img src="../../../../assets/images/seal-1.svg" alt="">
		<img src="../../../../assets/images/seal-2.svg" alt="">
		<img src="../../../../assets/images/seal-3.svg" alt="">
	</div>

	<!-- - - - - - - - - - - - - - - - HOME FOOTER -->
	<footer id="home-footer" class="pt-1">
		<div class="px-2 pt-2 pb-1">

			<div class="col col-6 pr-1 pt-02">
				<img src="../../../../assets/images/bitwild-partners-logo.svg">
			</div>
			<div class="col col-6">
				<ul class="footer-social tar">
					<li><a href="#" target="_blank"><img src="../../../../assets/images/icon-social-fb.svg"></a>
					</li>
					<li><a href="#" target="_blank"><img src="../../../../assets/images/icon-social-ig.svg"></a>
					</li>
				</ul>
			</div>

			<div class="cb mb-2"></div>
			<ul class="menu-footer">
				<li (click)="goChat()">{{'CHAT' |translate}}</li>
				<li (click)="goToTermConditions()">{{'TERMINOS_CONDICIONES' |translate}}</li>
				<li (click)="goToPrivacy()">{{'POLITICA_PRIVACIDAD' |translate}}</li>
			</ul>

			<div class="pt-3">
				<img src="../../../../assets/images/proveedores-w.jpg" alt="Listado de nuestros proveedores"
					class="providers">
			</div>
			<!--
            <div class="pt-3 pb-2">
               <img src="../../../../assets/images/secure.jpg" alt="ssl value, seguro y certificado, 18+, privacidad garantizada">
            </div>
            -->
			<div class="cb  pt-3"></div>
			<div class="fl">
				<button class="btn-lang-sm lang-dark txt-x1" (click)="onSelectLanguage()">
					<img src="../../../../assets/images/icon-lang.svg" class="icon" alt="icon">
					{{selectedLang ? selectedLang.siglas.toUpperCase() : ""}}
				</button>
			</div>

			<p class="fr txt-sssm txt-g6 tar">{{'DERECHOS_RESERVADOS' |translate}}.<br>v.1.0</p>
			<!-- <p class="fr txt-ssm txt-g6">v.1.0</p> -->
			<div class="cb"></div>
		</div>

	</footer>
	<!-- - - - - - - - - - - - - - - - / HOME FOOTER -->

</div>