<div class="w-cover coverB" [class.active]="wActive==true" (swipedown)="onCloseW()">

	<div class="window window-pb8" id="terminos-condiciones">
      <!-- - - - - - - - - - - - - - - - - - - LANDSCAPE -->
      <div class="hidden-portrait pt-1">
         <div class="w-title">Términos y Condiciones</div>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / LANDSCAPE -->

      <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
      <div class="hidden-landscape">
         <div class="btn-window-close" (click)="onCloseW()">
            <div class="btn-window-close-bar"></div>
         </div>
         <p class="w-title-cover txt-ssm txt-w tac">{{ 'TERMINOS_CONDICIONES' | translate }}</p>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

		<div class="legal-container-0 p-1">
			<iframe src="assets/iframes/terminos_y_condiciones.html" class="legal" height="100%" width="100%"></iframe>

		</div>

      <!-- - - - - - - - - - - - - - - - - - - LANDSCAPE -->
      <div class="hidden-portrait">
         <button (click)="onCloseW()" class="btn btn-w-close" type="button">
            <img src="../../../../assets/images/icon-b-close.svg">
         </button>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / LANDSCAPE -->

      <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
      <div class="hidden-landscape">
         <div class="btn2-actions px-15 pb-2">
               <button type="button" class="btn2 btn2-lg btn2-g full-w"
                  (click)="onCloseW()">{{ 'CERRAR' | translate }}</button>
         </div>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>

</div>
