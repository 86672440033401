import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {JugadorModel} from "../../../../shared/models/user.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/redux/general.reducers";
import * as CuentaAction from "../../../redux/cuenta.actions";
import {Subscription} from "rxjs";
import { WLanguagesComponent } from "../../../../shared/w-languages/w-languages.component";

@Component({
	selector: "app-tab-datos-crypto",
	templateUrl: "./tab-datos-crypto.component.html",
	styleUrls: ["./tab-datos-crypto.component.css"]
})
export class TabDatosCryptoComponent implements OnInit, OnDestroy {

	account: JugadorModel;

	accountForm: FormGroup;

	edit: boolean;

	emailRegex = /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-zA-Z]{2,9}$/;

	subscription = new Subscription();

	constructor(
		private formBuilder: FormBuilder,
		private store: Store<AppState>,
      private modalServiceLang: NgbModal
	) {
	}

	ngOnInit(): void {
		this.edit = false;
		this.subscription.add(
			this.store.subscribe((state) => {
				this.account = state.auth.player;
			})
		)
		this.initForm();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initForm() {
		this.accountForm = this.formBuilder.group(
			{
				username: new FormControl(this.account.usuario.usuario, [Validators.required, Validators.minLength(3)]),
				email: new FormControl(this.account.usuario.email, [Validators.pattern(this.emailRegex)]),
				fiatPlayer: new FormControl(!this.account.usuario.crypto, [Validators.required]),
				currency: new FormControl(18, Validators.required)
			}
		);
		this.accountForm.disable();
	}

	inputTouched(formName: string) {
		return this.accountForm.controls[formName].invalid && (this.accountForm.controls[formName].dirty || this.accountForm.controls[formName].touched);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON EDIT ()
	onEdit() {
		this.edit = true;
		this.accountForm.enable();
		this.accountForm.controls.email.disable();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SAVE ()
	disableRegister() {
		const controlOne = this.accountForm.controls.username;
		const controlThree = this.accountForm.controls.fiatPlayer;
		const controlFour = this.accountForm.controls.currency;
		if (!controlOne.valid || !controlThree.valid || !controlFour.valid) {
			return true;
		}
		return controlOne.value === null || controlThree.value === null || controlFour.value === null;
	}

	onSave() {
		if (this.accountForm.valid) {
			const accountModel = this.accountForm.value;
			const param = {
				data: {
					crypto: !accountModel.fiatPlayer,
					email: this.account.usuario.email,
					moneda_base_id: accountModel.currency,
					usuario: accountModel.username,
				}
			};
			this.patchAccountDataCrypto(param.data);
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DISCARD ()
	onDiscard() {
		this.accountForm.reset(
			{
				username: {value: this.account.usuario.usuario, disabled: true},
				email: {value: this.account.usuario.email, disabled: true},
				fiatPlayer: {value: !this.account.usuario.crypto, disabled: true},
				currency: {value: 18, disabled: true}
			}
		);
		this.edit = false;
	}

	patchAccountDataCrypto(param) {
		this.store.dispatch(CuentaAction.patchJugadorDataCrypto({params: param}));
		this.accountForm.disable();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON EDIT ACCORDION ()
	onEditAccordion(thisElement) {
		var accrdn = thisElement.closest(".formSection") as HTMLDivElement;
		var inps = accrdn.getElementsByTagName('input');
		var sels = accrdn.getElementsByTagName('select');
		if (inps.length > 0) {
			for (var i = 0; i < inps.length; i++) {
				inps[i].disabled = false;
			}
		}
		if (sels.length > 0) {
			for (var j = 0; j < sels.length; j++) {
				sels[j].disabled = false;
			}
		}
		var edts = accrdn.getElementsByClassName('edit');
		for (var k = 0; k < edts.length; k++) {
			edts[k].classList.toggle('active')
		}
		this.onEdit();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - DISABLE ACCORDION ()
	disableAccordion(accrdn) {
		var inps = accrdn.getElementsByTagName('input');
		var sels = accrdn.getElementsByTagName('select');
		if (inps.length > 0) {
			for (var i = 0; i < inps.length; i++) {
				inps[i].disabled = true;
			}
		}
		if (sels.length > 0) {
			for (var j = 0; j < sels.length; j++) {
				sels[j].disabled = true;
			}
		}
		var edts = accrdn.getElementsByClassName('edit');
		for (var k = 0; k < edts.length; k++) {
			edts[k].classList.toggle('active')
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ACCORDION ()
	onCancelAccordion(thisElement) {
		var accrdn = thisElement.closest(".formSection") as HTMLDivElement;
		this.disableAccordion(accrdn);
		this.onDiscard();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SAVE ACCORDION ()
	onSaveAccordion(thisElement) {
		var accrdn = thisElement.closest(".formSection") as HTMLDivElement;
		this.disableAccordion(accrdn);
		this.onSave();
	}

   // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT LANGUAGE ()
   onSelectLanguage() {
      const modalRefLang = this.modalServiceLang.open(WLanguagesComponent);
   }
}
