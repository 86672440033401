<div class="w-cover bg-g6t hidden-landscape" [class.active]="myMenu.show_exchager_component" id="w-exchanger-cover"
	 (swipedown)="onCloseExchanger()">

	<input type="text" autofocus="autofocus" style="display:none"/>

	<div id="w-exchanger">

			<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
			<div class="">
				<div class="btn-window-close" (click)="onCloseExchanger()">
					<div class="btn-window-close-bar"></div>
				</div>
				<p class="w-title-cover txt-ssm txt-w tac">{{'EXCHANGER' |translate}}</p>
				<div class="px-15 pb-2">
					<div class="bg-g1 pt-2 pb-15">
						<div class="flex-col">
							<div class="col col-3 flex-center">
								<img class="coin" *ngIf="selectedOrigenCrypto"
									 src="../../../../assets/images/crypto/{{selectedOrigenCrypto ? selectedOrigenCrypto.moneda.siglas.toLowerCase() : 'btc'}}.svg"
									 (error)="changeImg($event, selectedOrigenCrypto.moneda.imagen)">
							</div>
							<div class="col col-9 pr-1">
								<div class="sel2 faux-sel2 mb-03">
									<input class="inp2 inp2-nor full-w mb-03" placeholder="{{'MONEDA' |translate}}" id="inp-from"
										   value="{{selectedOrigenCrypto ? selectedOrigenCrypto.moneda.nombre : null}}">
									<select class="sel2 sel2-nor full-w" (change)="selectCurrencyOrigen($event)">
										<option [selected]="selectedOrigenCrypto === null" [value]="null">{{'MONEDA' |translate}}
										</option>
										<option *ngFor="let curr of walletCurrency; let i = index;"
												[selected]="selectedOrigenCrypto && selectedOrigenCrypto.id === curr.id"
												[value]="curr.id"> {{curr.moneda.siglas.toUpperCase()}}
											- {{curr.moneda.nombre}}</option>
									</select>
								</div>
								<p class="fl txt-sm txt-g8">{{'DISPONIBLE' |translate}}:</p>
								<p class="fr txt-sm txt-w"><span class="txt-c2">
									{{selectedOrigenCrypto ? selectedOrigenCrypto.moneda.siglas.toUpperCase() : ""}}
								</span>
									{{!selectedOrigenCrypto ? "0.00" : selectedOrigenCrypto.balance_real | number: "1.2-8"}}
								</p>
								<div class="cb mb-1"></div>
								<input type="text" class="inp2 inp2-nor full-w tar mb-03" placeholder="0.00000000"
									   placeholder="0.00000000" [imask]="numberIMask" [unmask]="true"
									   [(ngModel)]="origenCryptoValue" (keyup)="changeMonto()">
							</div>
						</div>
						<div class="col col-3"></div>
						<div class="col col-9 pr-1">
							<p class="fl txt-sm txt-g8">{{'EQUIV_A' |translate}}:</p>
							<p class="fr txt-sm txt-g8">{{'USD'.toUpperCase()}}
								{{!selectedOrigenCrypto ? "0.00" : calcUSD(selectedOrigenCrypto.cambio_moneda_base, origenCryptoValue) | number: "1.2-2"}}
							</p>
							<div class="cb"></div>
						</div>
						<div class="cb"></div>
					</div>

					<div class="exchanger-convert-icon">
						<img src="../../../../assets/images/icon-exch-ind.svg">
					</div>

					<div class="bg-g1 pt-2 pb-15 mb-2">
						<div class="flex-col">
							<div class="col col-3 flex-center">
								<img class="coin" *ngIf="destinyCryptoUtility"
									 src="../../../../assets/images/crypto/{{destinyCryptoUtility ? destinyCryptoUtility.siglas.toLowerCase() : 'eth'}}.svg"
									 (error)="changeImg($event, destinyCryptoUtility.imagen)">
							</div>
							<div class="col col-9 pr-1">
								<div class="sel2 faux-sel2 mb-03">
									<input type="text" class="inp2 inp2-nor full-w mb-03" placeholder="{{'MONEDA' |translate}}"
										   id="inp-to"
										   value="{{destinyCryptoUtility ? destinyCryptoUtility.siglas : null}}">
									<select class="sel2 sel2-nor full-w" (change)="selectCurrencyDestiny($event)">
										<option [selected]="destinyCryptoUtility === null" [value]="null">{{'MONEDA' |translate}}
										</option>
										<option *ngFor="let curr of utilityCurrency; let i = index;"
												[selected]="destinyCryptoUtility && destinyCryptoUtility.id === curr.id"
												[value]="curr.id"> {{curr.siglas.toUpperCase()}}
											- {{curr.nombre}}</option>
									</select>
								</div>
								<p class="fl txt-sm txt-g8">{{'DISPONIBLE' |translate}}:</p>
								<p class="fr txt-sm txt-w"><span class="txt-c2">
									{{destinyCryptoUtility ? destinyCryptoUtility.siglas.toUpperCase() : ""}} </span>
									{{!selectedDestinyCrypto ? "0.00" : selectedDestinyCrypto.balance_real | number:"1.2-8"}}
								</p>
								<div class="cb mb-1"></div>
								<input type="text" class="inp2 inp2-nor full-w tar mb-03" placeholder="0.00000000"
									   readonly
									   [(ngModel)]="destinyCryptoValue" [imask]="numberIMaskTwo" [unmask]="true">
							</div>
						</div>
						<div class="col col-3"></div>
						<div class="col col-9 pr-1">
							<p class="fl txt-sm txt-g8">{{'EQUIV_A' |translate}}:</p>
							<p class="fr txt-sm txt-g8">{{'USD'.toUpperCase()}}
								{{!selectedDestinyCrypto ? "0.00" : calcUSD(selectedDestinyCrypto.cambio_moneda_base, destinyCryptoValue) | number: "1.2-2"}}
							</p>
							<div class="cb"></div>
						</div>
						<div class="cb"></div>
					</div>

					<div class="btn2-actions p-2">
						<div class="col col-6 pr-07">
							<button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCloseExchanger()">
								{{'CANCELAR' |translate}}
							</button>
						</div>
						<div class="col col-6 pl-07">
							<button type="button" class="btn2 btn2-lg btn2-1st full-w" (click)="exchangeCrypto()"
									[disabled]="!destinyCryptoUtility || destinyCryptoUtility.precio_jugador === 0 || destinyCryptoValue === '0'">
								{{'INTERCAMBIAR' |translate}}
							</button>
						</div>
						<div class="cb"></div>
					</div>
				</div>
				<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

			</div>
		<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>
</div>
