import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AdminServices} from "../../shared/services/admin.service";

import * as bonoAction from "../../bonos/redux/bonos.actions";
import * as authAction from "../../authetication/redux/authentication.actions";
import {catchError, exhaustMap, map, tap} from "rxjs/operators";
import {of} from "rxjs";
import {HomeService} from "../../home/services/home.service";
import {BonosService} from "../services/bonos.service";
import {Store} from '@ngrx/store';
import {AppState, DataWithExpireTime} from 'src/app/shared/redux/general.reducers';
import {BonosVisitanteModel} from 'src/app/shared/models/bonos-visitante.model';
import * as cajeroAction from "../../cajero/redux/cajero.actions";
import * as generalAction from "../../shared/redux/general.actions";


@Injectable()
export class BonosEffects {

	private bonosHomeLocal: DataWithExpireTime<BonosVisitanteModel> = {value: [], expire: 0};

	constructor(
		private actions$: Actions,
		private adminService: AdminServices,
		private homeService: HomeService,
		private bonoService: BonosService,
		private store: Store<AppState>
	) {
		this.store.select((state) => state).subscribe(state => {
			this.bonosHomeLocal = state.bonos.bonosHome;
		});
	}

	bonoJugadorAuthAptos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.obtenerUsuarioLogueadoCompletado),
			exhaustMap(data =>
				of(bonoAction.bonoJugadorAptoAuthCompletado(data))
			)
		)
	);

	bonoHome$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bonoAction.getBonosHome),
			exhaustMap(action => {
				if (this.bonosHomeLocal.expire == 0 || new Date().getTime() > this.bonosHomeLocal.expire) {
					return this.homeService.getBonosVisitantes(null).pipe(
						map(data => bonoAction.getBonosHomeCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				} else {
					return of(generalAction.localDataLoad({dataName: 'BonosVisitanteModel'}))
				}
			})
		)
	);

	setBonoDeposito$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bonoAction.setBonoParaDeposito),
			exhaustMap(action => {
					return this.bonoService.postActivateBono({
						tipo: action.tipo,
						data: {
							bono_jugador_apto_id: action.data.bono_jugador_apto_id,
							moneda_id: action.data.moneda_id,
						}
					}).pipe(
						map(data => bonoAction.setBonoParaDepositoCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getBonosAptos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bonoAction.getBonosAptos),
			exhaustMap(action => {
				return this.bonoService.getAllBonos(null).pipe(
					map(data => bonoAction.getBonosAptosCompletado({data})),
					catchError(error => of(authAction.terminar_state({error})))
				)
			})
		)
	);

	getBonosActivos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bonoAction.getBonosActivos),
			exhaustMap(action => {
				return this.bonoService.getAllBonosActivos(null).pipe(
					map(data => bonoAction.getBonosActivosCompletado({data})),
					catchError(error => of(authAction.terminar_state({error})))
				)
			})
		)
	);

	activarBono$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bonoAction.activarBonos),
			tap((action) => this.store.dispatch(generalAction.showLoader({ httpRequestLoader: true,
				tipo: action.type }))),
			exhaustMap(action => {
				return this.bonoService.postActivateBono(action.param).pipe(
					map(data => {
						this.store.dispatch(bonoAction.getBonosAptos());
						this.store.dispatch(bonoAction.getBonosActivos());
						this.store.dispatch(cajeroAction.getWallets());
						this.store.dispatch(generalAction.hideLoader({ httpRequestLoader: true,
							tipo: action.type }));
						return bonoAction.activarBonosCompletado({data})
					}),
					catchError(error => of(authAction.terminar_state({error})))
				)
			})
		)
	);

	desactivarBono$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bonoAction.desactivarBonos),
			tap((action) => this.store.dispatch(generalAction.showLoader({ httpRequestLoader: true,
				tipo: action.type }))),
			exhaustMap(action => {
				return this.bonoService.patchDeactivateBono(action.param).pipe(
					map(data => {
						this.store.dispatch(bonoAction.getBonosActivos());
						this.store.dispatch(generalAction.hideLoader({ httpRequestLoader: true,
							tipo: action.type }));
						return bonoAction.desactivarBonosCompletado({data})
					}),
					catchError(error => of(authAction.terminar_state({error})))
				)
			})
		)
	);

	getBonosHistorial$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bonoAction.getBonosHistorial),
			tap((action) => this.store.dispatch(generalAction.showLoader({ httpRequestLoader: true,
				tipo: action.type}))),
			exhaustMap(action => {
				return this.bonoService.getBonosHistorial(action.filter).pipe(
					map(data => {
						this.store.dispatch(generalAction.hideLoader({ httpRequestLoader: true,
							tipo: action.type }));
						return bonoAction.getBonosHistorialCompletado({data})
					}),
					catchError(error => of(authAction.terminar_state({error})))
				)
			})
		)
	);

}
