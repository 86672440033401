import * as AuthenticationActions from "./authentication.actions";
import {JugadorModel} from "../../shared/models/user.model";

import {Action, createReducer, on} from "@ngrx/store";
import {BonosVisitanteModel} from "../../shared/models/bonos-visitante.model";

export class AuthState {
	player: JugadorModel;
	token: string;
	refresh_token: string;
	expires_in: number;
	expire_date_unix: number;
	is_refreshing: boolean;
	proceso_conversion_data: { bono: BonosVisitanteModel, monedaSel: any, proceso: string };

	constructor() {
		this.player = null;
		this.token = '';
		this.refresh_token = '';
		this.expires_in = 0;
		this.expire_date_unix = 0;
		this.is_refreshing = false;
		this.proceso_conversion_data = {bono: null, monedaSel: null, proceso: ''};
	}

}

export interface AuthRefreshTokenResult {
	access_token: string;
	refresh_token: string;
	expires_in: number;
}

const AuthenticationReducer = createReducer(
	new AuthState,
	on(AuthenticationActions.login, state => ({...state})),
	on(AuthenticationActions.loginFacebook, state => ({...state})),
	on(AuthenticationActions.refreshToken, state => ({
		...state,
		is_refreshing: true
	})),
	on(AuthenticationActions.loginCompletado, (state, {data}) => ({
		...state,
		token: data.access_token,
		refresh_token: data.refresh_token,
		expires_in: data.expires_in,
		expire_date_unix: new Date().getTime() + data.expires_in * 1000,
		is_refreshing: false
	})),
	on(AuthenticationActions.refreshTokenCompletado, (state, data) => ({
		...state,
		token: data.access_token,
		refresh_token: data.refresh_token,
		expires_in: data.expires_in,
		expire_date_unix: new Date().getTime() + data.expires_in * 1000,
		is_refreshing: false
	})),
	on(AuthenticationActions.refreshTokenError, (state, data) => {
		return {
			...state,
			is_refreshing: false
		};
	}),
	on(AuthenticationActions.obtenerUsuarioLogueado, state => ({...state})),
	on(AuthenticationActions.obtenerUsuarioLogueadoCompletado, (state, {data}) => ({
		...state,
		player: data.user_data.jugador,
	})),
	on(AuthenticationActions.logout, state => ({...state})),
	on(AuthenticationActions.logoutCompletado, state => new AuthState),
	on(AuthenticationActions.registerCompletado, (state, {data}) => ({
		...state,
		token: data.access_token,
		refresh_token: data.refresh_token,
		expires_in: data.expires_in,
		expire_date_unix: new Date().getTime() + data.expires_in * 1000
	})),
	on(AuthenticationActions.setRegistroConBonoYMoneda, (state, {bono, monedaSel, proceso}) => ({
		...state,
		proceso_conversion_data: {bono: bono, monedaSel: monedaSel, proceso: proceso}
	})),
	on(AuthenticationActions.clearRegistroConBonoYMoneda, (state, {}) => ({
		...state,
		proceso_conversion_data: {bono: null, monedaSel: null, proceso: ''}
	})),
	on(AuthenticationActions.onJugadorDataCryptoUpdate, (state, {data}) => ({
		...state,
		player: data.object
	})),
	on(AuthenticationActions.obtenerUsuarioLogueadoFromPusher, (state, {player}) => {

		return {
			...state,
			player: player
		};
	})
);

export function reducer(state: AuthState | undefined, action: Action) {
	return AuthenticationReducer(state, action);
}
