<div class="w-cover coverB" id="filtro-comisiones-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">

    <div class="window pt-1" id="filtro-comisiones">
        <div class="w-title mb-3">{{'FILTRAR_RESULTADOS' | translate}}</div>

        <div class="pl-1 pr-2">
            <form [formGroup]="searchForm" (ngSubmit)="onFilter($event)">

                <div class="col col-3"></div>
                <div class="col col-3 pl-1">
                    <p class="mb-03">&nbsp;</p>
                    <div class="pos-rel">
                        <div class="sel sel-1st">
                            <select class="sel-nor full-w" formControlName="mes">
                                <option value="null">{{'MES' | translate}}</option>
                                <option value="01">{{'ENERO' | translate}}</option>
                                <option value="02">{{'FEBRERO' | translate}}</option>
                                <option value="03">{{'MARZO' | translate}}</option>
                                <option value="04">{{'ABRIL' | translate}}</option>
                                <option value="05">{{'MAYO' | translate}}</option>
                                <option value="06">{{'JUNIO' | translate}}</option>
                                <option value="07">{{'JULIO' | translate}}</option>
                                <option value="08">{{'AGOSTO' | translate}}</option>
                                <option value="09">{{'SEPTIEMBRE' | translate}}</option>
                                <option value="10">{{'OCTUBRE' | translate}}</option>
                                <option value="11">{{'NOVIEMBRE' | translate}}</option>
                                <option value="12">{{'DICIEMBRE' | translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col col-3 pl-1">
                    <p class="mb-03">&nbsp;</p>
                    <div class="pos-rel">
                        <div class="sel sel-1st">
                            <select class="sel-nor full-w" formControlName="anno">
                                <option value="null">{{'ANNO' | translate}}</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="w-actions px-2">
            <div class="col col-3"></div>
            <div class="col col-6">
                <div class="col col-6 pr-05">
                    <button (click)="onCancel($event)" class="btn btn-nor btn-rnd btn-g btn-icon full-w" type="button">
                        {{'CANCELAR' | translate}}
                        <img src="../../../../../../assets/images/icon-b-close-c.svg">
                    </button>
                </div>
                <div class="col col-6 pl-05">
                    <button (click)="onFilter($event)" class="btn btn-nor btn-rnd btn-1st btn-icon full-w"
                            type="button">
                        {{'FILTRAR'}}
                        <img src="../../../../../../assets/images/icon-b-play-c.svg">
                    </button>
                </div>
            </div>
            <div class="cb"></div>
        </div>

        <button (click)="onCloseW()" class="btn btn-w-close" type="button">
            <img src="../../../../../../assets/images/icon-b-close.svg">
        </button>
    </div>

</div>
