import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CajeroRoutes} from "./cajero.routing";
import {CajeroService} from "./services/cajero.service";
import {SharedModule} from "../shared/shared.module";
import {VerifIdComponent} from "./components/verificacion/verif-id/verif-id.component";
import {RetirosComponent} from "./components/retiros/retiros.component";
import {VerifDatosComponent} from "./components/verificacion/verif-datos/verif-datos.component";
import {WRetiroComponent} from "./components/w-retiro/w-retiro.component";
import {WAddCoinComponent} from "./components/w-add-coin/w-add-coin.component";
import {VerifFbformComponent} from "./components/verificacion/verif-fbform/verif-fbform.component";
import {VerificacionesComponent} from "./components/verificacion/verificaciones/verificaciones.component";
import {WDepositoComponent} from "./components/w-deposito/w-deposito.component";
import {VerifResidenceComponent} from "./components/verificacion/verif-residence/verif-residence.component";
import {WalletComponent} from "./components/wallet/wallet.component";
import {ExchangerComponent} from "./components/exchanger/exchanger.component";
import {VerifCardComponent} from "./components/verificacion/verif-card/verif-card.component";
import {WDepositoBonoComponent} from "./components/w-deposito-bono/w-deposito-bono.component";
import {WFRetiroComponent} from "./components/w-f-retiro/w-f-retiro.component";
import {IMaskModule} from "angular-imask";
import {WPreDepositoComponent} from "./components/w-pre-deposito/w-pre-deposito.component";
import {StoreModule} from "@ngrx/store";
import * as fromCajeroReducer from "../cajero/redux/cajero.reducers";
import {EffectsModule} from "@ngrx/effects";
import {CajeroEffects} from "./redux/cajero.effects";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		RouterModule.forChild(CajeroRoutes),
		SharedModule,
		IMaskModule,
		StoreModule.forFeature("cajero", fromCajeroReducer.reducer),
		EffectsModule.forFeature([CajeroEffects]),
		TranslateModule
	],
	providers: [
		NgbActiveModal,
		CajeroService
	],
	declarations: [
		ExchangerComponent,
		RetirosComponent,
		VerifCardComponent,
		VerifDatosComponent,
		VerifFbformComponent,
		VerifIdComponent,
		VerifResidenceComponent,
		VerificacionesComponent,
		WAddCoinComponent,
		WDepositoComponent,
		WDepositoBonoComponent,
		WFRetiroComponent,
		WRetiroComponent,
		WalletComponent,
		WPreDepositoComponent
	],
	exports: []
})
export class CajeroModule {
}
