import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import { ResumenPeriodoAfiliadosModel } from "../../../shared/models/resumen-periodo-afiliados.model";
import * as GeneralAction from "../../../shared/redux/general.actions";
import * as AfiliadosActions from '../../../afiliados/redux/afiliados.actions';
import { AfiliadoModel } from "src/app/shared/models/afiliado.model";

@Component({
	selector: "app-home-afiliados",
	templateUrl: "./home-afiliados.component.html",
	styleUrls: ["./home-afiliados.component.css"]
})
export class HomeAfiliadosComponent implements OnInit {

	resumenPeriodoActual: ResumenPeriodoAfiliadosModel;
	afiliadosMode: boolean = true;
	afiliado: AfiliadoModel = null;

	constructor(
		private store: Store<AppState>
	) { }

	ngOnInit(): void {
		this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
			this.afiliado = state.afiliado.afiliado;
			this.resumenPeriodoActual = state.afiliado.resumen_periodo_actual.value;
		});

		this.store.dispatch(GeneralAction.setAfiliadosMode({ afiliadosMode: true }));
		this.store.dispatch(AfiliadosActions.afiliadosGetResumenPeriodoActual());
	}

	changeImgUrl(event, newImg) {
		event.target.src = newImg;
	}
}
