<section class="" id="home-afiliado">
    <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
    <div class="hidden-landscape full">
        <div class="section-header sh-afiliado full">
            <div class="section-title">
                <div class="txt-snor pr-1">{{'AFILIADOS' |translate}}</div>
                <img src="../../../../assets/images/icon-m-mov-afiliacion.svg" class="section-icon">

                <!-- *ngIf="account" -->
                <div class="pl-1 pr-05">
                    <div class="txt-ssm tar"><span class="txt-c4">{{'ID: '}}</span> {{ afiliado ? afiliado.id : ''
                        }}<br>
                        {{ afiliado ? afiliado.usuario : '' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="full">
            <div class="bg-g1 txt-nor tac p-05 mb-1">
                <span class=" txt-g6">{{'RESUMEN_PERIODO' |translate}}
                    : </span> {{resumenPeriodoActual ? resumenPeriodoActual.periodo
                : ""}}
            </div>

            <div class="">
                <div class="afiliado-resume">
                    <div class="afiliado-resume-card bg-g1">
                        <div class="ff-mid txt-xxlg">{{resumenPeriodoActual ? resumenPeriodoActual.total_registros : 0}}
                        </div>
                        <div class="txt-c4-l txt-sm">{{'TOTAL'|translate}}<br>{{'DE_REGISTROS' |translate}}</div>
                    </div>
                    <div class="afiliado-resume-card bg-g1">
                        <div class="ff-mid txt-xxlg">{{resumenPeriodoActual ? resumenPeriodoActual.total_conversiones :
                            0}}</div>
                        <div class="txt-c4-l txt-sm">{{'TOTAL'|translate}}<br>{{'CONVERSIONES'|translate}}</div>
                    </div>
                    <div class="afiliado-resume-card bg-g1">
                        <div class="ff-mid txt-xxlg">{{resumenPeriodoActual ? resumenPeriodoActual.total_referencial_usd
                            : 0}}
                        </div>
                        <div class="txt-c4-l txt-sm">{{'TOTAL'|translate}}<br>{{'EQUIV_USD'|translate}}</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

</section>