import {Routes} from "@angular/router";
import {PlaygroundComponent} from "./components/playground/playground.component";
import {BackComponent} from "./components/back/back.component";
import {VerifiedGuard} from "../home/guards/verified.guard";

export const GamesRoutes: Routes = [
	{
		path: "",
		redirectTo: "playground",
		pathMatch: "full"
	},
	{
		path: "playground",
		children: [
			{
				path: "",
				component: PlaygroundComponent,
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			},
			{
				path: "back",
				component: BackComponent,
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			},
			{
				path: "**",
				redirectTo: "",
				pathMatch: "full"
			}
		]
	},
	{
		path: "**",
		redirectTo: "playground",
		pathMatch: "full"
	}
];
