<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover coverB" id="ayuda-call-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">

	<div class="window" id="ayuda-call">

		<form [formGroup]="callForm" (submit)="onRequestCall($event)">

			<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
			<div class="hidden-landscape deposit1-container">
				<div class="btn-window-close" (click)="onCloseW()">
					<div class="btn-window-close-bar"></div>
				</div>
				<p class="w-title-cover txt-sm txt-w tac mb2">{{ 'SOLICITAR_LLAMADA' | translate }}</p>

				<div class="px-15">
					<p class="txt-gc mb-2">
						{{ 'LLEEN_SIGUIENTE_FORMULARIO' | translate }}
					</p>
					<div class="col col-9 pr-07">
						<p class="txt-gc mb-05">Fecha:</p>
						<div class="pos-rel">
							<div class="date">
								<input class="inp2 inp2-nor full-w date-txt" placeholder="yyyy-mm-dd" type="text"
									readonly>
								<input (change)="setDate($event)" class="inp2-nor full-w date-inp" type="date"
									formControlName="fecha">
							</div>
						</div>
					</div>
					<div class="cb mb-2"></div>
					<div class="pl-02">
						<div class="col col-3 pr-05">
							<p class="txt-gc mb-05">{{ 'HORA' | translate }}:</p>
							<div class="sel2">
								<select class="sel2 sel2-nor full-w" formControlName="hora"
									(click)="$event.stopPropagation()">
									<option [value]="" *ngFor="let item of arrayOne(12); let i = index;">
										{{i + 1 < 10 ? "0" + (i + 1) : i + 1}} </option>
								</select>
							</div>
						</div>
						<div class="col col-3 pr-05">
							<p class="txt-gc mb-05">&nbsp;</p>
							<div class="sel2">
								<select class="sel2 sel2-nor full-w" formControlName="minutos"
									(click)="$event.stopPropagation()">
									<option [value]="" *ngFor="let item of arrayOne(60); let i = index;">
										{{i < 10 ? "0" + i : i }} </option>
								</select>
							</div>
						</div>
						<div class="col col-3 pr-05">
							<p class="txt-gc mb-05">&nbsp;</p>
							<div class="sel2">
								<select class="sel2 sel2-nor full-w" formControlName="meridiano"
									(click)="$event.stopPropagation()">
									<option>{{ 'AM' | translate }}</option>
									<option>{{ 'PM' | translate }}</option>
								</select>
							</div>
						</div>
						<div class="col col-3">
							<p class="txt-gc mb-05">GMT:</p>
							<div class="sel2">
								<select class="sel2 sel2-nor full-w" formControlName="zona_horaria"
									(click)="$event.stopPropagation()">
									<option [value]="item.nombre" *ngFor="let item of timeZones; let i = index;">
										{{item.nombre}}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="cb mb-2"></div>
					<ng-container formGroupName="telefono">
						<p class="txt-gc mb-05">{{ 'MI_TELEFONO' | translate }}:</p>

						<div class="col col-4 pr-05">
							<p class="txt-ga mb-05">País:</p>
							<div class="sel2 faux-sel2">
								<input class="inp2 inp2-nor full-w" placeholder="City" id="inp-from"
									[value]="paisSeleccionado">
								<!-- value="+{{country.codigo_telefonico}}" -->
								<select class="sel2 sel2-nor full-w" (change)="onChangePais($event)"
									formControlName="pais">
									<option disabled [value]="null">País</option>
									<option *ngFor="let country of countries" [value]="country.codigo_telefonico">
										+{{country.codigo_telefonico}} - {{country.tx_iso2}}</option> &ndash;&gt;
								</select>
							</div>
						</div>
						<div class="col col-3 pr-05">
							<p class="txt-ga mb-05">Área:</p>
							<input class="inp2 inp2-nor full-w tac" placeholder="" type="text" formControlName="area"
								appDecimalDirective [maxLength]="4">
						</div>
						<div class="col col-5">
							<p class="txt-ga mb-05">{{ 'NUMERO' | translate }}:</p>
							<input class="inp2 inp2-nor full-w" placeholder="" type="text" formControlName="numero"
								appDecimalDirective [maxLength]="10">
						</div>
					</ng-container>
					<div class="cb"></div>
				</div>

				<div class="btn2-actions px-2 pb-2">
					<div class="col col-6 pr-07">
						<button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCancel($event)">
							{{ 'CANCELAR' | translate }}
						</button>
					</div>
					<div class="col col-6 pl-07">
						<button type="submit" class="btn2 btn2-lg btn2-1st full-w" [disabled]="!callForm.valid">{{
							'SOLICITAR' | translate }}</button>
					</div>
					<div class="cb"></div>
				</div>

			</div>
			<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

		</form>
	</div>

</div>