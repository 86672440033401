<div class="w-cover" id="w-notify-cover" (swipedown)="onCloseW()">

   <div class="window window-pb8" id="notify">
      <!-- - - - - - - - - - - - - - - - - - - LANDSCAPE -->
      <div class="hidden-portrait pt-1">
         <p>w-notificaciones works!</p>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / LANDSCAPE -->

      <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
      <div class="hidden-landscape full">
         <div class="btn-window-close" (click)="onCloseW()">
            <div class="btn-window-close-bar"></div>
         </div>
         <p class="w-title-cover txt-ssm txt-w tac mb-05">Notificaciones</p>

			<div class="notify-container px-15 of-y2">
				<div class="notificacion bg-g3" *ngIf="notificaciones.length == 0">
					No hay notificaciones
				</div>
				<ng-container *ngIf="notificaciones.length > 0">
					<div class="notificacion bg-g3" *ngFor="let item of notificaciones; let i = index;"
						 id="notif-{{i}}" [style]="!item.leido ? '' : 'background: none'">
						<span (click)="marcarComoLeida($event, item.id)"> {{item.descripcion + item.id}}</span>
						<div class="del-notify" (click)="onDelNotify($event, item.id)">
							<img src="../../../../assets/images/icon-b-trash.svg" alt="borrar notificación">
						</div>
					</div>
				</ng-container>
			</div>

         <div class="btn2-actions px-15 pb-2">
            <button type="button" class="btn2 btn2-lg btn2-g full-w"
               (click)="onCloseW()">{{ 'CERRAR' | translate }}</button>
         </div>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

	</div>

</div>
