import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from "@angular/platform-browser";
import {Injectable, NgModule} from "@angular/core";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AppComponent} from "./app.component";
import {appRoutes, AppRoutingModule} from "./app-routing.module";
import {TurnmobileComponent} from "./turnmobile/turnmobile.component";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {AdminGuard} from "./shared/guards/admin-guard.service";
import {NotificationService} from "./shared/services/notification.service";
import {SessionServices} from "./shared/services/session.services";
import {TokenInterceptor} from "./shared/services/token.inteceptor";
import {FormsModule} from "@angular/forms";
import {CajeroModule} from "./cajero/cajero.module";
import {CuentaModule} from "./cuenta/cuenta.module";
import {PreloadAllModules, RouterModule} from "@angular/router";
import {CommonModule, LocationStrategy, PathLocationStrategy} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthenticationModule} from "./authetication/authentication.module";
import {BonosModule} from "./bonos/bonos.module";
import {AfiliadosModule} from "./afiliados/afiliados.module";
import {GamesModule} from "./games/games.module";
import {ConstantsService} from "./shared/services/constants.service";
import {NavigationModule} from "./nav/navigation.module";
import {HelpModule} from "./ayuda/help.module";
import {WHomegameInfoComponent} from "./home/components/home-visitor/w-homegame-info/w-homegame-info.component";
import {HomeModule} from "./home/home.module";
import {AngularDraggableModule} from "angular2-draggable";
import {LoaderComponent} from "./loader/loader.component";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import * as fromGeneralReducer from "./shared/redux/general.reducers";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {metaReducers} from "./shared/redux/local.storage.sync";
import {GeneralEffects} from "./shared/redux/general.effects";
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from "ng-lazyload-image";
import {WLanguagesComponent} from "./shared/w-languages/w-languages.component";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/langs/", ".json");
}

declare var Hammer: any;

@Injectable()
export class HammerConfig extends HammerGestureConfig {
	overrides = <any> {
		// Use the swap gesture - deactivate the others to avoid overlaps
		// Hammer.DIRECTION_HORIZONTAL
		"swipe": {direction: Hammer.DIRECTION_ALL},
		"pinch": {enable: false},
		"rotate": {enable: false},
		"pan": {enable: false}
	};
}

@NgModule({
	declarations: [
		AppComponent,
		TurnmobileComponent,
		LoaderComponent,
		WLanguagesComponent
	],
	entryComponents: [],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		AppRoutingModule,
		NgbModule,
		RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules, onSameUrlNavigation: "reload"}),
		HttpClientModule,
		FormsModule,
		AuthenticationModule,
		CajeroModule,
		CuentaModule,
		BonosModule,
		AfiliadosModule,
		GamesModule,
		NavigationModule,
		HelpModule,
		HammerModule,
		HomeModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		AngularDraggableModule,
		StoreModule.forRoot({general: fromGeneralReducer.reducer}, {metaReducers}),
		EffectsModule.forRoot([GeneralEffects]),
		StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
		LazyLoadImageModule
	],
	providers: [
		NgbActiveModal,
		NotificationService,
		SessionServices,
		WHomegameInfoComponent,
		AdminGuard,
		ConstantsService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig
		},
		{provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
