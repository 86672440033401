import {Component, OnInit} from "@angular/core";
import {SessionServices} from "../../../../shared/services/session.services";

@Component({
	selector: "app-verif-datos",
	templateUrl: "./verif-datos.component.html",
	styleUrls: ["./verif-datos.component.css"]
})
export class VerifDatosComponent implements OnInit {

	dataCompleted: boolean;

	constructor(
		private sessionService: SessionServices
	) {
	}

	ngOnInit(): void {
		this.dataCompleted = this.sessionService.isDataComplete();
	}

}
