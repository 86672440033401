<div class="widget-control-area">


   <!-- - - - - - - - - - - - - - - - - - - LANDSCAPE -->
   <div class="widget-control hidden-portrait">
      <ul class="widget-section">
         <!-- - - - - - - - - - - - - - - - - FIRST -->
         <li>
				<div [class.active]="myMenu.show_catalog_component"
					id="wsCatalog" (click)="onToggleCompleteMenu($event, 'catalog')">
					<img src="../../../../assets/images/icon-catalog-s.svg">
				</div>
			</li>
         <!-- - - - - - - - - - - - - - - - - / FIRST -->
         <!-- - - - - - - - - - - - - - - - - SECOND -->
         <li>
            <div id="wsBack" id="wsBack"
					[class.active]="myMenu.show_back_component" (click)="onToggleCompleteMenu($event,'back')">
					<img src="../../../../assets/images/icon-back.svg">
				</div>
         </li>
         <!-- - - - - - - - - - - - - - - - - / SECOND -->
         <!-- - - - - - - - - - - - - - - - - THIRD -->
         <li>
            <div class="empty">&nbsp;</div>
         </li>
         <!-- - - - - - - - - - - - - - - - - / THIRD -->
			<!-- - - - - - - - - - - - - - - - - FOURTH -->
         <li>
            <div id="wsBack" id="wsBack" (click)="onToggleCompleteMenu($event,'inicio')">
					<img src="../../../../assets/images/icon-home.svg">
				</div>
         </li>
         <!-- - - - - - - - - - - - - - - - - / FOURTH -->
      </ul>
   </div>
   <!-- - - - - - - - - - - - - - - - - - - / LANDSCAPE -->
   <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
	<div class="widget-control hidden-landscape">
		<!-- - - - - - - - - - - - - - - - - NOTIFICATIONS -->
		<div class="widget-notifications" *ngIf="myMenu.notificaciones"
			(click)="onToggleCompleteMenu($event,'notificaciones')">
			<span class="notif-number" [class.active]="readNotifs()>0">{{readNotifs()}}</span>
			<img src="../../../../assets/images/icon-notifications.svg">
		</div>

		<ul class="widget-section">
			<!-- - - - - - - - - - - - - - - - - FIRST -->
			<li>
				<div class="empty" *ngIf="!myMenu.menu">&nbsp;</div>
				<div *ngIf="myMenu.menu" [class.active]="myMenu.show_menu_component"
					(click)="onToggleCompleteMenu($event,'menu')">
					<img src="../../../../assets/images/icon-menu.svg">
				</div>
			</li>
			<!-- - - - - - - - - - - - - - - - - / FIRST -->
			<!-- - - - - - - - - - - - - - - - - SECOND -->
			<li>
				<div class="empty" *ngIf="afiliadosMode || !myMenu.catalog">&nbsp;</div>
				<div *ngIf="!afiliadosMode && myMenu.catalog" [class.active]="myMenu.show_catalog_component"
					id="wsCatalog" (click)="onToggleCompleteMenu($event, 'catalog')">
					<img src="../../../../assets/images/icon-catalog-s.svg">
				</div>
			</li>
			<!-- - - - - - - - - - - - - - - - - / SECOND -->
			<!-- - - - - - - - - - - - - - - - - THIRD -->
			<li>
				<!-- EMPTY -->
				<div class="empty" *ngIf="afiliadosMode || !myMenu.exchanger || !mostrarExchanger">&nbsp;</div>
				<!-- EXCHANGER -->
				<div class="p-03" *ngIf="!afiliadosMode && myMenu.exchanger && mostrarExchanger"
					[class.active]="myMenu.show_exchager_component" id="wsExchange"
					(click)="onToggleCompleteMenu($event,'exchanger')">
					<img src="../../../../assets/images/icon-m-exchanger.svg">
				</div>

				<!--				Aqui va cuando va al playground-->
				<!--				<ng-container *ngIf="menuControl.backToGame">-->
				<!--					&lt;!&ndash; EMPTY &ndash;&gt;-->
				<!--					<div class="empty" *ngIf="menuControl.thirdEmpty || !activegame; else vemosBack">&nbsp;</div>-->
				<!--					&lt;!&ndash; BACK TO GAME &ndash;&gt;-->
				<!--					<ng-template #vemosBack>-->
				<!--						<div class="p-03" id="wsBack" *ngIf="activegame" (click)="onGoPlayground()">-->
				<!--							<img src="../../../../assets/images/icon-game-s-back.svg">-->
				<!--						</div>-->
				<!--					</ng-template>-->
				<!--				</ng-container>-->
			</li>
			<!-- - - - - - - - - - - - - - - - - / THIRD -->
			<!-- - - - - - - - - - - - - - - - - FOURTH -->
			<li>
				<div class="empty" *ngIf="ordenandoMenuFiltrosYBack('empty')">&nbsp;</div>
				<div *ngIf="ordenandoMenuFiltrosYBack('back')" id="wsBack" id="wsBack"
					[class.active]="myMenu.show_back_component" (click)="onToggleCompleteMenu($event,'back')">
					<img src="../../../../assets/images/icon-back.svg">
				</div>
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('catalogo')" (click)="onFilter($event)">
					<img src="../../../../assets/images/icon-filter.svg">
				</div>
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('catalogo_clear')"
					 (click)="onClearFiltersCatalogo($event)">
					<img src="../../../../assets/images/icon-unfilter.svg">
				</div>
				<!--				Filtro y limpiar filtro de historial de bonos-->
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('bono_historial')"
					(click)="onFilterHistorialBonos($event)">
					<img src="../../../../assets/images/icon-filter.svg">
				</div>
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('bono_historial_clear')"
					(click)="onClearFiltersHistorialBonos($event)">
					<img src="../../../../assets/images/icon-unfilter.svg">
				</div>
				<!--Filtro y limpiar filtro de movimientos-->
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('movimientos')"
					(click)="onFilterHistorialMovimientos($event)">
					<img src="../../../../assets/images/icon-filter.svg">
				</div>
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('movimientos_clear')"
					(click)="onClearFiltersMovimientos($event)">
					<img src="../../../../assets/images/icon-unfilter.svg">
				</div>
				<!--Filtro y limpiar filtro de act Juegos-->
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('act_juego')"
					(click)="onFilterHistorialHistorialJuegos($event)">
					<img src="../../../../assets/images/icon-filter.svg">
				</div>
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('act_juego_clear')"
					(click)="onClearFiltersHistorialJuegos($event)">
					<img src="../../../../assets/images/icon-unfilter.svg">
				</div>
				<!--Filtro y limpiar filtro de Intercambios crypto-->
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('intercambios')"
					(click)="onFilterHistorialIntercambiosCrypto($event)">
					<img src="../../../../assets/images/icon-filter.svg">
				</div>
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('intercambios_clear')"
					(click)="onClearFiltersHistorialIntercambiosCrypto($event)">
					<img src="../../../../assets/images/icon-unfilter.svg">
				</div>
				<!--Filtro y limpiar filtro de Transacciones crypto-->
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('transacciones')"
					(click)="onFilterHistorialTransaccionesCrypto($event)">
					<img src="../../../../assets/images/icon-filter.svg">
				</div>
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('transacciones_clear')"
					(click)="onClearFiltersHistorialTransaccionesCrypto($event)">
					<img src="../../../../assets/images/icon-unfilter.svg">
				</div>
				<!--icono de adicionar cuando estamos en la ruta cajero/wallet-->
				<div class="div2nd bigIcon" *ngIf="ordenandoMenuFiltrosYBack('cajero_wallet')" (click)="onAdd($event)">
					<img class="hidden-portrait" src="../../../../assets/images/icon-plus.svg">
					<img class="hidden-landscape" src="../../../../assets/images/icon-plus-c.svg">
				</div>
				<!--icono de adicionar cuando estamos en la ruta ayuda/ticket-->
				<div class="div2nd bigIcon" *ngIf="ordenandoMenuFiltrosYBack('ayuda_ticket')"
					(click)="onAddTicket($event)">
					<img class="hidden-portrait" src="../../../../assets/images/icon-plus.svg">
					<img class="hidden-landscape" src="../../../../assets/images/icon-plus-c.svg">
				</div>
				<!--icono de adicionar cuando estamos en la ruta afiliados/campannas en Modo Afiliado -->
				<div class="div2nd bigIcon" *ngIf="ordenandoMenuFiltrosYBack('campannas')"
					(click)="onAddCampanna($event)">
					<img class="hidden-portrait" src="../../../../assets/images/icon-plus.svg">
					<img class="hidden-landscape" src="../../../../assets/images/icon-plus-c.svg">
				</div>
				<!--Filtro y limpiar filtro de Control de Tráfico en la ruta /afiliados/trafico en Modo Afiliados -->
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('control_trafico')"
					(click)="onFilterControlTrafico($event)">
					<img src="../../../../assets/images/icon-filter.svg">
				</div>
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('control_trafico_clear')"
					(click)="onClearFilterControlTrafico($event)">
					<img src="../../../../assets/images/icon-unfilter.svg">
				</div>
				<!--Filtro y limpiar filtro de Batch Comisiones en la ruta /afiliados/comisiones en Modo Afiliados -->
				<div class="div2nd" *ngIf="ordenandoMenuFiltrosYBack('batch_comisiones')"
					(click)="onFilterBatchComisiones($event)">
					<img src="../../../../assets/images/icon-filter.svg">
				</div>
				<!-- UNFILTER - LIMPIAR FILTROS -->

				<!-- / UNFILTER - LIMPIAR FILTROS -->

				<!--				<div class="empty" *ngIf="myMenu.show_menu_component && rutaActiva=='/cajero/wallet'">-->
				<!--					&nbsp;-->
				<!--				</div>-->
			</li>
			<!-- - - - - - - - - - - - - - - - - / FOURTH -->
		</ul>

	</div>
   <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

</div>


<!-- COMPONENTS ALWAYS OPEN -->

<app-mainmenu></app-mainmenu>
<app-catalog *ngIf="!afiliadosMode"></app-catalog>
<app-w-exchanger *ngIf="!afiliadosMode && mostrarExchanger"></app-w-exchanger>
<app-w-back *ngIf="!afiliadosMode"></app-w-back>
<app-w-notificaciones></app-w-notificaciones>
