import {Component, OnDestroy, OnInit, AfterViewInit} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WAddCoinComponent} from "../w-add-coin/w-add-coin.component";
import {WDepositoComponent} from "../w-deposito/w-deposito.component";
import {WRetiroComponent} from "../w-retiro/w-retiro.component";
import {Router} from "@angular/router";
import {NotificationService} from "../../../shared/services/notification.service";
import Swal from "sweetalert2";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as CajeroAction from "../../redux/cajero.actions";
import {MonedaModel} from "../../../shared/models/wallet-currency.model";
import {Actions, ofType} from "@ngrx/effects";
import {TranslateService} from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-wallet",
	templateUrl: "./wallet.component.html",
	styleUrls: ["./wallet.component.css"]
})
export class WalletComponent implements OnInit, OnDestroy, AfterViewInit {

	walletCurrency: WalletGeneralModel[];
	cryptoCurrency: MonedaModel[];
	walletSeleccionado: WalletGeneralModel;

	subscription: Subscription = new Subscription();

	constructor(
		private modalService: NgbModal,
		private router: Router,
		private notification: NotificationService,
		private store: Store<AppState>,
		private actions$: Actions,
		private translateService: TranslateService,
	) {
	}

	ngOnInit(): void {
		this.initData();
		this.listeningActions();
	}

	ngAfterViewInit() {
		adjustSize();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initData() {
		this.subscription.add(this.store.select((state) => state).subscribe(state => {
			this.walletCurrency = state.cajero.wallets;
			this.cryptoCurrency = state.cajero.cryptoMonedas.value;
			this.walletSeleccionado = this.walletSeleccionado ? state.cajero.wallets.find(x => x.id === this.walletSeleccionado.id) : state.cajero.balance;
		}));
		this.getWalletGeneral();
		this.getCryptoCurrency();
	}

	listeningActions() {
		this.subscription.add(this.actions$
			.pipe(ofType(CajeroAction.addSolicitudRetiroCompletado))
			.subscribe(response => {
				const resp = response;
				Swal.fire(this.notification.BuildSuccess(resp.data.message)).then();
			}));
		this.subscription.add(this.actions$
			.pipe(ofType(CajeroAction.deleteWalletCompletado))
			.subscribe(response => {
				const resp = response;
				this.walletSeleccionado = this.walletCurrency[0];
			}));
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DEPOSIT ()
	onDeposit() {
		const modalRef = this.modalService.open(WDepositoComponent);
		modalRef.componentInstance.process = "SHOW_HASH_ONLY";
		modalRef.componentInstance.selectedWalletCurrency = this.walletSeleccionado.moneda;
		modalRef.result.then();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON WITHDRAW ()
	onWithdraw() {
		const modalRef = this.modalService.open(WRetiroComponent);
		modalRef.componentInstance.selectedWalletCurrency = this.walletSeleccionado;
		modalRef.result.then();
	}

	onDelete() {
		const messAlert = this.translateService.instant("DESEAS_ELIMINAR_MONEDA") + " " + this.walletSeleccionado.moneda.nombre + "?";
		Swal.fire(this.notification.BuildQuestion(messAlert)).then(
			(action) => {
				if (action.value) {
					const param = {
						id: this.walletSeleccionado.id
					};
					this.deleteWalletCurrency(param);
				}
			});
	}

	onExchange() {
		this.router.navigate(["/cajero/exchanger"], {queryParams: {crypto: this.walletSeleccionado.id}}).then();
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}


	// New API Calls


	createCurrencyHash() {
		this.onDeposit();
	}

	getWalletGeneral() {
		this.store.dispatch(CajeroAction.getWallets());
	}

	getCryptoCurrency() {
		this.store.dispatch(CajeroAction.getCryptoMonedas());
	}

	deleteWalletCurrency(param) {
		this.store.dispatch(CajeroAction.deleteWallet({param: param}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON WITHDRAW ()
	onSelectWallet(curr) {
		this.walletSeleccionado = curr;
	}

	comprobandoFreeSpins() {
		if (this.walletSeleccionado && this.walletSeleccionado.bono && this.walletSeleccionado.bono.tipo_pago === "TIRADA_GRATIS") {
			return true;
		}
		return false;
	}

}
