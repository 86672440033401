import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {Actions, ofType} from "@ngrx/effects";
import * as CuentaAction from "../../redux/cuenta.actions";
import {Subscription} from "rxjs";
import Swal from "sweetalert2";
import {NotificationService} from "../../../shared/services/notification.service";
import { TranslateService } from "@ngx-translate/core";


declare function adjustSize(): any;

@Component({
	selector: "app-cta-datos",
	templateUrl: "./cta-datos.component.html",
	styleUrls: ["./cta-datos.component.css"]
})
export class CtaDatosComponent implements OnInit, AfterViewInit, OnDestroy {

	accordion: string = 'datos';
	subscription = new Subscription();

   userFiat:boolean = false;

	constructor(
		private store: Store<AppState>,
		private actions$: Actions,
		private notificacionService: NotificationService,
		private translate: TranslateService
	) {
	}

	ngOnInit(): void {
		this.subscription = this.actions$
			.pipe(ofType(CuentaAction.patchJugadorPasswordsCompletado))
			.subscribe(response => {
				let messaje = this.translate.instant('CAMBIAR_PASSWORD_COMPLETADO');
				Swal.fire(this.notificacionService.BuildSuccess(response.data.message, messaje)).then();
			});
		this.subscription = this.actions$
			.pipe(ofType(CuentaAction.patchJugadorDataCryptoCompletado))
			.subscribe(response => {
				let messaje = this.translate.instant('ACTUALIZAR_DATOS_COMPLETADO');
				Swal.fire(this.notificacionService.BuildSuccess(response.data.message, messaje)).then();
			});
		this.subscription = this.actions$
			.pipe(ofType(CuentaAction.patchJugadorFiatCompletado))
			.subscribe(response => {
				let messaje = this.translate.instant('ACTUALIZAR_DATOS_COMPLETADO');
				Swal.fire(this.notificacionService.BuildSuccess(response.data.message, messaje)).then();
			});
	}

	ngAfterViewInit() {
		adjustSize();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOGGLE ACCORDION ()
	onToggleAccordion(event, accord) {
		event.stopPropagation();
		this.accordion = this.accordion == accord ? '' : accord;
	}


}
