import {Routes} from "@angular/router";
import {BonosComponent} from "./components/bonos/bonos.component";
import {BonosHistComponent} from "./components/bonos-hist/bonos-hist.component";
import {VerifiedGuard} from "../home/guards/verified.guard";

export const BonosRoutes: Routes = [
	{
		path: "",
		children: [
			{path: "", redirectTo: "mis_bonos", pathMatch: "full"},
			{
				path: "mis_bonos", component: BonosComponent,
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			},
			{
				path: "historial", component: BonosHistComponent,
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			}
		]
	},
];
