<div class="w-cover coverB" id="" [class.active]="wActive==true" (swipedown)="onCloseW()">
   <!-- -->

	<div class="window pt-1 pb-1" id="nodo-info">

		<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="btn-window-close" (click)="onCloseW()">
				<div class="btn-window-close-bar"></div>
			</div>
         <div class=" of-y2 p-2">
           <img class="w-node" *ngIf="nodoSeleccionado.imagen" src="{{nodoSeleccionado.imagen}}">
         </div>

         <div class=" of-y2 p-2 pb-3">
            <p class="txt-lg lh-2 tac">{{nodoSeleccionado.descripcion}}</p>
         </div>

         <div class="p-2">
               <button type="button" class="btn2 btn2-lg btn2-1st full-w" (click)="onCloseW()">{{'OK'}}</button>
            <div class="cb"></div>
         </div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->


	</div>

</div>
