import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {apiRoutes} from "../../api-routing";
import {FilterModel} from "../../shared/models/filter.model";

@Injectable({
	providedIn: "root"
})
export class HelpService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	getAllHelpTickets(filter: FilterModel): Observable<any> {
		const url = this.buildURL(this.formatFilters(filter, apiRoutes.r_frontend_tickets_filter_all));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postNewTicket(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_tickets_add);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	patchCreatedTicket(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_login);
		const response = this.httpClient.patch(url, param.data);
		return response.pipe(map((data: any) => data));
	}

	deleteCreatedTicket(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_tickets_delete);
		const response = this.httpClient.delete(url);
		return response.pipe(map((data: any) => data));
	}

	postUploadFile(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_uploads);
		const response = this.httpClient.post(url, param.data);
		return response.pipe(map((data: any) => data));
	}

	getNewTicketNota(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_ticket_notas_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getDownloadFile(url: any): Observable<any> {
		// const url = this.buildURL(param.file);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postNewTicketNota(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_ticket_notas_post);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	private formatFilters(flt: FilterModel, url) {
		url += `?paginate=` + flt.paginate + `&ordenado_por=created_at&direction=DESC&page=` + flt.page;
		let filter = ``;
		if (flt.filter.filter.length !== 0) {
			filter += `&filter=[`;
			let first = true;
			flt.filter.forEach(value => {
				if (first) {
					filter += `["${value.atribute}"`;
				} else {
					filter += `,["${value.atribute}"`;
				}
				filter += `,"${value.operator}"`;
				if (value.value === true) {
					filter += `,true]`;
				} else if (value.value === false) {
					filter += `,false]`;
				} else {
					filter += encodeURI(`,"${value.value}"]`);
				}
				first = false;
			});
			filter += `]`;

		}
		return url + filter;
	}


}
