import {AfterViewInit, Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SessionServices} from "../../../shared/services/session.services";
import {NotificationsModel} from "../../../shared/models/notifications.model";
import {NotificationService} from "../../../shared/services/notification.service";
import Swal from "sweetalert2";
import {WTerminosCondicionesComponent} from "../../../shared/w-terminos-condiciones/w-terminos-condiciones.component";
import {WNewAccountComponent} from "../../../afiliados/components/w-new-account/w-new-account.component";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as AuthenticationActions from "../../../authetication/redux/authentication.actions";
import {WCallRequestComponent} from '../../../shared/w-call-request/w-call-request.component';
import * as GeneralAction from "../../../shared/redux/general.actions";
import {MyControlModel} from "../../../shared/models/control.model";
import {AfiliadoModel} from "src/app/shared/models/afiliado.model";

declare function adjustSize(): any;

@Component({
	selector: "app-mainmenu",
	templateUrl: "./mainmenu.component.html",
	styleUrls: ["./mainmenu.component.css"]
})
export class MainmenuComponent implements OnInit, AfterViewInit {

	menu: string;
	logoutSelected: boolean = false;
	subMenu: string;
	afiliadosMode: boolean = false;
	afiliado: AfiliadoModel = null;
	myMenu: MyControlModel;

	notificationes: NotificationsModel[];

	constructor(
		private router: Router,
		private notification: NotificationService,
		private sessionService: SessionServices,
		private modalService: NgbModal,
		private store: Store<AppState>
	) {
	}

	ngOnInit() {
		this.getNotifications();
		this.store.select((state) => state).subscribe((state) => {
			this.notificationes = state.general.notificaciones;
			this.menu = state.general.myMenu.sub_menu_activo;
			this.subMenu = state.general.myMenu.sub_sub_menu_activo;
			this.afiliadosMode = state.general.afiliadosMode;
			this.afiliado = state.afiliado.afiliado;
			this.myMenu = state.general.myMenu;
			this.menu = this.myMenu.sub_menu_activo;
			this.subMenu = this.myMenu.sub_sub_menu_activo;
		})
		adjustSize();
	}

	ngAfterViewInit() {
		adjustSize();
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOGGLE MENU ()
	onToggleMenu() {
		if (this.myMenu.show_menu_component) {
			const nuevoMenu = {
				menu: true,
				catalog: true,
				exchanger: true,
				back: true,
				show_catalog_component: false,
				show_exchager_component: false,
				show_back_component: false,
				show_menu_component: false,
				notificaciones: true,
				show_notificaciones: false,
				sub_menu_activo: this.menu,
				sub_sub_menu_activo: this.subMenu,
				cuenta_verificada:true
			};

			this.store.dispatch(GeneralAction.setMyMenu({
				menu: nuevoMenu,
				eventEmiterComponent: this.constructor.name
			}));
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW TERMS ()
	onShowTerms(event, ms) {
		event.stopPropagation();
		//this.menu = ms;
		this.onToggleMenu();
		const modalRef = this.modalService.open(WTerminosCondicionesComponent);
		modalRef.result.then();
	}

	afiliadoAccount() {
		if (this.afiliado && this.afiliado.id) {
			return true;
		}

		return false;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET M2 ()
	onSetM2(event, ms) {
		event.stopPropagation();
		this.logoutSelected = false;
		// - - - - - - - - - - - - - - - - - - - - PLAYGROUND
		let player = this.sessionService.getPlayer();
		switch (ms) {
			case "inicio":
				if (this.afiliadosMode == true) {
					this.menu = 'cajero';
				}
				this.onToggleMenu();
				this.store.dispatch(GeneralAction.setAfiliadosMode({afiliadosMode: false}));
				this.router.navigate(["/inicio/jugador"]).then();
				return;
				break;
			case "ayuda":
				if (this.menu != 'ayuda') {
					ms = 'ayuda';
					break;
				} else {
					ms = '';
				}
			case "cajero":
				//console.log('EN CAJERO');
				break;
			case "bonos":
				break;
			case "logout":
				this.logOutAction();
				break;
			case "cuenta":
				break;
			case "afiliados":
				if (player) {
					if (player.estado_cuenta.toUpperCase() !== "ACTIVA") {
						Swal.fire(this.notification.BuildError("Por favor verfique su cuenta!", "Verificar")).then();
						return;
					}
				}
				if (!this.afiliadoAccount()) {
					this.onNewAfAccount(null, "crear cuenta");
					return;
				}
				this.menu = ms;
				this.subMenu = null;
				this.router.navigate(["/afiliados/inicio"]).then();
				this.onToggleMenu();
				break;
			case "playground":
				this.onToggleMenu();
				this.store.dispatch(GeneralAction.setAfiliadosMode({afiliadosMode: false}));
				this.menu = ms;
				//
				this.router.navigate(["/games/" + ms]).then();
				break;
			default:
				this.store.dispatch(GeneralAction.setAfiliadosMode({afiliadosMode: false}));
				break;
		}
		console.log("es esta");
		const nuevoMenu = {
			menu: true,
			catalog: true,
			exchanger: false,
			back: false,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: true,
			notificaciones: true,
			show_notificaciones: false,
			sub_menu_activo: ms,
			sub_sub_menu_activo: this.subMenu,
			cuenta_verificada: true
		};
		this.store.dispatch(GeneralAction.setMyMenu({menu: nuevoMenu, eventEmiterComponent: this.constructor.name}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GO M1 ()
	onGoM1(event, ms) {
		if (event) {
			event.stopPropagation();
		}
		if (this.menu === "cajero") {
			switch (ms) {
				case "wallet":
					this.router.navigate(["/cajero/wallet"]).then();
					break;
				case "exchanger":
					this.router.navigate(["/cajero/exchanger"]).then();
					break;
				case "verificacion":
					this.router.navigate(["/cajero/verificacion"]).then();
					break;
				case "retiros":
					this.router.navigate(["/cajero/retiros"]).then();
					break;
				default:
					this.router.navigate(["/cajero/"]).then();
					break;
			}
		} else if (this.menu === "bonos") {
			switch (ms) {
				case "mis_bonos":
					this.router.navigate(["/bonos/mis_bonos"]).then();
					break;
				case "historial":
					this.router.navigate(["/bonos/historial"]).then();
					break;
				default:
					this.router.navigate(["/bonos/"]).then();
					break;
			}
		} else if (this.menu === "cuenta") {
			switch (ms) {
				case "datos":
					this.router.navigate(["/cuenta/datos"]).then();
					break;
				case "movimientos":
					this.router.navigate(["/cuenta/movimientos"]).then();
					break;
				case "juegos":
					this.router.navigate(["/cuenta/juegos"]).then();
					break;
				case "movimientos_cripto":
					this.router.navigate(["/cuenta/movimientos_cripto"]).then();
					break;
				default:
					this.router.navigate(["/cuenta/"]).then();
					break;
			}
		} else if (this.menu === "afiliados") {
			switch (ms) {
				case "campannas":
					this.router.navigate(["/afiliados/campannas"]).then();
					break;
				case "trafico":
					this.router.navigate(["/afiliados/trafico"]).then();
					break;
				case "comisiones":
					this.router.navigate(["/afiliados/comisiones"]).then();
					break;
				case "balances_retiros":
					this.router.navigate(["/afiliados/balances_retiros"]).then();
					break;
				default:
					this.router.navigate(["/afiliados/"]).then();
					break;
			}
		}

		const nuevoMenu = {
			menu: true,
			catalog: true,
			exchanger: true,
			back: true,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: false,
			notificaciones: true,
			show_notificaciones: false,
			sub_menu_activo: this.menu,
			sub_sub_menu_activo:  this.sessionService.getPlayer().estado_cuenta.toUpperCase() === "ACTIVA" ? ms : '',
			cuenta_verificada: this.sessionService.getPlayer().estado_cuenta.toUpperCase() === "ACTIVA" ? true : false
		};
		this.store.dispatch(GeneralAction.setMyMenu({menu: nuevoMenu, eventEmiterComponent: this.constructor.name}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  /
	playerLogged() {
		return !!this.sessionService.getPlayer();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON NEW ACCOUNT ()
	onNewAfAccount(event, ms) {
		if (event) {
			event.stopPropagation();
		}
		const player: any = this.sessionService.getPlayer();
		if (player) {
			if (player.estado_cuenta.toUpperCase() !== "ACTIVA") {
				Swal.fire(this.notification.BuildError("Por favor verfique su cuenta!", "Verificar")).then();
				return;
			}
		}

		const modalRef = this.modalService.open(WNewAccountComponent);
		modalRef.result.then(
			(resp) => {
				if (resp) {
					this.menu = "afiliados";
					this.onGoM1(null, "campannas");
				}
			}
		);
		this.onToggleMenu();
	}

	logOutAction() {
		this.logoutSelected = true;
		const message = "Esta seguro de que desea salir de la aplicación";
		Swal.fire(this.notification.BuildQuestion(message, "Cerrar Sessión")).then(
			(result) => {
				if (result.value) {
					this.logoutSelected = false;
					this.store.dispatch(AuthenticationActions.logout());
				}
			}
		);

	}

	// API CALLS

	getNotifications() {
		this.store.dispatch(GeneralAction.getNotificaciones());
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON OPEN TICKETS ()
	onOpenTickets(event) {
		event.stopPropagation();
		this.onToggleMenu();
		this.router.navigate(["/ayuda/tickets"]);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CALL REQUEST ()
	onCallRequest(event) {
		event.stopPropagation();
		this.onToggleMenu();
		const modalRef = this.modalService.open(WCallRequestComponent);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  ON HELP CHAT
	onHelpChat(event) {
		event.stopPropagation();
   }

   onHelp(event) {
      event.stopPropagation();
   }

	readNotifs() {
		let counter = 0;
		for (const notif of this.notificationes) {
			if (!notif.leido) {
				counter++;
			}
		}
		return counter;
	}

	marcarComoLeida(event, id) {
		event.stopPropagation();
		this.store.dispatch(GeneralAction.marcarNotificacionComoLeido({id: id}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DEL NOTIF ()
	onDelNotify(event, id) {
		event.stopPropagation();
		this.store.dispatch(GeneralAction.eliminarNotificacion({id: id}));
	}

}
