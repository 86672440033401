import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthenticationService} from "./services/authentication.service";
import {SharedModule} from "../shared/shared.module";
import {RegisterComponent} from "./components/register/register.component";
import {FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from "angularx-social-login";
import {LoginComponent} from "./components/login/login.component";
import {StoreModule} from "@ngrx/store";
import * as fromAuthenticationReducer from "./redux/authentication.reducers";
import {EffectsModule} from "@ngrx/effects";
import {AuthenticationEffects} from "./redux/authentication.effects";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		SharedModule,
		SocialLoginModule,
		StoreModule.forFeature( "auth", fromAuthenticationReducer.reducer ),
		EffectsModule.forFeature([AuthenticationEffects]),
		TranslateModule
	],
	providers: [
		NgbActiveModal,
		AuthenticationService,
		{
			provide: "SocialAuthServiceConfig",
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: FacebookLoginProvider.PROVIDER_ID,
						provider: new FacebookLoginProvider("641323080140960"),
					}
				],
			} as SocialAuthServiceConfig,
		}
	],
	declarations: [
		RegisterComponent,
		LoginComponent
	],
	exports: [
		RegisterComponent,
		LoginComponent
	]
})
export class AuthenticationModule {
	static forRoot(): ModuleWithProviders<unknown> {
		return {
			ngModule: AuthenticationModule
		};
	}
}
