<!-- - - - - - - - - - - - - - - - - - - LANG -->
<div class="lang-cover p-1" (click)="noAction($event)">
   <div class="languages p-2 bg-g1">
      <ul class="lang-list mb-3">
         <ng-container *ngFor="let item of langs; let i = index;">
            <li [class.selected]="langSelected.id === item.id"
                (click)="onSetLang($event, i)">
               <img src="../../../../assets/images/flags/{{item.siglas.toLowerCase()}}.svg" alt="{{item.id}}">
               {{item.nombre.toUpperCase()}} - <Span class="txt-cap">{{item.siglas.toLowerCase()}}</Span>
            </li>
         </ng-container>
      </ul>
      <div class="col col-6 pr-05">
         <button (click)="onCancel($event)" class="btn2 btn2-nor btn2-g full-w tac" type="button">
            {{ 'CANCELAR' | translate }}
         </button>
      </div>
      <div class="col col-6 pl-05">
         <button (click)="onSave($event)" class="btn2 btn2-nor btn2-1st full-w tac" type="button">
            {{ 'SELECCIONAR' | translate }}
         </button>
      </div>
   </div>
</div>
<!-- - - - - - - - - - - - - - - - - - - / LANG -->
