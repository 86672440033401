<section class="bg-g6t" [class.active]="myMenu.show_back_component" id="back" (swipedown)="onCloseBack()">
	<div id="back-container-0" *ngIf="currentWallet">

		<div class="back-balances-container">

			<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
			<div class="hidden-landscape">
				<div class="btn-window-close" (click)="onCloseBack()">
					<div class="btn-window-close-bar"></div>
				</div>
				<p class="w-title-cover txt-ssm txt-w tac">{{'SELECCION_BALANCE_ACTIVO' |translate}}</p>
			</div>
			<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

			<div class="back-container-portrait">

				<p class="txt-sm txt-w tac pt-05 pb-05 pl-2 hidden-portrait">{{'SELECCION_BALANCE' |translate}}</p>

				<div class="pos-rel full-h">
					<div class="back-container-1">

						<!-- BALANCE SELECTED -->
						<!-- - - - - - - - - - - - - - - - - - LANDSCAPE -->
						<div [class.selected]="true" class="balance hidden-portrait">
							<!-- BALANCE AMOUNT -->
							<!-- <div class="uso active">EN JUEGO</div> -->

							<div class="balance-amount px-1 pt-1 pb-05">
								<p class="txt-ssm txt-w">
									<img class="balance-coin mr-05"
										 src="../../../../assets/images/crypto/{{currentWallet.moneda.siglas.toLowerCase()}}.svg"
										 (error)="changeImg($event, currentWallet.moneda.imagen)">
									<span class="balance-coin-name ellipsis">{{currentWallet.moneda.nombre}}</span>
								</p>
								<div class="cb mb-05"></div>
								<div class="ff-cnd txt-ssm">
									<span class="txt-c2">{{currentWallet.moneda.siglas.toUpperCase()}} </span>
									{{ currentWallet.balance_total | number: '1.2-8'}}

									<span class="fr">
										<span class="txt-c4">{{ 'usd'.toUpperCase()}} </span>
										<span class="txt-gc">
											{{ currentWallet.balance_moneda_base | number: '1.2-6'}}
										</span></span>
								</div>
								<div class="cb mb-02"></div>
							</div>
							<!-- / BALANCE AMOUNT -->
							<!-- BALANCE BONO -->
							<div class="balance-bono">
								<div *ngIf="!currentWallet.bono">
									<p class="txt-sssm txt-g9 ff-cnd">{{'NO_TIENE_BONO_ACTIVO' |translate}}</p>
								</div>
								<div *ngIf="currentWallet.bono">
									<p class="txt-sssm txt-w ff-cnd">
										{{ currentWallet.bono.nombre }}
									</p>
								</div>
							</div>
							<!-- / BALANCE BONO -->
						</div>
						<!-- - - - - - - - - - - - - - - - - - / LANDSCAPE -->

						<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
						<div [class.selected]="true" class="balance hidden-landscape">
							<div class="balance-amount">
								<img
									src="../../../../assets/images/crypto/{{currentWallet.moneda.siglas.toLowerCase()}}.svg"
									(error)="changeImg($event, currentWallet.moneda.imagen)">
								<div class="balance-name">{{currentWallet.moneda.nombre}}</div>
							</div>
							<div class="p-1 bb-1-g3">
								<div class="fl txt-sm txt-c2">{{currentWallet.moneda.siglas.toUpperCase()}}</div>
								<div class="fr txt-sm txt-w">{{ currentWallet.balance_total | number: '1.2-8'}}</div>
								<div class="cb mb-05"></div>
								<div class="fl txt-sm txt-c4">{{ 'usd'.toUpperCase()}}</div>
								<div
									class="fr txt-sm txt-w">{{ currentWallet.balance_moneda_base | number: '1.2-6'}}</div>
								<div class="cb"></div>
							</div>
							<div class="p-1">
								<p class="txt-ssm txt-g8 mb-03">{{'BONO_ACTIVO' |translate}}:</p>
								<p class="txt-ssm full-w ellipsis">
									{{currentWallet.bono ? currentWallet.bono.nombre : 'NO_TIENE_BONO_ACTIVO' |translate }}</p>
							</div>
						</div>
						<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->
						<!-- / BALANCE SELECTED -->


						<!-- BALANCE -->
						<ng-container *ngFor="let wallet of allWallets; let i = index">
							<ng-container *ngIf="!wallet.es_el_activo">
								<div (click)="onSelectBalance(wallet.moneda.id)" class="balance">
									<!-- - - - - - - - - - - - - - - - - - LANDSCAPE -->
									<div class="hidden-portrait">
										<!-- BALANCE AMOUNT -->
										<!-- <div class="uso active">EN JUEGO</div> -->
										<div class="balance-amount px-1 pt-1 pb-05">
											<p class="txt-ssm txt-w">
												<img class="balance-coin mr-05"
													 src="../../../../assets/images/crypto/{{wallet.moneda.siglas.toLowerCase()}}.svg"
													 (error)="changeImg($event, wallet.moneda.imagen)">
												{{wallet.moneda.nombre}}</p>
											<div class="cb mb-05"></div>
											<div class="ff-cnd txt-ssm">

												<span class="txt-c2">{{wallet.moneda.siglas.toUpperCase()}} </span>
												{{ wallet.balance_total | number: '1.2-6'}}

												<span class="fr">
                                    <span class="txt-c4">{{ 'usd'.toUpperCase() }} </span>&nbsp;<span
													class="fr txt-gc"> {{wallet.balance_moneda_base | number: '1.2-6'}}
                                    </span></span>

											</div>
											<div class="cb mb-02"></div>
										</div>
										<!-- / BALANCE AMOUNT -->
										<!-- BALANCE BONO -->
										<div class="balance-bono">
											<div *ngIf="!wallet.bono">
												<p class="txt-sssm txt-g9 ff-cnd">{{'NO_TIENE_BONO_ACTIVO' |translate}}</p>
											</div>
											<div (click)="showTooltip($event,3.5)" *ngIf="wallet.bono"
												 class="tooltip tt-bonus tt-rt">
												<p class="txt-sssm txt-w ff-cnd">
													{{wallet.bono ? wallet.bono.nombre : "Sin bono activo"}}
												</p>
												<span
													class="tt-content">{{wallet.bono.nombre}}.</span>
											</div>
										</div>
										<!-- / BALANCE BONO -->
									</div>
									<!-- - - - - - - - - - - - - - - - - - / LANDSCAPE -->

									<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
                           <div class="hidden-landscape">
                              <div class="balance-amount">
                                 <img
                                    src="../../../../assets/images/crypto/{{wallet.moneda.siglas.toLowerCase()}}.svg"
                                    (error)="changeImg($event, wallet.moneda.imagen)">
                                 <div class="balance-name">{{wallet.moneda.nombre}}</div>
                              </div>
                              <div class="p-1 bb-1-g3">
                                 <div class="fl txt-sm txt-c2">{{wallet.moneda.siglas.toUpperCase()}}</div>
                                 <div class="fr txt-sm txt-w">{{ wallet.balance_total | number: '1.2-6'}}</div>
                                 <div class="cb mb-05"></div>
                                 <div class="fl txt-sm txt-c4">{{ 'usd'.toUpperCase()}}</div>
                                 <div
                                    class="fr txt-sm txt-w">{{wallet.balance_moneda_base | number: '1.2-6'}}</div>
                                 <div class="cb"></div>
                              </div>
                              <div class="p-1">
                                 <p class="txt-ssm txt-g8 mb-03">{{'BONO_ACTIVO' |translate}}:</p>
                                 <p class="txt-ssm">
                                    {{wallet.bono ? wallet.bono.nombre : 'NO_TIENE_BONO_ACTIVO' |translate}}</p>
                              </div>
                           </div>
									<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->
								</div>
							</ng-container>
						</ng-container>
						<!-- / BALANCE -->


						<div class="balance empty hidden-portrait"></div>

						<!-- <div class="of-fade fade-x hidden-portrait"></div> -->
					</div>
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

			<!-- - - - - - - - - - - - - - - - - - LANDSCAPE -->
			<div class="pl-1 pr-2 hidden-portrait">
				<table class="tbl-balance tbl-striped ff-cnd bg-g1">
					<tr class="ff-cnd">
						<th></th>
						<th colspan="2" class="bl-1-k">{{'MONEDA' |translate}}</th>
						<th colspan="2" class="bl-1-k">{{'SUBUNIDAD' |translate}}</th>
						<th class="bl-1-k">{{'CREDITOS_MAQUINA' |translate}}</th>
						<th colspan="2" class="bl-1-k">USD</th>
					</tr>

					<tr class="">
						<td class="t-sm0 txt-w">{{'BALANCE' |translate}}:</td>
						<td class="bl-1-k txt-c2">{{currentWallet.moneda.siglas.toUpperCase()}}</td>
						<td class="txt-w tar">{{currentWallet.balance_total | number: "1.2-8"}}</td>
						<td class="bl-1-k txt-c4-l">m{{currentWallet.moneda.siglas.toUpperCase()}}</td>
						<td class="txt-cg tar">{{(currentWallet.balance_total) * 1000 | number: "1.0-0"}}</td>
						<td class="col-2 bl-1-k txt-cg tar">
							{{currentWallet.balances_maquina.balance_total | number: "1.0-0"}}</td>
						<td class="bl-1-k txt-c4-l">$</td>
						<td class="txt-cg tar">{{currentWallet.balance_moneda_base | number: "1.2-8"}}</td>
					</tr>
					<tr class="">
						<td class="txt-w">{{'ULTIMA_APUESTA' |translate}}:</td>
						<td class="bl-1-k txt-c2">{{currentWallet.moneda.siglas.toUpperCase()}}</td>
						<td class="txt-w tar">{{currentWallet.saldo_ultimo_apuesta | number: "1.2-8"}}</td>
						<td class="bl-1-k txt-c4-l">m{{currentWallet.moneda.siglas.toUpperCase()}}</td>
						<td class="txt-cg tar">{{(currentWallet.saldo_ultimo_apuesta) * 1000 | number: "1.0-0"}}</td>
						<td class="bl-1-k txt-cg tar">
							{{currentWallet.balances_maquina.saldo_ultimo_apuesta | number: "1.0-0"}}</td>
						<td class="bl-1-k txt-c4-l">$</td>
						<td class="txt-cg tar">{{currentWallet.saldo_ultimo_apuesta_moneda_base | number: "1.2-8"}}</td>
					</tr>
					<tr class="">
						<td class="txt-w">{{'ULTIMA_PREMIO' |translate}}:</td>
						<td class="bl-1-k txt-c2">{{currentWallet.moneda.siglas.toUpperCase()}}</td>
						<td class="txt-w tar">{{currentWallet.saldo_ultimo_premio | number: "1.2-8"}}</td>
						<td class="bl-1-k txt-c4-l">m{{currentWallet.moneda.siglas.toUpperCase()}}</td>
						<td class="txt-cg tar">{{(currentWallet.saldo_ultimo_premio) * 1000 | number: "1.0-0"}}</td>
						<td class="bl-1-k txt-cg tar">
							{{currentWallet.balances_maquina.saldo_ultimo_premio | number: "1.0-0"}}</td>
						<td class="bl-1-k txt-c4-l">$</td>
						<td class="txt-cg tar">{{currentWallet.saldo_ultimo_premio_moneda_base | number: "1.2-8"}}</td>
					</tr>
				</table>
			</div>

			<div class="pl-1 pr-2 hidden-portrait">
				<div class="p-1" id="ticker">
					<div class="ticker-inner">
						<div class="ticker-container">
							<!-- -->
							<span class="msg txt-uc">SCROOLING <b>TICKER</b>.</span>
							<span class="msg txt-uc">Et eus amici propter <b>SAPIENTIA</b> laudarunt.</span>
							<span class="msg txt-uc">Los primeros dados de seis lados se hallaron en Mesopotamia y se
								remontan al
								año 3000
								a.C.</span>
							<!-- -->
							<div class="cb"></div>
						</div>
					</div>
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - - - / LANDSCAPE -->

			<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
			<div class="hidden-landscape pt-2">
				<div class="pt-05 bg-g1">
					<table class="tbl2 tbl2-sm tbl2-striped striped-alt">
						<tr class="ff-cnd">
							<th></th>
							<th colspan="2">
								<span class="txt-sm">{{'MONEDA' |translate}}</span>
							</th>
							<th colspan="2">
								<span class="txt-sm">{{'EQUIV_USD' |translate}}</span>
							</th>
						</tr>
						<tr>
							<td class="ff-cnd">{{'BALANCE' |translate}}</td>
							<td class="ff-cnd txt-c2">{{currentWallet.moneda.siglas.toUpperCase()}}</td>
							<td class="tar">{{currentWallet.balance_total | number: "1.2-8"}}</td>
							<td class="ff-cnd txt-c4">$</td>
							<td class="tar">{{currentWallet.balance_moneda_base | number: "1.2-8"}}</td>
						</tr>
						<tr>
							<td class="ff-cnd">{{'ULTIMA_APUESTA' |translate}}</td>
							<td class="ff-cnd txt-c2">{{currentWallet.moneda.siglas.toUpperCase()}}</td>
							<td class="tar">{{currentWallet.saldo_ultimo_apuesta | number: "1.2-8"}}</td>
							<td class="ff-cnd txt-c4">$</td>
							<td class="tar">{{currentWallet.saldo_ultimo_apuesta_moneda_base | number: "1.2-8"}}</td>
						</tr>
						<tr>
							<td class="ff-cnd">{{'ULTIMA_PREMIO' |translate}}</td>
							<td class="ff-cnd txt-c2">{{currentWallet.moneda.siglas.toUpperCase()}}</td>
							<td class="tar">{{currentWallet.saldo_ultimo_premio | number: "1.2-8"}}</td>
							<td class="ff-cnd txt-c4">$</td>
							<td class="tar">{{currentWallet.saldo_ultimo_premio | number: "1.2-8"}}</td>
						</tr>
					</table>
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

		</div>

	</div>
</section>
