import {createAction, props} from '@ngrx/store';
import {CategoryGamesModel, GamesModel} from "../../shared/models/games.model";
import {GameCategoryModel} from "../../shared/models/game-category,model";
import {StartGameModel} from "../../shared/models/start-game.model";


export const juegosAuthCompletado = createAction('[Games] Juegos Auth Completado', props<{ data: any }>());
export const clasificacionesVisualesJuegos = createAction('[Games] Clasificaciones Visuales', props<{ endUrl: string }>());
export const clasificacionesVisualesJuegosCompletado = createAction('[Games] Clasificaciones Visuales Completado', props<{ data: any }>());
export const setCategoriaSeleccionada = createAction('[Games] Set Categoria Seleccionada', props<{ cat: GameCategoryModel }>());
export const gamesLogoutCompletado = createAction('[Games] Logout Completado');
export const setFilterGames = createAction('[Games] Set Filter games', props<{ name: string, moneda_id:number }>());
export const clearFilterGames = createAction('[Games] Clear Filter games');
export const putLikeFavorite = createAction('[Games] Setear como favorito',props<{ game: GamesModel }>());
export const putLikeFavoriteCompletado = createAction('[Games] Setear como favorito Completado', props<{ data: any }>());
export const setJuegoSeleccionado = createAction('[Games] Setear juego completado', props<{ game: GamesModel }>());
export const iniciarJuego = createAction('[Games] Iniciar Juego', props<{ id: number }>());
export const iniciarJuegoCompletado = createAction('[Games] Iniciar Juego Completado', props<{ data: StartGameModel }>());
export const iniciarJuegoError = createAction('[Games] Iniciar Juego Error', props<{ error: any }>());

export const getAllImagenes = createAction('[Games] Get All Imagenes');
export const getAllImagenesCompletado = createAction('[Games] Get All Imagenes Completado', props<{ data:any }>());

export const getSoloClasificaciones = createAction('[Games] Get Solo Clasificaciones Visuales');
export const getSoloClasificacionesCompletado = createAction('[Games] Get Solo Clasificaciones Visuales Completado', props<{ data:any }>());


