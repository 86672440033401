<section class="" id="wallet">
	<div class="section-header">
		<div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
			<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
		</div>

		<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="section-title">
				<div class="txt-snor pr-1">Wallet</div>
				<img src="../../../../assets/images/icon-m-wallet.svg" class="section-icon">
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>

	<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
	<div class="full-h hidden-landscape">
		<div class="wallet-container full-h">

			<div class=""></div>

			<div class="px-15">
				<!-- WALLET DETAIL -->
				<ng-container *ngIf="!comprobandoFreeSpins()">
					<ng-container>
						<div class="wallet-detail">
							<img class="wallet-coin"
								 src="../../../../assets/images/crypto/{{walletSeleccionado.moneda.siglas.toLowerCase()}}.svg">
							<div class="ff-mid txt-nor txt-w tac txt-cap mb-3">
								{{walletSeleccionado.moneda.nombre.toLowerCase()}}</div>
							<div class="col col-4 tac">
								<p class="txt-sm txt-g9 mb-15">{{'BALANCE' |translate}}<br>&nbsp;</p>
								<p class="txt-lg txt-w">{{walletSeleccionado.balance_total | number: "1.2-8"}}</p>
							</div>
							<div class="col col-4 tac">
								<p class="txt-sm txt-g9 mb-15">{{'EQUIV_USD' |translate}}<br>USD</p>
								<p class="txt-lg txt-w">{{walletSeleccionado.balance_moneda_base | number: "1.2-8"}}</p>
							</div>
							<div class="col col-4 tac">
								<p class="txt-sm txt-g9 mb-15">{{'DISPONIBLE_PARA_RETIRAR' |translate}}</p>
								<p class="txt-lg txt-w">{{walletSeleccionado.balance_retirable | number: '1.2-8'}}</p>
							</div>
							<div class="cb mb-3"></div>
							<div class="tar">
								<button type="button" *ngIf="walletSeleccionado.balance_total === 0"
										(click)="onDelete()" class="btn2 btn2-7th btn2-lg btn2-lg-icon mr-1">
									<img src="../../../../assets/images/icon-b-trash.svg">
								</button>
								<button type="button" (click)="onExchange()"
										class="btn2 btn2-4th btn2-lg btn2-lg-icon mr-1">
									<img src="../../../../assets/images/icon-m-exchanger.svg">
								</button>
								<button type="button" *ngIf="walletSeleccionado.balance_total !== 0"
										class="btn2 btn2-1st btn2-lg btn2-lg-icon mr-1" (click)="onWithdraw()">
									<img src="../../../../assets/images/icon-b-retiro.svg">
								</button>
								<button type="button" class="btn2 btn2-1st btn2-lg btn2-lg-icon"
										(click)="createCurrencyHash()">
									<img src="../../../../assets/images/icon-b-deposito.svg">
								</button>
							</div>
						</div>
					</ng-container>
				</ng-container>
				<!-- / WALLET DETAIL -->

				<!-- FREE SPINS -->
				<div class="wallet-detail" *ngIf="comprobandoFreeSpins()">
					<img class="wallet-coin spinning" src="../../../../assets/images/crypto/free_spin.svg">
					<div class="ff-mid txt-nor txt-w tac mb-3">{{'TIRADAS_GRATIS' |translate}}</div>
					<div class="tac">
						<p class="txt-sm txt-g9 mb-15">&nbsp;{{'TE_QUEDAN' |translate}}:</p>
						<h1 class="mb-3">{{walletSeleccionado.bono.no_tiradas_gratis}}</h1>
						<p class="txt-ssm txt-g9 pb-05">{{'JUGANDO_TIRADAS_GRATIS_LINK_1' |translate}} <span class="ff-bol txt-w" [routerLink]="['/bonos/mis_bonos']" [queryParams]="{activos: true}"> {{'MIS_BONOS' |translate}} </span> {{'JUGANDO_TIRADAS_GRATIS_LINK_2' |translate}}.</p>
					</div>
				</div>
				<!-- FREE SPINS -->
			</div>

			<!-- WALLETS -->
			<div class="wallets noScrlBr">
				<div class="wallet-item" *ngFor="let curr of walletCurrency; let i = index;"
					 [class.selected]="curr.id==walletSeleccionado.id" (click)="onSelectWallet(curr)">
					<img class="wallet-coin mb-03"
						 src="../../../../assets/images/crypto/{{curr.moneda.siglas.toLowerCase()}}.svg"
						 (error)="changeImg($event, curr.moneda.imagen)">
					<div class="full-w txt-sm txt-c2 px-05 mb-03">{{curr.moneda.siglas}}</div>
					<div class="txt-sssm">{{curr.balance_total | number: "1.2-8"}}</div>
				</div>
			</div>
			<!-- / WALLETS -->

		</div>
	</div>
	<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

</section>
