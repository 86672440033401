import {Component, OnDestroy, OnInit, AfterViewInit} from "@angular/core";
import {WWwTermsComponent} from "../w-ww-terms/w-ww-terms.component";
import {CircuitoJugadorModel, CircuitoModel, NodoJugadorModel} from "../../../shared/models/circuitos.model";
import * as GeneralAction from "../../../shared/redux/general.actions";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AppState} from "src/app/shared/redux/general.reducers";
import {Store} from "@ngrx/store";

import {WCircuitoNodeMsgComponent} from "../w-circuito-node-msg/w-circuito-node-msg.component";

@Component({
	selector: "app-circuito",
	templateUrl: "./circuito.component.html",
	styleUrls: ["./circuito.component.css"]
})
export class CircuitoComponent implements OnInit, AfterViewInit, OnDestroy {

	circuito: CircuitoModel;
	circuitoJugador: CircuitoJugadorModel;
	nodoJugador: NodoJugadorModel;

	circuitoEstado: string = "ACTIVO";
	node: number;
	currentNode: number;

	firstTime: boolean = true;

	counterOn: any;
	countDD: number;
	countHH: number;
	countMM: number;
	countSS: number;

	showCircuito: boolean = false;

	st: any;

	constructor(
		private modalService: NgbModal,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.node = 1;
		this.currentNode = 1;
		this.initData();
		// document.body.classList.add('landscape-turn');
	}

	ngAfterViewInit() {
	}

	initData() {
		this.store.select((state) => state).subscribe(state => {
				this.circuitoJugador = state.general.circuitoActivo.value;
				this.showCircuito = state.general.showCircuitoActivo;
				if (this.circuitoJugador) {
					this.circuito = this.circuitoJugador.circuito;
					this.nodoJugador = this.circuitoJugador.nodo_jugador;
					this.currentNode = this.circuitoJugador.nodo_jugador ? this.circuitoJugador.nodo_jugador.nodo.posicion : null;
				} else {
					this.circuito = null;
					this.nodoJugador = null;
					this.currentNode = 1;
				}

				if (this.circuito) {
					this.setCircuitoTimer();
				}
				if (this.showCircuito) {
					this.walkNodes();
				}
			});

		this.getCircuitosActivo();
	}

	ngOnDestroy() {
		// document.body.classList.remove('landscape-turn');
		if (this.counterOn) {
			clearInterval(this.counterOn);
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DO NOTHING ()
	doNothing(event) {
		event.stopPropagation();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW TERMS & CONDITIONS ()
	onShowTerms() {
		const modalRef = this.modalService.open(WWwTermsComponent);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON HIDE CIRCUIT ()
	onHideCircuit(event) {
		this.store.dispatch(GeneralAction.hideCircuitoActivo());
	}

	dhm(ms) {
		const days = Math.floor(ms / (24 * 60 * 60 * 1000));
		const daysms = ms % (24 * 60 * 60 * 1000);
		const hours = Math.floor((daysms) / (60 * 60 * 1000));
		const hoursms = ms % (60 * 60 * 1000);
		const minutes = Math.floor((hoursms) / (60 * 1000));
		const minutesms = ms % (60 * 1000);
		const sec = Math.floor((minutesms) / (1000));

		this.countDD = Number(days);
		this.countHH = Number(hours);
		this.countMM = Number(minutes);
		this.countSS = Number(sec);

	}

	setCircuitoTimer() {
		const today = new Date();
		let finalDay;
		if (this.circuito.estado === "ACTIVO") {
			finalDay = new Date(this.circuito.fecha_fin);
		} else {
			finalDay = new Date(this.circuito.fecha_inicio);
		}

		// @ts-ignore
		const diff = finalDay - today;
		this.dhm(diff);
		this.counterOnFunc();
	}

	counterOnFunc() {
		if (this.counterOn) {
			clearInterval(this.counterOn);
		}
		this.counterOn = setInterval(() => {
			this.countSS--;
			if (this.countSS < 0) {
				this.countSS = 59;
				this.countMM--;
				if (this.countMM < 0) {
					this.countMM = 59;
					this.countHH--;
					if (this.countHH < 1) {
						this.countHH = 23;
						this.countDD--;
					}
				}
			}
		}, 1000);
	}

	walkNodes() {

		const circuitoElement = document.getElementById("circuit-container");
		const walker = document.getElementById("mr-ww") as HTMLImageElement;
		if (this.showCircuito && this.firstTime && !this.st) {
			this.st = setInterval(
				() => {
					if (this.currentNode === this.node) {
						this.firstTime = false;
						clearInterval(this.st);
					} else {
						this.node++;
						setTimeout(() => {
							const walkertop = walker.offsetTop;
							//console.log("walkertop: " + walkertop);
							circuitoElement.scrollTo(0, walkertop);
						}, 850);
					}
				}, 900
			);
		}
	}

	getCircuitosActivo() {
		this.store.dispatch(GeneralAction.getCircuitoActivo());
	}


	onOpenNode(e: Event, node: any) {
		e.stopPropagation();
		const modalRef = this.modalService.open(WCircuitoNodeMsgComponent);
		modalRef.componentInstance.position = node;
		modalRef.result.then();
	}

}
