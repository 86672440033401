import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import * as GeneralAction from "../../../shared/redux/general.actions";

declare function adjustSize(): any;

@Component({
	selector: "app-cuenta",
	templateUrl: "./cuenta.component.html",
	styleUrls: ["./cuenta.component.css"]
})
export class CuentaComponent implements OnInit, AfterViewInit {

	afiliadosMode: boolean = true;

	constructor(private store: Store<AppState>) { }

	ngOnInit(): void {
		this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
		});
		this.store.dispatch(GeneralAction.setAfiliadosMode({ afiliadosMode: false }));
	}

	ngAfterViewInit() {
		adjustSize();
	}

}
