<!-- OJO - ID css-->
<div class="w-cover" id="cover-back"> <!-- (click)="onCloseW($event)" -->

	<div class="window px-1" id="back"><!-- (click)="noClick($event)" -->

		<article class="" id="back-balance">
			<div class="txt-xsm tac mb-05">{{'MIS_BALANCES' |translate}}</div>

			<div class="px-1">
				<div class="pos-rel">
					<div class="balances-container of-x">

						<!-- BALANCE -->
						<ng-container *ngFor="let item of arrayOne(5); let i = index;">
							<div (click)="onSelectBalance(i)" [class.selected]="balanceSelected==i" class="balance">
								<!-- BALANCE AMOUNT -->
								<div class="balance-amount mb-03 px-1 py-05">
									<p class="txt-sm txt-w">
										<img class="balance-coin mr-05"
											 src="../../../../assets/images/crypto/{{coins[i]}}.svg">
										{{coins2[i]}}</p>

									<div class="cb mb-05"></div>
									<span class="ff-cnd txt-ssm">
									<span class="txt-c2">{{coins[i].toUpperCase()}} </span>
									<span class="fr"> {{ 123.45678908 | number: '1.2-8'}} </span>
								</span>
									<div class="cb mb-02"></div>
									<span class="ff-cnd">
									<span class="txt-c4">{{ 'usd'.toUpperCase()}} </span>
									<span class="fr txt-gc"> {{'10,123.45'}} </span>
								</span>

								</div>
								<!-- / BALANCE AMOUNT -->
								<!-- BALANCE BONO -->
								<div class="balance-bono">
									<div *ngIf="bonus[i]==false">
										<p class="txt-xsm txt-g9 ff-cnd">No tiene bono activo</p>
									</div>
									<div (click)="showTooltip($event,3.5)" *ngIf="bonus[i]==true"
										 class="tooltip tt-bonus tt-rt">
										<p class="txt-xsm txt-w ff-cnd">
											{{ 'Tiene bono activo en las siguientes dos líneas' }}
										</p>
										<span class="tt-content">Tooltip: Descripción / aclaración / instrucción.</span>
									</div>
								</div>
								<!-- / BALANCE BONO -->
							</div>
						</ng-container>
						<!-- / BALANCE -->

						<div class="balance empty"></div>

						<div class="cb"></div>
					</div>
					<div class="cb"></div>
					<div class="of-fade fade-x"></div>
				</div>
			</div>
		</article>

		<article class="px-1 py-03" id="last-bet">
			<div class="col col-2">
				<span class="txt-gc">Balance:</span>
				<div class="cb mb-03"></div>
				<span class="txt-w">Última Apuesta:</span>
			</div>
			<div class="col col-2">
				<span class="txt-c2"> {{'mBTC'}} </span>
				<span class="txt-w fr"> {{'20.098748'}} </span>
				<div class="cb mb-03"></div>
				<span class="txt-c2"> {{'mBTC'}} </span>
				<span class="txt-w fr"> {{'0.00748'}} </span>
			</div>
			<div class="col col-1"></div>
			<div class="col col-2">
				<span class="txt-c4"> {{'usd'.toUpperCase()}} </span>
				<span class="txt-w fr">20.098748</span>
				<div class="cb mb-03"></div>
				<span class="txt-c4"> {{'usd'.toUpperCase()}} </span>
				<span class="txt-w fr">0.00748</span>
			</div>
			<div class="col col-5 tac pr-last-bet">
				<img class="last-bet-logo" src="../../../../assets/images/bitwild-logo-w.svg">
			</div>
			<div class="cb"></div>
		</article>

		<div class="p-1" id="ticker">
			<div class="ticker-inner">
				<div class="ticker-container">
					<!-- -->
					<span class="msg txt-uc">SCROOLING <b>TICKER</b>.</span>
					<span class="msg txt-uc">Et eus amici propter <b>SAPIENTIA</b> laudarunt.</span>
					<span class="msg txt-uc">Los primeros dados de seis lados se hallaron en Mesopotamia y se remontan al
					año 3000
					a.C.</span>
					<!-- -->
					<div class="cb"></div>
				</div>
			</div>
		</div>

		<button (click)="onCloseW($event)" class="btn btn-w-close" type="button">
			<img src="../../../../assets/images/icon-b-close.svg">
		</button>
	</div>

</div>

<!--
<div class="btn-ctrl-container sub-menu" id="btn-ctrl-exchanger">
	<button (click)="onGoExchanger()" class="btn btn-rnd btn-nor btn-1st bg-k7" id="btn-exchanger" type="button">
		<img class="icon-full" src="../../../../assets/images/icon-m-exchanger.svg">
	</button>
</div>
-->
