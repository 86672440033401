import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {BonosJugadorAptoModel, BonosModel} from "../../../shared/models/bonos.model";
import {CurrencyExchangeModel} from "../../../shared/models/monedas-exchanger.model";
import {TorneoModel} from "../../../shared/models/torneo.model";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import {CircuitoComponent} from "../circuito/circuito.component";
import * as GeneralAction from "../../../shared/redux/general.actions";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {MonedaModel} from "../../../shared/models/wallet-currency.model";
import {Actions, ofType} from "@ngrx/effects";
import * as AuthAction from "../../../authetication/redux/authentication.actions";
import * as BonoAction from "../../../bonos/redux/bonos.actions";
import {GamesModel} from "../../../shared/models/games.model";
import {MonedasSiglasLocal} from "../../../shared/utils/general.util";
import * as GamesAction from "../../../games/redux/games.actions";
import {SessionServices} from "../../../shared/services/session.services";

declare function adjustSize(): any;

@Component({
	selector: "app-home-jugador",
	templateUrl: "./home-jugador.component.html",
	styleUrls: ["./home-jugador.component.css"]
})
export class HomeJugadorComponent implements OnInit, OnDestroy, AfterViewInit {

	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	coinsA = MonedasSiglasLocal;
	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	allWallets: WalletGeneralModel[] = [];
	masJugados: GamesModel[] = [];
	destacados: GamesModel[] = [];
	bonos: BonosJugadorAptoModel[] = [];
	monedasExchange: CurrencyExchangeModel[] = [];
	cryptoCurrency: MonedaModel[] = [];
	torneo: TorneoModel;
	juegoActivo: GamesModel;

	wActive: boolean = false;

	hideImages: boolean;

	counterOn: any;
	countDD: number;
	countHH: number;
	countMM: number;
	countSS: number;

	@ViewChild(CircuitoComponent, {static: false}) private circuit: CircuitoComponent;

	destacadoActive: number = null;
	lastPlayedActive: number = null;

	masJugadoZoomIndex = 0;
	destacadosZoomIndex = 0;
	hPromosZoomIndex = 0;

	afiliadosMode: boolean = false;

	constructor(
		private router: Router,
		private store: Store<AppState>,
		private actions$: Actions,
		private sessionService: SessionServices
	) {
	}

	ngOnInit() {
		this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
		});

		this.store.dispatch(GeneralAction.setAfiliadosMode({afiliadosMode: false}));

		adjustSize();
		this.initData();
	}

	// - - - - - - - - - - - - - - *

	ngAfterViewInit() {
		adjustSize();
		// this.setWidthS();

		// INI SCROLL
		setTimeout(() => {
			this.setIniScroll();
			this.galScroll("#hPromos");
			this.gameScroll("#mostPlayed");
			this.gameScroll("#featuredGames");
		}, 100);
	}

	ngOnDestroy() {
		if (this.counterOn) {
			clearInterval(this.counterOn);
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - THIS ON RESIZE ()
	thisOnResize() {
		// this.setWidthS();
	}

	/*
	 // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SET WIDTHS ()
	 setWidthS() {
		 const iW = window.innerWidth;
		 const bCnCont = document.getElementsByClassName("bonos-casino-content")[0] as HTMLDivElement;
		 const bCyCont = document.getElementsByClassName("bonos-crypto-content")[0] as HTMLDivElement;
		 const bonoCnS = document.getElementsByClassName("bono-casino");
		 const trnCnS = document.getElementsByClassName("torneo-casino");
		 const bCn0 = bonoCnS[0] as HTMLDivElement;
		 let bCn0W;
		 if (bCn0) {
			 bCn0W = bCn0.offsetWidth;
			 const bonoCyS = document.getElementsByClassName("bono-crypto");
			 const bCy0 = bonoCyS[0] as HTMLDivElement;
			 let bCy0W;
			 if (bCy0) {
				 bCy0W = bCy0.offsetWidth;
				 const bCnML = parseFloat(window.getComputedStyle(bCn0, null).getPropertyValue("margin-left"));
				 const bCnPL = parseFloat(window.getComputedStyle(bCnCont, null).getPropertyValue("padding-left"));
				 const bYnML = parseFloat(window.getComputedStyle(bCy0, null).getPropertyValue("margin-left"));
				 const bYnPL = parseFloat(window.getComputedStyle(bCyCont, null).getPropertyValue("padding-left"));
				 //
				 const total = bonoCnS.length + trnCnS.length;
				 if (total > 1) {
					 const eW = (bCn0W + (bCnML * 2)) * total;
					 // console.log(bCn0W + (bCnML * 2) );
					 bCnCont.style.width = (eW + (bCnPL * 2) + 2) + "px";
				 }
				 if (bonoCyS.length > 1) {
					 // console.log(bonoCyS.length);
					 const eW = (bCy0W + (bYnML * 2)) * bonoCyS.length;
					 bCyCont.style.width = (eW + (bYnPL * 2) + 2) + "px";
				 }
				 const anchobCn = bCn0W + (bCnML * 2);
				 const anchobCy = bCy0W + (bYnML * 2);
				 // y1 = padding left + bono - (( wW - bono )/2)
				 const y1 = bCnPL + anchobCn - ((iW - anchobCn) / 2);
				 // y2 = padding left + bono crypto  - (( wW - bono )/2)
				 const y2 = bYnPL + anchobCy - ((iW - anchobCn) / 2);
				 setTimeout(() => {
					 document.getElementsByClassName("bonos-casino-container")[0].scroll(y1, 0);
					 document.getElementsByClassName("bonos-crypto-container")[0].scroll(y2, 0);
				 }, 150);
			 }
		 }

	 }
 */

	initData() {
		this.store.select((state) => state).subscribe(state => {
			this.bonos = state.bonos.bonosJugadorAptos;
			//this.bonos = this.bonos.filter(x => x.tipo_bono.id === 1);
			this.allWallets = state.cajero.wallets;
			this.cryptoCurrency = state.cajero.cryptoMonedas.value;
			this.monedasExchange = state.cajero.currencyExchangeHomeTable.value;
			this.torneo = state.general.torneoActivo.value;
			this.masJugados = state.games.masJugados.value;
			this.destacados = state.games.games.value.filter(x => {
				if (x.clasificacion_visual_juego_ids.find(element => element == 1)) {
					return x;
				}
			});
		});

		if (this.bonos.length === 0) {
			this.getAllUserBonos();
		}
		if (this.allWallets.length === 0) {
			this.getWalletGeneral();
		}
		if (this.monedasExchange.length === 0) {
			this.getCurrencyExchange();
		}
		if (!this.torneo) {
			this.getCurrentTorneo();
		} else {
			this.setTorneoTimer();
		}

		this.actions$
			.pipe(ofType(AuthAction.registerCompletado))
			.subscribe(response => {
				if (this.torneo) {
					this.setTorneoTimer();
				}
			});

		setTimeout(
			() => {
				this.thisOnResize();
			}, 350
		);
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOOGLE RANK */
	onToggleRank(event, id) {
		event.stopPropagation();
		const tcE = document.getElementById("tcE-" + id) as HTMLDivElement;
		tcE.classList.toggle("active");
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW CIRCUIT */
	onShowCircuit() {
		this.store.dispatch(GeneralAction.showCircuitoActivo());

	// document.body.classList.add('landscape-turn');
	}

	changeImgUrl(event, newImg) {
		event.target.src = newImg;
	}

	getPremioPlace(place) {
		return this.torneo.premios.find(x => Number(x.numero) === place);
	}

	dhm(ms) {
		const days = Math.floor(ms / (24 * 60 * 60 * 1000));
		const daysms = ms % (24 * 60 * 60 * 1000);
		const hours = Math.floor((daysms) / (60 * 60 * 1000));
		const hoursms = ms % (60 * 60 * 1000);
		const minutes = Math.floor((hoursms) / (60 * 1000));
		const minutesms = ms % (60 * 1000);
		const sec = Math.floor((minutesms) / (1000));

		this.countDD = Number(days);
		this.countHH = Number(hours);
		this.countMM = Number(minutes);
		this.countSS = Number(sec);
		// return days + ":" + hours + ":" + minutes + ":" + sec;
	}

	setTorneoTimer() {
		const today = new Date();
		let finalDay;
		if (this.torneo.estado === "ACTIVO") {
			finalDay = new Date(this.torneo.fecha_fin);
		} else {
			finalDay = new Date(this.torneo.fecha_inicio);
		}
		// @ts-ignore
		const diff = finalDay - today;
		this.dhm(diff);
		this.counterOnFunc();
	}

	counterOnFunc() {
		if (this.counterOn) {
			clearInterval(this.counterOn);
		}
		this.counterOn = setInterval(() => {
			this.countSS--;
			if (this.countSS < 0) {
				this.countSS = 59;
				this.countMM--;
				if (this.countMM < 0) {
					this.countMM = 59;
					this.countHH--;
					if (this.countHH < 1) {
						this.countHH = 23;
						this.countDD--;
					}
				}
			}
		}, 1000);
	}

	onGoBonos(event) {
		event.stopPropagation();
		this.router.navigate(["/bonos/mis_bonos"]).then();
	}

	// New API Calls
	getAllUserBonos() {
		this.store.dispatch(BonoAction.getBonosAptos());
	}

	getCryptoCurrency() {
		this.store.dispatch(CajeroAction.getCryptoMonedas());
	}

	getWalletGeneral() {
		this.store.dispatch(CajeroAction.getWallets());
	}

	getCurrentTorneo() {
		this.store.dispatch(GeneralAction.getTorneoActivo());
	}

	getCurrencyExchange() {
		this.store.dispatch(CajeroAction.getExchangerTableHome());
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - GAL SCROLL */
	galScroll(e) {
		const imgsContainer = document.querySelector(e);
		if (imgsContainer) {
			var imgs = imgsContainer.getElementsByClassName("img");
			var imgLimit = imgs.length > 0 ? imgs[0].offsetWidth / 3 : 0;
			//var imgLimit = window.outerWidth*.275;
			var wW = window.innerWidth;
			var minL = imgsContainer.scrollLeft + imgLimit;
			var maxR = imgsContainer.scrollLeft + wW - imgLimit;
			// IMG
			for (var i = 0; i < imgs.length; i++) {
				var img = imgs[i];
				var iL = img.offsetLeft;
				var iC = iL + (img.offsetWidth / 2);
				if (iC > minL && iC < maxR) {
					this.hPromosZoomIndex = i;
				}
			}
		}
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - GAL SCROLL */
	gameScroll(e) {
		const imgsContainer = document.querySelector(e);
		if (imgsContainer) {
			var imgs = imgsContainer.getElementsByClassName("game");
			var imgLimit = imgs.length > 0 ? imgs[0].offsetWidth / 3 : 0;
			//var imgLimit = window.outerWidth*.275;
			var wW = window.innerWidth;
			var minL = imgsContainer.scrollLeft + imgLimit;
			var maxR = imgsContainer.scrollLeft + wW - imgLimit;
			// IMG
			for (var i = 0; i < imgs.length; i++) {
				var img = imgs[i];
				var iL = img.offsetLeft;
				var iC = iL + (img.offsetWidth / 2);
				if (iC > minL && iC < maxR) {
					if (e == "#mostPlayed") {
						this.masJugadoZoomIndex = i;
					}
					if (e == "#featuredGames") {
						this.destacadosZoomIndex = i;
					}
				}
			}
		}
	}

	// - - - - - - - - - - - - - - SCROLL H
	scrollH: number;

	// - - - - - - - - - - - - - - SET SCROLL INICIAL
	setIniScroll() {
		setTimeout(() => {
			const eP = document.getElementById("hPromos");
			if (eP) {
				var ePS = eP.getElementsByClassName("img");
				// console.log('ePS: '+ ePS.length);
			}
			const eM = document.getElementById("mostPlayed");
			if (eM) {
				var eMS = eM.getElementsByClassName("game");
				// console.log('eMS: '+ eMS.length);
			}
			const eF = document.getElementById("featuredGames");
			if (eF) {
				var eFS = eF.getElementsByClassName("game");
				// console.log('eFS: '+ eFS.length);
				var rem15 = window.getComputedStyle(eF, null).getPropertyValue("padding-left");
				rem15 = rem15.substring(0, rem15.length - 2);
				//console.log('15rem = '+rem15+'px');
				var scrL = (window.outerWidth * .49) + parseFloat(rem15);
			}

			this.scrollH = scrL;
			//console.log('scrL ='+scrL);
			if (eP && ePS.length > 2) {
				eP.scrollLeft = scrL;
			} else {
				console.log("no Promos, or not enought");
			}
			if (eM && eMS.length > 4) {
				eM.scrollLeft = scrL;
			} else {
				console.log("no Most Played, or not enought");
			}
			if (eF && eFS.length > 4) {
				eF.scrollLeft = scrL;
			} else {
				console.log("no Favorites, or not enought");
			}
			//document.getElementById('hPromos').scrollLeft = 203
		}, 100);
	}

	// - - - - - - - - - - - - - - ON SWIPE
	onSwipe(event, element) {
		event.preventDefault();
		//console.log(event);
		const eP = document.querySelector(element);
		const x = Math.abs(event.deltaX) > 40 ? (event.deltaX > 0 ? "Right" : "Left") : "";
		//console.log(x);
		switch (x) {
			case "Right":
				eP.scrollLeft -= this.scrollH;
				break;
			case "Left":
				eP.scrollLeft += this.scrollH;
				break;
		}
	}

	// - - - - - - - - - - - - - - ON SELECT DESTACADO
	onSelectDestacado(event, i) {
		event.stopPropagation();
		this.destacadoActive = i;
	}

	// - - - - - - - - - - - - - - ON UNSELECT DESTACADO
	onUnselectDestacado(event) {
		event.stopPropagation();
		this.destacadoActive = null;
	}

	// - - - - - - - - - - - - - - ON SELECT LAST PLAYED
	onSelectLastPlayed(event, i) {
		event.stopPropagation();
		this.lastPlayedActive = i;
	}

	// - - - - - - - - - - - - - - ON UNSELECT LAST PLAYED
	onUnselectLastPlayed(event, i) {
		event.stopPropagation();
		this.lastPlayedActive = null;
	}

	// - - - - - - - - - - - - - - ON SHOW GAME INFO
	onShowGameInfo(event, game) {
		event.stopPropagation();
		this.juegoActivo = game;
		setTimeout(() => {
			this.wActive = true;
		}, 1);
	}

	// - - - - - - - - - - - - - - ON SELECT FAV
	onSelectFav(event, game) {
		event.stopPropagation();
		this.store.dispatch(GamesAction.putLikeFavorite({game: game}));
	}

	// - - - - - - - - - - - - - - ON HIDE GAME PORT
	onHideGameInfoPort() {
		// .stopPropagation();
		this.wActive = false;
		setTimeout(() => {
			this.juegoActivo = null;
		}, 250);
	}

	getSiglasMonedas(id) {
		const moneda = this.cryptoCurrency.find(x => x.id === id);
		if (moneda) {
			return moneda.siglas.toLowerCase();
		}
		return "";
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GO PLAY ()
	onGoPlay(event, game: GamesModel) {
		event.stopPropagation();
		this.wActive = false;
		setTimeout(() => {
			this.store.dispatch(GamesAction.setJuegoSeleccionado({game: game}));
			let nuevoMenu = {
				menu: true,
				catalog: true,
				exchanger: false,
				back: false,
				show_catalog_component: false,
				show_exchager_component: false,
				show_back_component: false,
				show_menu_component: false,
				notificaciones: true,
				show_notificaciones: false,
				sub_menu_activo: "catalogo",
				sub_sub_menu_activo: "playground",
				cuenta_verificada: this.sessionService.getPlayer().estado_cuenta.toUpperCase() === "ACTIVA" ? true : false
			};
			this.store.dispatch(GeneralAction.setMyMenu({menu: nuevoMenu, eventEmiterComponent: this.constructor.name}));
			this.lastPlayedActive = null;
			this.juegoActivo = null;
			this.router.navigate(["/games/playground"], {queryParams: {gameID: game.id}}).then();
		}, 250);
	}

}
