import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {GameCategoryModel} from "../../../shared/models/game-category,model";
import {SessionServices} from "../../../shared/services/session.services";
import {Store} from "@ngrx/store";
import * as GamesAction from '../../../games/redux/games.actions';
import {AppState} from "../../../shared/redux/general.reducers";
import {MonedasSiglasLocal} from "../../../shared/utils/general.util";
import {GamesModel} from "src/app/shared/models/games.model";
import * as GeneralAction from "../../../shared/redux/general.actions";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";
import {MonedaModel} from "src/app/shared/models/wallet-currency.model";
import {Subscription} from "rxjs";
import {MyControlModel} from "../../../shared/models/control.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GameInfoComponent} from "../game-info/game-info.component";


declare function adjustSize(): any;

@Component({
	selector: "app-catalog",
	templateUrl: "./catalog.component.html",
	styleUrls: ["./catalog.component.css"]
})
export class CatalogComponent implements OnInit, OnDestroy {

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	};

	coinsA = MonedasSiglasLocal;
	subscription = new Subscription();
	games: GamesModel[] = [];
	gamesCategory: GameCategoryModel[] = [];
	cryptoCurrency: MonedaModel[] = [];
	catSeleccionada: GameCategoryModel = null;

	gameSelected: GamesModel = null;
	coverActive: number;
	favorite: boolean;
	myMenu: MyControlModel;

	wGiActive: boolean = false;

	constructor(
		private sessionService: SessionServices,
		private router: Router,
		private store: Store<AppState>,
		private modalService: NgbModal
	) {
	}

	ngOnInit() {
		this.initData();
		adjustSize();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}


	initData() {
		this.subscription.add(this.store.select((state) => state).subscribe(state => {
			this.games = state.games.games.value;
			this.gamesCategory = state.games.clasificacionesVisuales.value;
			this.cryptoCurrency = state.cajero.cryptoMonedas.value;
			this.catSeleccionada = state.games.categoriaSeleccionada;
			this.catSeleccionada = this.catSeleccionada ? this.catSeleccionada : this.gamesCategory[0];
			const filterGames = state.games.filterGames;
			this.myMenu = state.general.myMenu;

			if (filterGames.name) {
				this.games = this.games.filter(x => {
					var nomberJuego = x.nombre_publico.toLocaleLowerCase();
					var nombreFiltro = filterGames.name.toLocaleLowerCase();
					if (nomberJuego.includes(nombreFiltro)) {
						return x;
					}
				});
			}
			if (filterGames.moneda_id) {
				this.games = this.games.filter(x => {
					if (x.moneda_ids.find(m => m === filterGames.moneda_id)) {
						return x;
					}
				});
			}
			if (!this.favorite) {
				this.games = this.games.filter(x => {
					if (x.clasificacion_visual_juego_ids.find(m => m === this.catSeleccionada.id)) {
						return x;
					}
				});
			} else {
				this.games = this.games.filter(x => x.favorito);
			}
		}));

		this.getCatAndGames();
		this.getCryptoCurrency();

		if (this.catSeleccionada) {
			this.onSetCat(this.catSeleccionada);
		} else {
			if (this.gamesCategory.length > 0) {
				this.onSetCat(this.gamesCategory[0]);
			}
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT GAME ()
	onSelectGame(event, game: GamesModel) {
		event.stopPropagation();
		this.coverActive = game.id;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON UNSELECT ()
	onUnselect(event) {
		event.stopPropagation();
		this.coverActive = null;
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW GAME INFO ()
	onShowGameInfo(event, game) {
		event.stopPropagation();
		this.gameSelected = game;
		const modalRef = this.modalService.open(GameInfoComponent);
		modalRef.componentInstance.game = this.gameSelected;
		modalRef.componentInstance.cryptoCurrency = this.cryptoCurrency;
		modalRef.result.then();
	}

	onShowGameInfoPort(event, game) {
		event.stopPropagation();
		this.gameSelected = game;
		setTimeout(() => {
			this.wGiActive = true;
		}, 0);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON HIDE GAME INFO ()
	onHideGameInfoPort(event) {
		event.stopPropagation();
		this.wGiActive = false;
		setTimeout(() => {
			this.gameSelected = null;
		}, 250);
	}

	onHideGameInfoPortS(event) {
		event.srcEvent.stopPropagation();
		this.wGiActive = false;
		setTimeout(() => {
			this.gameSelected = null;
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GO PLAY ()
	onGoPlay(event, game: GamesModel) {
		event.stopPropagation();
		this.store.dispatch(GamesAction.setJuegoSeleccionado({game: game}));
		let nuevoMenu = {
			menu: true,
			catalog: true,
			exchanger: false,
			back: false,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: false,
			notificaciones: true,
			show_notificaciones: false,
			sub_menu_activo: 'catalogo',
			sub_sub_menu_activo: 'playground',
			cuenta_verificada: this.sessionService.getPlayer().estado_cuenta.toUpperCase() === "ACTIVA" ? true : false
		};
		this.store.dispatch(GeneralAction.setMyMenu({menu: nuevoMenu, eventEmiterComponent: this.constructor.name}));
		this.router.navigate(["/games/playground"], {queryParams: {gameID: game.id}}).then();
		this.coverActive = null;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET CAT ()
	onSetCat(cat: GameCategoryModel) {
		this.coverActive = -1;
		this.favorite = false
		this.store.dispatch(GamesAction.setCategoriaSeleccionada({cat: cat}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET CAT ()
	onSetCatFav() {
		//this.catSel = "Favorite";
		this.coverActive = -1;
		this.favorite = true;
		this.store.dispatch(GamesAction.setCategoriaSeleccionada(null));
		//this.games = this.games.filter(x => x.favorito);
	}

	changeImg(event, cat: string) {
		switch (cat.replace(/\s?$/, "").toLocaleUpperCase()) {
			case "Favoritos".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-favorite.svg";
				break;
			case "Destacados".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-feature.svg";
				break;
			case "Slots".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-slot.svg";
				break;
			case "Slots Clásicos".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-slot2.svg";
				break;
			case "Mesas".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-table.svg";
				break;
			case "Especiales".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-special.svg";
				break;
			case "En Vivo".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-live.svg";
				break;
			default:
				event.target.src = "../../../../assets/images/icon-cat-slot.svg";
		}
	}

	itLoggedIn() {
		return this.sessionService.isLoggedIn();
	}

	// New API Calls

	getCatAndGames() {
		let endURL = "";
		if (this.sessionService.getPlayer()) {
			endURL = "?jugador_id=" + this.sessionService.getPlayer().id;
		}
		this.store.dispatch(GamesAction.clasificacionesVisualesJuegos({endUrl: endURL}));
	}

	getCryptoCurrency() {
		this.store.dispatch(CajeroAction.getCryptoMonedas());
	}

	onSelectFav(event, game: GamesModel) {
		event.stopPropagation();
		this.store.dispatch(GamesAction.putLikeFavorite({game: game}));
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SWIPE DOWN ()
	onSwipeDow(event) {
	   this.onCloseCatalog();
	}
	*/

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE CATALOG ()
	onCloseCatalog() {
		const nuevoMenu = {
			menu: true,
			catalog: true,
			exchanger: true,
			back: true,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: false,
			notificaciones: true,
			show_notificaciones: false,
			sub_menu_activo: this.myMenu.sub_menu_activo,
			sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
			cuenta_verificada: true
		};
		this.store.dispatch(GeneralAction.setMyMenu({menu: nuevoMenu, eventEmiterComponent: this.constructor.name}));
	}

	getSiglasMonedas(id) {
		const moneda = this.cryptoCurrency.find(x => x.id === id);
		if (moneda) {
			return moneda.siglas.toLowerCase();
		}
		return '';
	}

	fixCatName(name) {
		return name.replace(/\s?$/, "");
	}

}
