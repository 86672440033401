import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import * as authAction from './../../authetication/redux/authentication.actions';
import * as generalAction from "../../shared/redux/general.actions";
import {HomeService} from "../../home/services/home.service";
import {Store} from '@ngrx/store';
import {AppState, DataSingleWithExpireTime} from './general.reducers';
import {TorneoModel} from '../models/torneo.model';
import {MenuService} from "../../nav/services/menu.service";
import {CircuitoJugadorModel} from '../models/circuitos.model';
import {CircuitosService} from 'src/app/home/services/circuitos.service';
import {HelpService} from "../../ayuda/services/help.service";
import {FileUploadService} from "../services/file-upload.service";
import {SessionServices} from "../services/session.services";


@Injectable()
export class GeneralEffects {


	private torneoActivo: DataSingleWithExpireTime<TorneoModel> = {value: null, expire: 0};
	private circuitoActivo: DataSingleWithExpireTime<CircuitoJugadorModel> = {value: null, expire: 0};

	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private homeService: HomeService,
		private menuService: MenuService,
		private circuitosService: CircuitosService,
		private helpService: HelpService,
		private fileUploadService: FileUploadService,
		private sessionService: SessionServices
	) {
		this.store.select((state) => state).subscribe(state => {
			this.torneoActivo = state.general.torneoActivo;
			this.circuitoActivo = state.general.circuitoActivo;
		});
	}

	torneoActivoAuth$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.obtenerUsuarioLogueadoCompletado),
			exhaustMap(data =>
				of(generalAction.torneoActivoAuthCompletado(data))
			)
		)
	);

	getTorneoActivo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.getTorneoActivo),
			exhaustMap(action => {
					if (this.torneoActivo.expire == 0 || new Date().getTime() > this.torneoActivo.expire) {
						return this.homeService.getCurrentTorneo(null).pipe(
							map(data => generalAction.getTorneoActivoCompletado({data})),
							catchError(error => of(authAction.terminar_state({error})))
						)
					} else {
						return of(generalAction.localDataLoad({dataName: 'Torneo Activo'}))
					}
				}
			)
		)
	);

	getNotificaciones$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.getNotificaciones),
			exhaustMap(action => {
					return this.menuService.getNotifications([]).pipe(
						map(data => generalAction.getNotificacionesCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		));

	getEstadosPorPais$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.getEstadosPaises),
			exhaustMap(action => {
					return this.homeService.obtenerEstadosPorPais(action.params).pipe(
						map(data => generalAction.getEstadosPaisesCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		));

	marcarNotificacionComoLeida$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.marcarNotificacionComoLeido),
			exhaustMap(action => {
					return this.menuService.markAsReadNotification(action.id).pipe(
						map(data => generalAction.marcarNotificacionComoLeidoCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		));

	deleteNotificacion$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.eliminarNotificacion),
			exhaustMap(action => {
					return this.menuService.deleteNotification(action.id).pipe(
						map(data => generalAction.eliminarNotificacionCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		));

	getCircuitoActivo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.getCircuitoActivo),
			exhaustMap(action => {
				if (this.circuitoActivo.expire == 0 || new Date().getTime() > this.circuitoActivo.expire) {
					return this.circuitosService.getCircuitosActivo(null).pipe(
						map(data => generalAction.getCircuitoActivoCompletado(data)),
						catchError(error => of(authAction.terminar_state({error})))
					)
				} else {
					return of(generalAction.localDataLoad({dataName: 'Circuito Activo'}))
				}
			})
		)
	);

	getTickets$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.getTickets),
			exhaustMap(action => {
					return this.helpService.getAllHelpTickets(action.filter).pipe(
						map(data => generalAction.getTicketsCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		));

	addTickets$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.addTickets),
			exhaustMap(action => {
					return this.helpService.postNewTicket(action.params).pipe(
						map(data => {
							return generalAction.addTicketsCompletado({data});
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		));

	uploadFiles$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.uploadFiles),
			exhaustMap(action => {
					return this.fileUploadService.addFile(action.params).pipe(
						map(data => {
							return generalAction.uploadFilesCompletado({data});
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		));

	addNotaTicket$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.addNotaTicket),
			exhaustMap(action => {
					return this.helpService.postNewTicketNota(action.params).pipe(
						map(data => {
							return generalAction.addNotaTicketCompletado({data});
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		));

	getlangs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.getLangs),
			exhaustMap(action => {
					return this.homeService.getLangs(null).pipe(
						map(data => generalAction.getLangsCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					);
				}
			)
		));

	setuserLang$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalAction.setUserLang),
			exhaustMap(action => {
					return this.homeService.setUserLang(action.params).pipe(
						map(data => {
							return generalAction.setUserLangCompletado({data});
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		));


}
