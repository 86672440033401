import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as generaAction from "../../shared/redux/general.actions";
import * as authAction from "../../authetication/redux/authentication.actions";
import { catchError, exhaustMap, map, tap } from "rxjs/operators";
import { of } from "rxjs";
import * as gameAction from "../../games/redux/games.actions";
import { CatalogService } from "../services/catalog.service";
import { Store } from '@ngrx/store';
import { AppState, DataWithExpireTime } from 'src/app/shared/redux/general.reducers';
import { GamesModel } from 'src/app/shared/models/games.model';
import { GameCategoryModel } from 'src/app/shared/models/game-category,model';
import * as generalAction from "../../shared/redux/general.actions";
import {GamesService} from "../services/games.service";


@Injectable()
export class GamesEffects {

	private gamesLocal: DataWithExpireTime<GamesModel> = { value: [], expire: 0 };
	private clasificacionesVisualesLocal: DataWithExpireTime<GameCategoryModel> = { value: [], expire: 0 };

	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private catalogService: CatalogService,
		private gameService: GamesService,
	) {
		this.store.select((state) => state).subscribe(state => {
			this.gamesLocal = state.games.games;
			this.clasificacionesVisualesLocal = state.games.clasificacionesVisuales;
		});
	}

	juegosAuth$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.obtenerUsuarioLogueadoCompletado),
			exhaustMap(data =>
				of(gameAction.juegosAuthCompletado(data))
			)
		)
	);

	clasificacionesVisualesJuegos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(gameAction.clasificacionesVisualesJuegos),
			tap((action) => this.store.dispatch(generalAction.showLoader({ httpRequestLoader: true,
				tipo: action.type }))),
			exhaustMap(action => {
				if (this.clasificacionesVisualesLocal.expire == 0 || new Date().getTime() > this.clasificacionesVisualesLocal.expire) {
					return this.catalogService.getCatAndGames(action.endUrl).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({ httpRequestLoader: true,
								tipo: action.type }));
							return gameAction.clasificacionesVisualesJuegosCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({ error })))
					)
				} else {
					return of(generaAction.localDataLoad({ dataName: 'Clasificaciones Visuales + Juegos' }))
				}
			})
		)
	);
	putLikeFavorite$ = createEffect(() =>
		this.actions$.pipe(
			ofType(gameAction.putLikeFavorite),
			exhaustMap(action => {
					return this.catalogService.postFavoriteGame({juego_id: action.game.id}).pipe(
						map(data => {
							return gameAction.putLikeFavoriteCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({ error })))
					)
			})
		)
	);
	iniciarJuego$ = createEffect(() =>
		this.actions$.pipe(
			ofType(gameAction.iniciarJuego),
			exhaustMap(action => {
					return this.gameService.startGamesFull({id: action.id}).pipe(
						map(data => {
							return gameAction.iniciarJuegoCompletado({data})
						}),
						catchError(error => of(gameAction.iniciarJuegoError({ error })))
					)
			})
		)
	);

	// getAllImagenes$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(gameAction.clasificacionesVisualesJuegosCompletado),
	// 		exhaustMap(action => {
	// 				return this.catalogService.getCatalogGamesWhitImages(null).pipe(
	// 					map(data => {
	// 						return gameAction.getAllImagenesCompletado({data})
	// 					}),
	// 					catchError(error => of(authAction.terminar_state({ error })))
	// 				)
	// 		})
	// 	)
	// );
	getSoloClasificacionesVisueles$ = createEffect(() =>
		this.actions$.pipe(
			ofType(gameAction.getSoloClasificaciones),
			exhaustMap(action => {
					return this.catalogService.getGamesCategories(null).pipe(
						map(data => {
							return gameAction.getSoloClasificacionesCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({ error })))
					)
			})
		)
	);
}
