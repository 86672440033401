import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterModel } from "src/app/shared/models/filter.model";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import * as AfiliadosActions from '../../../../afiliados/redux/afiliados.actions';
import { Subscription } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";

import { fixAndroid } from '../../../../shared/utils/general.util';
declare function adjustSize(): any;

@Component({
	selector: "app-w-f-trafico",
	templateUrl: "./w-f-trafico.component.html",
	styleUrls: ["./w-f-trafico.component.css"]
})
export class WFTraficoComponent implements OnInit, OnDestroy {

	searchForm: FormGroup;
	filter: FilterModel;
	subscription = new Subscription();

   wActive:boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private store: Store<AppState>,
		private actions$: Actions
	) {
	}

	ngOnInit(): void {
		this.filter = new FilterModel(1000);
		this.initForm();
		this.store.select((state) => state).subscribe(state => {
			this.filter = state.afiliado.trafico.filter;
		});
      this.liseningGetTraficoCompletado();
      
      setTimeout(() => { this.wActive = true; }, 0);
      setTimeout(() => { fixAndroid('filtro-trafico-cover'); }, 250);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SET DATE W ()
	setDate(event) {
		const dateInp = event.target as HTMLInputElement;
		const dateVal = dateInp.value;
		const txtInp = dateInp.closest(".date").querySelector(".date-txt") as HTMLInputElement;
		txtInp.value = dateVal;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close();
         adjustSize();
      }, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON FILTER ()
	onFilter(event) {
      event.stopPropagation();
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close(true);
         this.onSearchForm();
      }, 250);
	}

	initForm() {
		const currentDate = new Date();
		const currentMonth = Number(currentDate.getMonth()) + 1 <= 9 ? "0" + (Number(currentDate.getMonth()) + 1) : String(Number(currentDate.getMonth()) + 1);
		this.searchForm = new FormGroup(
			{
				mes: new FormControl(currentMonth),
				anno: new FormControl(currentDate.getFullYear()),
				dateFrom: new FormControl(null),
				dateTo: new FormControl(null),
				searchType: new FormControl(false)
			}
		);
	}

	onSearchForm() {
		if (this.searchForm.valid) {
			let nuevofilter = new FilterModel(1000);
			const searchModel = this.searchForm.value;
			if (searchModel.searchType === false) {
				if (searchModel.mes && searchModel.mes !== "null") {
					nuevofilter.filter.push({
						atribute: "mes",
						operator: "like",
						value: searchModel.mes
					});
				}
				if (searchModel.anno && searchModel.anno !== "null") {
					nuevofilter.filter.push({
						atribute: "anno",
						operator: "like",
						value: searchModel.anno
					});
				}
			} else if (searchModel.searchType === true) {
				if (searchModel.dateFrom) {
					nuevofilter.filter.push({
						atribute: "fecha_registro",
						operator: ">=",
						value: searchModel.dateFrom
					});
				}
				if (searchModel.dateTo) {
					nuevofilter.filter.push({
						atribute: "fecha_registro",
						operator: "<=",
						value: searchModel.dateTo
					});
				}
			}

			this.store.dispatch(AfiliadosActions.afiliadosGetTrafico({ 'filter': nuevofilter }));
		}
	}

	liseningGetTraficoCompletado() {
		this.subscription.add(this.actions$
			.pipe(ofType(AfiliadosActions.afiliadosGetTraficoCompletado))
			.subscribe(response => {
				this.activeModal.close(true);
			}));
	}

}
