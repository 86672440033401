import {Action, createReducer, on} from "@ngrx/store";
import {WalletGeneralModel} from "../../shared/models/wallet-general.model";
import * as WalletsActions from "../../cajero/redux/cajero.actions";
import {MonedasExchangerModel} from "../../shared/models/monedas-exchanger.model";
import {GeneralUtil} from "../../shared/utils/general.util";
import {HashCurrencyModel} from "../../shared/models/hash-currency.model";
import {DataWithExpireTime} from 'src/app/shared/redux/general.reducers';
import {MonedaModel} from "src/app/shared/models/wallet-currency.model";
import {RetiroSolicitudesModel} from "../../shared/models/retiro-solicitudes.model";
import {JugadorDocumentosModel} from "../../shared/models/jugador-documentos.model";


export class CajeroState {
	wallets: WalletGeneralModel[];
	currencyExchangeHomeTable: DataWithExpireTime<MonedasExchangerModel>;
	balance: WalletGeneralModel;
	cryptoMonedas: DataWithExpireTime<MonedaModel>;
	jugadorHash: HashCurrencyModel;
	retiroSolicitudesJugador: RetiroSolicitudesModel[];
	retiroSolicitudesAfiliado: RetiroSolicitudesModel[];
	documentosJugador: JugadorDocumentosModel[]

	constructor() {
		this.wallets = [];
		this.currencyExchangeHomeTable = {value: [], expire: 0};
		this.balance = null;
		this.cryptoMonedas = {value: [], expire: 0};
		this.jugadorHash = null;
		this.retiroSolicitudesJugador = [];
		this.retiroSolicitudesAfiliado = [];
		this.documentosJugador = [];
	}
}

const CajeroReducer = createReducer(
	new CajeroState,
	on(WalletsActions.walletsAuthCompletado, (state, {data}) => ({
		...state,
		wallets: data.wallet,
		currencyExchangeHomeTable: GeneralUtil.setWithExpiry(data.tabla_monedas),
		balance: data.wallet.find(element => element.es_el_activo)
	})), on(WalletsActions.getCryptoMonedasCompletado, (state, {data}) => ({
		...state,
		cryptoMonedas: GeneralUtil.setWithExpiry(data.data, 3600000),/* 60 minutos */
	})), on(WalletsActions.getHashDepositoCompletado, (state, {data}) => ({
		...state,
		jugadorHash: data.data,
	})), on(WalletsActions.getHashDeposito, (state) => ({
		...state,
		jugadorHash: null,
	})), on(WalletsActions.getWallets, (state) => ({
		...state,
	})), on(WalletsActions.getWalletsCompletado, (state, {data}) => ({
		...state,
		wallets: data.data,
		balance: data.data.find(element => element.es_el_activo)
	})), on(WalletsActions.getExchangerTableHome, (state) => ({
		...state,
	})), on(WalletsActions.getExchangerTableHomeCompletado, (state, {data}) => ({
		...state,
		currencyExchangeHomeTable: GeneralUtil.setWithExpiry(data.data),
	})), on(WalletsActions.cambioMonedaActivaCompletado, (state, {data}) => ({
		...state,
		wallets: data.wallets,
		balance: data.wallets.find(element => element.es_el_activo)
	})), on(WalletsActions.cambioMonedaActiva, (state) => ({
		...state,
	})),
	on(WalletsActions.cajeroLogoutCompletado, (state) => {
		return {...new CajeroState(), cryptoMonedas: state.cryptoMonedas};
	}),
	on(WalletsActions.postExchangerCompletado, (state, {data}) => {
		return {
			...state,
			wallets: data.wallets,
			balance: data.wallets.find(element => element.es_el_activo)
		};
	}),
	on(WalletsActions.addNewWalletCompletado, (state, {data}) => {
		return {
			...state,
			wallets: data.wallets,
			balance: data.wallets.find(element => element.es_el_activo)
		};
	}),
	on(WalletsActions.deleteWalletCompletado, (state, {data}) => {
		return {
			...state,
			wallets: data.wallets,
			balance: data.wallets.find(element => element.es_el_activo)
		};
	}),
	on(WalletsActions.addSolicitudRetiroCompletado, (state, {data}) => {
		return {
			...state,
			wallets: data.wallets,
			balance: data.wallets.find(element => element.es_el_activo)
		};
	}),
	on(WalletsActions.pusherEventCambioBalance, (state, wallet) => {
		let newWalletActivo = wallet.es_el_activo ? wallet : state.balance;
		let newWalletsArray: WalletGeneralModel[] = [];
		for (let i = 0; i < state.wallets.length; i++) {
			newWalletsArray.push(wallet.id == state.wallets[i].id ? wallet : state.wallets[i]);
		}
		return {
			...state,
			wallets: newWalletsArray,
			balance: newWalletActivo
		};
	}),
	on(WalletsActions.getSolicitudesRetiroCompletado, (state, {data}) => {
		return {
			...state,
			retiroSolicitudesJugador: data.tipo == "JUGADOR" ? data.data : state.retiroSolicitudesJugador,
			retiroSolicitudesAfiliado: data.tipo == "AFILIADO" ? data.data : state.retiroSolicitudesAfiliado,
		};
	}),
	on(WalletsActions.getDocumentoJugadorCompletado, (state, {data}) => {
		return {
			...state,
			documentosJugador: data.data,
		};
	})
);

export function reducer(state: CajeroState | undefined, action: Action) {
	return CajeroReducer(state, action);
}
