import * as AfiliadosActions from './afiliados.actions';
import { Action, createReducer, on } from "@ngrx/store";
import { AfiliadoModel } from 'src/app/shared/models/afiliado.model';
import { ResumenPeriodoAfiliadosModel } from 'src/app/shared/models/resumen-periodo-afiliados.model';
import { DataSingleWithExpireTime, DataWithFilter } from 'src/app/shared/redux/general.reducers';
import { GeneralUtil } from 'src/app/shared/utils/general.util';
import { CampaignModel } from 'src/app/shared/models/campaign.model';
import { Filter, FilterModel } from 'src/app/shared/models/filter.model';
import { TrafficModel } from 'src/app/shared/models/traffic.model';
import { PeriodoCommissionsModel } from 'src/app/shared/models/periodo-commissions.model';
import { BatchCommissionsModel } from "../../shared/models/batch-commissions.model";
import { BalanceAfiliadosModel } from 'src/app/shared/models/balance-afiliados.model';


export class AfiliadosState {
	afiliado: AfiliadoModel;
	resumen_periodo_actual: DataSingleWithExpireTime<ResumenPeriodoAfiliadosModel>;
	campannas: DataWithFilter<CampaignModel>;
	trafico: DataWithFilter<TrafficModel>;
	comisiones_periodo_actual: PeriodoCommissionsModel[];
	comisiones_batch: DataWithFilter<BatchCommissionsModel>;
	comisiones_active_tab: number;
	balances: BalanceAfiliadosModel[];
	balanceActivo: BalanceAfiliadosModel;

	constructor() {
		this.afiliado = null;
		this.resumen_periodo_actual = { value: null, expire: 0 }
		this.campannas = { value: [], filter: new FilterModel(1000) };
		this.trafico = { value: [], filter: new FilterModel(1000) };
		this.comisiones_periodo_actual = [];
		this.comisiones_batch = { value: [], filter: CreateComisionesBatchDefaultFilter() };
		this.comisiones_active_tab = 1;
		this.balances = [];
		this.balanceActivo = null;
	}
}

const AfiliadosReducer = createReducer(
	new AfiliadosState(),
	on(AfiliadosActions.afiliadosCreateAccount, state => ({ ...state })),
	on(AfiliadosActions.afiliadosCreateAccountCompletado, (state, data) => {
		return {
			...state,
			afiliado: new AfiliadoModel(data.object),
			resumen_periodo_actual: GeneralUtil.setWithExpiry(data.object.resumen_periodo_actual, 600000),/* 10 minutos */
		};
	}),
	on(AfiliadosActions.afiliadosGetAccountAuthCompletado, (state, afiliadoData) => {
		return {
			...state,
			afiliado: new AfiliadoModel(afiliadoData),
			resumen_periodo_actual: GeneralUtil.setWithExpiry(afiliadoData.resumen_periodo_actual, 600000),/* 10 minutos */
		};
	}),
	on(AfiliadosActions.afiliadosGetCampanna, state => ({ ...state })),
	on(AfiliadosActions.afiliadosGetCampannaCompletado, (state, { data }) => {
		let nuevoArray = [];
		let nuevoFiltro = { ...state.campannas.filter };
		nuevoArray = data.data;

		nuevoFiltro.total = data.total;
		nuevoFiltro.last_page = data.last_page;
		nuevoFiltro.page = data.current_page;
		nuevoFiltro.from = data.from;
		nuevoFiltro.to = data.to;

		return {
			...state,
			campannas: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(AfiliadosActions.afiliadosCreateCampanna, state => ({ ...state })),
	on(AfiliadosActions.afiliadosCreateCampannaCompletado, (state, { data }) => {
		let nuevoArray = [];
		let nuevoFiltro = { ...state.campannas.filter };
		nuevoArray = [...state.campannas.value, data.object];

		nuevoFiltro.total = nuevoArray.length;

		return {
			...state,
			campannas: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(AfiliadosActions.afiliadosGetTrafico, (state, data) => ({ ...state, trafico: { ...state.trafico, filter: data.filter } })),
	on(AfiliadosActions.afiliadosGetTraficoCompletado, (state, { data }) => {
		let nuevoArray = [];
		let nuevoFiltro = { ...state.trafico.filter };
		nuevoArray = data.data;

		nuevoFiltro.total = data.total;
		nuevoFiltro.last_page = data.last_page;
		nuevoFiltro.page = data.current_page;
		nuevoFiltro.from = data.from;
		nuevoFiltro.to = data.to;

		return {
			...state,
			trafico: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(AfiliadosActions.afiliadosGetPeriodoActualComisiones, state => ({ ...state })),
	on(AfiliadosActions.afiliadosGetPeriodoActualComisionesCompletado, (state, { data }) => ({
		...state,
		comisiones_periodo_actual: data.data,
	})),
	on(AfiliadosActions.afiliadosGetBatchComisiones, (state, data) => ({ ...state, comisiones_batch: { ...state.comisiones_batch, filter: data.filter } })),
	on(AfiliadosActions.afiliadosGetBatchComisionesCompletado, (state, { data }) => {
		let nuevoArray = [];
		let nuevoFiltro = { ...state.comisiones_batch.filter };
		nuevoArray = data.data;

		nuevoFiltro.total = data.total;
		nuevoFiltro.last_page = data.last_page;
		nuevoFiltro.page = data.current_page;
		nuevoFiltro.from = data.from;
		nuevoFiltro.to = data.to;

		return {
			...state,
			comisiones_batch: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(AfiliadosActions.afiliadosSetComisionesActiveTab, (state, data) => ({ ...state, comisiones_active_tab: data.activeTab })),
	on(AfiliadosActions.afiliadosSetBlanceActive, (state, data) => ({ ...state, balanceActivo: data.activeBalance })),
	on(AfiliadosActions.afiliadosGetBalances, state => ({ ...state })),
	on(AfiliadosActions.afiliadosGetBalancesCompletado, (state, { data }) => {
		let existeBalanceActivo = false;
		if (state.balanceActivo) {
			for (let i = 0; i < data.data.length; i++) {
				console.log(data.data);
				if (data.data[i].moneda_id == state.balanceActivo.moneda_id) {
					existeBalanceActivo = true;
				}
			}
		}

		return {
			...state,
			balances: data.data,
			balanceActivo: existeBalanceActivo ? state.balanceActivo : null
		}
	}),
	on(AfiliadosActions.afiliadosAddRetiro, state => ({ ...state })),
	on(AfiliadosActions.afiliadosAddRetiroCompletado, (state, data) => {
		return {
			...state
		};
	}),
	on(AfiliadosActions.afiliadosLogoutCompletado, state => ({ ...new AfiliadosState() }))
);

export function reducer(state: AfiliadosState | undefined, action: Action) {
	return AfiliadosReducer(state, action);
}


export function CreateComisionesBatchDefaultFilter(): FilterModel {

	let fechaActual = new Date();
	let mesActual = fechaActual.getMonth();
	let annoActual = fechaActual.getFullYear();
	let fechaPeriodo;
	if (mesActual == 0) {
		fechaPeriodo = new Date(annoActual - 1, 11, 1);
	} else {
		fechaPeriodo = new Date(annoActual, mesActual - 1, 1);
	}

	let mesPeriodo = fechaPeriodo.getMonth() + 1;

	let comisionesDefaultFilter: Array<Filter> = [
		{ atribute: 'mes', operator: 'like', value: mesActual < 10 ? `0${mesPeriodo}` : `${mesPeriodo}` },
		{ atribute: 'anno', operator: 'like', value: `${fechaPeriodo.getFullYear()}` }
	];

	return new FilterModel(1000, comisionesDefaultFilter);
}