import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { SessionServices } from "./shared/services/session.services";
import { fromEvent, interval, Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "./shared/redux/general.reducers";
import * as GeneralAction from "./shared/redux/general.actions";
import * as CajeroAction from "./cajero/redux/cajero.actions";
import { Actions, ofType } from "@ngrx/effects";
import * as AuthAction from "./authetication/redux/authentication.actions";
import { PusherService } from "./games/services/pusher.service";
import { PusherServiceNotif } from "./nav/services/pusher-service-notif.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MyControlModel } from "./shared/models/control.model";
import { SubMenuActivo } from "./shared/utils/general.util";
import { PusherServiceActivacionCuenta } from "./nav/services/pusher-service-activacion-cuenta.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

	show: boolean = true;
	showLoader: boolean;
	subscriptions: Subscription = new Subscription();
	afiliadosMode: boolean = false;
	activeModals: NgbModalRef[] = [];
	myMenu: MyControlModel;
	peticionesActivas: any = [];
	incompleteRRequests: number;

	constructor(
		private sessionService: SessionServices,
		private router: Router,
		private store: Store<AppState>,
		private actions$: Actions,
		private pusherService: PusherService,
		private pusherServiceNotifications: PusherServiceNotif,
		private pusherServiceActivationAccount: PusherServiceActivacionCuenta,
		private cd: ChangeDetectorRef,
		private modalService: NgbModal,
		private translate: TranslateService,
	) {
	}

	ngOnInit() {
		this.store.select((state) => state).subscribe(state => {
			this.myMenu = state.general.myMenu;
			this.showLoader = state.general.showLoader;
			this.afiliadosMode = state.general.afiliadosMode;
			this.peticionesActivas = state.general.peticionesActivas;
			this.incompleteRRequests = state.general.incompleteRequests;
			const lang = state.general.selectedLang;
			if (lang && lang.siglas) {
				this.translate.setDefaultLang(lang.siglas);

				// the lang to use, if the lang isn't available, it will use the current loader to get them
				this.translate.use(lang.siglas);
			}
			
			this.cd.detectChanges();
		});

		let documentRedy = fromEvent(window, 'load')
			.subscribe(ev => {
				this.store.dispatch(GeneralAction.hideLoader({ httpRequestLoader: false, tipo: "" }));
			});

		this.listeningActionLogoutCompletado();
		this.listeningActionObtenerUsuarioLogueadoCompletado();

		setTimeout(
			() => {
				this.show = true;
			}, 350
		);

		this.checkCredentials();
		this.navigationStartActiveModalsListening();

		interval(10000).subscribe(x => {
			if (this.peticionesActivas.length > 0 || this.incompleteRRequests > 0) {
				this.store.dispatch(GeneralAction.hideLoaderOnPeticionesColgadas());
			}
		});
	}

	navigationStartActiveModalsListening() {
		this.subscriptions.add(
			this.modalService.activeInstances.subscribe(
				(modals: NgbModalRef[]) => {
					this.activeModals = modals;
				}
			)
		);

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				if (this.activeModals.length > 0) {
					for (let i = 0; i < this.activeModals.length; i++) {
						this.activeModals[i].close();
					}
				}
			}

			if (event instanceof NavigationEnd) {
				let nuevoMenu;
				let subMenuActivo = SubMenuActivo(event.url);
				if (this.afiliadosMode) {
					nuevoMenu = {
						...this.myMenu,
						menu: true,
						catalog: true,
						exchanger: true,
						back: true,
						show_catalog_component: false,
						show_exchager_component: false,
						show_back_component: false,
						show_menu_component: false,
						notificaciones: true,
						show_notificaciones: false,
						sub_menu_activo: 'afiliados',
						sub_sub_menu_activo: subMenuActivo,
						cuenta_verificada: true
					};
				} else {
					nuevoMenu = {
						...this.myMenu,
						menu: true,
						catalog: true,
						exchanger: true,
						back: true,
						show_catalog_component: false,
						show_exchager_component: false,
						show_back_component: false,
						show_menu_component: false,
						notificaciones: true,
						show_notificaciones: false,
						sub_menu_activo: this.myMenu.sub_menu_activo,
						sub_sub_menu_activo: subMenuActivo,
						cuenta_verificada: true
					};
				}

				this.store.dispatch(GeneralAction.setMyMenu({
					menu: nuevoMenu,
					eventEmiterComponent: this.constructor.name
				}));
			}
		});
	}

	ngAfterViewInit() {
		if (!this.router.url.includes("inicio/visitante")) {
			this.checkLoadingStatus();
		}
	}

	ngOnDestroy(): void {
		if (this.subscriptions) {
			this.subscriptions.unsubscribe();
		}
	}

	itsLoggedIn() {
		return this.sessionService.isLoggedIn();
	}

	itsUserLogued() {
		return !!this.sessionService.getPlayer();
	}

	checkCredentials() {
		const player = this.sessionService.getPlayer();

		if (player) {
			this.pusherConectListenBalanceNotificationChanels();

			if (!this.sessionService.isLoggedIn()) {
				this.router.navigate(["/inicio"]).then();
			}
		}
	}

	itsInicio() {
		return this.router.url.includes("inicio/visitante") || this.router.url.includes("afiliados/visitante");
	}

	checkLoadingStatus() {
		if (document.readyState === "complete") {
			this.showLoader = false;
		} else {
			document.onreadystatechange = () => {
				if (document.readyState === "complete") {
					this.showLoader = false;
				}
			};
		}
	}

	listeningActionLogoutCompletado() {
		//Escuchando la llegada del logout
		this.subscriptions.add(this.actions$
			.pipe(ofType(AuthAction.logoutCompletado))
			.subscribe(response => {
				this.pusherService.pusherDisconnect();
				this.pusherServiceNotifications.pusherDisconnect();
				if (this.afiliadosMode) {
					this.router.navigate(["/afiliados/visitante"]).then();
				} else {
					this.router.navigate(["/inicio"]);
				}
			}));
	}

	listeningActionObtenerUsuarioLogueadoCompletado() {
		this.subscriptions.add(this.actions$
			.pipe(ofType(AuthAction.obtenerUsuarioLogueadoCompletado))
			.subscribe(response => {
				this.pusherConectListenBalanceNotificationChanels();
			}));
	}

	pusherConectListenBalanceNotificationChanels() {
		if (!this.pusherService.isConnected()) {
			this.pusherService.pusherConnect();
			const channelName = "balances" + this.sessionService.getPlayer().id;
			const eventName = "App\\Events\\ActJugadorCreated";
			this.pusherService.subScribeToChannel(channelName, [eventName, "error"], (resp) => {
				if (resp.event === eventName) {
					this.store.dispatch(CajeroAction.pusherEventCambioBalance(resp.data.balances));
				}
			});
		}

		if (!this.pusherServiceNotifications.isConnected()) {
			this.pusherServiceNotifications.pusherConnect();
			const channelName = "notificaciones" + this.sessionService.getPlayer().id;
			const eventName = "App\\Events\\NotificacionCreated";
			this.pusherServiceNotifications.subScribeToChannel(channelName, [eventName, "error"], (resp) => {
				if (resp.event === eventName) {
					this.store.dispatch(GeneralAction.pusherEventNuevaNotificacion(resp.data.notificacion));
				}
			});
		}

		if (!this.pusherServiceActivationAccount.isConnected()) {
			this.pusherServiceActivationAccount.pusherConnect();
			const channelName = "activacion_cuenta" + this.sessionService.getPlayer().id;
			const eventName = "App\\Events\\AccountActivation";
			this.pusherServiceActivationAccount.subScribeToChannel(channelName, [eventName, "error"], (resp) => {
				if (resp.event === eventName) {
					this.store.dispatch(AuthAction.obtenerUsuarioLogueadoFromPusher({ player: resp.data.jugador }));
				}
			});
		}
	}

}
