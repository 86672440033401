import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import Pusher from "pusher-js";


@Injectable({
	providedIn: "root"
})
export class PusherService {
	private _pusher: Pusher = null;

	constructor() { }

	public get pusher(): Pusher {
		return this._pusher;
	}

	public set pusher(value: Pusher) {
		this._pusher = value;
	}

	subScribeToChannel(channelName: string, events: string[], cb) {
		const channel = this._pusher.subscribe(channelName);
		events.forEach(evento => {
			channel.bind(evento, (response) => {
				cb(
					{
						event: evento,
						data: response
					}
				);
			});
		});
	}

	pusherConnect() {
		this._pusher = new Pusher(environment.pusher.key, {
			cluster: environment.pusher.cluster,
			forceTLS: true
		});
	}

	pusherDisconnect() {
		if (!this._pusher) {
			return;
		}
		this._pusher.unbind_all();
		this._pusher.disconnect();
		this._pusher = null;
	}

	isConnected() {
		if (this._pusher == null) {
			return false
		}
		return true;
	}
}
