import {Component} from "@angular/core";

declare function adjustSize(): any;

@Component({
	selector: "app-controls",
	templateUrl: "./controls.component.html",
	styleUrls: ["./controls.component.css"]
})
export class ControlsComponent {
//
// 	modalRef: NgbModalRef;
// 	windowCloser: Subscription;
// 	catalogOpener: Subscription;
// 	startPusher: Subscription;
//
// 	catOpen: boolean;
// 	menuOpen: boolean;
//
// 	filter: FilterModel;
// 	notifications: NotificationsModel[];
//
// 	eventsSubject: Subject<void> = new Subject<void>();
//
// 	constructor(
// 		private route: ActivatedRoute,
// 		private router: Router,
// 		private location: Location,
// 		private modalService: NgbModal,
// 		private sessionService: SessionServices,
// 		private spinner: NgxSpinnerService,
// 		private notification: NotificationService,
// 		private windowsService: WindowsService,
// 		public activeModal: NgbActiveModal,
// 		private pusherService: PusherServiceNotif,
// 		private menuService: MenuService
// 	) {
// 	}
//
// 	ngOnInit() {
// 		this.notifications = [];
// 		this.menuOpen = false;
// 		this.catOpen = false;
//
// 		this.pusherServiceStartup();
// 		this.listerMenuActions();
//
// 		this.initFilter();
// 		this.getNotifications();
// 	}
//
// 	initFilter() {
// 		this.filter = new FilterModel();
// 	}
//
// 	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOGGLE MENU ()
// 	onToggleMenu() {
// 		if (this.catOpen) {
// 			this.catOpen = false;
// 		}
// 		if (!this.menuOpen) {
// 			const tempNotif = JSON.parse(sessionStorage.getItem("NOTIFICATIONS_USER"));
// 			if (tempNotif) {
// 				this.notifications = tempNotif;
// 			}
// 			this.modalRef = this.modalService.open(MainmenuComponent);
// 			this.modalRef.componentInstance.notifications = this.notifications;
// 			this.modalRef.componentInstance.filter = this.filter;
// 			this.modalRef.result.then(
// 				(resp) => {
// 					if (resp === "LOGOUT") {
// 						this.logOutAction();
// 					}
// 					this.menuOpen = false;
// 				}
// 			);
// 			// adjustSize();
// 			this.menuOpen = true;
// 		} else {
// 			this.modalRef.close();
// 			this.menuOpen = false;
// 		}
// 	}
//
// 	logOutAction() {
// 		const CRYPTO_CURRENCY = localStorage.getItem("CRYPTO_CURRENCY");
// 		const BONOS_JUGADOR_VISITANTES = localStorage.getItem("BONOS_JUGADOR_VISITANTES");
// 		const CLASIFICACIONES_JUEGOS = localStorage.getItem("CLASIFICACIONES_JUEGOS");
// 		Swal.fire(this.notification.BuildSuccess("Usted ha cerrado la Sesión satisfactoriamente", "Sesión cerrada!")).then(
// 			() => {
// 				this.pusherService.pusherDisconnect();
// 				localStorage.clear();
// 				sessionStorage.clear();
// 				if (environment.afiliadosMode) {
// 					this.router.navigate(["/afiliados/visitante"]).then();
// 				} else {
// 					this.router.navigate(["/inicio"]).then();
// 				}
// 			}
// 		);
// 	}
//
// 	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GO CATALOG ()
// 	onGoCatalog() {
// 		if (this.menuOpen) {
// 			this.modalRef.close();
// 			this.menuOpen = false;
// 		}
// 		this.catOpen = true;
// 		setTimeout(
// 			() => {
// 				this.emitEventToChild(true);
// 			}, 100
// 		);
// 	}
//
// 	onCloseCatalog() {
// 		this.emitEventToChild(false);
// 		setTimeout(
// 			() => {
// 				this.catOpen = false;
// 			}, 300
// 		);
// 	}
//
// 	listerMenuActions() {
// 		// noinspection JSUnusedLocalSymbols
// 		this.windowCloser = this.windowsService.closeMenuSubject.subscribe(
// 			(data) => {
// 				if (this.menuOpen) {
// 					this.modalRef.close();
// 					this.menuOpen = false;
// 				}
// 				if (this.catOpen) {
// 					this.modalRef.close();
// 					this.catOpen = false;
// 				}
// 			}
// 		);
// 		// noinspection JSUnusedLocalSymbols
// 		this.catalogOpener = this.windowsService.openCatalogSubject.subscribe(
// 			(data) => {
// 				this.onGoCatalog();
// 			}
// 		);
// 		// noinspection JSUnusedLocalSymbols
// 		this.startPusher = this.windowsService.startPusherSubject.subscribe(
// 			(data) => {
// 				if (data) {
// 					this.pusherServiceStartup();
// 				} else {
// 					this.pusherService.pusherDisconnect();
// 				}
// 			}
// 		);
// 	}
//
// 	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - GENERAL STUFF */
//
// 	pusherServiceStartup() {
// 		if (!this.itsLogIn()) {
// 			return;
// 		}
// 		this.pusherService.pusherConnect();
// 		const channelName = "notificaciones" + this.sessionService.getPlayer().id;
// 		const eventName = "App\\Events\\NotificacionCreated";
// 		setTimeout(() => {
// 			this.pusherService.subScribeToChannel(channelName, [eventName, "error"], (resp) => {
// 				if (resp.event === eventName) {
//
// 					this.notifications.unshift(resp.data.notificacion);
// 					// something
// 				}
// 				if (resp.event === "error") {
// 					Swal.fire(this.notification.BuildError(resp.data, "Pusher Error!")).then();
// 				}
// 			});
// 		}, 500);
// 	}
//
// 	emitEventToChild(send) {
// 		this.eventsSubject.next(send);
// 	}
//
// 	itsLogIn() {
// 		return !!this.sessionService.getPlayer();
// 	}
//
// 	showCatalogControl() {
// 		return environment.afiliadosMode !== true;
// 	}
//
//
// 	getNotifications() {
// 		this.menuService.getNotifications(this.filter).subscribe(
// 			(response) => {
// 				const resp: ResponseModel = response;
// 				this.notifications = resp.data.reverse();
// 			}
// 		);
// 	}
//
// 	readNotifs() {
// 		let counter = 0;
// 		for (const notif of this.notifications) {
// 			if (!notif.leido) {
// 				counter++;
// 			}
// 		}
// 		return counter;
// 	}

}
