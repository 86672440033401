import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {SessionServices} from "../../shared/services/session.services";

@Injectable({
	providedIn: "root"
})
export class JugadorGuard implements CanActivate {

	constructor(
		private sessionService: SessionServices,
		private router: Router
	) {
	}

	canActivate() {
		const player = this.sessionService.getPlayer();
		if (!player && !this.sessionService.isLoggedIn()) {
			this.router.navigate(["/inicio/visitante"]).then();
			return false;
		} else {
			return true;
		}
	}
}
