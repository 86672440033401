<div class="w-cover coverB" id="home-game-info-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">
   <!-- (click)="noClick($event)" -->

	<div class="window" id="home-game-info">

      <!-- - - - - - - - - - - - - - - - - - LANDSCAPE -->
      <div class="hidden-portrait pt-2 px-1 scale-down">
         <p class="info-title mb-15 pl-15">

            {{game.nombre_publico}}</p>

         <div class="px-1">

            <img class="game-play" [src]="game.imagen_juego">

            <div class="cb mb-15"></div>
            <p class="info-detail"><span
               class="ff-cnd info-label">{{'CATEGORIA_JUEGO' |translate}}:</span> {{game.categoria_juego.nombre}}</p>
            <p class="info-detail"><span class="ff-cnd info-label">{{'LINEAS' |translate}}:</span> {{game.lineas}}</p>
            <p class="info-detail"><span class="ff-cnd info-label">{{'MIN_EQUIV_A' |translate}}:</span> <span class="txt-c4"> USD
               </span>
               {{game.apuesta_minima | number:"1.2-2"}}</p>
            <p class="info-detail"><span class="ff-cnd info-label">{{'MAX_EQUIV_A' |translate}}:</span> <span class="txt-c4"> USD
               </span>
               {{game.apuesta_maxima | number:"1.2-2"}}</p>
            <p class="info-detail"><span class="ff-cnd info-label">{{'JACKPOT_EQUIV_A' |translate}}:</span> <span class="txt-c4"> USD
               </span>
               {{game.jackpot | number:"1.2-8"}}</p>

            <div class="cb"></div>
         </div>

         <div class="cb mb-15"></div>

         <div class="accepted-coins-container of-y pl-1 pb-1">
            <ul class="accepted-coins">
               <ng-container *ngFor="let curr of cryptoCurrency; let i = index;">
                  <li class="coin" *ngIf="game.moneda_ids.includes(curr.id)">
                     <img src="../../../../assets/images/crypto/{{ curr.siglas.toLowerCase() }}.svg"
                        (error)="changeImg($event, curr.imagen)">
                     {{ curr.siglas.toUpperCase() }}
                  </li>
               </ng-container>
            </ul>
            <div class="cb"></div>
         </div>

         <div class="w-actions px-2">
            <div class="col col-6 pr-1">
               <button (click)="onCancel($event)" class="btn btn-nor btn-rnd btn-g btn-icon full-w"
                     type="button">
                  {{'CERRAR' |translate}}
                  <img src="../../../../../assets/images/icon-b-close-c.svg">
               </button>
            </div>
            <div class="col col-6 pl-1">
               <button (click)="onPlay($event)" class="btn btn-nor btn-rnd btn-1st btn-icon full-w"
                     type="button">
                  {{'JUGAR' |translate}}
                  <img src="../../../../../assets/images/icon-b-play-c.svg">
               </button>
            </div>
            <div class="cb"></div>
         </div>


         <button (click)="onCloseW()" class="btn btn-w-close" type="button">
            <img src="../../../../../assets/images/icon-b-close.svg">
         </button>
      </div>
      <!-- - - - - - - - - - - - - - - - - - / LANDSCAPE -->

         <!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
         <div class="hidden-landscape">
            <div class="btn-window-close" (click)="onCancel($event)">
               <div class="btn-window-close-bar"></div>
            </div>
            <div class="px-1">
               <div class="p-15 bg-g1">
                  <p class="txt-lg mb-15 tac">{{game.nombre_publico}}</p>
                  <img class="game-play mb-25" [src]="game.imagen_juego">

                  <div class="col col-6 pr-05">
                     <span class="info-txt txt-g8 tar">{{'CATEGORIA_JUEGO' |translate}}:</span>
                     <span class="info-txt txt-g8 tar">{{'LINEAS' |translate}}:</span>
                     <span class="info-txt txt-g8 tar">{{'MIN_EQUIV_A' |translate}}:</span>
                     <span class="info-txt txt-g8 tar">{{'MAX_EQUIV_A' |translate}}:</span>
                     <span class="info-txt txt-g8 tar">{{'JACKPOT_EQUIV_A' |translate}}:</span>
                  </div>
                  <div class="col col-6 pl-05">
                     <span class="info-txt ellipsis">{{game.categoria_juego.nombre}}</span>
                     <span class="info-txt">{{game.lineas}}</span>
                     <span class="info-txt"><span class="txt-c4-l"> {{'USD'}} </span>{{game.apuesta_minima | number: '1.2-2'}}</span>
                     <span class="info-txt"><span class="txt-c4-l"> {{'USD'}} </span>{{game.apuesta_maxima | number: '1.2-2'}}</span>
                     <span class="info-txt"><span class="txt-c4-l"> {{'USD'}} </span>{{game.jackpot | number: '1.2-8'}}</span>
                  </div>
                  <div class="cb mb-25"></div>

                  <div class="accepted-coins-container of-y pl-1 pb-1">
                     <ul class="accepted-coins">
                        <ng-container *ngFor="let curr of cryptoCurrency; let i = index;">
                           <li class="coin" *ngIf="game.moneda_ids.includes(curr.id)">
                              <img src="../../../../assets/images/crypto/{{ curr.siglas.toLowerCase() }}.svg"
                                 (error)="changeImg($event, curr.imagen)">
                              {{ curr.siglas.toUpperCase() }}
                           </li>
                        </ng-container>
                     </ul>
                     <div class="cb"></div>
                  </div>
               </div>
            </div>

            <div class="btn2-actions px-2 pb-2">
               <div class="col col-6 pr-07">
                  <button type="button" class="btn2 btn2-lg btn2-g full-w"
                     (click)="onCancel($event)">{{'CERRAR' |translate}}</button>
               </div>
               <div class="col col-6 pl-07">
                  <button type="button" class="btn2 btn2-lg btn2-1st full-w" (click)="onPlay($event)">{{'JUGAR' |translate}}</button>
               </div>
               <div class="cb"></div>
            </div>

         </div>
         <!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
	</div>

</div>
