import {Injectable} from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class ConstantsService {
	//gCatActive:boolean = true;
	//gCatPrevState:boolean = true;
   gCatOpen: boolean = false;
   activegame:boolean = false;

   // verifica si hay juego activo ( boolean )
   onCheckGame() {
      this.activegame = JSON.parse(localStorage.getItem("LAST_GAME_PLAYED"));
			if (this.activegame) {
            //console.log('SÍ hay juego activo');
            this.activegame = true;
            //console.log(this.activegame);
			} else {
            //console.log('NO hay juego activo');
            this.activegame = false;
            //console.log(this.activegame);
         }
   }
}
