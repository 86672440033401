import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { AppState } from "src/app/shared/redux/general.reducers";
import { NotificationService } from "src/app/shared/services/notification.service";
import { BalanceAfiliadosModel } from "../../../../shared/models/balance-afiliados.model";
import { ResponseModel } from "../../../../shared/models/response.model";
import { SessionServices } from "../../../../shared/services/session.services";
import * as AfiliadosActions from '../../../../afiliados/redux/afiliados.actions';
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-w-add-balance",
	templateUrl: "./w-add-balance.component.html",
	styleUrls: ["./w-add-balance.component.css"]
})
export class WAddBalanceComponent implements OnInit, OnDestroy {

	balance: BalanceAfiliadosModel;

	wActive: boolean = false;
	onCompleteCreateRetiro: Subscription;

	constructor(
		private notification: NotificationService,
		private store: Store<AppState>,
		public activeModal: NgbActiveModal,
		private sessionService: SessionServices,
		private actions$: Actions,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.store.select((state) => state).subscribe(state => {
			this.balance = state.afiliado.balanceActivo;
		});

		this.listeningActions();

		setTimeout(() => { this.wActive = true; }, 0);
	}

	ngOnDestroy(): void {
		if (this.onCompleteCreateRetiro) {
			this.onCompleteCreateRetiro.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TRANSFERIR ()
	onTransferir(event) {
		event.stopPropagation();
		const param = {
			data: {
				afiliado_id: this.sessionService.getPlayer().afiliado.id,
				hash: "CUENTA",
				monto: this.balance.balance,
				moneda_id: this.balance.moneda_id,
				accion: "CUENTA",
				tipo: "AFILIADO"
			}
		};
		this.postRetiroAgregarBalance(param);
	}

	postRetiroAgregarBalance(param) {
		this.store.dispatch(AfiliadosActions.afiliadosAddRetiro({ retiroData: param }));
	}

	listeningActions() {
		this.onCompleteCreateRetiro = this.actions$
			.pipe(ofType(AfiliadosActions.afiliadosAddRetiroCompletado))
			.subscribe(response => {
				const message = this.translate.instant('MSG_ENVIO_BALANCE_SATISFACTORIO');
				Swal.fire(this.notification.BuildSuccess(message)).then(() => {
					this.activeModal.close();
				});

				this.store.dispatch(AfiliadosActions.afiliadosGetBalances());
			});
	}

}
