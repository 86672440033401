<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover coverB" id="new-ticket-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">
   <!-- -->

	<div class="window pt-1" id="new-ticket">
		<form [formGroup]="ticketForm" (submit)="onNewTicket($event)">

			<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
			<div class="hidden-lanscape">
				<div class="btn-window-close" (click)="onCloseW()">
					<div class="btn-window-close-bar"></div>
				</div>
				<p class="w-title-cover txt-ssm txt-w tac mb-1">{{'GENERAR_NUEVO_TICKET' |translate}}</p>
				<div class="px-2">
					<p class="txt-gc mb-03">{{'ASUNTO' |translate}}:</p>
					<input type="text" class="inp2 inp2-nor inp2-1st full-w mb-2" formControlName="asunto" value="" placeholder="">
					<p class="txt-gc mb-03">{{'CATEGORIA' |translate}}:</p>
					<div class="sel2">
						<select class="sel2 sel2-nor full-w" (click)="$event.stopPropagation()" formControlName="categoria">
							<option [value]="null">{{'SELECCIONE'|translate}}</option>
							<option [value]="cat.id" *ngFor="let cat of categorias">{{cat.nombre}}</option>
						</select>
					</div>
				</div>
            <div class="btn2-actions px-2 pb-2">
					<div class="col col-6 pr-07">
                  <button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCancel($event)">{{'CANCELAR' |translate}}</button>
               </div>
               <div class="col col-6 pl-07">
                  <button type="submit" class="btn2 btn2-lg btn2-1st full-w"  [disabled]="ticketForm.invalid">{{'GENERAR' |translate}}</button>
               </div>
               <div class="cb"></div>
				</div>
         </div>
			<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

		</form>
	</div>

</div>
