import { AfterViewInit, Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import { GameInfoComponent } from "../game-info/game-info.component";
import { Store } from "@ngrx/store";
import { AppState } from "../../../shared/redux/general.reducers";

declare function adjustSize(): any;

@Component({
	selector: "app-back",
	templateUrl: "./back.component.html",
	styleUrls: ["./back.component.css"]
})
export class BackComponent implements OnInit, AfterViewInit {

	coins = ["btc", "eth", "xem", "ltc", "xmr"];
	coins2 = ["Bitcoin", "Ethereum", "NEM", "Litecoin", "Monero"];
	bonus = [false, true, true, false, false];
	balanceSelected = 0;
	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	coveractive: number;

	constructor(
		private modalService: NgbModal,
		private router: Router,
		private store: Store<AppState>
	) {
	}

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	ngOnInit(): void {
		this.coveractive = -1;
		this.store.subscribe((state) => {

		})
	}

	ngAfterViewInit() {
		adjustSize();
		/*
		  setTimeout(() => {
			  this.aspectRatio();
		}, 25);
		*/
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ASPECT RATIO ()
	aspectRatio() {
		const imgE = document.querySelector(".game-logo") as HTMLImageElement;
		const iH = imgE.offsetWidth * .5;
		const imS = document.getElementsByClassName("game-logo");
		// tslint:disable-next-line:prefer-for-of
		for (let i = 0; i < imS.length; i++) {
			const img = imS[i] as HTMLImageElement;
			img.style.height = iH + "px";
		}
   }
   */

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - NO CLICK ()
	noClick(event) {
		event.stopPropagation();
		event.preventDefault();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW(event) {
		event.stopPropagation();
		//this.activeModal.close();
		adjustSize();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT BALANCE ()
	onSelectBalance(i) {
		this.balanceSelected = i;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT GAME ()
	onSelectGame(i) {
		this.coveractive = i;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON UNSELECT ()
	onUnselect(event) {
		event.stopPropagation();
		this.coveractive = -1;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW GAME INFO ()
	onShowGameInfo(ID) {
		const modalRef = this.modalService.open(GameInfoComponent);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SHOW TOOLTIP ()
	showTooltip(event, t) {
		event.stopPropagation();
		const tt = event.target as HTMLParagraphElement;
		tt.classList.add("active");
		setTimeout(() => {
			tt.classList.remove("active");
		}, t * 1000);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GO PLAY ()
	onGoPlay() {
		//
		this.router.navigate(["/playground"]).then();
	}

	onGoExchanger() {
		this.router.navigate(["/cajero/exchanger"]);
	}

}
