<form class="full-h" [formGroup]="accountForm">

   <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
   <div class="formSection hidden-landscape">
      <p class="txt-sm txt-gc mb-03">{{ 'NOMBRE_USUARIO' | translate }}</p>
      <input class="inp2 inp2-nor inp2-1st full-w mb-15" [style.border-color]="inputTouched('username') ? 'red' : ''"
         formControlName="username" placeholder="{{ 'NOMBRE_USUARIO' | translate }}" type="text">
      <p class="txt-sm txt-gc mb-03">{{ 'EMAIL' | translate }}</p>
      <input class="inp2 inp2-nor inp2-1st full-w mb-15" [style.border-color]="inputTouched('email') ? 'red' : ''"
         formControlName="email" placeholder="{{ 'EMAIL' | translate }}" type="email">
      <p class="txt-sm txt-gc mb-03">{{ 'MONEDA_REFERENCIAL' | translate }}</p>
      <div class="sel2 faux-sel2 sel2-only mb-15 disabled">
         <select class="sel2 sel2-nor full-w" [style.border-color]="inputTouched('currency') ? 'red' : ''"
            formControlName="currency">
            <option disabled [value]="null">{{ 'MONEDA_REFERENCIAL' | translate }}</option>
            <option [value]="18" selected>USD</option>
         </select>
      </div>

      <div class="col col-6">
         <p class="txt-sm txt-gc mb-03">{{ 'IDIOMA' | translate }}</p>
         <button class="btn-lang txt-x1" (click)="onSelectLanguage()">
            <img src="../../../../assets/images/icon-lang.svg" class="icon" alt="icon">
            {{'ESP'}}
         </button>
      </div>

      <div class="col col-6 tar pt-05">
         <div class="edit">
            <button type="button" class="btn2 btn2-1st btn2-1st-1st btn2-lg btn2-lg-icon mr-1" #save1
               (click)="onSaveAccordion(save1)">
               <img src="../../../../assets/images/icon-b-save-w.svg">
            </button>
            <button type="button" class="btn2 btn2-7th btn2-lg btn2-lg-icon" #cancel1
               (click)="onCancelAccordion(cancel1)">
               <img src="../../../../assets/images/icon-b-discard-w.svg">
            </button>
         </div>
         <div class="edit active">
            <button type="button" class="btn2 btn2-4th btn2-lg btn2-lg-icon" #editAcc1
               (click)="onEditAccordion(editAcc1)">
               <img src="../../../../assets/images/icon-b-edit-w.svg">
            </button>
         </div>
      </div>
      <div class="cb"></div>
   </div>
   <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->

</form>