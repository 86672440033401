<div class="full-h" id="fiat">
	<form class="full-h pos-rel" [formGroup]="accountForm">
		<!-- <div class="flex-col"> -->

		<div class="pt-1">

			<div class="col col-5-1 pr-05">
				<p class="txt-c4-l mb-03">{{ 'NOMBRE_S' | translate }}</p>
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w mb-15" placeholder=""
					[style.border-color]="inputTouched('name') ? 'red' : ''" formControlName="name" type="text">
			</div>
			<div class="col col-5-1 pl-05">
				<p class="txt-c4-l mb-03">{{ 'PRIMER_APELLIDO' | translate }}</p>
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w mb-15" placeholder=""
					[style.border-color]="inputTouched('lastNamePaternal') ? 'red' : ''"
					formControlName="lastNamePaternal" type="text">
			</div>
			<div class="col col-5-1 pr-05">
				<p class="txt-c4-l mb-03">{{ 'SEGUNDO_APELLIDO' | translate }}</p>
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w mb-15" placeholder=""
					[style.border-color]="inputTouched('lastNameMaternal') ? 'red' : ''"
					formControlName="lastNameMaternal" type="text">
			</div>
			<div class="col col-5-1 pl-05">
				<p class="txt-c4-l mb-03">{{ 'GENERO' | translate }}</p>
				<div class="pos-rel">
					<div class="sel sel-sm sel-1st">
						<select class="sel-sm full-w" formControlName="gender" [class.disabled]="edit==false"
							[style.border-color]="inputTouched('gender') ? 'red' : ''">
							<option value="M">{{ 'MASCULINO' | translate }}</option>
							<option value="F">{{ 'FEMENINO' | translate }}</option>
						</select>
					</div>
				</div>
			</div>

			<div class="col col-8">
				<p class="txt-c4-l mb-03">{{ 'FECHA_NACIMIENTO' | translate }}<span> {{ 'FECHA_NACIMIENTO_FORMATO' |
						translate }}</span></p>
				<div class="col col-4 pr-05">
					<div class="pos-rel">
						<div [class.disabled]="edit==false" class="sel sel-sm sel-1st">
							<select class="sel-sm full-w ff-cnd-sm"
								[style.border-color]="inputTouched('day') ? 'red' : ''" formControlName="day">
								<option disabled selected [value]="null">D</option>
								<option *ngFor="let item of monthArray(); let i = index;"
									[value]="i + 1 | number:'2.0'">
									{{ i + 1 | number:'2.0' }} </option>
							</select>
						</div>
					</div>
				</div>
				<div class="col col-4 pr-05">
					<div class="pos-rel">
						<div [class.disabled]="edit==false" class="sel sel-sm sel-1st">
							<select class="sel-sm full-w ff-cnd-sm" formControlName="month"
								[style.border-color]="inputTouched('month') ? 'red' : ''" (change)="setDays($event)">
								<option disabled selected [value]="null">M</option>
								<option *ngFor="let item of yearArray(); let i = index;" [value]="i + 1 | number:'2.0'">
									{{ i + 1 | number:'2.0' }} </option>
							</select>
						</div>
					</div>
				</div>
				<div class="col col-4">
					<div class="pos-rel">
						<div [class.disabled]="edit==false" class="sel sel-sm sel-1st">
							<select class="sel-sm full-w ff-cnd-sm" formControlName="year"
								[style.border-color]="inputTouched('year') ? 'red' : ''" (change)="setYear($event)">
								<option disabled selected [value]="null">{{ 'ANNO' | translate }}</option>
								<option *ngFor="let item of yearArrayComplete; let i = index;"
									[value]="lastValidYear - i">
									{{ lastValidYear - i }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="cb mb-2"></div>

		<div class="tar">
			<button (click)="onEdit()" *ngIf="edit==false" class="btn2 btn2-4th btn2-lg btn2-lg-icon" type="button">
				<img class="icon-full" src="../../../../../assets/images/icon-b-edit-w.svg">
			</button>

			<ng-container *ngIf="edit==true">
				<button (click)="onSave()" class="btn2 btn2-1st btn2-1st-1st btn2-lg btn2-lg-icon mr-1" type="button"
					[disabled]="disableRegister()" [style]="disableRegister() ? 'background-color:gray':''">
					<img class="icon-full" src="../../../../../assets/images/icon-b-save-w.svg">
				</button>
				<button (click)="onDiscard()" class="btn2 btn2-7th btn2-lg btn2-lg-icon" type="button">
					<img class="icon-full" src="../../../../../assets/images/icon-b-discard-w.svg">
				</button>
			</ng-container>
		</div>

		<!-- </div> -->
		<div class="cb"></div>
	</form>
</div>