import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RegisterComponent} from "../../../authetication/components/register/register.component";
import {MonedaModel} from "../../../shared/models/wallet-currency.model";
import {WDepositoComponent} from "../../../cajero/components/w-deposito/w-deposito.component";
import {SessionServices} from "../../../shared/services/session.services";
import {WPreDepositoComponent} from "../../../cajero/components/w-pre-deposito/w-pre-deposito.component";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import {HashCurrencyModel} from "../../../shared/models/hash-currency.model";
import {BonosVisitanteModel} from "../../../shared/models/bonos-visitante.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {Subscription} from "rxjs";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";


declare function adjustSize(): any;

@Component({
	selector: "app-w-conversion-coin",
	templateUrl: "./w-conversion-coin.component.html",
	styleUrls: ["./w-conversion-coin.component.css"]
})
export class WConversionCoinComponent implements OnInit, OnDestroy {

	@Input() cryptoCurrency: MonedaModel[];
	@Input() bono: any;
	@Input() process: string;

	hashKey: HashCurrencyModel;
	wallets: WalletGeneralModel[];
	selectedCoin: MonedaModel;
	subscription = new Subscription();

	wActive: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private sessionService: SessionServices,
		private modalService: NgbModal,
		private store: Store<AppState>,
	) {
	}

	ngOnInit(): void {
		this.subscription.add(this.store.subscribe(state => {
			this.hashKey = state.cajero.jugadorHash;
			this.wallets = state.cajero.wallets;
		}));
		setTimeout(() => {
			this.wActive = true;
		}, 0);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - NO CLICK ()
	noClick(event) {
		event.stopPropagation();
		event.preventDefault();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	onCancel(event) {
		this.onCloseW();
	}

	onNext(event) {
		if (event) {
			event.stopPropagation();
		}
		if (this.process === "ACTIVATED_BONO") {
			const foundCoin = this.wallets.find(x => x.moneda.id === this.selectedCoin.id);
			if (foundCoin) {
				if (foundCoin.deposito_usable_bono) {
					this.onActivateLastDeposit(this.bono);
				} else {
					this.openDeposit("ACTIVATED_BONO");
				}
			} else {
				this.onAddSelectedCoin(event);
			}
		}
		if (this.process === "SHOW_HASH_ONLY_REGISTER" || this.process === "REGISTER_BONO") {
			this.wActive = false;
			setTimeout(() => {
				this.activeModal.close();
				const modalRef = this.modalService.open(RegisterComponent);
				modalRef.componentInstance.selectedCoin = this.selectedCoin;
				modalRef.componentInstance.bono = this.bono;
				modalRef.componentInstance.process = this.process;
				let wallet = this.wallets ? this.wallets.find(x => x.moneda.id === this.selectedCoin.id) : null;
				modalRef.componentInstance.wallet = wallet ? wallet : null;
			}, 250);
		}
		adjustSize();
	}

	onSelectCoin(event, i) {
		event.stopPropagation();
		this.selectedCoin = i;
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	openDeposit(process) {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			const modalRef = this.modalService.open(WDepositoComponent);
			modalRef.componentInstance.selectedWalletCurrency = this.selectedCoin;
			modalRef.componentInstance.bono = this.bono;
			modalRef.componentInstance.process = process;
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ADD SELECTED COIN ()
	onAddSelectedCoin(event) {
		if (event) {
			event.stopPropagation();
		}
		const param = {
			data: {
				jugador_id: this.sessionService.getPlayer().id,
				moneda_id: this.selectedCoin.id
			}
		};
		this.store.dispatch(CajeroAction.addNewWallet({param: param.data}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATE GRATIS  ()
	onActivateLastDeposit(bono: BonosVisitanteModel) {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			const modalRef = this.modalService.open(WPreDepositoComponent);
			modalRef.componentInstance.bono = bono;
			modalRef.componentInstance.balance = this.wallets.find(x => x.moneda.id === this.selectedCoin.id);
			modalRef.componentInstance.process = this.process;
			modalRef.componentInstance.selectedCoin = this.selectedCoin;
			modalRef.result.then();
		}, 250);
	}

}
