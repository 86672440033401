import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {UtilityCurrencyModel} from "../../../shared/models/utility-currency.model";
import * as IMask from "imask";
import {NotificationService} from "../../../shared/services/notification.service";
import Swal from "sweetalert2";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import * as GeneralAction from "../../../shared/redux/general.actions";
import {Actions, ofType} from "@ngrx/effects";
import {Subscription} from "rxjs";
import {fixAndroid} from '../../../shared/utils/general.util';
import {MyControlModel} from "../../../shared/models/control.model";
import {MonedasExchangerModel} from "../../../shared/models/monedas-exchanger.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-w-exchanger",
	templateUrl: "./w-exchanger.component.html",
	styleUrls: ["./w-exchanger.component.css"]
})
export class WExchangerComponent implements OnInit, OnDestroy {

	walletCurrency: WalletGeneralModel[];
	walletCurrencyUntouched: WalletGeneralModel[];
	utilityCurrency: UtilityCurrencyModel[];
	cryptoBaseCurrency: MonedasExchangerModel[];

	selectedOrigenCrypto: WalletGeneralModel;
	selectedDestinyCrypto: WalletGeneralModel;

	origenCryptoValue: any;
	destinyCryptoValue: any;
	destinyCryptoUtility: UtilityCurrencyModel;

	numberIMask = IMask.createMask({
		mask: Number,
		scale: 8,
		signed: false,
		thousandsSeparator: ",",
		radix: "."
	});

	numberIMaskTwo = IMask.createMask({
		mask: Number,
		scale: 8,
		signed: false,
		thousandsSeparator: ",",
		radix: "."
	});
	subscription = new Subscription();
	myMenu: MyControlModel;

	constructor(
		private notification: NotificationService,
		private store: Store<AppState>,
		private actions$: Actions,
		private translate: TranslateService
	) {
	}

	ngOnInit(): void {
		console.log("entrando");
		this.subscription.add(this.store.subscribe((state) => {
				this.walletCurrencyUntouched = state.cajero.wallets;
				this.walletCurrency = this.walletCurrencyUntouched.filter(x => x.balance_real > 0);
				this.myMenu = state.general.myMenu;
			this.cryptoBaseCurrency = state.cajero.currencyExchangeHomeTable.value;
			})
		)
		this.destinyCryptoValue = "0";
		this.getWalletCurrency();
		this.onGetWallets();
		this.listeningActions();
		//
		setTimeout(() => {
			fixAndroid('w-exchanger-cover');
		}, 250);
	}

	ngOnDestroy() {
		console.log("saliendo");
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	listeningActions() {
		this.subscription.add(this.actions$
			.pipe(ofType(CajeroAction.getUtilidadesPorMonedaCompletado))
			.subscribe(response => {
				const resp = response;
				this.utilityCurrency = resp.data.data;
				this.destinyCryptoUtility = this.utilityCurrency[0];
				if (this.destinyCryptoUtility) {
					this.selectedDestinyCrypto = this.walletCurrencyUntouched.find(x => x.moneda.id === this.destinyCryptoUtility.moneda_destino_id);
				}
				if (this.origenCryptoValue) {
					this.changeMonto();
				}
			})
		)

		this.subscription.add(this.actions$
			.pipe(ofType(CajeroAction.postExchangerCompletado))
			.subscribe(response => {
				const resp = response;
				let asunto = this.translate.instant('INTERCAMBIO_SOLICITADO');
				Swal.fire(this.notification.BuildSuccess(resp.data.message, asunto)).then();
				this.origenCryptoValue = null;
				this.destinyCryptoValue = "0";
				const walletFinal = resp.data.wallets.find(x => x.id === this.selectedOrigenCrypto.id);
				this.selectedOrigenCrypto = walletFinal.balance_real > 0 ? walletFinal : this.walletCurrency[0];
				this.changeMonto();
			})
		)
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	selectCurrencyOrigen(event) {
		if (event) {
			event.stopPropagation();
		}
		this.selectedOrigenCrypto = this.walletCurrency.find(x => x.id === Number(event.target.value));
		const param = {
			id: this.selectedOrigenCrypto.id
		};
		this.getCurrencyUtilities(param);
	}

	selectCurrencyDestiny(event) {
		if (event) {
			event.stopPropagation();
		}
		this.destinyCryptoUtility = this.utilityCurrency.find(x => x.id === Number(event.target.value));
		if (this.destinyCryptoUtility) {
			this.selectedDestinyCrypto = this.walletCurrencyUntouched.find(x => x.moneda.id === this.destinyCryptoUtility.moneda_destino_id);
		}
		this.changeMonto();
	}

	calcUSD(base, multi) {
		multi = Number(multi);
		return base * multi;
	}

	changeMonto() {
		if (!this.origenCryptoValue) {
			this.destinyCryptoValue = "0";
			return;
		}
		if (Number(this.origenCryptoValue) > this.selectedOrigenCrypto.balance_real) {
			this.origenCryptoValue = String(this.selectedOrigenCrypto.balance_real);
		}
		this.destinyCryptoValue = String(Number(this.origenCryptoValue) * this.destinyCryptoUtility.precio_jugador);
	}

	exchangeCrypto() {
		const param = {
			data: {
				monto_origen: Number(this.origenCryptoValue),
				tarifa: this.destinyCryptoUtility.precio_jugador,
				exchanger: this.destinyCryptoUtility.proveedor,
				moneda_origen: this.selectedOrigenCrypto.moneda.id,
				moneda_destino: this.destinyCryptoUtility.moneda_destino_id
			}
		};
		this.postCurrencyExchange(param);
	}

	getWalletCurrency() {
		this.store.dispatch(CajeroAction.getWallets());
	}

	onGetWallets() {
		if (this.walletCurrency.length > 0) {
			this.selectedOrigenCrypto = this.walletCurrency[0];
			const param = {
				id: this.selectedOrigenCrypto.id
			};
			this.getCurrencyUtilities(param);
		}
	}

	getCurrencyUtilities(param) {
		this.store.dispatch(CajeroAction.getUtilidadesPorMoneda(param));
	}

	postCurrencyExchange(param) {
		this.store.dispatch(CajeroAction.postExchanger({param: param.data}));
	}

	closeCrypto() {
		const nuevoMenu = {
			menu: true,
			catalog: true,
			exchanger: true,
			back: true,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: false,
			notificaciones: true,
			show_notificaciones: false,
			sub_menu_activo: this.myMenu.sub_menu_activo,
			sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
			cuenta_verificada:true
		};
		this.store.dispatch(GeneralAction.setMyMenu({menu: nuevoMenu, eventEmiterComponent: this.constructor.name}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE EXCHANGER ()
	onCloseExchanger() {
		this.closeCrypto();
	}

}
