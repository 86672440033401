import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {apiRoutes} from "../../api-routing";
import {environment} from "../../../environments/environment";
import {FilterModel} from "../../shared/models/filter.model";

@Injectable({
	providedIn: "root"
})
export class GamesService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	getGamesCategories(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_clasificacion_visual_juegos_filter_all_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getCryptoCurrency(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_monedas_get + "?filter=[[\"crypto\",\"=\",\"1\"]]");
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getGamesDemo(filter: any): Observable<any> {
		const url = this.buildURL(this.formatFilters(filter, apiRoutes.r_frontend_clasificacion_visual_juegos_juegos_sin_favoritos_get.replace("{id}", filter.id)));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	startGamesDemo(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_juegos_iniciar_juego_demo_get.replace("{id}", param.id) + "?mobile=true");
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getGamesFull(filter: any): Observable<any> {
		const url = this.buildURL(this.formatFilters(filter, apiRoutes.r_frontend_clasificacion_visual_juegos_juegos_get.replace("{id}", filter.id)));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	startGamesFull(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_juegos_iniciar_juego_get.replace("{id}", param.id));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	setFavoriteGame(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugadores_cambio_favoritos_post);
		const response = this.httpClient.post(url, param.data);
		return response.pipe(map((data: any) => data));
	}

	getAllGames(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_juegos_all_juegos_get + param);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getBalanceInfo(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugadores_balance_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getCurrentWallets(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugadores_wallets_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getLastFiveGames(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugadores_get_last_games);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postChangeWallet(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugadores_cambio_moneda_activa_patch);
		const response = this.httpClient.patch(url, param);
		return response.pipe(map((data: any) => data));
	}

	private formatFilters(flt: FilterModel, url) {
		url += `?paginate=` + flt.paginate + `&ordenado_por=created_at&direction=DESC&page=` + flt.page;
		let filter = ``;
		if (flt.filter.filter.length !== 0) {
			filter += `&filter=[`;
			let first = true;
			flt.filter.forEach(value => {
				if (first) {
					filter += `["${value.atribute}"`;
				} else {
					filter += `,["${value.atribute}"`;
				}
				filter += `,"${value.operator}"`;
				if (value.value === true) {
					filter += `,true]`;
				} else if (value.value === false) {
					filter += `,false]`;
				} else {
					filter += encodeURI(`,"${value.value}"]`);
				}
				first = false;
			});
			filter += `]`;

		}
		return url + filter;
	}


}
