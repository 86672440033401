export class FilterModel {
	page: number;
	filter: Filter[];

	total: number;
	next: number;
	previous: number;
	paginate: number;
	last_page: number;
	per_page: number;
	from: number;
	to: number;
	id: number;
	amount_last_page: number;

	constructor(paginate?: number, filter?: Array<Filter>) {
		this.filter = filter && filter.length > 0 ? filter : [];
		this.total = 0;
		this.last_page = 0;
		this.next = 0;
		this.previous = 0;
		this.paginate = paginate ? paginate : 25;
		this.page = 1;
		this.from = 0;
		this.to = 0;
		this.amount_last_page = 0;
	}

	
}

export class Filter {
	atribute: string;
	operator: string;
	value: any;
}

export function ExistFieldInFilter(field: string, filter: FilterModel): boolean {
	for (let i = 0; i < filter.filter.length; i++) {
		if (filter.filter[i].atribute == field) {
			return true;
		}
	}

	return false;
}

export function ExtracFieldValueFromFilter(field: string, filter: FilterModel, defaultValue?: any): any {
	if (ExistFieldInFilter(field, filter)) {
		for (let i = 0; i < filter.filter.length; i++) {
			if (filter.filter[i].atribute == field) {
				return filter.filter[i].value;
			}
		}
	}

	return defaultValue ? defaultValue : false;
}