import {Injectable} from "@angular/core";
import {tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {JugadorModel} from "../models/user.model";
import {environment} from "../../../environments/environment";
import {apiRoutes} from "../../api-routing";

import {Store} from "@ngrx/store";
import {AppState} from "../redux/general.reducers";
import { Observable, of } from "rxjs";


@Injectable()
export class SessionServices {

	private access_token: string = '';
	private refresh_token: string = '';
	private token_expire_date_unix: number = 0;
	private player: JugadorModel;
	private api: string = environment.apiUrl;

	constructor(private http: HttpClient, private store: Store<AppState>) {
		this.store.select((state: AppState) => state.auth).subscribe(state => {
			this.access_token = state.token;
			this.refresh_token = state.refresh_token;
			this.token_expire_date_unix = state.expire_date_unix;
			this.player = state.player;
		});
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	/**
	 * Check if the User Token is still valid
	 */
	isLoggedIn() {
		let actualTime = new Date().getTime();
		if (this.player && actualTime > this.token_expire_date_unix) {
			return false;
		}
		if (!this.player) {
			return false;
		}

		return true;
	}

	/**
	 * HTTP Post to Refresh the Token
	 */
	refreshToken() {
		const url = this.buildURL(apiRoutes.r_frontend_refresh);
		return this.http.post<any>(url, {
			refresh_token: this.refresh_token
		});
	}

	getPlayer(): JugadorModel {
		return this.player;
	}

	getPlayerObs(): Observable<JugadorModel>  {
		return of(this.player);
	}

	isDataComplete() {
		const player: JugadorModel = this.getPlayer();
		if (!player.nombre || player.nombre.length === 0) {
			return false;
		}
		if (!player.apellido_paterno || player.apellido_paterno.length === 0) {
			return false;
		}
		if (!player.fecha_nacimiento || player.fecha_nacimiento.length === 0) {
			return false;
		}
		if (!player.genero || player.genero.length === 0) {
			return false;
		}
		if (!player.telefono_celular || player.telefono_celular.length === 0) {
			return false;
		}
		if (!player.pais || player.pais.length === 0) {
			return false;
		}
		if (!player.estado || player.estado.length === 0) {
			return false;
		}
		if (!player.ciudad || player.ciudad.length === 0) {
			return false;
		}
		if (!player.colonia || player.colonia.length === 0) {
			return false;
		}
		if (!player.calle || player.calle.length === 0) {
			return false;
		}
		if (!player.codigo_postal || player.codigo_postal.length === 0) {
			return false;
		}
		return true;
	}
}
