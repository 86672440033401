import {Component, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SessionServices} from "../../../shared/services/session.services";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";
import * as GeneralAction from "../../../shared/redux/general.actions";
import {MyControlModel} from "../../../shared/models/control.model";
import {NavigationStart, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Actions, ofType} from "@ngrx/effects";
import * as AuthAction from "../../../authetication/redux/authentication.actions";
import Swal from "sweetalert2";
import * as GamesAction from "../../redux/games.actions";
import {GamesModel} from "../../../shared/models/games.model";

@Component({
	selector: "app-w-back",
	templateUrl: "./w-back.component.html",
	styleUrls: ["./w-back.component.css"]
})
export class WBackComponent implements OnInit, OnDestroy {

	currentWallet: WalletGeneralModel;
	allWallets: WalletGeneralModel[];
	myMenu: MyControlModel;
	balanceSelected: number;
	rutaActiva: string;
	subscription = new Subscription();
	juegoSeleccionado: GamesModel;
	estoyEnPlayground: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private sessionService: SessionServices,
		private store: Store<AppState>,
		private router: Router,
		private actions$: Actions,
	) {
	}

	ngOnInit(): void {
		this.store.subscribe((state) => {
			this.currentWallet = state.cajero.balance;
			this.allWallets = state.cajero.wallets;
			this.balanceSelected = this.currentWallet ? this.currentWallet.moneda.id : 0;
			this.myMenu = state.general.myMenu;
			this.juegoSeleccionado = state.games.juegoSeleccionado;
		})
		this.getCurrentWallets();

		this.subscription.add(this.actions$
			.pipe(ofType(CajeroAction.cambioMonedaActivaCompletado))
			.subscribe(response => {
				if (this.estoyEnPlayground) {
					this.store.dispatch(GamesAction.setJuegoSeleccionado({game: this.juegoSeleccionado}));
				}
			}));

		this.subscription.add(this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.rutaActiva = event.url;
				if (this.rutaActiva.includes('/games/playground')) {
					this.estoyEnPlayground = true;
				} else {
					this.estoyEnPlayground = false;
				}
			}
		}));
	}

	ngOnDestroy() {
		if (this.subscription)
			this.subscription.unsubscribe();
	}

	onSelectBalance(id) {
		this.balanceSelected = id;
		const param = {
			moneda_id: id
		};
		this.postChangeWallet(param);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SHOW TOOLTIP ()
	showTooltip(event, t) {
		event.stopPropagation();
		const tt = event.target as HTMLParagraphElement;
		tt.classList.add("active");
		setTimeout(() => {
			tt.classList.remove("active");
		}, t * 1000);
	}

	wCloseWindow() {
		if (document.getElementById("back").classList.contains("active")) {
			document.getElementById("back").classList.remove("active");
			var wbak = document.getElementById("wsBack") as HTMLDivElement;
			if (wbak) {
				wbak.classList.toggle("active");
			}

		}
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	getCurrentWallets() {
		this.store.dispatch(CajeroAction.getWallets());
	}

	postChangeWallet(param) {
		this.store.dispatch(CajeroAction.cambioMonedaActiva(param))

	}

	onCloseBack() {
		const nuevoMenu = {
			menu: true,
			catalog: true,
			exchanger: true,
			back: true,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: false,
			notificaciones: true,
			show_notificaciones: false,
			sub_menu_activo: this.myMenu.sub_menu_activo,
			sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
			cuenta_verificada: true
		};
		this.store.dispatch(GeneralAction.setMyMenu({menu: nuevoMenu, eventEmiterComponent: this.constructor.name}));
	}

}
