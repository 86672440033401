<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover coverB" id="w-afil-retiro-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">

	<div class="window window-pb8 pt-1" id="w-afil-retiro">
		<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="btn-window-close" (click)="onCloseW()">
				<div class="btn-window-close-bar"></div>
			</div>
			<p class="w-title-cover txt-sm txt-w tac mb-1">{{'RETIRAR' | translate}}</p>
			<div class="px-15 pb-3">
				<p class="txt-g9 mb-2">{{'INTRODUZCA_LA' | translate}} <span class="txt-w mb-1">{{'DIRECCION' | translate}}</span> {{'A_LA_Q_SE_ENVIARAN_GANACIAS' | translate}}.</p>

				<p class="txt-gc mb-05">{{'DIRECCION' | translate}}:</p>
				<div class="col col-btn2-11 pr-1">
					<textarea [(ngModel)]="walletHash" class="ta2 ta2-nor full-w txt-w tac"></textarea>
				</div>
				<div class="col col-btn2-1">
					<button type="button" class="btn2 btn2-4th btn2-lg btn2-lg-icon" (click)="onPasteHash($event)">
						<img src="../../../../assets/images/icon-paste.svg">
					</button>
				</div>
				<div class="cb"></div>
			</div>
			<div class="btn2-actions px-2 pb-2">
				<div class="col col-6 pr-07">
					<button type="button" class="btn2 btn2-lg btn2-g full-w"
						(click)="onCancel($event)">{{'CANCELAR' |translate}}</button>
				</div>
				<div class="col col-6 pl-07">
					<button type="button" class="btn2 btn2-lg btn2-1st full-w" [disabled]="walletHash == ''"
						(click)="onRetiro($event)">{{'CONTINUAR' | translate}}</button>
				</div>
				<div class="cb"></div>
			</div>

		</div>
		<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

	</div>

</div>
