import {Component, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../../../shared/services/notification.service";
import {HelpService} from "../../services/help.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as GeneralAction from "../../../shared/redux/general.actions";

import {fixAndroid} from "../../../shared/utils/general.util";
import {TranslateService} from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-w-new-ticket",
	templateUrl: "./w-new-ticket.component.html",
	styleUrls: ["./w-new-ticket.component.css"]
})
export class WNewTicketComponent implements OnInit, OnDestroy {

	ticketForm: FormGroup;
	categorias: { id: number, nombre: string }[];

	wActive: boolean = false;

	constructor(
		private helpService: HelpService,
		private formBuilder: FormBuilder,
		public activeModal: NgbActiveModal,
		private notification: NotificationService,
		private store: Store<AppState>,
		private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		this.categorias = [
			{id: 1, nombre: this.translateService.instant("TICKET_CAT_APERTURA_CUENTA")},
			{id: 2, nombre: this.translateService.instant("TICKET_CAT_VERIFICACION")},
			{id: 3, nombre: this.translateService.instant("TICKET_CAT_FALLA_TECNICA")},
			{id: 4, nombre: this.translateService.instant("TICKET_CAT_DEPOSITOS")},
			{id: 5, nombre: this.translateService.instant("TICKET_CAT_EXCHANGER")},
			{id: 6, nombre: this.translateService.instant("TICKET_CAT_RETIROS")},
			{id: 8, nombre: this.translateService.instant("TICKET_CAT_OTROS")},
			];
		this.initForm();
		setTimeout(() => {
			this.wActive = true;
		}, 0);
		setTimeout(() => {
			fixAndroid("new-ticket-cover");
		}, 250);
	}

	ngOnDestroy(): void {
	}

	initForm() {
		this.ticketForm = this.formBuilder.group(
			{
				asunto: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				categoria: new FormControl(null, [Validators.required])
			}
		);
	}

	inputTouched(formName: string) {
		return this.ticketForm.controls[formName].invalid && (this.ticketForm.controls[formName].dirty || this.ticketForm.controls[formName].touched);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON NEW TICKET ()
	onNewTicket(event) {
		event.stopPropagation();
		const ticketModel = this.ticketForm.value;
		const param = {
			data: {
				asunto: ticketModel.asunto,
				categoria_id: ticketModel.categoria
			}
		};
		this.postNewTicket(param.data);
	}

	// API CALLS

	postNewTicket(param) {
		this.store.dispatch(GeneralAction.addTickets({params: param}));
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
		}, 250);
	}


}
