<section class="wider" id="cuenta-afiliado">

   <div class="section-header sh-afiliado">
      <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
      <div class="hidden-landscape">
         <div class="section-title">
            <div class="txt-snor pr-1">{{'AFILIADOS' | translate}}</div>
            <img src="../../../../assets/images/icon-m-mov-afiliacion.svg" class="section-icon">

            <!-- *ngIf="afiliado" -->
            <div class="pl-1 pr-05">
               <div class="txt-ssm tar"><span class="txt-c4" *ngIf="afiliado && afiliado.id">ID: </span> {{ afiliado ? afiliado.id :
                  "" }}<br>
                  {{ afiliado ? afiliado.usuario : "" }}
               </div>
            </div>
         </div>
      </div>      
   </div>
   <div class="full-h pos-rel hidden-landscape">

      <router-outlet></router-outlet>

      <ul class="" id="menu-datos">
         <li routerLink="/afiliados/campannas" routerLinkActive="active"><span class="txt-sm tac">{{'CAMPANNAS' | translate}}</span></li>
         <li routerLink="/afiliados/trafico" routerLinkActive="active"><span class="txt-sm tac">{{'CONTROL_TRAFICO' | translate}}</span></li>
         <li routerLink="/afiliados/comisiones" routerLinkActive="active"><span class="txt-sm tac">{{'MIS_COMISIONES' | translate}}</span>
         </li>
         <li routerLink="/afiliados/balances_retiros" routerLinkActive="active"><span class="txt-sm tac">{{'BALANCES' | translate}} /
               {{'RETIROS' | translate}}</span></li>
      </ul>
   </div>
   <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
</section>
