import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { NotificationService } from "../../../shared/services/notification.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import * as AfiliadosActions from '../../redux/afiliados.actions';
import { Subscription } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import * as GeneralAction from "../../../shared/redux/general.actions";
import { Router } from "@angular/router";

import { fixAndroid } from '../../../shared/utils/general.util';
import { TranslateService } from "@ngx-translate/core";
declare function adjustSize(): any;

@Component({
	selector: "app-w-new-account",
	templateUrl: "./w-new-account.component.html",
	styleUrls: ["./w-new-account.component.css"]
})
export class WNewAccountComponent implements OnInit, OnDestroy {

	registerForm: FormGroup;
	accountAfiliadoCreatedSubscription: Subscription = null;
	afiliadosMode: boolean = false;

	wActive: boolean = false;

	constructor(
		private notification: NotificationService,
		public activeModal: NgbActiveModal,
		private store: Store<AppState>,
		private router: Router,
		private actions$: Actions,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		adjustSize();
		this.initForm();

		this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
		});

		this.listeningActions();
		setTimeout(() => { this.wActive = true; }, 0);
		setTimeout(() => { fixAndroid('w-afiliado-new-account-cover'); }, 250);
	}

	ngOnDestroy(): void {
		if (this.accountAfiliadoCreatedSubscription) {
			this.accountAfiliadoCreatedSubscription.unsubscribe();
		}
	}

	initForm() {
		this.registerForm = new FormGroup(
			{
				username: new FormControl(null, [Validators.required, Validators.minLength(3)])
			}
		);
	}

	inputTouched(formName: string) {
		return this.registerForm.controls[formName].invalid && (this.registerForm.controls[formName].dirty || this.registerForm.controls[formName].touched);
	}

	onCreateAfiliado(event) {
		event.stopPropagation();
		if (this.registerForm.valid) {
			const usuarioAfiliado = this.registerForm.value.username;
			this.store.dispatch(AfiliadosActions.afiliadosCreateAccount({ 'usuario': usuarioAfiliado }));
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

	onCancelPort() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			console.log(1);
			this.router.navigate(["/inicio/jugador"]).then();
		}, 250);
	}

	listeningActions() {
		this.accountAfiliadoCreatedSubscription = this.actions$
			.pipe(ofType(AfiliadosActions.afiliadosCreateAccountCompletado))
			.subscribe(response => {
				if (!this.afiliadosMode) {
					this.store.dispatch(GeneralAction.setAfiliadosMode({ afiliadosMode: true }));
				}

				const message = this.translate.instant('MSG_AFILIADO_GUARDADO_SATISFATORIO');
				Swal.fire(this.notification.BuildSuccess(message)).then(() => {
					this.activeModal.close('OnCreateAfilateAccountBtn');
					this.router.navigate(["/afiliados/inicio"]).then();
				});

			})
	}

}
