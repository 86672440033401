import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import {StartGameModel} from "../../../shared/models/start-game.model";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {GamesModel} from "../../../shared/models/games.model";
import * as GameAction from "../../redux/games.actions";
import {Actions, ofType} from "@ngrx/effects";
import {Location} from "@angular/common";

declare function adjustSize(): any;

@Component({
	selector: "app-playground",
	templateUrl: "./playground.component.html",
	styleUrls: ["./playground.component.css"]
})
export class PlaygroundComponent implements OnInit, AfterViewInit, OnDestroy {

	game: StartGameModel;
	juegoSeleccionado: GamesModel;
	subscription = new Subscription();

	constructor(
		private store: Store<AppState>,
		private actions$: Actions,
		private _location: Location
	) {

	}

	ngOnInit() {
		this.setListeners();
		document.body.classList.add('landscape-turn');
	}

	ngAfterViewInit() {
		adjustSize();
	}

	ngOnDestroy() {
		document.body.classList.remove('landscape-turn');
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	setListeners() {
		this.subscription.add(this.store.subscribe((state) => {
			this.juegoSeleccionado = state.games.juegoSeleccionado;
		}));
		if (this.juegoSeleccionado) {
			this.startGamesFull({id: this.juegoSeleccionado.id});
		}
		this.subscription.add(this.actions$
			.pipe(ofType(GameAction.setJuegoSeleccionado))
			.subscribe(
				(response) => {
					this.juegoSeleccionado = response.game;
					this.startGamesFull({id: this.juegoSeleccionado.id});
				}));


		this.subscription.add(this.actions$
			.pipe(ofType(GameAction.iniciarJuegoCompletado))
			.subscribe(
				(response) => {
					this.game = response.data;
				}));

		//Cunado da errores
		this.subscription.add(this.actions$
			.pipe(ofType(GameAction.iniciarJuegoError))
			.subscribe(
				(response) => {
					document.body.classList.remove('landscape-turn');
					this._location.back();
					//this.router.navigate(["/games/playground"], {queryParams: {gameID: game.id}}).then();
				}));
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - GENERAL STUFF */

	startGamesFull(param) {
		this.store.dispatch(GameAction.iniciarJuego(param));
	}

}
