<input type="text" autofocus="autofocus" style="display:none"/>

<div class="w-cover coverB" id="w-campaign-info-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">

	<div class="window" id="campaign-info">
      <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
      <div class="hidden-landscape">
         <div class="btn-window-close" (click)="onCloseW()">
            <div class="btn-window-close-bar"></div>
         </div>
         <p class="w-title-cover txt-ssm txt-w tac mb-1"><span class="txt-g8">{{'CAMPANNA' | translate}}: </span> {{campaign.nombre}}</p>
         <div class="px-15">
            <div class="bg-g1 p-15">
               <p class="txt-sm txt-g8 mb-03">{{'TIPO_TRATO' | translate}}</p>
               <input type="text" class="inp2 inp2-nor inp2-1st full-w mb-1" value="{{campaign.trato.tipo_trato.nombre}}" readonly>
               <div class="col col-6 pr-07">
                  <p class="txt-sm txt-g8 mb-03">{{'TRATO_CPA' | translate}}:</p>
               <input type="text" class="inp2 inp2-nor inp2-1st full-w tac mb-1" value="{{campaign.trato.monto_cpa}}" readonly>
               </div>
               <div class="col col-6 pl-07">
                  <p class="txt-sm txt-g8 mb-03">{{'TRATO_REV_SHARE' | translate}}</p>
               <input type="text" class="inp2 inp2-nor inp2-1st full-w tac mb-1" value="{{campaign.trato.porciento_rev_share}}" readonly>
               </div>
               <div class="col col-6 pr-07">
                  <p class="txt-sm txt-g8 mb-03">&nbsp;<br>{{'MONTO_MINIMO_FTD' | translate}}:</p>
               <input type="text" class="inp2 inp2-nor inp2-1st full-w tac mb-1" value="{{campaign.trato.monto_minimo_ftd_activacion}}" readonly>
               </div>
               <div class="col col-6 pl-07">
                  <p class="txt-sm txt-g8 mb-03">{{'VOLUMEN_MAXIMO_APUESTAS_ACTIVACION' | translate}}</p>
               <input type="text" class="inp2 inp2-nor inp2-1st full-w tac mb-1" value="{{campaign.trato.monto_minimo_wared_activacion}}" readonly>
               </div>
               <div class="cb"></div>
            </div>
            <div class="pt-2">
               <div class="col col-6 pr-07">
                  <button class="btn2 btn2-lg btn2-g full-w" type="button" (click)="onCloseW()">{{'CERRAR'}}</button>
               </div>
               <div class="cb"></div>
            </div>
         </div>

      </div>
      <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

	</div>

</div>
