import {Component, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "app-w-ww-terms",
	templateUrl: "./w-ww-terms.component.html",
	styleUrls: ["./w-ww-terms.component.css"]
})
export class WWwTermsComponent implements OnInit {

	constructor(
		public activeModal: NgbActiveModal
	) {
	}

	ngOnInit(): void {
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW(event) {
		event.stopPropagation();
		this.activeModal.close(false);
	}

}
