import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CampaignModel } from "../../../../shared/models/campaign.model";
import * as IMask from "imask";

@Component({
	selector: "app-w-campaign-info",
	templateUrl: "./w-campaign-info.component.html",
	styleUrls: ["./w-campaign-info.component.css"]
})
export class WCampaignInfoComponent implements OnInit {

	@Input() campaign: CampaignModel;

	numberIMask = IMask.createMask({
		mask: Number,
		scale: 8,
		signed: false,
		thousandsSeparator: ",",
		radix: "."
   });

   wActive:boolean = false;

	constructor(
		public activeModal: NgbActiveModal
	) { }

	ngOnInit(): void {
      setTimeout(() => { this.wActive = true; }, 0);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close();
      }, 250);
	}

}
