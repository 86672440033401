import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {Subscription} from "rxjs";
import {MyControlModel} from "../../../shared/models/control.model";
import {NotificationsModel} from "../../../shared/models/notifications.model";
import * as GeneralAction from "../../../shared/redux/general.actions";

import { fixAndroid } from '../../../shared/utils/general.util';

@Component({
	selector: 'app-w-notificaciones',
	templateUrl: './w-notificaciones.component.html',
	styleUrls: ['./w-notificaciones.component.css']
})
export class WNotificacionesComponent implements OnInit, OnDestroy {

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	notyfyNum = 5;
	subscription = new Subscription();
	myMenu: MyControlModel;
	notificaciones: NotificationsModel[];

	constructor(
		public activeModal: NgbActiveModal,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.subscription.add(this.store.subscribe((state) => {
			this.myMenu = state.general.myMenu;
			this.notificaciones = state.general.notificaciones;
		}))
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		const nuevoMenu = {
			menu: true,
			catalog: true,
			exchanger: true,
			back: true,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: false,
			notificaciones:true,
			show_notificaciones:false,
			sub_menu_activo: this.myMenu.sub_menu_activo,
			sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
			cuenta_verificada: true
		};
		this.store.dispatch(GeneralAction.setMyMenu({menu: nuevoMenu, eventEmiterComponent:this.constructor.name}));
	}

	marcarComoLeida(event, id) {
		event.stopPropagation();
		this.store.dispatch(GeneralAction.marcarNotificacionComoLeido({id: id}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DEL NOTIF ()
	onDelNotify(event, id) {
		event.stopPropagation();
		this.store.dispatch(GeneralAction.eliminarNotificacion({id: id}));
	}

}
