import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {apiRoutes} from "../../api-routing";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class CatalogService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	getCatAndGames(endUrl) {
		const url = this.buildURL(apiRoutes.r_frontend_games_general + endUrl);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getGamesCategories(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_clasificacion_visual_juegos_filter_all_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getCryptoCurrency(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_monedas_get + "?filter=[[\"crypto\",\"=\",\"1\"]]");
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postFavoriteGame(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugadores_cambio_favoritos_post);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	getCatalogGames(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_juegos_all_juegos_get + param);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getCatalogGamesWhitImages(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_juegos_all_juegos_imagenes_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}
}
