<input type="text" autofocus="autofocus" style="display:none"/>

<div class="w-cover coverB" id="w-cover-register" [class.active]="wActive==true" (swipedown)="onCloseW()">
    <!-- (click)="noClick($event)" -->

    <div class="window" id="registro">
        <form class="full" [formGroup]="registerForm" (ngSubmit)="onRegister($event)">
            <!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
            <div class="hidden-landscape">
                <div class="btn-window-close" (click)="onCloseW()">
                    <div class="btn-window-close-bar"></div>
                </div>
                <p class="w-title-cover txt-sm txt-w tac">{{'REGISTRO' |translate}}</p>

                <div class="px-1">
                    <div class="bg-g1 p-15 invalid">
                        <div class="col col-6 pr-05">
                            <p class="txt-gc mb-05">{{'NOMBRE_USUARIO'|translate}}</p>
                            <input type="text" class="inp2 inp2-nor full-w" placeholder="" value=""
                                   [style.border-color]="inputTouched('username') ? 'red' : ''"
                                   formControlName="username">
                        </div>
                        <div class="cb mb-15"></div>
                        <p class="txt-gc mb-05">{{'EMAIL'|translate}}</p>
                        <input type="email" class="inp2 inp2-nor full-w mb-2" placeholder="" value=""
                               [style.border-color]="inputTouched('email') ? 'red' : ''" formControlName="email">
                        <div class="col col-6 pr-05">
                            <p class="txt-gc mb-05">{{'CONTRASENNA'|translate}} <span class="txt-c4-l">*</span></p>
                            <input type="password" class="inp2 inp2-nor full-w" placeholder="" value=""
                                   [style.border-color]="inputTouched('password') ? 'red' : ''"
                                   formControlName="password">
                        </div>
                        <div class="col col-6 pl-05">
                            <p class="txt-gc mb-05">{{'REPETIR_CONTRASENNA'|translate}} <span class="txt-c4-l">*</span>
                            </p>
                            <input type="password" class="inp2 inp2-nor full-w" placeholder="" value=""
                                   [style.border-color]="inputTouched('passwordConfirmation') ? 'red' : ''"
                                   formControlName="passwordConfirmation">
                        </div>
                        <div class="cb mb-15"></div>

                        <div class="txt-x2">
                            <p *ngIf="checkForValidPasword('minlength')"><span
                                    class="txt-c4-l">*</span> {{'USA_SEIS_CARACTERES' |translate}}.</p>
                            <p *ngIf="checkForValidPasword('validPasswordMayuscula')"><span class="txt-c4-l">*</span>
                                {{'USA_UNA_MAYUSCULA' |translate}}.</p>
                            <p *ngIf="checkForValidPasword('validPasswordMinuscula')"><span class="txt-c4-l">*</span>
                                {{'USA_UNA_MINUSCULA' |translate}}.</p>
                            <p *ngIf="checkForValidPasword('validPasswordNumero')"><span
                                    class="txt-c4-l">*</span> {{'USA_UN_NUMERO' |translate}}.</p>
                            <p *ngIf="checkForValidPasword('validPasswordCaracterRaro')"><span class="txt-c4-l">*</span>
                                {{'USA_UN_SIMBOLO' |translate}}.</p>
                        </div>
                    </div>
                </div>

                <div class="btn2-actions px-2 pb-2">
                    <div class="chb chb-4th chb-nor mb-3">
                        <input class="" type="checkbox" (click)="$event.stopPropagation()"
                               formControlName="termsAndConditions"
                               [style.border-color]="inputTouched('termsAndConditions') ? 'red' : ''">
                        <span>Acepto los <a (click)="goToTermConditions()"
                                            href="#">{{'TERMINOS_CONDICIONES' |translate}}</a></span>
                    </div>
                    <button (click)="onRegister($event)" class="btn2 btn2-lg btn2-1st btn2-lg-icon btn2-log full-w mb-3"
                            type="button">
                        <!-- [disabled]="disableRegister()" -->
                        {{'REGISTRATE' |translate}}
                        <img src="../../../../assets/images/icon-b-log-c.svg" alt="ingresar">
                    </button>
                    <button (click)="onRegisterFB($event)" class="btn2 btn2-lg btn2-fb btn2-lg-icon full-w"
                            type="button">
                        {{'REGISTRATE' |translate}}
                        <img src="../../../../assets/images/icon-b-fb-c.svg" alt="ingresar facebook">
                    </button>
                    <p class="txt-a pt-2 tac" (click)="goToLogin($event)">{{'YA_TENGO_CUENTA'|translate}}</p>
                </div>

            </div>
            <!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

        </form>
    </div>

</div>
