import {Component, OnDestroy, OnInit} from "@angular/core";
import {JugadorDocumentosModel} from "../../../../shared/models/jugador-documentos.model";
import {NotificationService} from "../../../../shared/services/notification.service";
import Swal from "sweetalert2";
import { HttpEventType} from "@angular/common/http";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/redux/general.reducers";
import {Actions, ofType} from "@ngrx/effects";
import * as GeneralAction from "../../../../shared/redux/general.actions";
import * as CajeroAction from "../../../redux/cajero.actions";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-verif-fbform",
	templateUrl: "./verif-fbform.component.html",
	styleUrls: ["./verif-fbform.component.css"]
})
export class VerifFbformComponent implements OnInit, OnDestroy {

	selectedDocuments: JugadorDocumentosModel[];
	subscription = new Subscription();

	progress = 0;
	uploadError = false;
	barStatus: string;

	constructor(
		private notification: NotificationService,
		private store: Store<AppState>,
		private actions$: Actions,
		private translateService: TranslateService,
	) {
	}

	ngOnInit(): void {
		this.subscription.add(this.store.subscribe((state) => {
			this.selectedDocuments = state.cajero.documentosJugador.filter(x => x.tipo_documento_id === 3);
		}))
		this.subscription.add(this.actions$
			.pipe(ofType(GeneralAction.uploadFilesCompletado))
			.subscribe(response => {
				switch (response.data.type) {
					case HttpEventType.Sent:
						this.uploadError = false;
						this.progress = 0;
						break;
					case HttpEventType.ResponseHeader:
						break;
					case HttpEventType.UploadProgress:
						this.progress = Math.round(response.data.loaded / response.data.total * 100);
						if (this.progress !== 100) {
							this.barStatus = String(this.progress) + "%";
						}
						if (this.progress === 100) {
							this.barStatus = this.translateService.instant("ESPERE_POR_FAVOR");
						}
						break;
					case HttpEventType.Response:
						this.barStatus = this.translateService.instant("COMPLETADO");
						this.progress = 0;
						const param = {
							archivo_temporal_id: response.data.body.object.id,
							tipo_documento_id: 3
						};
						this.postDocumentosJugador(param);
						setTimeout(() => this.barStatus = null, 1000);
						return;
				}

			}));
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DELETE DOC ()
	onDeleteDoc(event, doc: JugadorDocumentosModel) {
		event.stopPropagation();
		const message = this.translateService.instant("DESEAS_ELIMINAR_DOCUMENTO");
		Swal.fire(this.notification.BuildQuestion(message, this.translateService.instant("ELIMINAR_DOCUMENTO"))).then(
			result => {
				if (result.value) {
					const param = {
						id: doc.id
					};
					this.deleteDocumentosJugador(param);
				}
			}
		);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ACTION ()
	openCamera() {
		document.querySelector("input").click();
	}

	handle(event: any) {
		if (!event.target.files[0]) {
			return;
		}
		const inpF = event.target as HTMLInputElement;

		if ((inpF.files[0] as any)) {
			const input = event.target;
			const file = input.files[0];
			const fileName = (inpF.files[0] as any).name;

			const sizeByte = file.size * 1;
			const result = sizeByte / 2048;
			const sizeKiloByte = parseInt(result + "", 10);

			if (sizeKiloByte > 2048) {
				Swal.fire(this.notification.BuildError(this.translateService.instant("TAMANNO_MAXIMO_SUPERA_MAXIMO", { value: 2 }), this.translateService.instant("OPSS"))).then(() => {
				});
				return;
			}

			this.uploadImageFile(file, fileName);
		}
	}

	uploadImageFile(file, filename) {
		const params = {
			model: 'DocumentoJugador',
			file: file,
			filename: filename
		};
		this.store.dispatch(GeneralAction.uploadFiles({params: params}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - OTHER ()

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	// API CALLS


	postDocumentosJugador(param) {
		this.store.dispatch(CajeroAction.postDocumentoJugador({params: param}));
	}

	deleteDocumentosJugador(param) {
		this.store.dispatch(CajeroAction.deleteDocumentoJugador({param: param}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOGGLE TOLLTIP2 ()
	timerTT;

	onToggleTooltip2(event, tt) {
		event.stopPropagation();
		var thisTT = document.getElementById(tt) as HTMLDivElement;
		if (thisTT) {
			if (thisTT.classList.contains('active')) {
				thisTT.classList.remove('active');
				clearTimeout(this.timerTT);
			} else {
				this.timerTT = setTimeout(() => {
					thisTT.classList.remove('active');
				}, 5000);
				thisTT.classList.add('active');
			}
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - FIRE FILE ()
	fireFile(event) {
		event.stopPropagation();
		var thisE = event.target as HTMLButtonElement;
		var thisF = thisE.closest('div').querySelector('input') as HTMLInputElement;
		thisF.click();
		console.log(thisF);
	}

	dibujarImagen(doc, tipo) {
		const extension = doc.url.split(".");
		const leng = extension.length;
		if (tipo == "imagen") {
			if (extension[leng - 1] === "pdf") {
				return false
			} else {
				return true;
			}
		} else {
			if (extension[leng - 1] === "pdf") {
				return true
			} else {
				return false;
			}
		}
	}

	onShow(event, item) {
		event.stopPropagation();
		window.open(item.url, '_blank');
	}


}
