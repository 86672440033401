/* tslint:disable:variable-name */

import {AfiliadoModel} from "./afiliado.model";
import {IdiomaModel} from "./idioma.model";

// noinspection JSUnusedGlobalSymbols,SpellCheckingInspection
export class JugadorModel {
	actividad_economica_id: number;
	afiliado: AfiliadoModel;
	apellido_materno: string;
	apellido_paterno: string;
	calle: string;
	categoria_economica_id: number;
	ciudad: string;
	codigo_postal: string;
	colonia: string;
	direccion1: string;
	estado: string;
	fecha_nacimiento: string;
	genero: string;
	id: number;
	moneda_base_id: number;
	moneda_id: number;
	municipio: string;
	nombre: string;
	numero_exterior: string;
	numero_interior: string;
	pais: string;
	pais_id: string;
	sector_economico_id: string;
	segundo_nombre: string;
	telefono_celular: string;
	telefono_fijo: string;
	usuario: UsuarioModel;
	estado_cuenta: string;
	estado_cuenta_trans: string;
	estado_pais_id: number;
	idioma: IdiomaModel;
	idioma_id: number;
}

// noinspection JSUnusedGlobalSymbols,SpellCheckingInspection
export class UsuarioModel {
	crypto: boolean;
	email: string;
	usuario: string;
}

export class Tokens {
	access_token: string;
	refresh_token: string;
	expires_in: number;
}

export class RegisterData {
	crypto: boolean;
	email: string;
	moneda_base_id: number;
	moneda_id: number;
	password: string;
	password_confirmation: string;
	terminos: boolean;
	username: string;
	quiere_afiliado: boolean;
	campana_codigo: string;
}

export class Credenciales {
	username: string;
	password: string;
}
