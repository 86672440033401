import {Component, OnInit} from "@angular/core";
import {JugadorModel} from "../../../../shared/models/user.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/redux/general.reducers";
import * as CuentaAction from "../../../redux/cuenta.actions";
import * as GeneralAction from "../../../../shared/redux/general.actions";
import * as countriesJSON from "../../../../../assets/json/countries.json";
import {CountryModel} from "../../../../shared/models/country.model";
import {Actions, ofType} from "@ngrx/effects";
import {SessionServices} from "../../../../shared/services/session.services";

@Component({
	selector: "app-tab-datos-direccion",
	templateUrl: "./tab-datos-direccion.component.html",
	styleUrls: ["./tab-datos-direccion.component.css"]
})
export class TabDatosDireccionComponent implements OnInit {


	account: JugadorModel;
	countries: CountryModel[];
	accountForm: FormGroup;
	estados: any[];
	edit: boolean = false;

	subscription = new Subscription();

	constructor(
		private formBuilder: FormBuilder,
		private store: Store<AppState>,
		private sessionService: SessionServices,
		private actions$: Actions,
	) {
	}

	ngOnInit(): void {
		this.edit = false;
		this.account = this.sessionService.getPlayer();
		this.countries = countriesJSON.countries;
		this.initForm();


		this.subscription.add(this.store.subscribe((state) => {
			this.account = state.auth.player;
			this.estados = state.general.estadoPaises;
		}))

		if (this.account.pais_id) {
			this.store.dispatch(GeneralAction.getEstadosPaises({params: {id: this.account.pais_id}}));
		}

		this.subscription.add(this.actions$
			.pipe(ofType(GeneralAction.getEstadosPaisesCompletado))
			.subscribe(response => {
				setTimeout(() => this.accountForm.patchValue({estado_pais_id: this.account.estado_pais_id}), 100);
			}));


	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initForm() {
		this.accountForm = this.formBuilder.group(
			{
				pais_id: new FormControl(this.account.pais_id, Validators.required),
				estado_pais_id: new FormControl(null, Validators.required),
				municipio: new FormControl(this.account.municipio, Validators.required),
				numero_interior: new FormControl(this.account.numero_interior, Validators.required),
				numero_exterior: new FormControl(this.account.numero_exterior, Validators.required),
				codigo_postal: new FormControl(this.account.codigo_postal, Validators.required),
				ciudad: new FormControl(this.account.ciudad, Validators.required),
				colonia: new FormControl(this.account.colonia, Validators.required)
			}
		);

		this.accountForm.disable();
	}

	inputTouched(formName: string) {
		return this.accountForm.controls[formName].invalid && (this.accountForm.controls[formName].dirty || this.accountForm.controls[formName].touched);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON EDIT ()
	onEdit() {
		this.edit = true;
		this.accountForm.enable();
	}


	getEstadosByCountry(event) {
		const countryId = event.target.value;
		this.store.dispatch(GeneralAction.getEstadosPaises({params: {id: countryId}}));
	}

	onSave() {
		if (this.accountForm.valid) {
			const accountModel = this.accountForm.value;
			const param = {
				pais_id: accountModel.pais_id,
				estado_pais_id: accountModel.estado_pais_id,
				municipio: accountModel.municipio,
				numero_interior: accountModel.numero_interior,
				numero_exterior: accountModel.numero_exterior,
				codigo_postal: accountModel.codigo_postal,
				ciudad: accountModel.ciudad,
				colonia: accountModel.colonia,
				paso: 3
			};
			this.patchAccountDataPerfil(param);
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DISCARD ()
	onDiscard() {
		this.accountForm.reset(
			{
				pais_id: {value: this.account.pais_id, disabled: true},
				estado_pais_id: {value: this.account.estado_pais_id, disabled: true},
				municipio: {value: this.account.municipio, disabled: true},
				numero_interior: {value: this.account.numero_interior, disabled: true},
				numero_exterior: {value: this.account.numero_exterior, disabled: true},
				codigo_postal: {value: this.account.codigo_postal, disabled: true},
				ciudad: {value: this.account.ciudad, disabled: true},
				colonia: {value: this.account.colonia, disabled: true},
			}
		);
		this.edit = false;
	}

	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}


	patchAccountDataPerfil(param) {
		this.store.dispatch(CuentaAction.patchJugadorFiat({params: param}))
		this.edit = false;
		this.accountForm.disable();
	}

}
