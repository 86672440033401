<section class="wider" id="bonos-historial">
	<div class="section-header">
      <div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
			<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
		</div>

      <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="section-title">
				<div class="txt-snor pr-1">{{'HISTORIAL_BONOS' |translate}}</div>
				<img src="../../../../assets/images/icon-m-bonos-hist.svg" class="section-icon">
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>



   <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
   <div class="full-h px-1 of-y2 hidden-landscape">
      <div class="col col-3 full-h">
         <table class="tbl2 tbl2-lg tbl2-striped striped-alt tbl-vert tbl2-head">
            <tr>
               <th>{{'TX' |translate}}.</th>
            </tr>
            <tr>
               <th class="th-2x">{{'NOMBRE_BONO' |translate}}</th>
            </tr>
            <tr>
               <th>{{'FECHA_TERMINO' |translate}}</th>
            </tr>
            <tr>
               <th>{{'TIPO' |translate}}</th>
            </tr>
            <tr>
               <th>{{'MONTO' |translate}} / %</th>
            </tr>
            <tr>
               <th>{{'MONEDA' |translate}}</th>
            </tr>
            <tr>
               <th>{{'ESTADO' |translate}}</th>
            </tr>
            <tr>
               <th></th>
            </tr>
         </table>
      </div>
      <div class="col col-9 full-h of-x2">
         <div class="tbl2 tbl2-lg tbl2-striped tbl-vert faux-tbl-vert bg-g1" #divContenerdor *ngIf="bonosHistorialJugador.length > 0" infiniteScroll [horizontal]="true" [infiniteScrollDistance]="0.1" [infiniteScrollUpDistance]="0.1" [infiniteScrollThrottle]="50"
			  [alwaysCallback]="true" (scrolled)="onScroll()" (scrolledUp)="onScrollUp()" [scrollWindow]="false">
            <!-- EMPTY -->
            <div class="col tbl2-col-empty tac">
               <div class="td">&nbsp;</div>
               <div class="td td-2x">&nbsp;</div>
               <div class="td">&nbsp;</div>
               <div class="td">&nbsp;</div>
               <div class="td">&nbsp;</div>
               <div class="td">&nbsp;</div>
               <div class="td">&nbsp;</div>
               <div class="td">&nbsp;</div>
            </div>
            <!-- -->
            <!-- DATA -->
			 <div class="col  tac">
				 <div class="td">&nbsp;</div>
				 <div class="td td-2x">&nbsp;</div>
				 <div class="td">&nbsp;</div>
				 <div class="td">&nbsp;</div>
				 <div class="td">&nbsp;</div>
				 <div class="td">&nbsp;</div>
				 <div class="td">&nbsp;</div>
				 <div class="cb"></div>
			 </div>
            <div class="col tbl2-col-n tac"
            *ngFor="let bono of bonosHistorialJugador; let i = index;">
               <div class="td">{{bono.id}}</div>
               <div class="td td-2x">{{bono.nombre}}</div>
               <div class="td">
                  <span>{{bono.activacion.split(" ")[0]}}<br>
                  <span class="t-ssm">{{bono.activacion.split(" ")[1]}}</span></span>
               </div>
               <div class="td">{{bono.tipo_bono.nombre}}</div>
               <div class="td">
                  <span *ngIf="bono.tipo_pago=='DINERO' && bono.monto_porciento">{{bono.monto}} <span>%</span></span>
                  <span *ngIf="bono.tipo_pago=='DINERO' && !bono.monto_porciento">{{bono.monto}} <span>$</span></span>
                  <span *ngIf="bono.tipo_pago!='DINERO'">{{bono.monto}} </span>
               </div>
               <div class="td">
                  {{bono.moneda.nombre}}
               </div>
               <div class="td">{{bono.estado_bono.nombre}}</div>
               <div class="td td-icon" (click)="onShowRules(bono)">
                  <img src="../../../../assets/images/icon-info.svg">
               </div>
               <div class="cb"></div>
            </div>
            <!-- / DATA -->
         </div>

         <div class="flex-center full" *ngIf="bonosHistorialJugador.length < 1">
            <div class="bg-g1 p-2">{{'NO_HAY_OPERACIONES_LISTADAS' |translate}}.</div>
         </div>
      </div>
   </div>
   <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

</section>
