<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
<div class="bonos-container hidden-landscape">
	<div class="bonos-content full noScrlBr">
      <!-- BONO ITEM -->
      <ng-container *ngIf="allBonosActivos.length > 0">
			<div class="bono-item" *ngFor="let bono of allBonosActivos; let i = index;">
				<div class="txt-xnor fl">{{bono.nombre}}</div>
				<div class="txt-xnor fr">
					{{bono.monto}}&nbsp;
               <span class="txt-c2" *ngIf="bono.tipo_pago=='DINERO' && bono.monto_porciento">%</span>
               <span class="ff-cnd txt-c2" *ngIf="bono.tipo_pago=='DINERO' && !bono.monto_porciento">Crypto</span>
            </div>
            <div class="cb mb-03"></div>
            <div class="txt-sm txt-gc mb-15">{{bono.tipo_bono.nombre}}</div>
            <div class="bono-coin" *ngFor="let item of bono.detalles; let i = index;">
               {{ item.siglas.toUpperCase() }}
            </div>
            <div class="cb mb-15"></div>
            <div class="tar">
               <button type="button" class="btn2 btn2-7th btn2-sm btn2-sm-icon mr-1" (click)="onCancelBono(bono)">
                  <img src="../../../../assets/images/icon-b-trash.svg">
               </button>
               <button type="button" class="btn2 btn2-4th btn2-sm btn2-sm-icon" (click)="onShowRules(bono)">
                  <img src="../../../../assets/images/icon-info-lg.svg">
               </button>
            </div>
			</div>
      </ng-container>
      <!-- / BONO ITEM -->
      <!-- NO BONO -->
      <ng-container *ngIf="allBonosActivos.length < 1">
         <div class="bg-g1 p-2 tac">{{'NO_TIENES' | translate}} {{'BONOS_ACTIVOS' | translate}}.</div>
      </ng-container>
      <!-- / NO BONO -->
	</div>
</div>
<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
