import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CatalogService} from "../../games/services/catalog.service";
import {Store} from '@ngrx/store';
import {AppState} from "../../shared/redux/general.reducers";
import * as authAction from "../../authetication/redux/authentication.actions";
import {catchError, exhaustMap, map, tap} from "rxjs/operators";
import {of} from "rxjs";
import * as cuentaAction from "../../cuenta/redux/cuenta.actions";
import {CuentaService} from "../services/cuenta.service";
import * as generalAction from "../../shared/redux/general.actions";


@Injectable()
export class CuentaEffects {

	getMovimientos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(cuentaAction.getMovimientos),
			tap((action) => this.store.dispatch(generalAction.showLoader({httpRequestLoader: true,
				tipo: action.type}))),
			exhaustMap(action => {
					return this.cuentaService.getHistorialMovimientos(action.filter).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({httpRequestLoader: true,
								tipo: action.type}));
							return cuentaAction.getMovimientosCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getActJugadores$ = createEffect(() =>
		this.actions$.pipe(
			ofType(cuentaAction.getHistorialJuego),
			tap((action) => this.store.dispatch(generalAction.showLoader({httpRequestLoader: true,
				tipo: action.type}))),
			exhaustMap(action => {
					return this.cuentaService.getHistorialJuego(action.filter).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({httpRequestLoader: true,
								tipo: action.type}));
							return cuentaAction.getHistorialJuegoCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getHistorialIntercambio$ = createEffect(() =>
		this.actions$.pipe(
			ofType(cuentaAction.getHistorialIntercambio),
			tap((action) => this.store.dispatch(generalAction.showLoader({httpRequestLoader: true,
				tipo: action.type}))),
			exhaustMap(action => {
					return this.cuentaService.getCryptoIntercambio(action.filter).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({httpRequestLoader: true,
								tipo: action.type}));
							return cuentaAction.getHistorialIntercambioCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getHistorialDepositos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(cuentaAction.getHistorialTransaccionesCrypto),
			tap((action) => this.store.dispatch(generalAction.showLoader({httpRequestLoader: true,
				tipo: action.type}))),
			exhaustMap(action => {
					return this.cuentaService.getCryptoTransacciones(action.filter).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({httpRequestLoader: true,
								tipo: action.type}));
							return cuentaAction.getHistorialTransaccionesCryptoCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getConceptos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(cuentaAction.getConceptos),
			exhaustMap(action => {
					return this.cuentaService.getMovimientosConcepto(null).pipe(
						map(data => {
							return cuentaAction.getConceptosCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	patchGuardarDatosCryptoJugador$ = createEffect(() =>
		this.actions$.pipe(
			ofType(cuentaAction.patchJugadorDataCrypto),
			exhaustMap(action => {
					return this.cuentaService.patchAccountDataCrypto(action.params).pipe(
						map(data => {
							this.store.dispatch(authAction.onJugadorDataCryptoUpdate({data}));
							return cuentaAction.patchJugadorDataCryptoCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	patchResetearPasswordsJugador$ = createEffect(() =>
		this.actions$.pipe(
			ofType(cuentaAction.patchJugadorPasswords),
			exhaustMap(action => {
					return this.cuentaService.patchUpdatePassword(action.params).pipe(
						map(data => {
							return cuentaAction.patchJugadorPasswordsCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	patchActualizarDatosFiatJugador$ = createEffect(() =>
		this.actions$.pipe(
			ofType(cuentaAction.patchJugadorFiat),
			exhaustMap(action => {
					return this.cuentaService.patchAccountDataPerfil(action.params).pipe(
						map(data => {
							this.store.dispatch(authAction.onJugadorDataCryptoUpdate({data}));
							return cuentaAction.patchJugadorFiatCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	constructor(
		private actions$: Actions,
		private catalogService: CatalogService,
		private store: Store<AppState>,
		private cuentaService: CuentaService
	) {

	}
}
