import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
	selector: "[appNumericDirective]"
})
export class NumericDirective {

	private regex: RegExp = new RegExp(/[0-9]$/g);
	private specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight", "Del", "Delete"];

	constructor(
		private el: ElementRef
	) {
	}


	@HostListener("keydown", ["$event"]) onKeyDown(event) {
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}
		if (!event.key.match(this.regex)) {
			event.preventDefault();
		}
	}

}
