import {Component, OnInit} from "@angular/core";

@Component({
	selector: "app-turnmobile",
	templateUrl: "./turnmobile.component.html",
	styleUrls: ["./turnmobile.component.css"]
})
export class TurnmobileComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
