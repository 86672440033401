<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover coverB" id="filtro-cta-crypto-tx-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">
	<!-- -->

	<div class="window" id="filtro-cta-crypto-tx">
		<form [formGroup]="searchForm" (ngSubmit)="onFilter($event)">

			<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
			<div class="hidden-landscape">
				<div class="btn-window-close" (click)="onCloseW()">
					<div class="btn-window-close-bar"></div>
				</div>
				<p class="w-title-cover txt-ssm txt-w tac">{{ 'FILTRAR_RESULTADOS' | translate }}</p>
				<div class="px-1">
					<div class="bg-g1 p-15 pb-2">
						<div class="col col-6 pr-07">
							<p class="txt-gc mb-05">{{ 'FECHA_DESDE' | translate }}:</p>
							<div class="pos-rel">
								<div class="date">
									<input class="inp2 inp2-nor full-w date-txt" placeholder="yyyy-mm-dd" type="text"
										[value]="fechaInicio" readonly>
									<input (change)="setDate($event, 'fecha_inicio')" class="inp2-nor full-w date-inp"
										type="date" formControlName="fecha_inicio">
								</div>
							</div>
						</div>
						<div class="col col-6 pl-07">
							<p class="txt-gc mb-05">{{ 'FECHA_HASTA' | translate }}:</p>
							<div class="pos-rel">
								<div class="date">
									<input class="inp2 inp2-nor full-w date-txt" placeholder="yyyy-mm-dd" type="text"
										[value]="fechaFin" readonly>
									<input (change)="setDate($event, 'fecha_fin')" class="inp2-nor full-w date-inp"
										type="date" formControlName="fecha_fin">
								</div>
							</div>
						</div>
						<div class="cb mb-2"></div>
						<div class="col col-6 pr-07">
							<p class="txt-gc mb-05">Moneda:</p>
							<div class="sel2">
								<select class="sel2 sel2-nor full-w" formControlName="moneda_id">
									<option [value]="null">{{ 'TODAS' | translate }}</option>
									<option [value]="curr.id" *ngFor="let curr of cryptoCurrency">{{curr.siglas}}
									</option>
								</select>
							</div>
						</div>
						<div class="cb"></div>
					</div>

				</div>
				<div class="btn2-actions px-2 pb-2">
					<div class="col col-6 pr-07">
						<button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCancel($event)">{{'CANCELAR'
							| translate}}</button>
					</div>
					<div class="col col-6 pl-07">
						<button type="submit" class="btn2 btn2-lg btn2-1st full-w">{{ 'FILTAR' | translate }}</button>
					</div>
					<div class="cb"></div>
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

		</form>
	</div>

</div>