<section class="wider tabbed" id="cuenta">
   <div class="section-header">
      <div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
         <img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
      </div>

      <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
      <div class="hidden-landscape">
         <div class="section-title">
            <div class="txt-snor pr-1">{{ 'MIS_DATOS' | translate }}</div>
            <img src="../../../../assets/images/icon-m-id.svg" class="section-icon">
         </div>
      </div>
      <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
   </div>

   <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
   <div class="full-h hidden-landscape">

      <div class="full-h datos-container px-1 noScrlBr" id="datos-container">

         <div class="accordion2 mb-1" [class.active]="accordion=='datos'">
            <div class="accordion2-header" (click)="onToggleAccordion($event,'datos')" id="acc-datos">
               {{ 'DATOS_DE_USUARIO' | translate }}
               <div class="accordion2-indicator"><img src="../../../../assets/images/icon-indicator.svg"></div>
            </div>
            <div class="accordion2-content">
               <app-tab-datos-crypto *ngIf="accordion=='datos'"></app-tab-datos-crypto>
            </div>
         </div>
         <div class="accordion2 mb-1" [class.active]="accordion=='password'">
            <div class="accordion2-header" (click)="onToggleAccordion($event,'password')" id="acc-password">
               {{ 'MI_CONTRASENA' | translate }}
               <div class="accordion2-indicator"><img src="../../../../assets/images/icon-indicator.svg"></div>
            </div>
            <div class="accordion2-content">
               <app-tab-datos-password *ngIf="accordion=='password'"></app-tab-datos-password>
            </div>
         </div>

         <!-- DESHABILITADO HASTA QUE SE USEN DATOS FIAT-->
         <ng-container *ngIf="userFiat===true">
            <div class="accordion2 mb-1" [class.active]="accordion=='fiat'">
               <div class="accordion2-header" (click)="onToggleAccordion($event,'fiat')" id="acc-fiat">
                  {{ 'MIS_DATOS_FIAT' | translate }}
                  <div class="accordion2-indicator"><img src="../../../../assets/images/icon-indicator.svg"></div>
               </div>
               <div class="accordion2-content">
                  <app-tab-datos-fiat *ngIf="accordion=='fiat'"></app-tab-datos-fiat>
               </div>
            </div>
            <div class="accordion2 mb-1" [class.active]="accordion=='contacto'">
               <div class="accordion2-header" (click)="onToggleAccordion($event,'contacto')" id="acc-contacto">
                  {{ 'MIS_DATOS_DE_CONTACTO' | translate }}
                  <div class="accordion2-indicator"><img src="../../../../assets/images/icon-indicator.svg"></div>
               </div>
               <div class="accordion2-content">
                  <app-tab-datos-contacto *ngIf="accordion=='contacto'"></app-tab-datos-contacto>
               </div>
            </div>
            <div class="accordion2 mb-1" [class.active]="accordion=='direccion'">
               <div class="accordion2-header" (click)="onToggleAccordion($event,'direccion')" id="acc-direccion">
                  {{ 'DIRECCION' | translate }}
                  <div class="accordion2-indicator"><img src="../../../../assets/images/icon-indicator.svg"></div>
               </div>
               <div class="accordion2-content">
                  <app-tab-datos-direccion *ngIf="accordion=='direccion'"></app-tab-datos-direccion>
               </div>
            </div>
         </ng-container>
         <!-- / DESHABILITADO HASTA QUE SE USEN DATOS FIAT-->

      </div>

      <!--
      <ul id="menu-datos" class="">
         <li [class.active]="tab2==='datos'" (click)="onTab2('datos')">Datos</li>
         <li [class.active]="tab2==='movimientos'" (click)="onTab2('movimientos')">Movimientos</li>
         <li [class.active]="tab2==='juego'" (click)="onTab2('juego')">Juego</li>
         <li [class.active]="tab2==='crypto'" (click)="onTab2('crypto')">Crypto</li>
      </ul>
      -->
   </div>
   <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

</section>