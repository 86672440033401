import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import * as authAction from "../../authetication/redux/authentication.actions";
import * as generaAction from "../../shared/redux/general.actions";
import * as walletAction from "./cajero.actions";
import {CatalogService} from "../../games/services/catalog.service";
import {CajeroService} from "../services/cajero.service";
import {Store} from '@ngrx/store';
import {AppState, DataWithExpireTime} from 'src/app/shared/redux/general.reducers';
import {MonedaModel} from "../../shared/models/wallet-currency.model";
import {HomeService} from "../../home/services/home.service";
import {GamesService} from "../../games/services/games.service";
import * as generalAction from "../../shared/redux/general.actions";


@Injectable()
export class CajeroEffects {

	walletsAuth$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.obtenerUsuarioLogueadoCompletado),
			exhaustMap(data =>
				of(walletAction.walletsAuthCompletado(data))
			)
		)
	);

	cryptoMonedas$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.getCryptoMonedas),
			exhaustMap(action => {
				if (this.cryptoMonedas.expire == 0 || new Date().getTime() > this.cryptoMonedas.expire) {
					return this.catalogService.getCryptoCurrency(null).pipe(
						map(data => walletAction.getCryptoMonedasCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				} else {
					return of(generaAction.localDataLoad({dataName: 'CryptoMonedas'}))
				}
			})
		)
	);

	cryptoHashDeposito$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.getHashDeposito),
			exhaustMap(action => {
					return this.cajeroService.createCurrencyHash({moneda_id: action.moneda_id}).pipe(
						map(data => walletAction.getHashDepositoCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getWalletsGeneral$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.getWallets),
			exhaustMap(action => {
					return this.cajeroService.getWalletGeneral(null).pipe(
						map(data => walletAction.getWalletsCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getExchangerTableHome$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.getExchangerTableHome),
			exhaustMap(action => {
					return this.homeService.getMonedasHomeExchanger(null).pipe(
						map(data => walletAction.getExchangerTableHomeCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	cambioMonedaActiva$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.cambioMonedaActiva),
			exhaustMap(action => {
					return this.gameService.postChangeWallet({moneda_id: action.moneda_id}).pipe(
						map(data => walletAction.cambioMonedaActivaCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getUtilidadesPorMoneda$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.getUtilidadesPorMoneda),
			exhaustMap(action => {
					return this.cajeroService.getCurrencyUtilities({id: action.id}).pipe(
						map(data => walletAction.getUtilidadesPorMonedaCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getAllCryptoCurreniesInMonedaBase$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.getAllCryptoCurrenciesInMonedaBase),
			exhaustMap(action => {
					return this.cajeroService.getAllCurrencyBase(null).pipe(
						map(data => walletAction.getAllCryptoCurrenciesInMonedaBaseCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	postExchanger$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.postExchanger),
			tap((action) => this.store.dispatch(generalAction.showLoader({ httpRequestLoader: true,
				tipo: action.type }))),
			exhaustMap(action => {
					return this.cajeroService.postCurrencyExchange(action.param).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({ httpRequestLoader: true ,
								tipo: action.type}));
							return walletAction.postExchangerCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	addNewWallet$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.addNewWallet),
			tap((action) => this.store.dispatch(generalAction.showLoader({ httpRequestLoader: true,
				tipo: action.type }))),
			exhaustMap(action => {
					return this.cajeroService.postAddNewCurrency(action.param).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({ httpRequestLoader: true,
								tipo: action.type }));
							return walletAction.addNewWalletCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	deleteWallet$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.deleteWallet),
			tap((action) => this.store.dispatch(generalAction.showLoader({ httpRequestLoader: true,
				tipo: action.type }))),
			exhaustMap(action => {
					return this.cajeroService.deleteWalletCurrency(action.param).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({ httpRequestLoader: true ,
								tipo: action.type}));
							return walletAction.deleteWalletCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);
	addSolicitudRetiro$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.addSolicitudRetiro),
			tap((action) => this.store.dispatch(generalAction.showLoader({ httpRequestLoader: true,
				tipo: action.type }))),
			exhaustMap(action => {
					return this.cajeroService.retirarCurrencyWallet(action.param).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({ httpRequestLoader: true,
								tipo: action.type }));
							return walletAction.addSolicitudRetiroCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getSolicitudesRetiro$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.getSolicitudesRetiro),
			exhaustMap(action => {
					return this.cajeroService.getSolicitudRetiros(action.filter).pipe(
						map(data => {
							return walletAction.getSolicitudesRetiroCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);
	cancelarSolicitudesRetiro$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.cancelarSolicitudRetiro),
			tap((action) => this.store.dispatch(generalAction.showLoader({ httpRequestLoader: true,
				tipo: action.type }))),
			exhaustMap(action => {
					return this.cajeroService.patchCancelRetiro(action.param).pipe(
						map(data => {
							this.store.dispatch(generalAction.hideLoader({ httpRequestLoader: true,
								tipo: action.type }));
							return walletAction.cancelarSolicitudRetiroCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	getDocumentosJugador$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.getDocumentoJugador),
			exhaustMap(action => {
					return this.cajeroService.getDocumentosJugador(null).pipe(
						map(data => {
							return walletAction.getDocumentoJugadorCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	postDocumentosJugador$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.postDocumentoJugador),
			exhaustMap(action => {
					return this.cajeroService.postDocumentosJugador(action.params).pipe(
						map(data => {
							this.store.dispatch(walletAction.getDocumentoJugador());
							return walletAction.postDocumentoJugadorCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	deleteDocumentosJugador$ = createEffect(() =>
		this.actions$.pipe(
			ofType(walletAction.deleteDocumentoJugador),
			exhaustMap(action => {
					return this.cajeroService.deleteDocumentosJugador(action.param).pipe(
						map(data => {
							this.store.dispatch(walletAction.getDocumentoJugador());
							return walletAction.deleteDocumentoJugadorCompletado({data})
						}),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);


	private cryptoMonedas: DataWithExpireTime<MonedaModel> = {value: [], expire: 0};

	constructor(
		private actions$: Actions,
		private catalogService: CatalogService,
		private cajeroService: CajeroService,
		private store: Store<AppState>,
		private homeService: HomeService,
		private gameService: GamesService,
	) {
		this.store.select((state) => state).subscribe(state => {
			this.cryptoMonedas = state.cajero.cryptoMonedas;
		});
	}
}
