import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {JugadorModel} from "../../../../shared/models/user.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/redux/general.reducers"
import * as CuentaAction from "../../../redux/cuenta.actions";

@Component({
	selector: "app-tab-datos-fiat",
	templateUrl: "./tab-datos-fiat.component.html",
	styleUrls: ["./tab-datos-fiat.component.css"]
})
export class TabDatosFiatComponent implements OnInit, OnDestroy {

	account: JugadorModel;

	accountForm: FormGroup;

	edit: boolean;

	currentYear: number;
	selectedMonth: number;
	selectedYear: number;

	lastValidYear;
	lastValidMonth;

	yearArrayComplete: number[]
	subscription = new Subscription();

	constructor(
		private formBuilder: FormBuilder,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.edit = false;
		this.subscription.add(this.store.subscribe((state) => {
			this.account = state.auth.player;
		}))

		const currentDate = new Date();
		this.yearArrayComplete = this.arrayOne(80);
		this.currentYear = currentDate.getFullYear();
		this.lastValidYear = this.currentYear - 18;
		this.lastValidMonth = currentDate.getMonth() + 1;

		this.initForm();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initForm() {
		const gentoo = this.account.fecha_nacimiento;
		const day = gentoo ? gentoo.split("-")[2] : null;
		const month = gentoo ? gentoo.split("-")[1] : null;
		const year = gentoo ? gentoo.split("-")[0] : null;
		this.accountForm = this.formBuilder.group(
			{
				name: new FormControl(this.account.nombre, Validators.required),
				lastNamePaternal: new FormControl(this.account.apellido_paterno, Validators.required),
				lastNameMaternal: new FormControl(this.account.apellido_materno, Validators.required),
				day: new FormControl(day, Validators.required),
				month: new FormControl(month, Validators.required),
				year: new FormControl(year, Validators.required),
				gender: new FormControl(this.account.genero, Validators.required)
			}
		);
		this.accountForm.disable();
	}

	inputTouched(formName: string) {
		return this.accountForm.controls[formName].invalid && (this.accountForm.controls[formName].dirty || this.accountForm.controls[formName].touched);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON EDIT ()
	onEdit() {
		this.edit = true;
		this.accountForm.enable();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SAVE ()
	disableRegister() {
		const controlOne = this.accountForm.controls.name;
		const controlTwo = this.accountForm.controls.lastNamePaternal;
		const controlThree = this.accountForm.controls.lastNameMaternal;
		const controlFour = this.accountForm.controls.day;
		const controlFive = this.accountForm.controls.month;
		const controlSix = this.accountForm.controls.year;
		const controlSeven = this.accountForm.controls.gender;
		if (!controlOne.valid || !controlTwo.valid || !controlThree.valid || !controlFour.valid || !controlFive.valid || !controlSix.valid || !controlSeven.valid) {
			return true;
		}
		return controlOne.value === null || controlTwo.value === null || controlThree.value === null || controlFour.value === null || controlFive.value === null
			|| controlSix.value === null || controlSeven.value === null;
	}

	onSave() {
		if (this.accountForm.valid) {
			const accountModel = this.accountForm.value;
			const param = {
				nombre: accountModel.name,
				apellido_paterno: accountModel.lastNamePaternal,
				apellido_materno: accountModel.lastNameMaternal,
				fecha_nacimiento: accountModel.year + "-" + accountModel.month + "-" + accountModel.day,
				genero: accountModel.gender,
				paso: 1
			};
			this.patchAccountDataPerfil(param);
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DISCARD ()
	onDiscard() {
		const gentoo = this.account.fecha_nacimiento;
		const day = gentoo ? gentoo.split("-")[2] : null;
		const month = gentoo ? gentoo.split("-")[1] : null;
		const year = gentoo ? gentoo.split("-")[0] : null;
		this.accountForm.reset(
			{
				name: {value: this.account.nombre, disabled: true},
				lastNamePaternal: {value: this.account.apellido_paterno, disabled: true},
				lastNameMaternal: {value: this.account.apellido_materno, disabled: true},
				day: {value: day, disabled: true},
				month: {value: month, disabled: true},
				year: {value: year, disabled: true},
				gender: {value: this.account.genero, disabled: true}
			}
		);
		this.edit = false;
	}

	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	isLeapYear() {
		if (!this.selectedYear) {
			return false;
		}
		if (this.selectedYear % 4 !== 0) {
			return false;
		} else if (this.selectedYear % 100 !== 0) {
			return false;
		} else {
			return this.selectedYear % 400 === 0;
		}
	}

	yearArray() {
		if (this.selectedYear === this.lastValidYear) {
			return this.arrayOne(this.lastValidMonth);
		} else {
			return this.arrayOne(12);
		}
	}

	monthArray() {
		const bigMonth = [1, 3, 5, 7, 8, 10, 12];
		const smallMonth = [4, 6, 9, 11];
		const smallestMonth = [2];
		const date = new Date();
		const currentMonth = date.getMonth() + 1;
		if (!this.selectedMonth || bigMonth.includes(this.selectedMonth)) {
			if (this.lastValidYear === this.selectedYear && currentMonth === this.selectedMonth) {
				return Array(date.getDay());
			}
			return Array(31);
		}
		if (smallMonth.includes(this.selectedMonth)) {
			if (this.lastValidYear === this.selectedYear && currentMonth === this.selectedMonth) {
				return Array(date.getDay());
			}
			return Array(30);
		}
		if (smallestMonth.includes(this.selectedMonth)) {
			if (this.lastValidYear === this.selectedYear && currentMonth === this.selectedMonth) {
				return Array(date.getDay());
			}
			if (this.isLeapYear()) {
				return Array(29);
			} else {
				return Array(28);
			}
		}
	}

	setDays(event) {
		this.selectedMonth = Number(event.target.value);
		const controlDay = this.accountForm.controls.day;
		if (Number(controlDay.value) > this.monthArray().length) {
			this.accountForm.controls.day.setValue(this.monthArray().length);
		}
	}

	setYear(event) {
		this.selectedYear = Number(event.target.value);
		if (this.selectedYear === this.lastValidYear) {
			const date = new Date();
			if (date.getMonth() + 1 <= this.selectedMonth) {
				this.accountForm.controls.month.setValue(null);
				this.accountForm.controls.day.setValue(null);
			}
		}
	}

	patchAccountDataPerfil(param) {
		this.store.dispatch(CuentaAction.patchJugadorFiat({params: param}))
		this.edit = false;
		this.accountForm.disable();
	}

}
