<div class="w-cover" id="wcHelp" (click)="onCloseW($event)">

	<div class="window pt-1" id="ayuda">
		<div class="w-title mb-4">{{'AYUDA' |translate}}</div>

		<div class="px-5 pt-2">
			<div class="flex-x px-7">

				<div class="tac" [hidden]="!isLogIn()">
					<div class="btn btn-xl btn-rnd btn-4th mb-1 ilb pos-rel" (click)="onOpenTickets($event)">
						<img class="m-icon" src="../../../../assets/images/icon-m-ticket.svg">
					</div>
					<div class="cb">{{'TICKETS' |translate}}</div>
				</div>

				<div class="tac" [hidden]="!isLogIn()">
					<div class="btn btn-xl btn-rnd btn-4th mb-1 ilb pos-rel" (click)="onCallRequest($event)">
						<img class="m-icon" src="../../../../assets/images/icon-m-call.svg">
					</div>
					<div class="cb">{{'SOLICITAR_LLAMADA' |translate}}</div>
				</div>

				<div class="tac">
					<div class="btn btn-xl btn-rnd btn-4th mb-1 ilb pos-rel" (click)="goChat($event)">
						<img class="m-icon" src="../../../../assets/images/icon-m-chat.svg">
					</div>
					<div class="cb">{{'CHAT' |translate}}</div>
				</div>

			</div>
		</div>

		<button (click)="onCloseW($event)" class="btn btn-w-close" type="button">
			<img src="assets/images/icon-b-close.svg">
		</button>

	</div>

</div>
