import { Routes } from "@angular/router";
import { AfiliadosComponent } from "./components/afiliados/afiliados.component";
import { CampaignsComponent } from "./components/campaigns/campaigns.component";
import { AfilBalancesRetirosComponent } from "./components/afil-balances-retiros/afil-balances-retiros.component";
import { ComisionesComponent } from "./components/comisiones/comisiones.component";
import { TraficoComponent } from "./components/trafico/trafico.component";
import { HomeAfiliadosVisitorComponent } from "../home/components/home-afiliados-visitor/home-afiliados-visitor.component";
import { HomeAfiliadosComponent } from "../home/components/home-afiliados/home-afiliados.component";
import { AfiliadoGuard } from "../home/guards/afiliado.guard";
import { VerifiedGuard } from "../home/guards/verified.guard";

export const AfiliadosRoutes: Routes = [
	{
		path: "", component: AfiliadosComponent,
		children: [
			{ path: "", redirectTo: "campannas", pathMatch: "full" },
			{ path: "campannas", component: CampaignsComponent, canActivate: [VerifiedGuard] },
			{ path: "trafico", component: TraficoComponent, canActivate: [VerifiedGuard] },
			{ path: "comisiones", component: ComisionesComponent, canActivate: [VerifiedGuard] },
			{ path: "balances_retiros", component: AfilBalancesRetirosComponent, canActivate: [VerifiedGuard] }
		],
		canActivate: [AfiliadoGuard]
	},
	{
		path: "visitante",
		component: HomeAfiliadosVisitorComponent
	},
	{
		path: "inicio",
		component: HomeAfiliadosComponent,
		canActivate: [AfiliadoGuard]
	},
];
