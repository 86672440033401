<div class="w-cover">

	<div class="window" id="bono-activate-0">
		<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="btn-window-close" (click)="onCloseW($event)">
				<div class="btn-window-close-bar"></div>
			</div>
         <p class="w-title-cover txt-sm txt-w tac mb-1">{{'ACTIVAR_BONO_GRATIS' |translate}}</p>

         <div class="px-15">
            <div class="faux-inp2 mb-3">{{bono.nombre}}</div>
            <p class="txt-gc mb-05">{{'ACTIVARA_BALANCE_EN_MONEDA'|translate}}:</p>
            <div class="faux-inp2 tac mb-2">
               <img class="fi-coin" src="../../../../assets/images/crypto/{{balance.moneda.siglas.toLowerCase()}}.svg" (error)="changeImg($event, balance.moneda.imagen)">
						<span class="txt-c2"> {{balance.moneda.siglas.toUpperCase()}} - </span>
						{{balance.moneda.nombre}}
            </div>
            <p class="txt-ssm txt-gc taj mb-2">{{'BONO_AFECTARA_BALANCE_MONEDA_SELECCIONADA_NO_OTROS'|translate}}.</p>
         </div>

         <div class="p-2">
            <div class="col col-6 pr-07">
               <button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCancel($event)">{{'CANCELAR'|translate}}</button>
            </div>
            <div class="col col-6 pl-07">
               <button type="button" class="btn2 btn2-lg btn2-1st full-w" (click)="onActivate($event)">{{'CONTINUAR'|translate}}</button>
            </div>
            <div class="cb"></div>
         </div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->




	</div>

</div>
