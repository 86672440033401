import { Component, OnDestroy, OnInit } from "@angular/core";
import { FilterModel } from "../../../shared/models/filter.model";
import { TrafficModel } from "../../../shared/models/traffic.model";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import * as AfiliadosActions from '../../../afiliados/redux/afiliados.actions';
import { Subscription } from "rxjs";
import { GetNombreMesesEspannol } from "../../../shared/utils/general.util";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-trafico",
	templateUrl: "./trafico.component.html",
	styleUrls: ["./trafico.component.css"]
})
export class TraficoComponent implements OnInit, OnDestroy {

	allTraffic: TrafficModel[];

	filter: FilterModel;
	subscriptions: Subscription = new Subscription();

	constructor(
		private store: Store<AppState>,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.filter = new FilterModel(1000);
		this.allTraffic = [];

		this.subscriptions.add(this.store.select((state) => state).subscribe(state => {
			this.allTraffic = state.afiliado.trafico.value;
			this.filter = state.afiliado.trafico.filter;
		}));

		this.store.dispatch(AfiliadosActions.afiliadosGetTrafico({ 'filter': this.filter }));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	filtradoPorFechas() {
		let fechaFiltroFlag = false;

		if (this.filter.filter && this.filter.filter.length > 0) {
			for (let i = 0; i < this.filter.filter.length; i++) {
				if (this.filter.filter[i].atribute == 'fecha_registro') {
					fechaFiltroFlag = true;
				}
			}
		}

		return fechaFiltroFlag;
	}

	getPeriodoActual() {
		if (this.filtradoPorFechas()) {
			if (this.filter.filter.length > 1) {
				let fechaInicial = this.filter.filter[0].value;
				let fechafinal = this.filter.filter[1].value;

				return `${fechaInicial} - ${fechafinal}`;
			} else /* solo entra a este else si length es igual a 1 */ {
				let fecha = this.filter.filter[0].value;
				let operator = this.filter.filter[0].operator;
			
				
				let operatorDescrip = this.translate.instant('ANTES_DE');
				if(operator == '>='){
					operatorDescrip = this.translate.instant('DESPUES_DE');;
				}

				return `${operatorDescrip} - ${fecha}`;
			}

		} else {
			let todayDate = new Date();
			let mesActual = todayDate.getMonth() + 1;
			let mesActualString = GetNombreMesesEspannol(mesActual < 10 ? `0${mesActual}` : `${mesActual}`);

			let annoActual = todayDate.getFullYear();

			if (this.filter.filter && this.filter.filter.length > 0) {
				for (let i = 0; i < this.filter.filter.length; i++) {

					if (this.filter.filter[i].atribute == 'mes') {
						mesActualString = GetNombreMesesEspannol(this.filter.filter[i].value);
					}

					if (this.filter.filter[i].atribute == 'anno') {
						annoActual = this.filter.filter[i].value;
					}
				}
			}

			return `${mesActualString}, ${annoActual}`;
		}
	}

}
