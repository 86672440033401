import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import * as GeneralAction from "../../../shared/redux/general.actions";
import { Store } from "@ngrx/store";
import { AppState } from "../../../shared/redux/general.reducers";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";
import * as BonoAction from "../../../bonos/redux/bonos.actions";
import { Actions, ofType } from "@ngrx/effects";
import Swal from "sweetalert2";
import { Subscription } from "rxjs";
import { NotificationService } from "src/app/shared/services/notification.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-bonos",
	templateUrl: "./bonos.component.html",
	styleUrls: ["./bonos.component.css"]
})
export class BonosComponent implements OnInit, AfterViewInit, OnDestroy {

	selectedTab: number;
	subscription = new Subscription();

	afiliadosMode: boolean = false;

	constructor(
		private store: Store<AppState>,
		private actions$: Actions,
		private notifications: NotificationService,
		private activatedRoute: ActivatedRoute,
		private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
		});
		this.store.dispatch(GeneralAction.setAfiliadosMode({ afiliadosMode: false }));
		this.initData();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	ngAfterViewInit() {
		adjustSize();
	}

	initData() {
		const queryParam = this.activatedRoute.snapshot.queryParams.activos;
		this.selectedTab = queryParam ? 1 : 0;
		this.getAllUserBonos();
		this.getWalletGeneral();
		this.getAllBonosActivos();
		this.getCryptoCurrency();
		this.subscription.add(this.actions$
			.pipe(ofType(BonoAction.activarBonosCompletado))
			.subscribe(response => {
				const resp = response;
				Swal.fire(this.notifications.BuildSuccess(resp.data.message, this.translateService.instant("BONO_ACTIVADO"))).then();
			}));

		this.subscription.add(this.actions$
			.pipe(ofType(BonoAction.desactivarBonosCompletado))
			.subscribe(response => {
				const resp = response;
				Swal.fire(this.notifications.BuildSuccess(resp.data.message, this.translateService.instant("BONO_CANCELADO"))).then();
			}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET TAB ()
	onSetTab(tab) {
		this.selectedTab = tab;
	}

	// New API Calls
	getAllUserBonos() {
		this.store.dispatch(BonoAction.getBonosAptos());
	}

	getCryptoCurrency() {
		this.store.dispatch(CajeroAction.getCryptoMonedas())
	}

	getAllBonosActivos() {
		this.store.dispatch(BonoAction.getBonosActivos());
	}

	getWalletGeneral() {
		this.store.dispatch(CajeroAction.getWallets())
	}
}
