import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { apiRoutes } from "../../api-routing";
import { FilterModel } from "../../shared/models/filter.model";

@Injectable({
	providedIn: "root"
})
export class AfiliadosService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	getUserLogged(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_obtener_usuario_logueado);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getAllCampaigns(filter: FilterModel): Observable<any> {
		const url = this.buildURL(this.formatFilters(filter, apiRoutes.r_frontend_campannas_filter_all));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postNewCampaign(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_campannas_post);
		const response = this.httpClient.post(url, param);
		return response.pipe(map((data: any) => data));
	}

	getAllTraffic(filter: FilterModel): Observable<any> {
		const url = this.buildURL(this.formatFilters(filter, apiRoutes.r_frontend_campannas_filter_trafico));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getAllComisionesByPeriod(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_campannas_filter_reporte_periodo_actual);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getAllComisionesByBatch(filter: FilterModel): Observable<any> {
		const url = this.buildURL(this.formatFilters(filter, apiRoutes.r_frontend_campannas_filter_reporte_batch));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getAllBalancesAfiliados(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_afiliados_balances);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postRetiroAgregarBalance(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_solicitud_retiros_post);
		const response = this.httpClient.post(url, param.data);
		return response.pipe(map((data: any) => data));
	}

	postNewAfiliadoUser(usuario: any): Observable<any> {
		const paramRequestBody = {
			usuario: usuario
		};

		const url = this.buildURL(apiRoutes.r_frontend_afiliados_post);
		const response = this.httpClient.post(url, paramRequestBody);
		return response.pipe(map((data: any) => data));
	}

	private formatFilters(flt: FilterModel, url) {
		url += `?paginate=` + flt.paginate + `&ordenado_por=created_at&direction=DESC&page=` + flt.page;
		let filter = ``;
		if (flt.filter.filter.length !== 0) {
			filter += `&filter=[`;
			let first = true;
			flt.filter.forEach(value => {
				if (first) {
					filter += `["${value.atribute}"`;
				} else {
					filter += `,["${value.atribute}"`;
				}
				filter += `,"${value.operator}"`;
				if (value.value === true) {
					filter += `,true]`;
				} else if (value.value === false) {
					filter += `,false]`;
				} else {
					filter += encodeURI(`,"${value.value}"]`);
				}
				first = false;
			});
			filter += `]`;

		}
		return url + filter;
	}


}
