export const apiRoutes = {

	// Auth
	r_frontend_login: "/frontend/auth/login",
	r_frontend_logout: "/frontend/auth/logout",
	r_frontend_refresh: "/frontend/auth/login/refresh",
	r_frontend_activacion: "/frontend/auth/login/activacion",
	r_frontend_login_facebook: "/frontend/auth/login/facebook",
	r_frontend_login_reenviar_codigo_confirmacion: "/frontend/auth/login/reenviar_codigo_confirmacion",
	r_frontend_obtener_usuario_logueado: "/frontend/auth/obtener_usuario_logueado",
	r_frontend_obtener_usuario_logueado_primera_vez: "/frontend/auth/obtener_usuario_logueado_primera_vez",
	r_frontend_jugador_registro_crypto: "/frontend/jugador/registro_crypto",

	// Idiomas
	r_frontend_idiomas: "/frontend/idiomas",

	// Jugadores
	r_frontend_jugadores_balance_get: "/frontend/jugadores/balance",
	r_frontend_jugadores_detail_get: "/frontend/jugadores/detail",
	r_frontend_jugadores_jugador_tiko_get: "/frontend/jugadores/jugador_tiko",
	r_frontend_jugadores_actualizacion_perfil_patch: "/frontend/jugadores/actualizacion_perfil",
	r_frontend_jugadores_actualizacion_perfil_crypto_patch: "/frontend/jugadores/actualizacion_perfil_crypto",
	r_frontend_jugadores_actualizacion_password_patch: "/frontend/jugadores/actualizacion_password",
	r_frontend_jugadores_top_ganadores_get: "/frontend/jugadores/top_ganadores",
	r_frontend_jugadores_wallets_get: "/frontend/jugadores/wallets",
	r_frontend_jugadores_cambio_moneda_activa_patch: "/frontend/jugadores/cambio_moneda_activa",
	r_frontend_jugadores_cambio_favoritos_post: "/frontend/jugadores/cambio_favoritos",
	r_frontend_jugadores_juegos_favoritos: "/frontend/jugadores/juegos",
	r_frontend_jugadores_create_hash: "/frontend/jugadores/create_hash",
	r_frontend_jugadores_get_last_games: "/frontend/session_casinos/juegos/ultimos",
	r_frontend_jugadores_cambio_idioma: "/frontend/jugadores/cambio_idioma",

	// Juegos
	r_frontend_juegos_iniciar_juego_demo_get: "/frontend/juegos/{id}/iniciar_juego_demo_belatra",
	r_frontend_juegos_iniciar_juego_get: "/frontend/juegos/iniciar_juego/{id}",
	r_frontend_juegos_filter_all_get: "/frontend/juegos/filter/all",
	r_frontend_juegos_all_get: "/frontend/juegos/all",
	r_frontend_juegos_clasif_visual_juego_get: "/frontend/juegos/clasif_visual_juego",
	r_frontend_juegos_show_get: "/frontend/juegos/{id}",
	r_frontend_juegos_clasif_visual_juego_show_get: "/frontend/juegos/clasificacion_visual_juego/{id}",
	r_frontend_juegos_all_juegos_get: "/frontend/juegos/activos/all",
	r_frontend_juegos_all_juegos_imagenes_get: "/frontend/juegos/activos/all/imagenes",

	// Categorias de juego
	r_frontend_categoria_juegos_get: "/frontend/categoria_juegos",
	r_frontend_categoria_juegos_show_get: "/frontend/categoria_juegos/{id}",
	r_frontend_categoria_juegos_filter_all_get: "/frontend/categoria_juegos/filter/all",

	// Clasificaciones visuales de juegos

	r_frontend_clasificacion_visual_juegos_get: "/frontend/clasificacion_visual_juegos",
	r_frontend_clasificacion_visual_juegos_show_get: "/frontend/clasificacion_visual_juegos/{id}",
	r_frontend_clasificacion_visual_juegos_filter_all_get: "/frontend/clasificacion_visual_juegos/filter/all",
	r_frontend_clasificacion_visual_juegos_juegos_get: "/frontend/clasificacion_visual_juegos/{id}/juegos",
	r_frontend_clasificacion_visual_juegos_juegos_sin_favoritos_get: "/frontend/clasificacion_visual_juegos/{id}/juegos/sin_favoritos",

	// Monedas
	r_frontend_monedas_get: "/frontend/monedas",
	r_frontend_monedas_show_get: "/frontend/monedas/{id}",
	r_frontend_monedas_utilidades_get: "/frontend/monedas/{id}/utilidades",
	r_frontend_monedas_utilidades_btc_get: "/frontend/monedas/utilidades/btc",
	r_frontend_monedas_cambio_moneda_en_moneda_base_get: "/frontend/monedas/get_cambio_moneda/moneda_base",
	r_frontend_monedas_get_all_crypto_moneda_base_get: "/frontend/monedas/get_all_crypto/moneda_base",

	// Paises
	r_frontend_paises_get: "/frontend/paises",
	r_frontend_paises_show_get: "/frontend/paises/{id}",
	r_frontend_paises_estados_get: "/frontend/paises/{id}/estados",

	// Movimientos
	r_frontend_movimientos_get: "/frontend/movimientos",
	r_frontend_movimientos_filter_all: "/frontend/movimientos/filter/all",
	r_frontend_movimientos_filter_session: "/frontend/movimientos/filter/session",
	r_frontend_movimientos_depositos_all: "/frontend/movimientos/depositos/all",

	// ActJugador
	r_frontend_act_jugadores_get: "/frontend/act_jugadores",
	r_frontend_act_jugadores_filter_all: "/frontend/act_jugadores/filter/all",
	r_frontend_act_jugadores_filter_spin: "/frontend/act_jugadores/filter/spin",
	r_frontend_act_jugadores_robot: "/frontend/act_jugadores/robot",

	// Jugador monedas
	r_frontend_jugador_monedas_get: "/frontend/jugador_monedas",
	r_frontend_jugador_monedas_post: "/frontend/jugador_monedas",
	r_frontend_jugador_monedas_delete: "/frontend/jugador_monedas/{id}",

	//
	r_frontend_conceptos_get: "/frontend/conceptos",

	// Paises y estados
	r_general_paises_get: "/frontend/paises",
	r_general_paises_estados_get: "/frontend/paises/{id}/estados",

	// Solicitudes de exchanger
	r_frontend_solicitud_exchangers_get: "/frontend/solicitud_exchangers",
	r_frontend_solicitud_exchangers_filter_all: "/frontend/solicitud_exchangers/filter/all",
	r_frontend_solicitud_exchangers_post: "/frontend/solicitud_exchangers",
	r_frontend_solicitud_exchangers_estados_all: "/frontend/solicitud_exchangers/estados/all",

	// Afiliados
	r_frontend_afiliados_get: "/frontend/afiliados",
	r_frontend_afiliados_balances: "/frontend/afiliados/balances",
	r_frontend_afiliados_filter_all: "/frontend/afiliados/filter/all",
	r_frontend_afiliados_post: "/frontend/afiliados",

	// Campannas
	r_frontend_campannas_get: "/frontend/campannas",
	r_frontend_campannas_filter_all: "/frontend/campannas/filter/all",
	r_frontend_campannas_filter_trafico: "/frontend/campannas/filter/trafico",
	r_frontend_campannas_post: "/frontend/campannas",
	r_frontend_campannas_filter_reporte_periodo_actual: "/frontend/campannas/filter/reporte_periodo_actual_agrupado_por_campanna",
	r_frontend_campannas_filter_reporte_batch: "/frontend/campannas/filter/reporte_batch",

	// Visitas a campanna
	r_frontend_visitante_campanna_post: "/frontend/visitante_campanna",


	// Bonos
	// Tipo de bonos
	r_frontend_bono_tipo_bonos_get: "/bonos/tipo_bonos",

	// Estado Bonos
	r_frontend_bono_estado_bonos_get: "/bonos/estado_bonos",

	// bono jugadores
	r_frontend_bono_bono_jugadores_get: "/frontend/bono_jugadores",
	r_frontend_bono_bono_jugadores_show_activo_get: "/frontend/bono_jugadores/show/activo",
	r_frontend_bono_bono_jugadores_filter_all: "/frontend/bono_jugadores/filter/all",
	r_frontend_bono_bono_jugadores_filter_historial: "/frontend/bono_jugadores/filter/historial",
	r_frontend_bono_bono_jugadores_cancelacion: "/frontend/bono_jugadores/{id}/cancelacion",
	r_frontend_bono_bono_jugadores_activacion_bono_post: "/frontend/bono_jugadores/activacion/bono",
	r_frontend_bono_bono_jugadores_tomar_bono_devolucion_post: "/frontend/bono_jugadores/tomar_bono_devolucion",
	r_frontend_bono_bono_jugadores_activacion_bono_ultimo_deposito: "/frontend/bono_jugadores/activacion_ultimo_deposito/bono",
	r_frontend_bono_bono_jugadores_bono_jugador_depositos: "/frontend/bono_jugador_depositos",

	// bono jugador aptos
	r_frontend_bono_bono_jugador_aptos_get: "/frontend/bono_jugador_aptos",
	r_frontend_bono_bono_jugador_aptos_filter_all: "/frontend/bono_jugador_aptos/filter/all",
	r_frontend_bono_bono_jugador_delete_moneda_bono: "/frontend/bono_jugador_depositos/bonos/{bono_id}/monedas/{moneda_id}",


	// Uploads
	r_frontend_uploads: "/frontend/uploads",

	// Verificaciones
	r_frontend_documento_jugadores_get: "/frontend/documento_jugadores",
	r_frontend_documento_jugadores_filter_all: "/frontend/documento_jugadores/filter/all",
	r_frontend_documento_jugadores_show: "/frontend/documento_jugadores/show/por_tipo",
	r_frontend_documento_jugadores_post: "/frontend/documento_jugadores",
	r_frontend_documento_jugadores_put_subir_archivo: "/frontend/documento_jugadores/{id}/subir_archivo",
	r_frontend_documento_jugadores_get_faxbackform: "/frontend/documento_jugadores/get/faxbackform",


	// Solicitudes de retiro
	r_frontend_solicitud_retiros_get: "/frontend/solicitud_retiros",
	r_frontend_solicitud_retiros_show: "/frontend/solicitud_retiros/{id}",
	r_frontend_solicitud_retiros_cancelacion: "/frontend/solicitud_retiros/{id}/cancelacion",
	r_frontend_solicitud_retiros_filter_all: "/frontend/solicitud_retiros/filter/all",
	r_frontend_solicitud_retiros_post: "/frontend/solicitud_retiros",
	r_frontend_solicitud_retiros_update: "/frontend/solicitud_retiros",
	r_frontend_solicitud_retiros_delete: "/frontend/solicitud_retiros/{id}",

	// Tickets
	r_frontend_tickets_get: "/frontend/tickets",
	r_frontend_tickets_filter_all: "/frontend/tickets/filter/all",
	r_frontend_tickets_add: "/frontend/tickets",
	r_frontend_tickets_show: "/frontend/tickets/{id}",
	r_frontend_tickets_update: "/frontend/tickets/{id}",
	r_frontend_tickets_delete: "/frontend/tickets/{id}",
	r_frontend_tickets_cambio_estado: "/frontend/tickets/{id}/cambio_estado",

	// Tickets notas
	r_frontend_ticket_notas_get: "/frontend/ticket_notas",
	r_frontend_ticket_notas_post: "/frontend/ticket_notas",

	// Movimientos
	r_frontend_movimientos_deposito_get: "/frontend/movimientos/depositos/usefull_for_bono",

	// Home
	r_frontend_bono_visitantes: "/frontend/bonos/home",
	r_frontend_monedas_exchanger_visitantes: "/frontend/home_public/moneda_utilidades",
	r_frontend_monedas_exchanger_jugador: "/bono/bono_jugadores",
	r_frontend_afiliados_home_tabla: "/frontend/afiliados/resumen_periodo?actual",

	// Notifications
	r_frontend_notifications_get: "/frontend/notificaciones",
	r_frontend_notifications_mark_as_read: "/frontend/notificaciones/{id}/marcar_leida",
	r_frontend_notifications_delete: "/frontend/notificaciones/{id}",

	// Torneos
	r_torneos_get_torneo: "/frontend/torneos/activo/leaderboard",

	// Wallet General
	r_frontend_wallet_general: "/frontend/jugadores/wallets/general",

	// Games General
	r_frontend_games_general: "/frontend/juegos/activos/juegos_clasificaciones",

	// Currency Exchange
	r_frontend_currency_exchange: "/frontend/home_public/moneda_utilidades",

	// Circuitos
	r_frontend_circuitos: "/frontend/circuitos/activo",

};

