<section class="wider tabbed" id="cta-games">
	<div class="section-header">
		<div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
			<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
		</div>


		<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="section-title">
				<div class="txt-snor pr-1">{{ 'HISTORIAL_DE_JUEGOS' | translate }}</div>
				<img src="../../../../assets/images/icon-m-retiro.svg" class="section-icon">
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>



	<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
	<div class="hidden-landscape full-h">
		<div class="col col-3 full-h">
			<table class="tbl2 tbl2-lg tbl2-striped striped-alt tbl-vert tbl2-head">
				<tr class="">
					<th>{{ 'MOV_ABREVIATURA' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'FECHA' | translate }}</th>
				</tr>
				<tr>
					<th class="th-2x">{{ 'CATEGORIA' | translate }}<br>{{ 'DE_JUEGO' | translate }}</th>
				</tr>
				<tr>
					<th class="th-2x">{{ 'JUEGO' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'CONCEPTO' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'MONEDA' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'IMPORTE' | translate }}</th>
				</tr>
			</table>
		</div>
		<div class="col col-9 full-h of-x2">
			<div class="tbl2 tbl2-lg tbl2-striped tbl-vert faux-tbl-vert bg-g1" #divContenerdor
				*ngIf="juegosHistorial.length > 0" infiniteScroll [horizontal]="true" [infiniteScrollDistance]="0.1"
				[infiniteScrollUpDistance]="0.1" [infiniteScrollThrottle]="50" [alwaysCallback]="true"
				(scrolled)="onScroll()" (scrolledUp)="onScrollUp()" [scrollWindow]="false">
				<!-- EMPTY -->
				<div class="col tbl2-col-empty tac">
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="cb"></div>
				</div>
				<!-- / EMPTY -->
				<!-- DATA -->
				<!-- EMPTY -->
				<div class="col  tac">
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="cb"></div>
				</div>
				<!-- -->
				<div class="col tbl2-col-n tac" *ngFor="let juego of juegosHistorial; let i = index;">
					<div class="td">{{juego.id}}</div>
					<div class="td">
						<span>{{juego.fecha.split(" ")[0]}} <br>
							<span class="t-sm wordBreak">{{juego.fecha.split(" ")[1]}}</span></span>
					</div>
					<div class="td td-2x">{{juego.clasificacionVisuales}}</div>
					<div class="td td-2x">
						<span class="txt-sm wordBreak">{{juego.juego}}</span>
					</div>
					<div class="td">{{juego.concepto}}</div>
					<div class="td">{{ juego.moneda.toUpperCase() }}</div>
					<div class="td">{{juego.apuesta | number:"1.2-8"}}</div>
					<div class="cb"></div>
				</div>
				<!-- / DATA -->
			</div>
			<div class="flex-center full" *ngIf="juegosHistorial.length < 1">
				<div class="bg-g1 p-2">{{ 'NO_HAY_ACTIVIDAD_DE_JUEGO' | translate }}</div>
			</div>
		</div>
	</div>
	<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
</section>