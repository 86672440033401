<div class="w-cover">

    <div class="window" id="bono-activate-2">
        <!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
        <div class="hidden-landscape full-h">
            <div class="btn-window-close" (click)="onCloseW($event)">
                <div class="btn-window-close-bar"></div>
            </div>
            <p class="w-title-cover txt-sm txt-w tac mb-1">{{'ACTIVAR_BONO_DEVOLUCION'|translate}}</p>
            <p class="w-title-cover txt-sm txt-gc tac mb-1">{{'SELECCIONE_MONEDA_DEVOLUCION' |translate}}.</p>
            <div class="pl-15 inner-dev-bono">
                <div class="pr-15">
                    <div class="faux-inp2 mb-2" *ngIf="bono">{{bono.nombre}}</div>
                </div>
                <div class="dev-coin-container-2 pr-1 mb-15">
                    <!-- COIN -->
                    <ng-container *ngFor="let curr of walletCurrency; let i = index;">
                        <div class="coin" [class.selected]="selectedCoin == curr.moneda.id"
                             (click)="onSelectCoin($event, curr.moneda.id)">
                            <div class="coin-icon">
                                <img class=""
                                     src="../../../../assets/images/crypto/{{curr.moneda.siglas.toLowerCase()}}.svg"
                                     (error)="changeImg($event, curr.moneda.imagen)">
                            </div>
                            <div class="coin-desc">
								<span class="txt-nor">
									<span class="txt-c2">{{curr.moneda.siglas.toUpperCase()}}</span><br>
                                    {{curr.moneda.nombre}}</span>
                            </div>
                            <div class="cb"></div>
                        </div>
                    </ng-container>
                    <!-- / COIN -->
                </div>
                <p class="txt-sssm txt-g9 tac pr-15">{{'MSGS_SE_DEVOLVERA_ULTIMO_DEPOSITO_REALIZADO_1' |translate}} <br> {{'MSGS_SE_DEVOLVERA_ULTIMO_DEPOSITO_REALIZADO_2' |translate}}.</p>
                <div class="cb"></div>
            </div>

            <div class="w-actions px-2 pb-1">
                <div class="col col-6 pr-05">
                    <button (click)="onCancel($event)" class="btn2 btn2-nor btn2-7th btn2-nor-icon full-w"
                            type="button">
                        {{'CANCELAR' |translate}}
                        <img src="../../../../assets/images/icon-b-close7-c.svg">
                    </button>
                </div>
                <div class="col col-6 pl-05">
                    <button (click)="onActivate($event)" class="btn2 btn2-nor btn2-1st btn2-nor-icon full-w"
                            type="button">
                        {{'CONTINUAR' |translate}}
                        <img src="../../../../assets/images/icon-b-play-c.svg">
                    </button>
                </div>
                <div class="cb"></div>
            </div>

        </div>
        <!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
    </div>

</div>
