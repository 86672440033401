import {AfterViewInit, Component, OnInit} from "@angular/core";

@Component({
	selector: "app-loader",
	templateUrl: "./loader.component.html",
	styleUrls: ["./loader.component.css"]
})
export class LoaderComponent implements OnInit, AfterViewInit {

	cHeight: number;
	cWidth: number;

	constructor() {
	}

	ngOnInit(): void {
		this.cHeight = window.innerHeight;
		this.cWidth = window.innerWidth;
	}

	ngAfterViewInit() {
		this.cHeight = window.innerHeight;
		this.cWidth = window.innerWidth;
	}

}
