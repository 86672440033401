import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {BonosModel} from "../../../shared/models/bonos.model";
import {ResponseModel} from "../../../shared/models/response.model";
import {Subject} from "rxjs";
import Swal from "sweetalert2";
import {NotificationService} from "../../../shared/services/notification.service";
import {DepositoModel} from "../../../shared/models/deposito.model";
import {BonosService} from "../../services/bonos.service";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-w-activate-deposito",
	templateUrl: "./w-activate-deposito.component.html",
	styleUrls: ["./w-activate-deposito.component.css"]
})
export class WActivateDepositoComponent implements OnInit, OnDestroy {

	@Input() bono: BonosModel;
	@Input() balance: WalletGeneralModel;
	@Input() lastDeposit: DepositoModel;

	tipoActivacion: string;
	modeActivation: number;

	protected ngUnsubscribe: Subject<any> = new Subject<any>();

	constructor(
		private bonosService: BonosService,
		public activeModal: NgbActiveModal,
		private router: Router,
		private notifications: NotificationService,
		private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		if (this.lastDeposit) {
			this.tipoActivacion = "pre1"; // pre1
		} else {
			this.tipoActivacion = "pre0"; // pre1
		}
		this.modeActivation = 0;
	}

	ngOnDestroy(): void {
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW(event) {
		event.stopPropagation();
		this.activeModal.close();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW(event);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATION MODE ()
	onActivationMode(event, mode) {
		event.stopPropagation();
		this.modeActivation = mode;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATE ()
	onActivate(event) {
		if (this.modeActivation === 0) {
			this.onActivateAndGo(event);
			return;
		}

		event.stopPropagation();
		const param = {
			type: "ULTIMO_DEPOSITO",
			data: {
				bono_jugador_apto_id: this.bono.id
			}
		};
		this.postActivateBono(param);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATE AND GO ()
	onActivateAndGo(event) {
		event.stopPropagation();

		const param = {
			type: "DEPOSITO",
			data: {
				bono_jugador_apto_id: this.bono.id
			}
		};
		this.postActivateBono(param);
	}

	postActivateBono(param) {
		this.bonosService.postActivateBono(param).subscribe(
			(response) => {
				const resp: ResponseModel = response;
				Swal.fire(this.notifications.BuildSuccess(this.translateService.instant("DEPOSITE_EN_WALLET_ACTIVAR_BONO"), this.translateService.instant("BONO_USADO"))).then(
					() => {
						if (this.modeActivation === 0) {
							this.activeModal.close(true);
							this.router.navigate(["/cajero/wallet"], {queryParams: {bono: resp.object.moneda_id}}).then();
						} else {
							this.activeModal.close(true);
						}
					}
				);
			}
		);
	}
}
