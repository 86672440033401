<div class="tab-container-afiliados-0">   
   <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
   <div class="afiliados-content full-h hidden-landscape px-15 noScrlBr">
      <!-- CAMPAIGN -->
      <div class="campaign" *ngFor="let camp of campaigns; let i = index;">
         <div class="title mb-1">{{camp.nombre}}</div>
         <div class="txt-sm txt-g9 mb-1">Link:</div>
         <div class="txt-gc mb-12 wordBreak">{{baseUrl + camp.codigo}}</div>
         <input id="link{{i}}" type="hidden" value="{{baseUrl + camp.codigo}}">
         <div class="tar">
            <button type="button" class="btn2 btn2-2nd btn2-lg btn2-lg-icon mr-1" (click)="onFacebookShare(camp.codigo)">
               <img src="../../../../assets/images/icon-share-fb.svg">
            </button>
            <button type="button" class="btn2 btn2-2nd btn2-lg btn2-lg-icon mr-1" (click)="onTwitterShare(camp.codigo)">
               <img src="../../../../assets/images/icon-share-tt.svg">
            </button>
            <button type="button" class="btn2 btn2-4th btn2-lg btn2-lg-icon mr-1" (click)="onShowInfo(camp)">
               <img src="../../../../assets/images/icon-info-lg.svg">
            </button>
            <button type="button" class="btn2 btn2-4th btn2-lg btn2-lg-icon" (click)="onCopyHash($event, camp.codigo)">
               <img src="../../../../assets/images/icon-copy.svg">
            </button>
         </div>
      </div>
      <!-- / CAMPAIGN -->
   </div>
   <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

</div>
