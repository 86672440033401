<!-- <div class="main-cover"></div> -->

<app-loader *ngIf="showLoader"></app-loader>

<div class="noScrlBr" id="main-container" [hidden]="!show">

	<router-outlet></router-outlet>


	<!--    <app-controls *ngIf="itsLoggedIn()" [hidden]="itsInicio()"></app-controls>-->


	<app-widget-control [hidden]="itsInicio()" *ngIf="itsUserLogued()"></app-widget-control>
	<!-- *ngIf="itsLoggedIn()"  -->

</div>

<app-turnmobile></app-turnmobile>
