import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
	name: "sanitizer"
})
export class SanitizerPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) {
	}

	transform(url) {
		if (!url) {
			return null;
		}
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);

	}

}
