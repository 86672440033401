<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover coverB" id="w-new-link-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">

	<div class="window" id="new-link">
		<form [formGroup]="campaignForm" (ngSubmit)="onGenerateLink($event)">
			<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
			<div class="hidden-landscape">
				<div class="btn-window-close" (click)="onCloseW()">
					<div class="btn-window-close-bar"></div>
				</div>
				<p class="w-title-cover txt-ssm txt-w tac mb-1">{{'GENERAR_NUEVO_LINK' | translate}}</p>
				<div class="p-15 pb-3">
					<div class="bg-g1 p-15 pb-3">
                  <div class="tooltip2-inline-container">
                     <p class="fl txt-sm txt-g8 mb-03">{{'NOMBRE_CAMPANNA' | translate}}:</p>
                     <!-- TOOTLTIP2 -->
                     <button type="button" class="fr tooltip2 tooltip2-inline"
                        (click)="onToggleTooltip2($event,'tt1')"><img
                           src="../../../../../assets/images/icon-question.svg"></button>
                     <div class="tooltip2-contentainer RU" id="tt1" (click)="onToggleTooltip2($event,'tt1')">
                        <div class="tooltip2-content">
                           <span class="txt-sm">{{'NOMBRE_INTERNO_PARA_IDENTIFICACION' | translate}}</span>
                           </div>
                        </div>
                        <!-- / TOOTLTIP2 -->
                     </div>
							<div class="cb"></div>
							<input class="inp2 inp2-nor inp2-1st full-w" placeholder="" type="text"
								[style.border-color]="inputTouched('name') ? 'red' : ''" formControlName="name">
						</div>
					</div>
					<div class="btn2-actions px-15 pb-2">
						<div class="col col-6 pr-07">
							<button class="btn2 btn2-lg btn2-g full-w" type="button"
								(click)="onCancel($event)">{{'CANCELAR' |translate}}</button>
						</div>
						<div class="col col-6 pl-07">
							<button class="btn2 btn2-lg btn2-1st full-w" type="button"
								(click)="onGenerateLink($event)" [disabled]="campaignForm.invalid">{{'CONFIRMAR' |translate}}</button>
						</div>
						<div class="cb"></div>
					</div>
				</div>
				<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
		</form>
	</div>

</div>
