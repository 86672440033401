import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import * as AfiliadosActions from '../../../redux/afiliados.actions';
import { Actions, ofType } from "@ngrx/effects";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";
import { NotificationService } from "src/app/shared/services/notification.service";
import { TranslateService } from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-w-new-link",
	templateUrl: "./w-new-link.component.html",
	styleUrls: ["./w-new-link.component.css"]
})
export class WNewLinkComponent implements OnInit, OnDestroy {

	campaignForm: FormGroup;
	onCompleteCreateCampannaAfilliadoSubcription: Subscription;

	wActive: boolean = false;

	constructor(
		private notification: NotificationService,
		private formBuilder: FormBuilder,
		public activeModal: NgbActiveModal,
		private store: Store<AppState>,
		private actions$: Actions,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.initForm();
		this.listeningActions();
		setTimeout(() => { this.wActive = true; }, 0);
	}

	ngOnDestroy(): void {
		if (this.onCompleteCreateCampannaAfilliadoSubcription) {
			this.onCompleteCreateCampannaAfilliadoSubcription.unsubscribe();
		}
	}

	initForm() {
		this.campaignForm = this.formBuilder.group(
			{
				name: new FormControl(null, [Validators.required, Validators.minLength(3)]),
			}
		);
	}

	inputTouched(formName: string) {
		return this.campaignForm.controls[formName].invalid && (this.campaignForm.controls[formName].dirty || this.campaignForm.controls[formName].touched);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SHOW TOOLTIP ()
	showTooltip(event, t) {
		event.stopPropagation();
		const tt = event.target as HTMLParagraphElement;

		tt.classList.add("active");
		setTimeout(() => {
			tt.classList.remove("active");
		}, t * 1000);

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GENERATE LINK ()
	onGenerateLink(event) {
		event.stopPropagation();
		if (this.campaignForm.valid) {
			const nombre = this.campaignForm.value.name;
			this.store.dispatch(AfiliadosActions.afiliadosCreateCampanna({ 'nombre': nombre }));
		}
	}

	listeningActions() {
		this.onCompleteCreateCampannaAfilliadoSubcription = this.actions$
			.pipe(ofType(AfiliadosActions.afiliadosCreateCampannaCompletado))
			.subscribe(response => {
				const message = this.translate.instant('MSG_CAMPANNA_GUARDADA_SATISFATORIO');
				Swal.fire(this.notification.BuildSuccess(message)).then(() => {
					this.activeModal.close();
				});
			})
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOGGLE TOLLTIP2 ()
	timerTT;
	onToggleTooltip2(event, tt) {
		event.stopPropagation();
		var thisTT = document.getElementById(tt) as HTMLDivElement;
		if (thisTT) {
			if (thisTT.classList.contains('active')) {
				thisTT.classList.remove('active');
				clearTimeout(this.timerTT);
			} else {
				this.timerTT = setTimeout(() => {
					thisTT.classList.remove('active');
				}, 5000);
				thisTT.classList.add('active');
			}
		}
	}

}
