import {AfterViewInit, Component, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as CuentaAction from "../../redux/cuenta.actions";


declare function adjustSize(): any;

@Component({
	selector: "app-cta-crypto",
	templateUrl: "./cta-crypto.component.html",
	styleUrls: ["./cta-crypto.component.css"]
})
export class CtaCryptoComponent implements OnInit, AfterViewInit {

	currentTab = 0;

	constructor(
		private store:Store<AppState>
	) {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit() {
		adjustSize();
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET TAB ()
	onSetTab(tab) {
		this.currentTab = tab;
		this.store.dispatch(CuentaAction.changeSwitchVistaCrypto({position:tab}));
	}

}
