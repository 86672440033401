<div class="full-w" id="home-visitor">

	<div class="full-w" id="home-1">

		<div class="home-1-1">
			<!-- - - - - - - - - - - - - - - - HOME HEADER -->
			<div class="home-header">
				<div class="home-header-container pr-1" *ngIf="itsLogged()">
					<div class="btn btn-menu-visitor bm-user" (click)="onUserMenu()">
						<img src="../../../../assets/images/icon-m-af-reg.svg">
					</div>
					<div class="btn btn-menu-visitor bm-lang tac flex-center" (click)="onUserMenu()" *ngIf="false">
						<div id="lang-in-use">{{selectedLang.siglas}}</div>

					</div>
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - / HOME HEADER -->

			<!-- - - - - - - - - - - - - - - - HOME SLIDER -->
			<div id="home-slider" class="">
				<div class="home-slider-container">
					<img src="../../../../assets/images/home-slider/top1.jpg" class="home-slide">
					<img src="../../../../assets/images/home-slider/top2.jpg" class="home-slide">
					<img src="../../../../assets/images/home-slider/top3.jpg" class="home-slide">
					<img src="../../../../assets/images/home-slider/top4.jpg" class="home-slide">
               <img src="../../../../assets/images/home-slider/top5.jpg" class="home-slide" (click)="onGoMrWW()">
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - HOME SLIDER -->

			<!-- - - - - - - - - - - - - - - - HOME LOG -->
			<div class="home-log px-1 py-15" *ngIf="!itsLogged()">
				<div class="col col-6 pr-05">
					<button (click)="onLogIn()" class="btn2 btn2-lg btn2-lg-icon btn2-1st btn-log full-w pl-4"
						type="button">
						{{'INGRESA' |translate}}
						<img src="../../../../assets/images/icon-b-log-w.svg" alt="ingresar">
					</button>
				</div>
				<div class="col col-6 pl-05">
					<button (click)="onGoRegister()" class="btn2 btn2-lg btn2-lg-icon btn2-2nd btn-log full-w pl-4"
						type="button">
						{{'REGISTRATE' |translate}}
						<img src="../../../../assets/images/icon-b-log-w.svg" alt="registro">
					</button>
				</div>
				<div class="cb"></div>
			</div>
			<div class="home-log px-1 py-15" *ngIf="itsLogged()">
				<div class="col col-6 pr-05">
					<button (click)="logOutAction()" class="btn2 btn2-lg btn2-lg-icon btn2-1st btn-log full-w pl-4"
						type="button">
						{{'CERRAR_SESSION' |translate}}
						<img src="../../../../assets/images/icon-logout.svg" alt="salir">
					</button>
				</div>
				<div class="col col-6 pl-05">
					<button (click)="onUserMenu()" class="btn2 btn2-lg btn2-lg-icon btn2-2nd btn-log full-w pl-4"
						type="button">
						{{'IR_A_HOBBY' |translate}}
						<img src="../../../../assets/images/icon-home-c.svg" alt="registro">
					</button>
				</div>
				<div class="cb"></div>
			</div>
			<!-- - - - - - - - - - - - - - - - / HOME LOG -->
		</div>

		<div class="home-1-2 pt-15 pb-15">
			<!-- - - - - - - - - - - - - - - - BONOS CASINO -->
			<div class="bonos-casino">
				<div class="bonos-casino-container full-h">
					<!-- <div class="bonos-casino-content full-h test"> -->

					<!-- - - - - - - - - - - - - - - - BONO CASINO -->
					<div class="bono-casino" *ngFor="let bono of bonos; let i = index;">
						<img [src]="bono.imagen" class="bc-img">
						<div class="bc-title ff-cnd txt-bol">
							<div class="col col-9">
								<div class="ellipsis">
									{{bono.nombre_publico}}
								</div>
							</div>
							<div class="col col-3 tar pr-1" (click)="onActivateBono(bono)">
								<p class="ff-nor txt-a">{{'ACTIVAR' |translate}}</p>
							</div>
						</div>
					</div>
					<!-- - - - - - - - - - - - - - - - / BONO CASINO -->

					<!-- - - - - - - - - - - - - - - - TORNEO CASINO -->
					<div class="torneo-casino" id="tcE-{{0}}" *ngIf="torneo">
						<div class="flex-torneoC full-h">
							<div class="bc-title ff-cnd txt-bol tac">{{torneo.nombre}}</div>
							<div class=" full">
								<!-- TC BLOCK -->
								<div class="full-h tc-block flex-tc-block pb-05">
									<div class="min-h"></div>
									<div class="px-1">
										<div class="full-w py-1">
											<div class="col col-4-1">
												<div class="ff-cnd txt-lg txt-bol txt-c1 tar"
													*ngIf="torneo.estado !== 'ACTIVO'">{{'COMIENZA_EN' |translate}}:
												</div>
												<div class="txt-sm txt-mid txt-c1 tar"
													*ngIf="torneo.estado === 'ACTIVO'">{{'TERMINA_EN' |translate}}...
												</div>
											</div>
											<div class="col col-6-1">
												<div class="counter tac pb-05">
													<div class="col-2 ilb">
														<div class="counterNum days txt-bol txt-gc">
															{{!countDD || countDD < 1 ? "00" : countDD < 10 ? "0" + countDD : countDD}}
														</div>
														<span class="ff-cnd">{{'DIAS' |translate}}</span>
													</div>
													<div class="ilb">
														<div class="counterDiv">:</div>
														<span>&nbsp;</span>
													</div>
													<div class="col-2 ilb">
														<div class="counterNum hours txt-bol">
															{{!countHH || countHH < 1 ? "00" : countHH < 10 ? "0" + countHH : countHH}}
														</div>
														<span class="ff-cnd">{{'HORAS' |translate}}</span>
													</div>
													<div class="ilb">
														<div class="counterDiv">:</div>
														<span>&nbsp;</span>
													</div>
													<div class="col-2 ilb">
														<div class="counterNum minutes txt-bol">
															{{!countMM || countMM < 1 ? "00" : countMM < 10 ? "0" + countMM : countMM}}
														</div>
														<span class="ff-cnd">{{'MINS' |translate}}.</span>
													</div>
													<div class="ilb">
														<div class="counterDiv">:</div>
														<span>&nbsp;</span>
													</div>
													<div class="col-2 ilb">
														<div class="counterNum seconds txt-bol">
															{{!countSS || countSS < 1 ? "00" : countSS < 10 ? "0" + countSS : countSS}}
														</div>
														<span class="ff-cnd">{{'SEGS' |translate}}.</span>
													</div>
													<div class="cb"></div>
												</div>
											</div>
										</div>
									</div>
									<div class="px-1">
										<table class="tbl-tc">
											<tr>
												<td class="txt-c4-l"><span class="txt-lg txt-bol">1</span><span
														class="txt-xsm">{{'ER_PREMIO' | translate}}</span></td>
												<td class="ammount tar"> {{getPremioPlace(1).premio}} </td>
												<td class="crypto ff-cnd">
													{{getPremioPlace(1).moneda.siglas.toUpperCase()}} </td>
												<td class="ff-cnd tar">
													<span class="txt-c4"> {{'USD'.toUpperCase()}} </span>
													<span class="txt-ga">
														{{getPremioPlace(1).premio_referencial | number: "1.2-2"}}
													</span>
												</td>
											</tr>
											<tr>
												<td class="txt-c4-l"><span class="txt-lg txt-bol">2</span><span
														class="txt-xsm">{{'DO_PREMIO' |translate}}</span></td>
												<td class="ammount tar"> {{getPremioPlace(2).premio}} </td>
												<td class="crypto ff-cnd">
													{{getPremioPlace(2).moneda.siglas.toUpperCase()}} </td>
												<td class="ff-cnd tar">
													<span class="txt-c4"> {{'USD'.toUpperCase()}} </span>
													<span class="txt-ga">
														{{getPremioPlace(2).premio_referencial | number: "1.2-2"}}
													</span>
												</td>
											</tr>
											<tr>
												<td class="txt-c4-l"><span class="txt-lg txt-bol">3</span><span
														class="txt-xsm">{{'ER_PREMIO' | translate}}</span></td>
												<td class="ammount tar"> {{getPremioPlace(3).premio}} </td>
												<td class="crypto ff-cnd">
													{{getPremioPlace(3).moneda.siglas.toUpperCase()}} </td>
												<td class="ff-cnd tar">
													<span class="txt-c4"> {{'USD'.toUpperCase()}} </span>
													<span class="txt-ga">
														{{getPremioPlace(3).premio_referencial | number: "1.2-2"}}
													</span>
												</td>
											</tr>
										</table>
									</div>
									<!-- <div class="min-h"></div> -->
									<div class="tar pr-1">
										<p class="ff-nor txt-a" (click)="onLogIn()" *ngIf="torneo.estado === 'ACTIVO'">
											{{'ACTIVAR' |translate}}</p>

									</div>
								</div>
								<!-- / TC BLOCK -->
							</div>

							<div class="min-h"></div>
						</div>
					</div>
					<!-- - - - - - - - - - - - - - - - / TORNEO CASINO -->

					<!-- </div> -->
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - / BONOS CASINO -->
		</div>

		<div class="home-1-3">
			<!-- - - - - - - - - - - - - - - - HOMES COIN -->
			<div class="home-coins pt-08">
				<!-- <p class="txt-ssm txt-x2 txt-gc tac">Deposita, Juega e Intercambia</p> -->

				<div class="home-coins-container mb-03 px-2">
					<!--
                  <div class="home-coins-content autoscroll as1">
						<div class="col col-6">
                     -->
					<div class="col col-show-coin tac" *ngFor="let item of arrayOne(16); let i = index;">
						<img src="../../../../assets/images/crypto/{{coinsA[i]}}.svg" class="show-coin">
					</div>

				</div>
				<div class="cb"></div>
				<p class="txt-sm txt-c1 tac pt-08 pb-1">{{'DEPOSITA_JUEGA_INTERCAMBIA' |translate}}</p>
			</div>
			<!-- - - - - - - - - - - - - - - - / HOMES COIN -->


		</div>

		<!-- <div class="min-h"></div> -->

	</div>

	<div id="homeDown" class="bg-k7 full-w">

		<!-- <div id="stck-Container"> -->
		<div class="full-w pt-1" id="home-2">
			<p class="txt-g9 txt-x2 tac mb-05">{{'NUESTROS_JUEGOS' |translate}}</p>
			<!-- <div class="min-h necesario"></div> -->


			<ul class="hc-menu">
				<ng-container *ngFor="let cat of gamesCategory; let i=index;">
					<li (click)="onSetCat(cat)" [class.active]="categoriaSeleccionada.id ===cat.id" *ngIf="i < 5">
						<img [src]="cat.imagen" (error)="changeImg($event, cat.nombre)">
						<p>{{cat.nombre}}</p>
					</li>
				</ng-container>
			</ul>

			<!-- - - - - - - - - - - - - - - - HOME CATALOG -->
			<!-- <div class="hc-outer pos-rel test"> -->
			<div class="home-catalog bg-k7" #divContenerdor infiniteScroll [horizontal]="true"
				[infiniteScrollDistance]="0.1" [infiniteScrollThrottle]="50" [alwaysCallback]="true"
				(scrolled)="onScroll()" [scrollWindow]="false">

				<div class="hc-game" *ngFor="let game of juegosAMostrar; let i=index;">
					<ng-container *ngIf="i < noImagenesACargar">
						<img [src]="game.imagen_logo_base64">
						<div class="btn-hc-info" (click)="onShowGameInfo(game)">
							<img src="../../../../assets/images/icon-info-lg.svg" class="">
						</div>
					</ng-container>
				</div>

				<!-- BOTÓN DE CARGAR
				<div class="hc-game" (click)="onScroll()" *ngIf="filteredGames.length > noImagenesACargar">
					<img src="../../../../assets/images/icon-plus-c.svg">
            </div>
            -->

			</div>
			<!-- - - - - - - - - - - - - - - - / HOME CATALOG -->

			<!-- - - - - - - - - - - - - - - - HOME LIVE -->
			<div class="home-live bg-k7 pt-1">
				<p class="txt-g9 txt-x2 tac mb-05">{{'JUEGOS_VIVO' |translate}}</p>
				<div class="home-live-content full-w">
					<div class="hc-game-live" *ngFor="let item of arrayOne(16); let i = index;">
						<!--
						<video preload="auto" muted loop playsinline autoplay oncanplay="this.play()"
									 onloadedmetadata="this.muted = true"
								  <source src="../../../../assets/images/games/live/vc1.mp4" type="video/mp4">
						</video>
						-->
						<img src="../../../../assets/images/games/live/live1.jpg" alt="live game">
						<div class="game-title bg-k2">A very long Long Game Name {{i}}</div>
					</div>
				</div>
			</div>
			<!-- - - - - - - - - - - - - - - - / HOME LIVE -->

		</div>
		<!-- </div> -->
		<!-- - - - - - - - - - - - - - - - HOME UP -->
		<!--
         <div class="home-up bg-k6 tac p-1">
            <button class="btn btn-rnd btn-nor btn-4th-l" type="button">
               <img class="icon-full" src="../../../../assets/images/icon-b-up4.svg">
            </button>
         </div>
         -->
		<!-- - - - - - - - - - - - - - - - / HOME UP -->
		<!-- - - - - - - - - - - - - - - - HOME LOGOS -->
		<div id="home-afil-container" class="">
			<div class="necesario"></div>
			<p class="txt-afil">{{'TRAE_A_TUS_AMIGOS' |translate}}<br>{{'COBRA_TIEMPO_REAL' |translate}}</p>
			<button (click)="onGoAfil()" class="btn2 btn2-nor btn2-4th btn2-nor-icon full-w" type="button">
				{{'MAS_INFO' |translate}}
				<img src="../../../../assets/images/icon-b-log-cw.svg" alt="ingresar">
			</button>
			<div class="necesario"></div>
		</div>
		<!-- - - - - - - - - - - - - - - - / HOME LOGOS -->

      <!-- - - - - - - - - - - - - - - - HOME FOOTER -->
      <app-footer-jugador></app-footer-jugador>
		<!-- - - - - - - - - - - - - - - - / HOME FOOTER -->

	</div>

	<button class="btn btn-nor btn-rnd btn-w bg-k7" id="btn-homeV-help" type="button" (click)="goChat()">
		<img src="../../../../assets/images/icon-help-chat-w.svg" alt="ingresar">
	</button>

</div>
