

<section id="catalog" [class.active]="myMenu.show_catalog_component" (swipedown)="onCloseCatalog()">

<!-- - - - - - - - - - - - - - - - - - LANDSCAPE -->
<div class="" id="catalog-container-0" class="hidden-portrait">

   <div class="col col-cat-L full-h">
      <ul id="catalog-menu" class="">
         <li (click)="onSetCat(cat)" [class.active]="!favorite && catSeleccionada && catSeleccionada.id === cat.id"
            *ngFor="let cat of gamesCategory">
            <img [src]="cat.imagen" (error)="changeImg($event, cat.nombre)">
            <!-- <p>{{cat.nombre}}</p> -->
         </li>
         <li (click)="onSetCatFav()" [class.active]="favorite" *ngIf="itLoggedIn()">
            <img src="../../../../assets/images/icon-cat-favorite.svg">
         </li>
      </ul>
   </div>

   <div class="col col-cat-R full-h py-02 " id="games-container">
      <div class="catalog-branding tac">
         <img src="../../../../assets/images/bitwild-logo-w.svg" class="branding-logo">
      </div>

      <!-- JUEGO NORMAL -->
      <div class="catalog-container-1 of-y"><!-- *ngIf="gameType == 'game'" -->

         <!-- GAME -->
         <ng-container *ngFor="let game of games; let i=index;"><!-- *ngFor="let game of filteredGames; let i=index;" -->
            <div (click)="onSelectGame($event, game)" class="game">
               <img class="game-logo" [src]="game.imagen_logo">
               <!-- <p class="game-title">{{game.nombre_publico}}</p> -->
               <!-- -->
               <div (click)="onUnselect($event)" *ngIf="game.id === coverActive" class="game-cover">
                  <button (click)="onShowGameInfo($event, game)"
                     class="btn btn-sm btn-rnd btn-4th bg-k5 btn-info" type="button">
                     <img src="../../../../assets/images/icon-info.svg">
                  </button>
                  <button [class.favorite]="game.favorito" *ngIf="itLoggedIn()" style="z-index: 999999"
                     class="btn btn-sm btn-rnd btn-4th bg-k5 btn-favorite"
                     (click)="onSelectFav($event,game)" type="button">
					  <img src="../../../../assets/images/icon-favorite-lg.svg" *ngIf="!game.favorito">
					  <img src="../../../../assets/images/icon-favorite-red.svg" *ngIf="game.favorito">
                  </button>
                  <button (click)="onGoPlay($event, game)" class="btn btn-lg btn-rnd btn-1st bg-k5 btn-play"
                     type="button">
                     <img src="../../../../assets/images/icon-play.svg">
                  </button>
                  <!-- -->
               </div>
               <!-- -->
            </div>
         </ng-container>
         <!-- / GAME -->

      </div>
      <!-- / JUEGO NORMAL -->

      <!-- JUEGO EN VIVO -->
      <ng-container>
         <div class="catalog-container-1 of-y" [hidden]="true">
            <div class="live-container">

               <!-- VIVO -->
               <ng-container>
                  <div class="game-live" *ngFor="let item of arrayOne(16); let i = index;">
                     <!--
                     <video preload="auto" muted loop playsinline autoplay oncanplay="this.play()"
                        onloadedmetadata="this.muted = true">
                        <source src="../../../../assets/images/games/live/{{thumb[i]}}.mp4"
                           type="video/mp4">
                     </video>
                     -->
                     <img src="../../../../assets/images/games/live/live1.jpg">
                     <div class="game-title">
                        <p>A very long Long Game Name {{i}}</p>
                     </div>
                  </div>
               </ng-container>

               <!-- VIVO -->
            </div>

         </div>
      </ng-container>
      <!-- JUEGO EN VIVO -->

   </div>

</div>
<!-- - - - - - - - - - - - - - - - - - / LANDSCAPE -->

<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
   <div id="catalog-port" class="hidden-landscape">
   <div class="btn-window-close" (click)="onCloseCatalog()">
      <div class="btn-window-close-bar"></div>
   </div>
   <p class="w-title-cover txt-ssm txt-g8 tac">Nuestros Juegos: <span class="txt-w">{{favorite ? "Favoritos" : catSeleccionada ? catSeleccionada.nombre:''}}</span></p>

   <div id="games-port-container">

      <!-- JUEGO NORMAL -->
      <div class="games-port-content noScrlBr">
         <!-- GAME -->
         <ng-container *ngFor="let game of games; let i=index;">
            <div (click)="onSelectGame($event,game)" class="game-port">
               <img class="game-logo" [src]="game.imagen_logo_base64">
               <div (click)="onUnselect($event)" *ngIf="game.id === coverActive" class="game-cover">
                  <div class="b-game b-info" (click)="onShowGameInfoPort($event, game)">
                     <img src="../../../../assets/images/icon-info-lg-circle.svg">
                  </div>
                  <div class="b-game b-favorite" (click)="onSelectFav($event, game)">
                     <img src="../../../../assets/images/icon-favorite-lg.svg" *ngIf="!game.favorito">
                     <img src="../../../../assets/images/icon-favorite-red.svg" *ngIf="game.favorito">
                  </div>
                  <div class="b-game b-play" (click)="onGoPlay($event, game)">
                     <img src="../../../../assets/images/icon-play-lg.svg">
                  </div>
               </div>
            </div>
         </ng-container>
      </div>
      <!-- / JUEGO NORMAL -->
      <!-- JUEGO EN VIVO -->

      <!-- / JUEGO EN VIVO -->
   </div>

   <ul id="catalog-menu-port" class="">
      <li (click)="onSetCat(cat)" [class.active]="!favorite && catSeleccionada && catSeleccionada.id === cat.id"
         *ngFor="let cat of gamesCategory">
         <img [src]="cat.imagen" (error)="changeImg($event, cat.nombre)">
         <!-- <p>{{cat.nombre}}</p> -->
      </li>
      <li (click)="onSetCatFav()" [class.active]="favorite" *ngIf="itLoggedIn()">
         <img src="../../../../assets/images/icon-cat-favorite.svg">
         <!-- <p>Favoritos</p> -->
      </li>
   </ul>




   <!-- GAME INFO PROTRAIT -->
   <div class="w-cover coverB" id="game-port-info-cover" *ngIf="gameSelected" [class.active]="wGiActive==true" (click)="onHideGameInfoPort($event)">
      <!-- (swipedown)="onHideGameInfoPortS($event)" -->
      <div id="game-port-info">
         <div class="btn-window-close" (click)="onHideGameInfoPort($event)">
            <div class="btn-window-close-bar"></div>
         </div>
            <div class="game-info">

            <div>
               <!-- <img class="fav-indicator mr-1">  [class.active]="game.favorito" -->
                  <img class="fav-indicator mr-1 op-5" src="../../../../assets/images/icon-favorite-lg.svg" *ngIf="!gameSelected.favorito">
                  <img class="fav-indicator mr-1 selected" src="../../../../assets/images/icon-favorite-red.svg" *ngIf="gameSelected.favorito">
               <span class="ilb txt-nor game-info-title">{{gameSelected.nombre_publico}}</span>
               <div class="cb mb-05"></div>
               <img [src]="gameSelected.imagen_juego">
            </div>

            <div>
               <div class="col col-6 pr-05">
                  <span class="info-txt txt-g8 tar">Categoría de Juego:</span>
                  <span class="info-txt txt-g8 tar">Líneas:</span>
                  <span class="info-txt txt-g8 tar">Mínimo equivalente a:</span>
                  <span class="info-txt txt-g8 tar">Máximo equivalente a:</span>
                  <span class="info-txt txt-g8 tar">Jackpot equivalente a:</span>
               </div>
               <div class="col col-6 pl-05">
                  <span class="info-txt ellipsis">{{gameSelected.categoria_juego.nombre}}</span>
                  <span class="info-txt">{{gameSelected.lineas}}</span>
                  <span class="info-txt"><span class="txt-c4-l"> {{'USD'}} </span>{{gameSelected.apuesta_minima | number: '1.2-2'}}</span>
                  <span class="info-txt"><span class="txt-c4-l"> {{'USD'}} </span>{{gameSelected.apuesta_maxima | number: '1.2-2'}}</span>
                  <span class="info-txt"><span class="txt-c4-l"> {{'USD'}} </span>{{gameSelected.jackpot | number: '1.2-2'}}</span>
               </div>
               <div class="cb"></div>
            </div>

            <div>
            <div class="info-coin" *ngFor="let item of gameSelected.moneda_ids; let i = index;">
               <ng-container *ngIf="getSiglasMonedas(item) != ''">
                  <img [src]="'../../../../assets/images/crypto/' + getSiglasMonedas(item) + '.svg'"><br>
                  <span class="ilb pt-02">{{getSiglasMonedas(item).toUpperCase()}}</span>
               </ng-container>
            </div>
            </div>

         </div>

         <div class="px-1 pt-1 pb-15 bg-k">
            <div class="col col-6 pr-07">
            <button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onHideGameInfoPort($event)">CERRAR</button>
            </div>
            <div class="col col-6 pl-07">
               <button type="button" class="btn2 btn2-lg btn2-1st full-w" (click)="onGoPlay($event, gameSelected)">JUGAR</button>
            </div>
            <div class="cb"></div>
         </div>

      </div>
   </div>
   <!-- / GAME INFO PROTRAIT -->

   </div>
<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->
</section>
