import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SessionServices } from "../../../../shared/services/session.services";
import { Location } from "@angular/common";
import { JugadorDocumentosModel } from "../../../../shared/models/jugador-documentos.model";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../shared/redux/general.reducers";
import * as CajeroAction from "../../../redux/cajero.actions"
import { Subscription } from "rxjs";

declare function adjustSize(): any;

@Component({
	selector: "app-verificaciones",
	templateUrl: "./verificaciones.component.html",
	styleUrls: ["./verificaciones.component.css"]
})
export class VerificacionesComponent implements OnInit, AfterViewInit, OnDestroy {

	selected: string;

	vDatos: string;
	vCard: string;
	vId: string;
	vRes: string;
	vFbf: string;

	allDocuments: JugadorDocumentosModel[];
	selectedDocuments: JugadorDocumentosModel[];
	subscription = new Subscription();

	loaded: boolean;

	constructor(
		private router: Router,
		private sessionService: SessionServices,
		private location: Location,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.allDocuments = [];
		this.setTab();
		this.getDocumentosJugador();

		this.subscription.add(this.store.subscribe((state) => {
			this.allDocuments = state.cajero.documentosJugador;
			this.setCompletedInit();
		}))
	}

	ngAfterViewInit() {
		adjustSize();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}


	setTab() {
		const currentTab = this.router.url;
		if (currentTab.includes("datos")) {
			this.selected = "datos";
		}
		if (currentTab.includes("tarjetas")) {
			this.selected = "tarjetas";
		}
		if (currentTab.includes("id")) {
			this.selected = "id";
		}
		if (currentTab.includes("domicilio")) {
			this.selected = "domicilio";
		}
		if (currentTab.includes("faxbackform")) {
			this.selected = "faxbackform";
		}
	}

	setCompletedInit() {
		this.vDatos = this.sessionService.isDataComplete() ? "verificado" : "por_subir";
		this.vCard = "por_subir";
		this.vId = "por_subir";
		this.vRes = "por_subir";
		this.vFbf = "por_subir";
		const cardDocs: JugadorDocumentosModel[] = this.allDocuments.filter(x => x.tipo_documento_id === 1);
		const fbDocs: JugadorDocumentosModel[] = this.allDocuments.filter(x => x.tipo_documento_id === 3);
		const IDDocs: JugadorDocumentosModel = this.allDocuments.find(x => x.tipo_documento_id === 2);
		const resDocs: JugadorDocumentosModel = this.allDocuments.find(x => x.tipo_documento_id === 5);
		if (cardDocs.length > 0) {
			this.vCard = "verificando";
			this.vCard = !!cardDocs.find(x => x.estado === "APROBADO") ? "verificado" : "verificando";
			this.vCard = !!cardDocs.find(x => x.estado === "RECHAZADO") ? "rechazado" : this.vCard;
		}
		if (fbDocs.length > 0) {
			this.vFbf = "verificando";
			this.vFbf = !!cardDocs.find(x => x.estado === "APROBADO") ? "verificado" : "verificando";
			this.vFbf = !!cardDocs.find(x => x.estado === "RECHAZADO") ? "rechazado" : this.vCard;
		}
		if (IDDocs) {
			this.vId = "verificando";
			switch (IDDocs.estado) {
				case "SUBIDO":
					this.vId = "verificando";
					break;
				case "APROBADO":
					this.vId = "verificado";
					break;
				case "RECHAZADO":
					this.vId = "rechazado";
					break;
				default:
					break;
			}
		}
		if (resDocs) {
			this.vRes = "verificando";
			switch (resDocs.estado) {
				case "SUBIDO":
					this.vRes = "verificando";
					break;
				case "APROBADO":
					this.vRes = "verificado";
					break;
				case "RECHAZADO":
					this.vRes = "rechazado";
					break;
				default:
					break;
			}
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT ()
	onSelect(tab) {
		this.selected = tab;
		this.location.replaceState("/cajero/verificacion/" + tab);
	}

	getDocumentosJugador() {
		this.store.dispatch(CajeroAction.getDocumentoJugador());
	}

}
