<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover coverB" id="w-cover-login" [class.active]="wActive==true" (swipedown)="onCloseW()">
	<!-- (click)="noClick($event)" -->

	<div class="window" id="login">


		<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape full-h">
			<div class="btn-window-close" (click)="onCloseW()">
				<div class="btn-window-close-bar"></div>
			</div>
			<p class="w-title-cover txt-ssm txt-w tac">{{'INICIAR_SESSION' | translate}}</p>

			<div class="register-container flex-y2">

				<div class="p-15">
					<input class="inp2 inp2-nor full-w mb-2" placeholder="{{'NOMBRE_USUARIO' | translate}}" type="text"
						[(ngModel)]="username">
					<input class="inp2 inp2-nor full-w mb-2" placeholder="{{'CONTRASENNA' | translate}}" type="password"
						(keyup.enter)="onLogIn()" [(ngModel)]="password">
					<button (click)="onLogIn()" class="btn2 btn2-lg btn2-1st btn2-lg-icon btn2-log full-w mb-4"
						[disabled]="!isValid()" type="button">
						{{'INGRESA' | translate}}
						<img src="../../../../assets/images/icon-b-log-c.svg" alt="ingresar">
					</button>
					<button (click)="onLogInFB()" class="btn2 btn2-lg btn2-fb btn2-lg-icon full-w" type="button">
						{{'INGRESA' | translate}}
						<img src="../../../../assets/images/icon-b-fb-c.svg" alt="ingresar facebook">
					</button>
					<div class="cb"></div>
				</div>

				<div class="px-7">
					<img src="../../../../assets/images/bitwild-logo-k.svg" alt="Bitwild">
				</div>


				<div class="register">
					<div class="pl-15 pb-2">
						<button class="btn-lang-sm txt-x1" (click)="onSelectLanguage()">
							<img src="../../../../assets/images/icon-lang.svg" class="icon" alt="icon">
							{{'ESP'}}
						</button>
					</div>
					<div class="p-15 bg-g0">
						<div class="col col-6 pr-05">
							<p class="label-lg ff-cnd-portrait">{{'NO_TIENES_CUENTA' | translate}}</p>
						</div>
						<div class="col col-6 pl-05">
							<button (click)="onGoRegister()" class="btn2 btn2-lg btn2-2nd btn2-lg-icon full-w pl-4"
								type="button">
								{{'REGISTRATE' | translate}}
								<img src="../../../../assets/images/icon-b-log-cw.svg" alt="registro">
							</button>
						</div>
						<div class="cb"></div>
					</div>
				</div>

			</div>

		</div>

		<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

	</div>

</div>
