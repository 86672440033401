<section class="wider tabbed" id="movimientos">
	<div class="section-header">
		<div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
			<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
		</div>

		<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="section-title">
				<div class="txt-snor pr-1">{{ 'HISTORIAL_DE_MOVIMIENTOS' | translate }}</div>
				<img src="../../../../assets/images/icon-m-retiro.svg" class="section-icon">
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>


	<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
	<div class="hidden-landscape full-h">
		<div class="col col-3 full-h">
			<table class="tbl2 tbl2-lg tbl2-striped striped-alt tbl-vert tbl2-head">
				<tr class="">
					<th>{{ 'MOVIMIENTOS' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'FECHA' | translate }}</th>
				</tr>
				<tr>
					<th class="th-2x">{{ 'CONCEPTO' | translate }}</th>
				</tr>
				<tr>
					<th class="th-2x">{{ 'DESCRIPCION' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'ESTADO' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'MONEDA' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'IMPORTE' | translate }}</th>
				</tr>
			</table>
		</div>
		<div class="col col-9 full-h of-x2">
			<div class="tbl2 tbl2-lg tbl2-striped tbl-vert faux-tbl-vert bg-g1" #divContenerdor
				*ngIf="movimientosHistorial.length > 0" infiniteScroll [horizontal]="true"
				[infiniteScrollDistance]="0.1" [infiniteScrollUpDistance]="0.1" [infiniteScrollThrottle]="50"
				[alwaysCallback]="true" (scrolled)="onScroll()" (scrolledUp)="onScrollUp()" [scrollWindow]="false">
				<!-- EMPTY -->
				<div class="col tbl2-col-empty tac">
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="cb"></div>
				</div>
				<!-- / EMPTY -->
				<!-- DATA -->
				<div class="col tac">
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="cb"></div>
				</div>
				<div class="col tbl2-col-n tac" *ngFor="let mov of movimientosHistorial; let i = index;">
					<div class="td">{{mov.id}}</div>
					<div class="td">
						<span>{{mov.fecha.split(" ")[0]}} <br>
							<span class="t-sm wordBreak">{{mov.fecha.split(" ")[1]}}</span></span>
					</div>
					<div class="td td-2x">{{mov.concepto}}</div>
					<div class="td td-2x">
						<span class="txt-sm wordBreak">{{mov.descripcion}}</span>
					</div>
					<div class="td">
						{{ mov.estado }}
					</div>
					<div class="td">{{ mov.moneda.toUpperCase() }}</div>
					<div class="td">{{mov.importe | number: "1.2-8"}}</div>
					<div class="cb"></div>
				</div>
				<!-- / DATA -->
			</div>
			<div class="flex-center full" *ngIf="movimientosHistorial.length < 1">
				<div class="bg-g1 p-2">{{ 'NO_HAY_MOVIMIENTOS_LISTADO' | translate }}</div>
			</div>
		</div>
	</div>
	<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->

</section>
<!-- - - - - - - - - - - - - - - - - - - / LANDSCAPE -->