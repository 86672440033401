import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import * as afiliadosActions from './afiliados.actions';
import { AfiliadosService } from "../services/afiliados.service";
import { Store } from '@ngrx/store';
import * as authAction from "../../authetication/redux/authentication.actions";
import { AppState, DataSingleWithExpireTime } from '../../shared/redux/general.reducers';
import { HomeService } from 'src/app/home/services/home.service';
import { ResumenPeriodoAfiliadosModel } from 'src/app/shared/models/resumen-periodo-afiliados.model';
import * as generalAction from "../../shared/redux/general.actions";

@Injectable()
export class AfiliadosEffects {
	private resumenPeriodoActual: DataSingleWithExpireTime<ResumenPeriodoAfiliadosModel> = { value: null, expire: 0 };

	constructor(
		private actions$: Actions,
		private homeService: HomeService,
		private afiliadosService: AfiliadosService,
		private store: Store<AppState>
	) {
		this.store.select((state) => state).subscribe(state => {
			this.resumenPeriodoActual = state.afiliado.resumen_periodo_actual;
		});
	}

	afiliadosCreateAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(afiliadosActions.afiliadosCreateAccount),
			exhaustMap(action =>
				this.afiliadosService.postNewAfiliadoUser(action.usuario).pipe(
					map(data => afiliadosActions.afiliadosCreateAccountCompletado(data)),
					catchError(error => of(authAction.terminar_state({ error })))
				)
			)
		)
	);

	afiliadosCreateCampanna$ = createEffect(() =>
		this.actions$.pipe(
			ofType(afiliadosActions.afiliadosCreateCampanna),
			exhaustMap(action =>
				this.afiliadosService.postNewCampaign({ nombre: action.nombre }).pipe(
					map(data => { return afiliadosActions.afiliadosCreateCampannaCompletado({ data }) }),
					catchError(error => of(authAction.terminar_state({ error })))
				)
			)
		)
	);

	getAfiliadoAccountAuth$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.obtenerUsuarioLogueadoCompletado),
			exhaustMap(data => {
				return of(afiliadosActions.afiliadosGetAccountAuthCompletado(data.data.user_data.jugador.afiliado))
			}
			)
		)
	);

	afiliadosGetResumenPeriodoActual$ = createEffect(() =>
		this.actions$.pipe(
			ofType(afiliadosActions.afiliadosGetResumenPeriodoActual),
			exhaustMap(action => {
				if (this.resumenPeriodoActual.expire == 0 || new Date().getTime() > this.resumenPeriodoActual.expire) {
					return this.homeService.getHomeAfiliadosData(null).pipe(
						map(data => afiliadosActions.afiliadosGetResumenPeriodoActualCompletado(data)),
						catchError(error => of(authAction.terminar_state({ error })))
					)
				} else {
					return of(generalAction.localDataLoad({ dataName: 'Afiliados Resumen Periodo Actual.' }))
				}
			})
		)
	);

	afiliadosGetCampannas$ = createEffect(() =>
		this.actions$.pipe(
			ofType(afiliadosActions.afiliadosGetCampanna),
			exhaustMap(action => this.afiliadosService.getAllCampaigns(action.filter).pipe(
				map(data => afiliadosActions.afiliadosGetCampannaCompletado({ data })),
				catchError(error => of(authAction.terminar_state({ error })))
			)
			)
		)
	);

	afiliadosGetTrafico$ = createEffect(() =>
		this.actions$.pipe(
			ofType(afiliadosActions.afiliadosGetTrafico),
			tap((action) => this.store.dispatch(generalAction.showLoader({
				httpRequestLoader: true,
				tipo: action.type
			}))),
			exhaustMap(action => this.afiliadosService.getAllTraffic(action.filter).pipe(
				map(data => {
					this.store.dispatch(generalAction.hideLoader({
						httpRequestLoader: true,
						tipo: action.type
					}));
					return afiliadosActions.afiliadosGetTraficoCompletado({ data })
				}),
				catchError(error => of(authAction.terminar_state({ error })))
			)
			)
		)
	);

	afiliadosGetComisionesPeriodoActual$ = createEffect(() =>
		this.actions$.pipe(
			ofType(afiliadosActions.afiliadosGetPeriodoActualComisiones),
			tap((action) => this.store.dispatch(generalAction.showLoader({
				httpRequestLoader: true,
				tipo: action.type
			}))),
			exhaustMap(action => this.afiliadosService.getAllComisionesByPeriod(null).pipe(
				map(data => {
					this.store.dispatch(generalAction.hideLoader({
						httpRequestLoader: true,
						tipo: action.type
					}));
					return afiliadosActions.afiliadosGetPeriodoActualComisionesCompletado({ data })
				}),
				catchError(error => of(authAction.terminar_state({ error })))
			)
			)
		)
	);

	afiliadosGetComisionesBatch$ = createEffect(() =>
		this.actions$.pipe(
			ofType(afiliadosActions.afiliadosGetBatchComisiones),
			tap((action) => this.store.dispatch(generalAction.showLoader({
				httpRequestLoader: true,
				tipo: action.type
			}))),
			exhaustMap(action => this.afiliadosService.getAllComisionesByBatch(action.filter).pipe(
				map(data => {
					this.store.dispatch(generalAction.hideLoader({
						httpRequestLoader: true,
						tipo: action.type
					}));
					return afiliadosActions.afiliadosGetBatchComisionesCompletado({ data })
				}),
				catchError(error => of(authAction.terminar_state({ error })))
			)
			)
		)
	);

	afiliadosGetBalances$ = createEffect(() =>
		this.actions$.pipe(
			ofType(afiliadosActions.afiliadosGetBalances),
			tap((action) => this.store.dispatch(generalAction.showLoader({
				httpRequestLoader: true,
				tipo: action.type
			}))),
			exhaustMap(action => this.afiliadosService.getAllBalancesAfiliados(null).pipe(
				map(data => {
					this.store.dispatch(generalAction.hideLoader({
						httpRequestLoader: true,
						tipo: action.type
					}));
					return afiliadosActions.afiliadosGetBalancesCompletado({ data })
				}),
				catchError(error => of(authAction.terminar_state({ error })))
			)
			)
		)
	);

	afiliadosAddRetiro$ = createEffect(() =>
		this.actions$.pipe(
			ofType(afiliadosActions.afiliadosAddRetiro),
			tap((action) => this.store.dispatch(generalAction.showLoader({
				httpRequestLoader: true,
				tipo: action.type
			}))),
			exhaustMap(action => this.afiliadosService.postRetiroAgregarBalance(action.retiroData).pipe(
				map(data => {
					this.store.dispatch(generalAction.hideLoader({
						httpRequestLoader: true,
						tipo: action.type
					}));
					return afiliadosActions.afiliadosAddRetiroCompletado({ data })
				}),
				catchError(error => of(authAction.terminar_state({ error })))
			)
			)
		)
	);

}
