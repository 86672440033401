import {Action, createReducer, on} from "@ngrx/store";
import * as GeneralActions from "../../shared/redux/general.actions";
import {AuthState} from "../../authetication/redux/authentication.reducers";
import {BonoState} from "../../bonos/redux/bonos.reducers";
import {GameState} from "../../games/redux/games.reducers";
import {CajeroState} from "../../cajero/redux/cajero.reducers";
import {AfiliadosState} from "../../afiliados/redux/afiliados.reducers";
import {TorneoModel} from "../models/torneo.model";
import {GeneralUtil} from "../utils/general.util";
import {MyControlModel} from "../models/control.model";
import {NotificationsModel} from "../models/notifications.model";
import {CircuitoJugadorModel} from "../models/circuitos.model";
import {CuentaState} from "../../cuenta/redux/cuenta.reducers";
import {FilterModel} from "../models/filter.model";
import {TicketsModel} from "../models/tickets.model";
import {SessionServices} from "../services/session.services";
import Swal from "sweetalert2";
import {IdiomaModel} from "../models/idioma.model";

export class AppState {
	auth: AuthState;
	general: GeneralState;
	bonos: BonoState;
	games: GameState;
	cajero: CajeroState;
	afiliado: AfiliadosState;
	cuenta: CuentaState;
}

export interface DataWithExpireTime<T> {
	value: T[];
	expire: number;
}

export interface DataWithFilter<T> {
	value: T[];
	filter: FilterModel;
}

export interface DataSingleWithExpireTime<T> {
	value: T;
	expire: number;
}

export class GeneralState {
	showLoader: boolean;
	afiliadosMode: boolean;
	incompleteRequests: number;
	peticionesActivas: { nombre: string, timestamp: number }[];
	myMenu: MyControlModel;
	notificaciones: NotificationsModel[];
	torneoActivo: DataSingleWithExpireTime<TorneoModel>;
	circuitoActivo: DataSingleWithExpireTime<CircuitoJugadorModel>;
	tickets: DataWithFilter<TicketsModel>;
	estadoPaises: any[];
	selectedLang: IdiomaModel;
	langs: IdiomaModel[];
	showCircuitoActivo: boolean;

	constructor() {
		this.showLoader = true;
		this.afiliadosMode = false;
		this.incompleteRequests = 0;
		this.peticionesActivas = [];
		this.estadoPaises = [];
		this.showCircuitoActivo = false;
		this.selectedLang = {id: 1, nombre: "Spanish", siglas: "es", imagen: "", default: true, active: true};
		this.langs = [];
		this.myMenu = {
			menu: true,
			catalog: true,
			exchanger: true,
			back: true,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: false,
			notificaciones: true,
			show_notificaciones: false,
			sub_menu_activo: "",
			sub_sub_menu_activo: "",
			cuenta_verificada: true
		};
		this.torneoActivo = {value: null, expire: 0};
		this.notificaciones = [];
		this.circuitoActivo = {value: null, expire: 0};
		this.tickets = {
			value: [],
			filter: new FilterModel()
		};

	}
}

const GeneralReducer = createReducer(
	new GeneralState(),
	on(GeneralActions.showLoader, (state, data) => {
		let incompletRequestsCount = state.incompleteRequests;
		let showLoader = true;
		let peticionesActivas = [...state.peticionesActivas];
		if (data.httpRequestLoader) {
			incompletRequestsCount = state.incompleteRequests + 1;
			showLoader = incompletRequestsCount > 0 ? true : false;
			// nuevo cod
			peticionesActivas.push({nombre: data.tipo, timestamp: new Date().getTime()});
		}


		return {
			...state,
			showLoader: showLoader,
			incompleteRequests: incompletRequestsCount,
			peticionesActivas: peticionesActivas
		};
	}),
	on(GeneralActions.hideLoader, (state, data) => {
		let incompletRequestsCount = state.incompleteRequests;
		let showLoader = false;
		let nuevoArrregloPeticionesAcrivas = [];
		if (data.httpRequestLoader) {
			incompletRequestsCount = state.incompleteRequests <= 0 ? 0 : state.incompleteRequests - 1;
			showLoader = incompletRequestsCount > 0 ? true : false;
			state.peticionesActivas.forEach(pet => {
				if (pet.nombre != data.tipo) {
					nuevoArrregloPeticionesAcrivas.push(pet);
				}
			});
		} else {
			showLoader = state.incompleteRequests > 0 ? true : false;
		}


		return {
			...state,
			showLoader: showLoader,
			incompleteRequests: incompletRequestsCount,
			peticionesActivas: nuevoArrregloPeticionesAcrivas
		};
	}),
	on(GeneralActions.hideLoaderOnPeticionesColgadas, (state, data) => {
		let incompletRequestsCount = state.incompleteRequests;
		let showLoader = false;
		let nuevoArrregloPeticionesAcrivas = [];
		state.peticionesActivas.forEach(pet => {
			let tiempoactual = new Date().getTime();
			let diferencia = tiempoactual - pet.timestamp;
			if (diferencia >= 15000) {
				incompletRequestsCount = state.incompleteRequests <= 0 ? 0 : state.incompleteRequests - 1;
			} else {
				nuevoArrregloPeticionesAcrivas.push(pet);
			}
		});
		if (nuevoArrregloPeticionesAcrivas.length == 0 && incompletRequestsCount > 0) {
			incompletRequestsCount = 0;
		}
		showLoader = incompletRequestsCount > 0 ? true : false;

		return {
			...state,
			showLoader: showLoader,
			incompleteRequests: incompletRequestsCount,
			peticionesActivas: nuevoArrregloPeticionesAcrivas
		};
	}),
	on(GeneralActions.localDataLoad, (state, data) => {
		if (data.dataName == "Clasificaciones Visuales + Juegos") {
			let incompletRequestsCount = state.incompleteRequests <= 0 ? 0 : state.incompleteRequests - 1;
			let showLoader = incompletRequestsCount > 0 ? true : false;
			return {...state, showLoader: showLoader, incompleteRequests: incompletRequestsCount};
		}
		return {...state};
	}),
	on(GeneralActions.getTorneoActivo, (state) => ({
		...state
	})),
	on(GeneralActions.torneoActivoAuthCompletado, (state, {data}) => ({
		...state,
		torneoActivo: GeneralUtil.setWithExpiry(data.torneo, 1200000),/* 20 minutos */
	})),
	on(GeneralActions.getTorneoActivoCompletado, (state, {data}) => ({
		...state,
		torneoActivo: GeneralUtil.setWithExpiry(data.torneo, 1200000),/* 20 minutos */
	})),
	on(GeneralActions.getCircuitoActivo, (state) => ({
		...state
	})),
	on(GeneralActions.getCircuitoActivoCompletado, (state, {data}) => ({
		...state,
		circuitoActivo: GeneralUtil.setWithExpiry(data ? data : null, 600000),/* 10 minutos */
	})),
	on(GeneralActions.generalLogoutCompletado, (state) => {
		return {
			...new GeneralState(),
			torneoActivo: state.torneoActivo,
			showLoader: state.showLoader,
			afiliadosMode: state.afiliadosMode
		};
	}),
	on(GeneralActions.setMyMenu, (state, data) => {
		let menu = data.menu;
		if (!menu.cuenta_verificada) {
			menu = {...state.myMenu};
		}
		return {
			...state,
			myMenu: menu
		};
	}),
	on(GeneralActions.getNotificaciones, (state) => {
		return {...state};
	}),
	on(GeneralActions.getNotificacionesCompletado, (state, {data}) => {
		return {...state, notificaciones: data.data};
	}),
	on(GeneralActions.setAfiliadosMode, (state, data) => {
		return {...state, afiliadosMode: data.afiliadosMode};
	}),
	on(GeneralActions.pusherEventNuevaNotificacion, (state, data) => {
		let arregloNuevo = [...state.notificaciones];
		arregloNuevo.unshift(data);
		return {
			...state,
			notificaciones: arregloNuevo
		};
	}),
	on(GeneralActions.marcarNotificacionComoLeidoCompletado, (state, data) => {
		let arregloNuevo = [];
		state.notificaciones.forEach(element => {
				let nuevoElemento = {...element};
				if (element.id === data.data.id * 1) {
					nuevoElemento.leido = true;
				}
				arregloNuevo.push(nuevoElemento);
			}
		);
		return {
			...state,
			notificaciones: arregloNuevo
		};
	}),
	on(GeneralActions.eliminarNotificacionCompletado, (state, data) => {
		let arregloNuevo = [];
		state.notificaciones.forEach(element => {
				if (element.id != data.data.id * 1) {
					arregloNuevo.push(element);
				}
			}
		);
		return {
			...state,
			notificaciones: arregloNuevo
		};
	}),
	on(GeneralActions.getTicketsCompletado, (state, {data}) => {
		let nuevoArray = [...state.tickets.value];
		let nuevoFiltro = {...state.tickets.filter};

		nuevoFiltro.total = data.total;
		nuevoFiltro.last_page = data.last_page;
		nuevoFiltro.page = data.current_page;
		nuevoFiltro.from = data.from;
		nuevoFiltro.to = data.to;

		if ((data.last_page == data.current_page) && (data.data.length > 0) && (data.data.length <= 7) && data.current_page != 1) {
			nuevoArray = nuevoArray.concat(data.data);
			nuevoFiltro.amount_last_page = data.data.length;
		} else {
			nuevoArray = [];
			nuevoArray = data.data;
			nuevoFiltro.amount_last_page = 0;
		}

		return {
			...state,
			tickets: GeneralUtil.setWithFilter(nuevoArray, nuevoFiltro),
		};
	}),
	on(GeneralActions.getTickets, (state, {filter}) => ({
		...state,
		tickets: GeneralUtil.setWithFilter(state.tickets.value, filter),
	})),
	on(GeneralActions.addNotaTicketCompletado, (state, {data}) => {
		let nuevoArray = [];

		state.tickets.value.forEach((element) => {
			if (element.id != data.ticket.id) {
				nuevoArray.push(element);
			} else {
				nuevoArray.push(data.ticket);
			}
		});
		return {
			...state,
			tickets: GeneralUtil.setWithFilter(nuevoArray, state.tickets.filter),
		};
	}),
	on(GeneralActions.getEstadosPaisesCompletado, (state, {data}) => {
		return {
			...state,
			estadoPaises: data.data
		};
	}),
	on(GeneralActions.getLangsCompletado, (state, {data}) => {
		return {
			...state,
			langs: data.data
		};
	}),
	on(GeneralActions.changeLang, (state, {lang}) => {
		return {
			...state,
			selectedLang: lang
		};
	}),
	on(GeneralActions.setUserLangCompletado, (state, {data}) => {
		return {
			...state,
			selectedLang: data.idioma
		};
	}),
	on(GeneralActions.showCircuitoActivo, (state) => {
		return {
			...state,
			showCircuitoActivo: true
		};
	}),
	on(GeneralActions.hideCircuitoActivo, (state) => {
		return {
			...state,
			showCircuitoActivo: false
		};
	})
);

export function reducer(state: GeneralState | undefined, action: Action) {
	return GeneralReducer(state, action);
}

