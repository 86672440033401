import { Routes } from "@angular/router";
import { WalletComponent } from "./components/wallet/wallet.component";
import { ExchangerComponent } from "./components/exchanger/exchanger.component";
import { VerificacionesComponent } from "./components/verificacion/verificaciones/verificaciones.component";
import { RetirosComponent } from "./components/retiros/retiros.component";
import { VerifiedGuard } from "../home/guards/verified.guard";

export const CajeroRoutes: Routes = [
	{
		path: "",
		children: [
			{ path: "wallet", component: WalletComponent },
			{
				path: "exchanger",
				component: ExchangerComponent,
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			},
			{
				path: "verificacion", component: VerificacionesComponent,
				children: [
					{ path: "", redirectTo: "datos", pathMatch: "full" },
					{ path: "datos", component: VerificacionesComponent },
					{ path: "tarjetas", component: VerificacionesComponent },
					{ path: "id", component: VerificacionesComponent },
					{ path: "domicilio", component: VerificacionesComponent },
					{ path: "faxbackform", component: VerificacionesComponent }
				],
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			},
			{
				path: "retiros",
				component: RetirosComponent,
				canActivate: [VerifiedGuard],
				canLoad: [VerifiedGuard]
			}
		]
	},
];
