import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HelpRoutes} from "./help.routing";
import {HelpService} from "./services/help.service";
import {SharedModule} from "../shared/shared.module";
import {HelpTicketsComponent} from "./components/help-tickets/help-tickets.component";
import {WNewTicketComponent} from "./components/w-new-ticket/w-new-ticket.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {WHelpComponent} from "./components/w-help/w-help.component";
import { WNewMsgComponent } from './components/w-new-msg/w-new-msg.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		RouterModule.forChild(HelpRoutes),
		SharedModule,
		InfiniteScrollModule,
		TranslateModule
	],
	providers: [
		NgbActiveModal,
		HelpService
	],
	declarations: [
		HelpTicketsComponent,
		WNewTicketComponent,
		WHelpComponent,
		WNewMsgComponent
	],
	exports: [
		WNewTicketComponent
	]
})
export class HelpModule {
}
