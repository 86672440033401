<div class="w-cover coverB" id="reglas-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">
   <!-- -->

	<div class="window" id="reglas">

		<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="btn-window-close" (click)="onCloseW()">
				<div class="btn-window-close-bar"></div>
			</div>
			<p class="w-title-cover txt-sm txt-w tac">{{bono.nombre}}</p>
			<div class="px-15 pt-1">
				<p class="w-title-cover txt-sm txt-g9 mb-2">{{bono.descripcion}}</p>

				<div class="col col-3 bg-g3">
					<table class="tbl2 tbl2-md tbl2-striped tbl-vert tbl2-head">
						<tr class="">
							<th>{{'MONEDA' |translate}}</th>
						</tr>
						<tr>
							<th>{{'DEPOSITO_MINIMO' |translate}}</th>
						</tr>
						<tr>
							<th>{{'ROLLOVER' |translate}}</th>
						</tr>
						<tr>
							<th>{{'RETIRO_MAXIMO' |translate}}</th>
						</tr>
						<tr>
							<th>{{'MONEDA_EQUIV' |translate}}</th>
						</tr>
						<tr>
							<th *ngIf="tipo === 'BONOJUGADORAPTO'">{{'DEPOSITO_MINIMO_EQUIV' |translate}}</th>
							<th *ngIf="tipo === 'BONOJUGADOR'"></th>
						</tr>
						<tr>
							<th *ngIf="tipo === 'BONOJUGADORAPTO'">{{'RETIRO_MAXIMO_EQUIV' |translate}}</th>
							<th *ngIf="tipo === 'BONOJUGADOR'"></th>
						</tr>
					</table>
				</div>
				<div class="col col-9 full-h of-x2">
               <div class="tbl2 tbl2-md tbl2-striped tbl-vert faux-tbl-vert bg-g1"> <!-- *ngIf="retiroSolicitudesAfiliado.length > 0" -->
                  <!-- EMPTY -->
                  <div class="col tbl2-col-empty tac">
                     <div class="td">&nbsp;</div>
                     <div class="td">&nbsp;</div>
                     <div class="td">&nbsp;</div>
                     <div class="td">&nbsp;</div>
                     <div class="td">&nbsp;</div>
                     <div class="td">&nbsp;</div>
                     <div class="td">&nbsp;</div>
                     <div class="cb"></div>
                  </div>
                  <!-- -->
                  <!-- DATA -->
                  <div class="col tbl2-col-n2 tac" *ngFor="let item of bono.detalles; let i = index;">
                     <div class="td">{{ item.siglas.toUpperCase() }}</div>
                     <div class="td">{{item.deposito_minimo | number: '1.2-4'}}</div>
                     <div class="td">&times; {{item.rollover | number: '1.2-2'}}</div>
                     <div class="td">{{item.maximo_retiro | number: '1.2-2'}}</div>
                     <div class="td txt-g8">{{item.siglas.toUpperCase()}}</div>
                     <div class="td txt-g8">{{item.deposito_minimo_equivalente | number: '1.2-2'}}</div>
                     <div class="td txt-g8">{{item.maximo_retiro_equivalente | number: '1.2-2'}}</div>
                     <div class="cb"></div>
                  </div>
                  <!-- / DATA -->
               </div>
            </div>
            <div class="cb"></div>
         </div>
         <div class="p-2">
            <button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCloseW()">{{'CERRAR' |translate}}</button>
         </div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>
</div>
