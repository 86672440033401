import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BonosModel} from "../../../shared/models/bonos.model";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import * as BonoAction from "../../../bonos/redux/bonos.actions";

declare function adjustSize(): any;

@Component({
	selector: "app-w-activate-bono-devolucion",
	templateUrl: "./w-activate-bono-devolucion.component.html",
	styleUrls: ["./w-activate-bono-devolucion.component.css"]
})
export class WActivateBonoDevolucionComponent implements OnInit, OnDestroy {

	@Input() bono: BonosModel;
	walletCurrency: WalletGeneralModel[];
	selectedCoin: number;
	subscription = new Subscription();

	constructor(
		public activeModal: NgbActiveModal,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.selectedCoin = -1;
		this.subscription.add(this.store.subscribe((state) => {
			this.walletCurrency = state.cajero.wallets;
		}));
	}

	ngOnDestroy(): void {
		if(this.subscription){
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW(event) {
		event.stopPropagation();
		this.activeModal.close();
		adjustSize();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW(event);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATE ()
	onActivate(event) {
		event.stopPropagation();
		const tipo = "DEVOLUCION";
		const param = {
			tipo,
			data: {
				bono_jugador_apto_id: this.bono.id,
				moneda_id: this.selectedCoin
			}
		};
		this.postActivateBono(param);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT COIN ()
	onSelectCoin(event, id) {
		event.stopPropagation();
		this.selectedCoin = id;
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	postActivateBono(param) {
		this.activeModal.close(true);
		this.store.dispatch(BonoAction.activarBonos({ param : param}));
	}

}
