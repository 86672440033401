import {NgModule} from "@angular/core";
import {HomeRoutes} from "./home.routing";
import {IdiomasComponent} from "./components/idiomas/idiomas.component";
import {HomeAfiliadosComponent} from "./components/home-afiliados/home-afiliados.component";
import {RouterModule} from "@angular/router";
import {WCallRequestComponent} from "../shared/w-call-request/w-call-request.component";
import {CommonModule} from "@angular/common";
import {CircuitosService} from "./services/circuitos.service";
import {HomeJugadorComponent} from "./components/home-jugador/home-jugador.component";
import {CircuitoComponent} from "./components/circuito/circuito.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HomeVisitorComponent} from "./components/home-visitor/home-visitor.component";
import {WTerminosCondicionesComponent} from "../shared/w-terminos-condiciones/w-terminos-condiciones.component";
import {WWwTermsComponent} from "./components/w-ww-terms/w-ww-terms.component";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {HomeAfiliadosVisitorComponent} from "./components/home-afiliados-visitor/home-afiliados-visitor.component";
import {SharedModule} from "../shared/shared.module";
import {HomeService} from "./services/home.service";
import {WHomegameInfoComponent} from "./components/home-visitor/w-homegame-info/w-homegame-info.component";
import {WConversionCoinComponent} from "./components/w-conversion-coin/w-conversion-coin.component";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { FooterJugadorComponent } from './components/footer-jugador/footer-jugador.component';
import {TranslateModule} from "@ngx-translate/core";
import { WCircuitoNodeMsgComponent } from './components/w-circuito-node-msg/w-circuito-node-msg.component';


@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		RouterModule.forChild(HomeRoutes),
		SharedModule,
		InfiniteScrollModule,
		LazyLoadImageModule,
		TranslateModule,
	],
	providers: [
		NgbActiveModal,
		CircuitosService,
		HomeService
	],
	declarations: [
		HomeJugadorComponent,
		HomeVisitorComponent,
		WHomegameInfoComponent,
		WTerminosCondicionesComponent,
		WCallRequestComponent,
		WConversionCoinComponent,
		HomeAfiliadosVisitorComponent,
		HomeAfiliadosComponent,
		CircuitoComponent,
		WWwTermsComponent,
		IdiomasComponent,
		FooterJugadorComponent,
		WCircuitoNodeMsgComponent
	],
	exports: []
})
export class HomeModule {
}
