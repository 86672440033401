<div class="w-cover coverB" id="w-add-balance-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">

    <div class="window window-pb8 pt-1" id="w-add-balance">
        <!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
        <div class="hidden-landscape">
            <div class="btn-window-close" (click)="onCloseW()">
                <div class="btn-window-close-bar"></div>
            </div>
            <p class="w-title-cover txt-sm txt-w tac mb-1">{{'ENVIO_BALANCE_JUGADOR' | translate}}</p>
            <div class="px-1 pb-3">
                <div class="bg-g1 p-15">
                    <p class="txt-nor lh-15 txt-gc taj">{{'BALACE_TOTAL_EN_MONEDA' |translate}} <span class="txt-c2">
							{{ balance.moneda_siglas.toUpperCase() }} </span> {{'BALACE_TOTAL_EN_MONEDA_CONTINUACION' | translate}}
                        .</p>
                </div>
            </div>
            <div class="btn2-actions px-2 pb-2">
                <div class="col col-6 pr-07">
                    <button type="button" class="btn2 btn2-lg btn2-g full-w"
                            (click)="onCancel($event)">{{'CANCELAR' |translate }}</button>
                </div>
                <div class="col col-6 pl-07">
                    <button type="button" class="btn2 btn2-lg btn2-1st full-w"
                            (click)="onTransferir($event)">{{'CONFIRMAR' |translate }}</button>
                </div>
                <div class="cb"></div>
            </div>
            <!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->
        </div>

    </div>
