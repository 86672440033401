import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BonosJugadorAptoModel, BonosModel} from "../../../shared/models/bonos.model";

declare function adjustSize(): any;

@Component({
	selector: "app-w-rules",
	templateUrl: "./w-rules.component.html",
	styleUrls: ["./w-rules.component.css"]
})
export class WRulesComponent implements OnInit {

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	@Input() bono: any;
	@Input() tipo: string;

	wActive: boolean = false;

	constructor(
		public activeModal: NgbActiveModal
	) {
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.wActive = true;
		}, 0);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	changeImg(event, newImg) {
		if (!newImg) {
			event.target.src = "../../../../assets/images/crypto/btc.svg";
		} else {
			event.target.src = newImg;
		}
	}

}
