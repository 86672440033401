import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormGroup} from "@angular/forms";
import {MonedaModel} from "../../../shared/models/wallet-currency.model";

declare function adjustSize(): any;

@Component({
	selector: "app-w-f-retiro",
	templateUrl: "./w-f-retiro.component.html",
	styleUrls: ["./w-f-retiro.component.css"]
})
export class WFRetiroComponent implements OnInit {

	@Input() searchForm: FormGroup;
	@Input() cryptoCurrency: MonedaModel[];

	constructor(
		public activeModal: NgbActiveModal
	) {
	}

	ngOnInit(): void {

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SET DATE W ()
	setDate(event) {
		const dateInp = event.target as HTMLInputElement;
		const dateVal = dateInp.value;
		const txtInp = dateInp.closest(".date").querySelector(".date-txt") as HTMLInputElement;
		txtInp.value = dateVal;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW(event) {
		event.stopPropagation();
		this.activeModal.close();
		adjustSize();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW(event);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON FILTER ()
	onFilter(event) {
		event.stopPropagation();
		this.activeModal.close(true);
	}

}
