import { Injectable } from "@angular/core";
import { CanActivate, CanLoad } from "@angular/router";
import { SessionServices } from "../../shared/services/session.services";
import { NotificationService } from "../../shared/services/notification.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
	providedIn: "root"
})
export class VerifiedGuard implements CanActivate, CanLoad {

	constructor(
		private notification: NotificationService,
		private sessionService: SessionServices,
		private translate: TranslateService
	) {
	}

	canLoad() {
		return true;
	}

	canActivate() {
		const player: any = this.sessionService.getPlayer();
		if (player) {
			if (player.estado_cuenta.toUpperCase() !== "ACTIVA") {
				let message = this.translate.instant('MSG_GUARD_VERIFICAR_CUENTA');
				let asunto = this.translate.instant('VERIFICAR');
				Swal.fire(this.notification.BuildError(message, asunto)).then();
				return false;
			}
			return true;
		} else {
			return false;
		}


	}

}
