<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover">

	<div class="window" id="filtro-retiros">
		<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="btn-window-close" (click)="onCancel($event)">
				<div class="btn-window-close-bar"></div>
			</div>
			<p class="w-title-cover txt-ssm txt-w tac">{{'FILTRAR_RESULTADOS' |translate}}</p>
			<div class="px-1">
				<div class="bg-g1 p-15 pb-2">
					<div class="col col-6 pr-07">
						<p class="txt-gc mb-05">{{'FECHA_DESDE' |translate}}:</p>
						<div class="pos-rel">
							<div class="date">
								<input class="inp2 inp2-nor full-w date-txt" placeholder="yyyy-mm-dd" type="text"
									formControlName="dateFrom" readonly>
								<input (change)="setDate($event)" class="inp2-nor full-w date-inp" type="date"
									formControlName="dateFrom">
							</div>
						</div>
					</div>
					<div class="col col-6 pl-07">
						<p class="txt-gc mb-05">{{'FECHA_HASTA' |translate}}:</p>
						<div class="pos-rel">
							<div class="date">
								<input class="inp2 inp2-nor inp2-4th full-w date-txt" placeholder="yyyy-mm-dd"
									type="text" formControlName="dateTo" readonly>
								<input (change)="setDate($event)" class="inp2-nor full-w date-inp" type="date"
									formControlName="dateTo">
							</div>
						</div>
					</div>
					<div class="cb mb-2"></div>
					<div class="col col-6 pr-07">
						<p class="txt-gc mb-05">{{'MONEDAS' |translate}}:</p>
						<div class="sel2">
							<select class="sel2 sel2-nor full-w" formControlName="moneda_id"
								(click)="$event.stopPropagation()">
								<option [value]="null">{{'TODAS' |translate}}</option>
								<option [value]="curr.id" *ngFor="let curr of cryptoCurrency">{{curr.siglas}}</option>
							</select>
						</div>
					</div>
					<div class="col col-6 pl-07">
						<p class="txt-gc mb-05">{{'ESTADOS' |translate}}:</p>
						<div class="sel2">
							<select class="sel2 sel2-nor full-w" formControlName="estado"
								(click)="$event.stopPropagation()">
								<option [value]="null">{{'TODOS' |translate}}</option>
								<option value="PENDIENTE">Pendiente</option>
								<option value="APROBADO">Aprobado</option>
								<option value="RECHAZADO">Rechazado</option>
								<option value="CANCELADO">Cancelado</option>
							</select>
						</div>
					</div>
					<div class="cb"></div>
				</div>
			</div>
			<div class="btn2-actions px-2 pb-2">
				<div class="col col-6 pr-07">
					<button type="button" class="btn2 btn2-lg btn2-g full-w"
						(click)="onCancel($event)">{{'CANCELAR' |translate}}</button>
				</div>
				<div class="col col-6 pl-07">
					<button type="button" class="btn2 btn2-lg btn2-1st full-w"
						(click)="onFilter($event)">{{'FILTRAR' |translate}}</button>
				</div>
				<div class="cb"></div>
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

	</div>

</div>
