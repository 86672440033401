<div class="w-cover coverB" id="home-conversion-coin-cover" [class.active]="wActive==true" (swipedown)="onCloseW()">
   <!--  (click)="onCloseW()" -->

	<div class="window" id="home-conversion-coin" (click)="noClick($event)">



		<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape full-h">
			<div class="btn-window-close" (click)="onCloseW()">
				<div class="btn-window-close-bar"></div>
			</div>
			<p class="w-title-cover txt-sm txt-w tac mb-1">{{'SELECCIONE_MONEDA_BONO' |translate}}</p>

			<div class="add-coin-container of-y2 px-15">
            <!-- COIN -->
            <ng-container *ngFor="let currency of cryptoCurrency; let i = index;">
               <div (click)="onSelectCoin($event, currency)" [class.selected]="selectedCoin && currency.id === selectedCoin.id" class="coin">
                  <div class="coin-icon">
                     <img class="" src="../../../../assets/images/crypto/{{currency.siglas.toLowerCase()}}.svg" (error)="changeImg($event, currency.imagen)">
                  </div>
                  <div class="coin-desc">
                     <span class="txt-nor">
                        <span class="txt-c2">{{currency.siglas.toUpperCase()}}</span><br>
                        {{currency.nombre}}</span>
                  </div>
               </div>
            </ng-container>
            <!-- / COIN -->
         </div>

         <div class="p-2">
            <div class="col col-6 pr-07">
               <button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCloseW()">{{'CANCELAR' |translate}}</button>
            </div>
            <div class="col col-6 pl-07">
               <button type="button" class="btn2 btn2-lg btn2-1st full-w" (click)="onNext($event)">{{'CONTINUAR' |translate}}</button>
            </div>
            <div class="cb"></div>
         </div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

	</div>

</div>
