import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class WindowsService {

	closeMenuSubject: Subject<any> = new Subject<any>();
	openCatalogSubject: Subject<any> = new Subject<any>();
	startPusherSubject: Subject<any> = new Subject<any>();

	toggleFilterSubject: Subject<any> = new Subject<any>();

	constructor() {
	}

	emitCloseMenuSubject(data) {
		this.closeMenuSubject.next(data);
	}

	emitOpenCatalogSubject(data) {
		this.openCatalogSubject.next(data);
	}

	emitStartPusherSubject(data) {
		this.startPusherSubject.next(data);
	}
}
