import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormControl, FormBuilder, FormGroup} from "@angular/forms";
import {MonedaModel} from "../../../shared/models/wallet-currency.model";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {FilterModel} from "../../../shared/models/filter.model";
import * as BonoAction from "../../../bonos/redux/bonos.actions";
import {TranslateService} from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-w-f-bonos",
	templateUrl: "./w-f-bonos.component.html",
	styleUrls: ["./w-f-bonos.component.css"]
})
export class WFBonosComponent implements OnInit, OnDestroy {

	tipos: { id: number; nombre: string }[];
	estados: { id: number; nombre: string }[];

	searchForm: FormGroup;
	filter: FilterModel;
	cryptoCurrency: MonedaModel[];
	subscription = new Subscription();
	fechaInicio: string = '';
	fechaFin: string = '';

	wActive: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private formBuilder: FormBuilder,
		private store: Store<AppState>,
		private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		this.subscription.add(
			this.store.subscribe((state) => {
				this.cryptoCurrency = state.cajero.cryptoMonedas.value;
				this.filter = state.bonos.bonosHistorial.filter;
			})
		);
		this.tipos = [
			{id: 1, nombre: this.translateService.instant("BONO_TIPO_DESPOSITO")},
			{id: 2, nombre:  this.translateService.instant("BONO_TIPO_GRATIS")},
			{id: 3, nombre:  this.translateService.instant("BONO_TIPO_DEVOLUCION")},
			];
		this.estados = [
			{id: 6, nombre:  this.translateService.instant("LIBERADO")},
			{id: 7, nombre:  this.translateService.instant("CANCELADO")},
			{id: 8, nombre:  this.translateService.instant("EXPIRADO")},
			{id: 10, nombre:  this.translateService.instant("FINALIZADO")}
		];
		this.initFilter();
		setTimeout(() => {
			this.wActive = true;
		}, 0);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initFilter() {
		let nombre_publico = '';
		let moneda_id = '';
		let tipo_bono_id = '';
		let estado_bono_id = '';
		let fecha_inicio = '';
		let fecha_fin = '';
		for (var i = 0; i < this.filter.filter.length; i++) {
			if (this.filter.filter[i]['atribute'] == 'nombre_publico') {
				nombre_publico = this.filter.filter[i]['value'].replace(/[%]/gi, '');
			}
			if (this.filter.filter[i]['atribute'] == 'moneda_id') {
				moneda_id = this.filter.filter[i]['value'];
			}
			if (this.filter.filter[i]['atribute'] == 'tipo_bono_id') {
				tipo_bono_id = this.filter.filter[i]['value'];
			}
			if (this.filter.filter[i]['atribute'] == 'estado_bono_id') {
				estado_bono_id = this.filter.filter[i]['value'];
			}
			if (this.filter.filter[i]['atribute'] == 'fecha_inicio') {
				fecha_inicio = this.filter.filter[i]['value'];
			}
			if (this.filter.filter[i]['atribute'] == 'fecha_fin') {
				fecha_fin = this.filter.filter[i]['value'];
			}
		}

		this.searchForm = this.formBuilder.group(
			{
				nombre_publico: new FormControl(nombre_publico, []),
				moneda_id: new FormControl(moneda_id, []),
				tipo_bono_id: new FormControl(tipo_bono_id, []),
				estado_bono_id: new FormControl(estado_bono_id, []),
				fecha_inicio: new FormControl(fecha_inicio, []),
				fecha_fin: new FormControl(fecha_fin, []),
			}
		);

		this.fechaInicio = fecha_inicio;
		this.fechaFin = fecha_fin;
	}

	setDate(event, tipo) {
		const dateInp = event.target as HTMLInputElement;
		const dateVal = dateInp.value;
		// const txtInp = dateInp.closest(".date").querySelector(".date-txt") as HTMLInputElement;
		// txtInp.value = dateVal;
		if (tipo == "fecha_inicio") {
			this.fechaInicio = dateVal;
		} else {
			this.fechaFin = dateVal;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON FILTER ()
	onFilter(event) {
		event.stopPropagation();
		let filter = new FilterModel();
		const searchModel = this.searchForm.value;
		if (searchModel.tipo_bono_id) {
			filter.filter.push({
				atribute: "tipo_bono_id",
				operator: "=",
				value: searchModel.tipo_bono_id
			});
		}
		if (searchModel.fecha_fin) {
			filter.filter.push({
				atribute: "fecha_activacion",
				operator: ">=",
				value: searchModel.fecha_fin
			});
		}
		if (searchModel.fecha_inicio) {
			filter.filter.push({
				atribute: "fecha_activacion",
				operator: "<=",
				value: searchModel.fecha_inicio
			});
		}
		if (searchModel.moneda_id) {
			filter.filter.push({
				atribute: "moneda_id",
				operator: "=",
				value: searchModel.moneda_id
			});
		}
		if (searchModel.estado_bono_id) {
			filter.filter.push({
				atribute: "estado_bono_id",
				operator: "=",
				value: searchModel.estado_bono_id
			});
		}
		if (searchModel.nombre_publico) {
			filter.filter.push({
				atribute: "nombre_publico",
				operator: "like",
				value: "%" + searchModel.nombre_publico + "%"
			});
		}
		this.store.dispatch(BonoAction.getBonosHistorial({filter: filter}));

		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close(true);
		}, 250);
	}

}
