<section class="wider tabbed" id="movimientos">
	<div class="section-header">
		<div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
			<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
		</div>

		<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="section-title">
				<div class="txt-snor pr-1">{{ 'MOVIMIENTOS_CRYPTO' | translate }}</div>
				<img src="../../../../assets/images/icon-m-retiro.svg" class="section-icon">
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>

	<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
	<app-crypto-intercambios *ngIf="currentTab===0"></app-crypto-intercambios>

	<app-crypto-transacciones *ngIf="currentTab===1"></app-crypto-transacciones>

	<ul id="menu-datos" class="">
		<li [class.active]="currentTab==0" (click)="onSetTab(0)">
			<span class="txt-sm">{{ 'INTERCAMBIOS' | translate }}</span>
		</li>
		<li [class.active]="currentTab==1" (click)="onSetTab(1)">
			<span class="txt-sm">{{ 'TRANSACCIONES' | translate }}</span>
		</li>
	</ul>
	<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

</section>