import {Component, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {NotificationService} from "../../../shared/services/notification.service";
import {FacebookLoginProvider, SocialAuthService} from "angularx-social-login";
import * as AuthAction from "../../redux/authentication.actions";
import {Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import {Subscription} from "rxjs";
import {AppState} from "src/app/shared/redux/general.reducers";
import {Credenciales, JugadorModel} from "src/app/shared/models/user.model";
import {WNewAccountComponent} from "../../../afiliados/components/w-new-account/w-new-account.component";
import {WLanguagesComponent} from "../../../shared/w-languages/w-languages.component";

import {fixAndroid} from "../../../shared/utils/general.util";
import {TranslateService} from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit, OnDestroy {

	username: string;
	password: string;
	afiliadosMode: boolean = false;
	usuarioLogueado: JugadorModel = null;

	usuarioLogueadoCompletadoSubscription: Subscription = null;

	wActive: boolean = false;


	constructor(
		private notification: NotificationService,
		public activeModal: NgbActiveModal,
		private router: Router,
		private authService: SocialAuthService,
		private actions$: Actions,
		private modalService: NgbModal,
		private modalServiceLang: NgbModal,
		private store: Store<AppState>,
		private translateService: TranslateService
	) {

	}

	ngOnInit(): void {
		this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
			this.usuarioLogueado = state.auth.player;
		});

		this.listeningActions();
		setTimeout(() => {
			this.wActive = true;
		}, 0);
		setTimeout(() => {
			fixAndroid("w-cover-login");
		}, 250);
		//
	}

	ngOnDestroy(): void {
		if (this.usuarioLogueadoCompletadoSubscription) {
			this.usuarioLogueadoCompletadoSubscription.unsubscribe();
		}
	}

	listeningActions() {
		// Escuchando la llegada del obtener usuario logueado
		this.usuarioLogueadoCompletadoSubscription = this.actions$
			.pipe(ofType(AuthAction.obtenerUsuarioLogueadoCompletado))
			.subscribe(response => {
				const resp = response;

				this.wActive = false;
				setTimeout(() => {
					this.activeModal.close(true);
				}, 250);

				if (!this.afiliadosMode) {
					this.router.navigate(["/inicio/jugador"]).then();
				} else {
					if (this.usuarioLogueado.afiliado) {
						this.router.navigate(["/afiliados/inicio"]).then();
					} else {
						const modalRef = this.modalService.open(WNewAccountComponent);
						modalRef.result.then(
							(resp: any) => {
								if (resp != "OnCreateAfilateAccountBtn") {
									console.log(3);
									this.router.navigate(["/inicio/jugador"]).then();
								}
							});
					}
				}

				const messAlert = this.translateService.instant("INGRESADO_SATISFACTORIAMENTE");
				Swal.fire(this.notification.BuildSuccess(messAlert)).then(() => {
				});
				this.usuarioLogueadoCompletadoSubscription.unsubscribe();
			});
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GO REGISTER ()
	onGoRegister() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close("GoToRegister");
		}, 250);
	}

	isValid() {
		return !(!this.username || this.username.length === 0 || !this.password || this.password.length === 0);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON LOGIN ()
	onLogIn() {
		if (this.isValid()) {
			let credenciales: Credenciales = {
				username: this.username,
				password: this.password
			};
			this.store.dispatch(AuthAction.login({credenciales}));
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON LOGIN FB ()
	onLogInFB() {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
			(userData) => {
				const param = {
					data: {
						token: userData.authToken,
						email: userData.email
					}
				};
				this.store.dispatch(AuthAction.loginFacebook({token: userData.authToken, email: userData.email}));
			}
		);
	}

	getUserDataFirstTime() {
		this.store.dispatch(AuthAction.obtenerUsuarioLogueado());
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT LANGUAGE ()
	onSelectLanguage() {
		const modalRefLang = this.modalServiceLang.open(WLanguagesComponent);
	}

}
