<div class="full-h" id="direccion">
	<form class="full-h pos-rel" [formGroup]="accountForm">
		<!-- <div class="flex-col"> -->

		<div class="pt-1">

			<div class="col col-4">
				<p class="txt-c4-l mb-03">{{ 'PAIS' | translate }}</p>
				<div class="pos-rel">
					<div [class.disabled]="edit==false" class="sel sel-sm sel-1st">
						<select class="sel-sm full-w ff-cnd-sm" formControlName="pais_id"
							(change)="getEstadosByCountry($event)">
							<option value="null" selected disabled>{{ 'PAIS' | translate }}...</option>
							<option *ngFor="let country of countries" [value]="country.id">
								{{country.nombre}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col col-4 pl-1">
				<p class="txt-c4-l mb-03">{{ 'ESTADO_PROVINCIA' | translate }}</p>
				<div class="pos-rel">
					<div [class.disabled]="edit==false" class="sel sel-sm sel-1st">
						<select class="sel-sm full-w ff-cnd-sm" formControlName="estado_pais_id">
							<option value="null" selected disabled>{{ 'ESTADO' | translate }}...</option>
							<option *ngFor="let est of estados; let i = index;" [value]="est.id">
								{{est.ds_state}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col col-4 pl-1">
				<p class="txt-c4-l mb-03">{{ 'MUNICIPIO' | translate }}</p>
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w ff-cnd-sm" type="text" value=""
					formControlName="municipio">
			</div>
			<div class="cb mb-15"></div>

			<div class="col col-6 pr-05">
				<p class="txt-c4-l mb-03">{{ 'CIUDAD' | translate }}</p>
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w ff-cnd-sm" type="text" value=""
					formControlName="ciudad">
			</div>

			<div class="col col-6 pl-05">
				<p class="txt-c4-l mb-03">{{ 'COLONIA' | translate }}</p>
				<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w ff-cnd-sm" type="text" value=""
					formControlName="colonia">
			</div>
			<div class="cb mb-15"></div>

			<div class="">
				<div class="col col-4 pr-1">
					<p class="txt-c4-l mb-03">{{ 'NO_EXTERIOR' | translate }}</p>
					<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w tac ff-cnd-sm" type="text"
						value="127-A" formControlName="numero_interior">
				</div>
				<div class="col col-4 pr-1">
					<p class="txt-c4-l mb-03">{{ 'NO_INTERIOR' | translate }}</p>
					<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w tac ff-cnd-sm" type="text"
						value="16-B" formControlName="numero_exterior">
				</div>
				<div class="col col-4">
					<p class="txt-c4-l mb-03">{{ 'CODIGO_POSTAL_SIGLAS' | translate }} <span class="txt-c9"> / </span>
						{{ 'CODIGO_POSTAL_ZIP' | translate }}</p>
					<input [class.disabled]="edit==false" class="inp inp-sm inp-1st full-w tac ff-cnd-sm" type="text"
						appDecimalDirective [maxLength]="7" value="2650" formControlName="codigo_postal">
				</div>
			</div>

		</div>
		<div class="cb mb-2"></div>

		<div class="tar">
			<button (click)="onEdit()" *ngIf="edit==false" class="btn2 btn2-4th btn2-lg btn2-lg-icon" type="button">
				<img class="icon-full" src="../../../../assets/images/icon-b-edit-w.svg">
			</button>

			<ng-container *ngIf="edit==true">
				<button (click)="onSave()" [disabled]="accountForm.invalid"
					[style]="accountForm.invalid ? 'background-color:gray':''"
					class="btn2 btn2-1st btn2-1st-1st btn2-lg btn2-lg-icon mr-1" type="button">
					<img class="icon-full" src="../../../../assets/images/icon-b-save-w.svg">
				</button>
				<button (click)="onDiscard()" class="btn2 btn2-7th btn2-lg btn2-lg-icon" type="button">
					<img class="icon-full" src="../../../../assets/images/icon-b-discard-w.svg">
				</button>
			</ng-container>
		</div>

		<!-- </div> -->
		<div class="cb"></div>
	</form>
</div>