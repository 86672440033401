import {Component, OnDestroy, OnInit, AfterViewInit, ViewChild} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WNewMsgComponent} from "../w-new-msg/w-new-msg.component";
import {FilterModel} from "../../../shared/models/filter.model";
import {TicketsModel} from "../../../shared/models/tickets.model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SessionServices} from "../../../shared/services/session.services";
import {JugadorModel} from "../../../shared/models/user.model";
import {HelpService} from "../../services/help.service";
import {AppState} from "../../../shared/redux/general.reducers";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import * as GeneralAction from "../../../shared/redux/general.actions";
import * as generalAction from "../../../shared/redux/general.actions";
import {Actions, ofType} from "@ngrx/effects";
import Swal from "sweetalert2";
import {NotificationService} from "../../../shared/services/notification.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
	selector: "app-help-tickets",
	templateUrl: "./help-tickets.component.html",
	styleUrls: ["./help-tickets.component.css"]
})
export class HelpTicketsComponent implements OnInit, AfterViewInit, OnDestroy {

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	selectedTicket: TicketsModel;
	accordion: TicketsModel = null;

	tickets: TicketsModel[];
	filter: FilterModel;
	ticketForm: FormGroup;
	@ViewChild('divContenerdor') divContenerdor: any;

	player: JugadorModel;
	subscription = new Subscription();
	recargar = true;

	constructor(
		private helpService: HelpService,
		private formBuilder: FormBuilder,
		private modalService: NgbModal,
		private sessionService: SessionServices,
		private store: Store<AppState>,
		private notification: NotificationService,
		private actions$: Actions,
		private translateService: TranslateService,
	) {
	}

	ngOnInit(): void {
		this.initData();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.accContentH();
		}, 250);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}


	initData() {
		this.subscription.add(
			this.store.subscribe((state) => {
				this.tickets = state.general.tickets.value;
				this.filter = state.general.tickets.filter;
				this.player = state.auth.player;
			})
		)
		this.subscription.add(this.actions$
			.pipe(ofType(GeneralAction.addTicketsCompletado))
			.subscribe(response => {
				this.store.dispatch(generalAction.getTickets({filter: this.filter}));
				Swal.fire(this.notification.BuildSuccess(response.data.message, this.translateService.instant("TICKET_ENVIADO"))).then();
			}));
		this.subscription.add(this.actions$
			.pipe(ofType(GeneralAction.getTicketsCompletado))
			.subscribe(response => {
				this.recargar = true;
				if (this.divContenerdor) {
					if (this.filter.amount_last_page != 0) {
						setTimeout(
							() => {
								this.divContenerdor.nativeElement.scrollTop = this.divContenerdor.nativeElement.scrollTop + 200;
							}, 350
						);
					} else {
						this.divContenerdor.nativeElement.scrollTop = 10;
					}
				}
			}));


		this.getAllHelpTickets();
	}

	inputTouched(formName: string) {
		return this.ticketForm.controls[formName].invalid && (this.ticketForm.controls[formName].dirty || this.ticketForm.controls[formName].touched);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ACCORDION CONTENT HEIGTH ()
	accContentH() {
		var tic = document.getElementById('tic') as HTMLDivElement;
		if (tic) {
			let accordionMaxH = tic.offsetHeight - 172;
			let root = document.documentElement;
			root.style.setProperty('--acc2ContH', accordionMaxH + "px");
		}
	}

	onScroll() {
		if (this.filter.last_page != this.filter.page) {
			this.getAllHelpTickets("scroll+");
		}
	}

	onScrollUp() {
		if (this.filter.page != 1) {
			this.getAllHelpTickets("scroll-");
		}
	}


	// API CALLS

	getAllHelpTickets(tipo = "primero") {
		let nuevoFiltro = new FilterModel();
		if (tipo != "primero") {
			nuevoFiltro = {...this.filter};
		}
		if (tipo == "scroll+") {
			nuevoFiltro.page++;
		}
		if (tipo == "scroll-") {
			nuevoFiltro.page--;
		}
		if (this.filter.amount_last_page != 0 && this.filter.page > 2) {
			nuevoFiltro.page--;
		}
		if (this.recargar) {
			this.recargar = false;
			this.store.dispatch(GeneralAction.getTickets({filter: nuevoFiltro}));
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOGGLE ACCORDION ()
	onToggleAccordion(event, accord) {
		event.stopPropagation();
		this.accordion = this.accordion ? null : accord;
		this.selectedTicket = accord;
		//
		var accCont = document.getElementById('tic') as HTMLDivElement;
		let act = document.getElementById('ticket' + accord.id) as HTMLDivElement;
		setTimeout(() => {
			let scrTop = act.offsetTop;
			accCont.scrollTop = scrTop;
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ADD MSG ()
	addMsg(selected) {
		const modalRef = this.modalService.open(WNewMsgComponent);
		this.selectedTicket = selected;
		modalRef.componentInstance.ticket = this.selectedTicket;
		modalRef.result.then();
	}

	openFile(nota) {
		window.open(nota.url, '_blank');
	}

	construirNota(nota){
		let mess = nota.nota;
		if(nota.zona_horaria){
			mess += " Zona: " + nota.zona_horaria;
		}

		return mess;
	}

}
