<section class="wider tabbed" id="tickets">
	<div class="section-header">
		<div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
			<img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
		</div>

		<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape">
			<div class="section-title">
				<div class="txt-snor pr-1">{{'TICKETS' |translate}}</div>
				<img src="../../../../assets/images/icon-m-ticket.svg" class="section-icon">
				<div class="pl-1 pr-05">
					<div class="txt-ssm tar"><span class="txt-c4">{{'ID: '}}</span> {{player.id}}<br>
						{{player.usuario.usuario}}
					</div>
				</div>
			</div>
		</div>
		<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>

	<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
	<div class="full hidden-landscape">
		<div class="tickets-i-container px-15" id="tic" #divContenerdor infiniteScroll [infiniteScrollDistance]="0.1" [infiniteScrollUpDistance]="0.1"
			 [infiniteScrollThrottle]="50"
			 [alwaysCallback]="true" (scrolled)="onScroll()" (scrolledUp)="onScrollUp()" [scrollWindow]="false">

			<!-- - - - - - - - - - - - TICKET -->
			<ng-container *ngFor="let item of tickets; let i = index;">
				<div class="accordion2 acc2ticket mb-1" [class.active]="accordion && accordion.id==item.id" id="ticket{{item.id}}">
					<div class="accordion2-header ahw-title" (click)="onToggleAccordion($event, item)">
						<div class="pr-4">
							<div class="col col-7 txt-sm txt-gc">
								{{item.categoria.nombre}}
							</div>
							<div class="col col-5 txt-sm txt-c4 tar pr-05">
								{{item.fecha}}
							</div>
						</div>
						<p class="cb txt-nor txt-w title ellipsis">{{item.asunto}}</p>
						<div class="accordion2-indicator"><img src="../../../../assets/images/icon-indicator.svg"></div>
					</div>
					<!-- - - - - - - - - - - - CONTENT -->
					<div class="accordion2-content">
						<!-- SAMPLE -->
						<!-- - - - - - - - - - - - TICKET MSG -->
						<ng-container *ngFor="let nota of item.notas; let j = index;">
							<div class="ticket-msg {{nota.owner == 'OPERADOR' ? 'admin' : 'client'}}">
								<p class="txt-sm txt-c4-l fl">{{nota.usuario}}</p>
								<p class="txt-sm txt-c4 fr">{{nota.fecha}}</p>
								<div class="cb mb-03"></div>
								<div class="txt-nor">{{construirNota(nota)}}</div>
								<button type="button" class="btn2 btn2-4th btn2-lg btn2-lg-icon mt-05" (click)="openFile(nota)"
										*ngIf="nota.url!=''">
									<img src="../../../../assets/images/icon-b-doc.svg">
								</button>
							</div>
						</ng-container>
						<!-- - - - - - - - - - - - TICKET MSG -->
						<!-- - - - - - - - - - - - TICKET MSG -->
						<!-- / SAMPLE -->
						<div class="pt-1">
							<button type="button" class="btn2 btn2-nor btn2-4th full-w" (click)="addMsg(item)">
								{{'AGREGAR_MENSAJE' |translate}}
							</button>

						</div>
					</div>
					<!-- - - - - - - - - - - - / CONTENT -->
				</div>
			</ng-container>
			<!-- - - - - - - - - - - - / TICKET -->

		</div>
	</div>
	<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

</section>
