<div class="bg-w " id="circuit-container" (click)="doNothing($event)" [class.active]="showCircuito">

	<div class="circuit-header" (click)="onHideCircuit($event)">
		<div class="tac pt-02 pb-03 header-top">
			<img src="../../../../assets/images/mr_worldwild/mr_worldwild-logo-k.svg" id="mr-ww-logo">
		</div>

		<div class="cb"></div>
		<div class="tac pb-1">
			<p class="txt-xsmm txt-mx2 txt-gc tac">{{'CIRCUITO_RECORRE_EL_MAPA' |translate}} <b class="txt-c2"> 1 BTC </b> {{'SEMANAL' |translate}}.</p>
		</div>

		<div class="bg-k px-2 py-1">
			<div class="col col-4">
				<div class="txt-lg txt-mid txt-g8 pt-08">
					<span *ngIf="circuitoEstado !== 'ACTIVO'">{{'COMIENZA_EN' |translate}}:</span>
					<span *ngIf="circuitoEstado === 'ACTIVO'">{{'TERMINA_EN' |translate}}:</span>
				</div>
			</div>
			<div class="col col-8">
				<div class="counter tac">
					<div class="col-2-1">
						<div class="counterNum days txt-g9">
					{{!countDD || countDD < 1 ? "00" : countDD < 10 ? "0" + countDD : countDD}}</div>
						<span class="ff-cnd">{{'DIAS' |translate}}</span>
					</div>
					<div class="ilb">
						<div class="counterDiv">:</div>
						<span>&nbsp;</span>
					</div>
					<div class="col-2-1">
						<div class="counterNum hours">
					{{!countHH || countHH < 1 ? "00" : countHH < 10 ? "0" + countHH : countHH}}</div>
						<span class="ff-cnd">{{'HORAS' |translate}}</span>
					</div>
					<div class="ilb">
						<div class="counterDiv">:</div>
						<span>&nbsp;</span>
					</div>
					<div class="col-2-1">
						<div class="counterNum minutes">
					{{!countMM || countMM < 1 ? "00" : countMM < 10 ? "0" + countMM : countMM}}</div>
						<span class="ff-cnd">{{'MINS' |translate}}.</span>
					</div>
					<div class="ilb">
						<div class="counterDiv">:</div>
						<span>&nbsp;</span>
					</div>
					<div class="col-2-1">
						<div class="counterNum seconds">
					{{!countSS || countSS < 1 ? "00" : countSS < 10 ? "0" + countSS : countSS}}</div>
						<span class="ff-cnd">{{'SEGS' |translate}}.</span>
					</div>
					<div class="cb"></div>
				</div>
			</div>
         <div class="cb"></div>
		</div>

	</div>

	<!-- ESPERANDO APROBACIÓN DEL MAPA -->
	<!--
	<div class="prizes bg-cir1 txt-bol">
		<div class="txt-nor txt-c1 tac mb-05">GANA:</div>
		<div class="flex-x-c pb-03">
			<div class="txt-c1 tac px-05">
				<img class="icon icon-c2" src="../../../../assets/images/icon-crown.svg">
				<div class="cb mb-03"></div>
				<span class="txt-w">Título<br>"Mr. WorldWild"</span>
			</div>
			<div class="txt-c1 tac px-05">
				<img class="icon icon-c2" src="../../../../assets/images/icon-gift.svg">
				<div class="cb mb-03"></div>
				<span class="txt-w">Premios<br>Físicos</span>
			</div>
			<div class="txt-c1 tac px-05">
				<img class="icon icon-c2" src="../../../../assets/images/icon-usd.svg">
				<div class="cb mb-03"></div>
				<span class="txt-w">Hasta<br>USD 20,000</span>
			</div>
		</div>
	</div>
   -->
	<!-- / ESPERANDO APROBACIÓN DEL MAPA -->

	<div class="circuit-map-container">
		<!-- <img id="ww-map" alt="mapa" src="../../../../assets/images/mr_worldwild/mr_ww-bg-mobile.jpg"> -->
      <img id="ww-map" alt="mapa" src="../../../../assets/images/mr_worldwild/mr_ww-bg-mobile-1.jpg">

		<img id="" alt="node node-4" class="node node-4" src="../../../../assets/images/mr_worldwild/node-4.png"
      (click)="onOpenNode($event, 4)">
		<img id="" alt="node node-6" class="node node-6" src="../../../../assets/images/mr_worldwild/node-6.png"
      (click)="onOpenNode($event, 6)">
		<img id="" alt="node node-11" class="node node-11" src="../../../../assets/images/mr_worldwild/node-11.png"
      (click)="onOpenNode($event, 11)">
		<img id="" alt="node node-15" class="node node-15" src="../../../../assets/images/mr_worldwild/node-15.png"
      (click)="onOpenNode($event, 15)">
		<img id="" alt="node node-18" class="node node-18" src="../../../../assets/images/mr_worldwild/node-18.png"
      (click)="onOpenNode($event, 18)">
		<img id="" alt="node node-21" class="node node-21" src="../../../../assets/images/mr_worldwild/node-21.png"
      (click)="onOpenNode($event, 21)">
		<img id="" alt="node node-25" class="node node-25" src="../../../../assets/images/mr_worldwild/node-25.png"
      (click)="onOpenNode($event, 25)">
		<img id="" alt="node node-28" class="node node-28" src="../../../../assets/images/mr_worldwild/node-28.png"
      (click)="onOpenNode($event, 28)">
		<img id="" alt="node node-30" class="node node-30" src="../../../../assets/images/mr_worldwild/node-30.png"
      (click)="onOpenNode($event, 30)">

		<img id="" alt="star star-1" class="star star-1" src="../../../../assets/images/mr_worldwild/star-1.svg">
		<img id="" alt="star star-4" class="star star-4" src="../../../../assets/images/mr_worldwild/star-4.svg">
		<img id="" alt="star star-6" class="star star-6" src="../../../../assets/images/mr_worldwild/star-6.svg">
		<img id="" alt="star star-11" class="star star-11" src="../../../../assets/images/mr_worldwild/star-11.svg">
		<img id="" alt="star star-15" class="star star-15" src="../../../../assets/images/mr_worldwild/star-15.svg">
		<img id="" alt="star star-18" class="star star-18" src="../../../../assets/images/mr_worldwild/star-18.svg">
		<img id="" alt="star star-21" class="star star-21" src="../../../../assets/images/mr_worldwild/star-21.svg">
		<img id="" alt="star star-25" class="star star-25" src="../../../../assets/images/mr_worldwild/star-25.svg">
		<img id="" alt="star star-28" class="star star-28" src="../../../../assets/images/mr_worldwild/star-28.svg">
		<img id="" alt="star star-30" class="star star-30" src="../../../../assets/images/mr_worldwild/star-30.svg">

		<img id="mr-ww" src="../../../../assets/images/mr_worldwild/mr-world-wild-map.png" alt="Mr Worldwild"
			class="node{{node}}" data-stage=10>

      <div class="btn-node btn-node-1" (click)="onOpenNode($event, 1)"></div>
      <div class="btn-node btn-node-2" (click)="onOpenNode($event, 2)"></div>
      <div class="btn-node btn-node-3" (click)="onOpenNode($event, 3)"></div>
      <div class="btn-node btn-node-4" (click)="onOpenNode($event, 4)"></div>
      <div class="btn-node btn-node-5" (click)="onOpenNode($event, 5)"></div>
      <div class="btn-node btn-node-6" (click)="onOpenNode($event, 6)"></div>
      <div class="btn-node btn-node-7" (click)="onOpenNode($event, 7)"></div>
      <div class="btn-node btn-node-8" (click)="onOpenNode($event, 8)"></div>
      <div class="btn-node btn-node-9" (click)="onOpenNode($event, 9)"></div>
      <div class="btn-node btn-node-10" (click)="onOpenNode($event, 10)"></div>
      <div class="btn-node btn-node-11" (click)="onOpenNode($event, 11)"></div>
      <div class="btn-node btn-node-12" (click)="onOpenNode($event, 12)"></div>
      <div class="btn-node btn-node-13" (click)="onOpenNode($event, 13)"></div>
      <div class="btn-node btn-node-14" (click)="onOpenNode($event, 14)"></div>
      <div class="btn-node btn-node-15" (click)="onOpenNode($event, 15)"></div>
      <div class="btn-node btn-node-16" (click)="onOpenNode($event, 16)"></div>
      <div class="btn-node btn-node-17" (click)="onOpenNode($event, 17)"></div>
      <div class="btn-node btn-node-18" (click)="onOpenNode($event, 18)"></div>
      <div class="btn-node btn-node-19" (click)="onOpenNode($event, 19)"></div>
      <div class="btn-node btn-node-20" (click)="onOpenNode($event, 20)"></div>
      <div class="btn-node btn-node-21" (click)="onOpenNode($event, 21)"></div>
      <div class="btn-node btn-node-22" (click)="onOpenNode($event, 22)"></div>
      <div class="btn-node btn-node-23" (click)="onOpenNode($event, 23)"></div>
      <div class="btn-node btn-node-24" (click)="onOpenNode($event, 24)"></div>
      <div class="btn-node btn-node-25" (click)="onOpenNode($event, 25)"></div>
      <div class="btn-node btn-node-26" (click)="onOpenNode($event, 26)"></div>
      <div class="btn-node btn-node-27" (click)="onOpenNode($event, 27)"></div>
      <div class="btn-node btn-node-28" (click)="onOpenNode($event, 28)"></div>
      <div class="btn-node btn-node-29" (click)="onOpenNode($event, 29)"></div>
      <div class="btn-node btn-node-30" (click)="onOpenNode($event, 30)"></div>

	</div>

	<div class="ww-footer bg-w full-w tac">
		<div class="ilb txt-sssm txt-lnk pt-07" (click)="onShowTerms()">{{'TERMINOS_CONDICIONES' |translate}}</div>
	</div>

	<div class="btn-ctrl-container" id="btn-ctrl-closeDown">
		<button (click)="onHideCircuit($event)" class="btn btn-rnd btn-lg btn-7th-7" id="btn-closeDown" type="button">
			<img class="icon-full" src="../../../../assets/images/icon-b-discard.svg">
		</button>
	</div>

</div>
