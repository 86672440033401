import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule, Routes} from "@angular/router";
import {AdminGuard} from "./shared/guards/admin-guard.service";
import {HomeVisitorComponent} from "./home/components/home-visitor/home-visitor.component";
import {HomeAfiliadosComponent} from "./home/components/home-afiliados/home-afiliados.component";
import {HomeAfiliadosVisitorComponent} from "./home/components/home-afiliados-visitor/home-afiliados-visitor.component";
import {AfiliadoGuard} from "./home/guards/afiliado.guard";
import {CircuitoComponent} from "./home/components/circuito/circuito.component";

export const appRoutes: Routes = [
	{
		path: "",
		redirectTo: "inicio",
		pathMatch: "full"
	},
	// Estas direcciones las puse para poder dieñar:
	// hi, ha, hav
   /*
	{
		path: "hi",
		component: HomeVisitorComponent
	},
	{
		path: "ha",
		component: HomeAfiliadosComponent,
		canActivate: [AfiliadoGuard]
	},
	{
		path: "hav",
		component: HomeAfiliadosVisitorComponent
	},
   */
	{
		path: "circuito",
		component: CircuitoComponent
	},
	// Se pueden borrar cuando no se necesiten
	{
		path: "inicio",
		loadChildren: "./home/home.module#HomeModule"
	},
	{
		path: "ayuda",
		loadChildren: "./ayuda/help.module#HelpModule",
		canActivate: [AdminGuard],
		canLoad: [AdminGuard]
   },

	{
		path: "games",
		loadChildren: "./games/games.module#GamesModule"
	},

	{
		path: "cajero",
		loadChildren: "./cajero/cajero.module#CajeroModule",
		canActivate: [AdminGuard],
		canLoad: [AdminGuard]
	},

	{
		path: "cuenta",
		loadChildren: "./cuenta/cuenta.module#CuentaModule",
		canActivate: [AdminGuard],
		canLoad: [AdminGuard]
	},

	{
		path: "bonos",
		loadChildren: "./bonos/bonos.module#BonosModule",
		canActivate: [AdminGuard],
		canLoad: [AdminGuard]
	},

	{
		path: "afiliados",
		loadChildren: "./afiliados/afiliados.module#AfiliadosModule"
	},

	{
		path: "**",
		redirectTo: "inicio",
		pathMatch: "full"
	}
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forRoot(appRoutes)
	],
	exports: [
		RouterModule
	],
	declarations: []
})

export class AppRoutingModule {
}
