import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BonosRoutes} from "./bonos.routing";
import {BonosService} from "./services/bonos.service";
import {SharedModule} from "../shared/shared.module";
import {WFBonosComponent} from "./components/w-f-bonos/w-f-bonos.component";
import {BonosComponent} from "./components/bonos/bonos.component";
import {WRulesComponent} from "./components/w-rules/w-rules.component";
import {BonosHistComponent} from "./components/bonos-hist/bonos-hist.component";
import {WActivateDepositoComponent} from "./components/w-activate-deposito/w-activate-deposito.component";
import {WActivateGratisDevolucionComponent} from "./components/w-activate-gratis-devolucion/w-activate-gratis-devolucion.component";
import {DisponiblesBonosComponent} from "./components/bonos/disponibles-bonos/disponibles-bonos.component";
import {ActivosBonosComponent} from "./components/bonos/activos-bonos/activos-bonos.component";
import {WActivateBonoDevolucionComponent} from "./components/w-activate-bono-devolucion/w-activate-bono-devolucion.component";
import {StoreModule} from "@ngrx/store";
import * as fromBonosReducer from "./redux/bonos.reducers";
import {EffectsModule} from "@ngrx/effects";
import {AuthenticationEffects} from "../authetication/redux/authentication.effects";
import {BonosEffects} from "./redux/bonos.effects";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		RouterModule.forChild(BonosRoutes),
		SharedModule,
		InfiniteScrollModule,
		StoreModule.forFeature("bonos", fromBonosReducer.reducer),
		EffectsModule.forFeature([BonosEffects]),
		TranslateModule
	],
	providers: [
		NgbActiveModal,
		BonosService
	],
	declarations: [
		BonosComponent,
		BonosHistComponent,
		WActivateDepositoComponent,
		WActivateGratisDevolucionComponent,
		WFBonosComponent,
		WRulesComponent,
		DisponiblesBonosComponent,
		ActivosBonosComponent,
		WActivateBonoDevolucionComponent
	],
	exports: [
		WActivateDepositoComponent,
		WActivateGratisDevolucionComponent,
		WFBonosComponent,
		WRulesComponent,
		WActivateBonoDevolucionComponent
	]
})
export class BonosModule {
}
