import {Injectable} from "@angular/core";
import {SweetAlertOptions} from "sweetalert2";

@Injectable()
export class NotificationService {
	BuildConfirmation(mess: string, titleStr: string = "") {
		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			icon: "question",
			heightAuto: false,
			showCancelButton: true,
			cancelButtonText: "CANCELAR",
			confirmButtonText: "CONFIRMAR",
			reverseButtons: true,
			html: mess,
			customClass: {}
		};
		return body;
	}

	BuildMessage(mess: string, titleStr: string = "") {
		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			timer: 6000,
			heightAuto: false,
			showConfirmButton: false,
			showCancelButton: false,
			html: mess,
			customClass: {}
		};
		return body;
	}

	BuildSuccess(mess: string, titleStr: string = "", timerInt: number = 4000) {
		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			icon: "success",
			timer: timerInt,
			heightAuto: false,
			showConfirmButton: false,
			showCancelButton: false,
			html: mess,
			customClass: {}
		};
		return body;
	}

	BuildSuccessConfirmation(mess: string, titleStr: string = "") {
		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			icon: "success",
			heightAuto: false,
			showConfirmButton: true,
			showCancelButton: false,
			confirmButtonText: "ENTENDIDO",
			html: mess,
			customClass: {}
		};
		return body;
	}

	BuildSuccessQuestion(mess: string, titleStr: string = "") {
		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			icon: "success",
			heightAuto: false,
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: "CONFIRMAR",
			cancelButtonText: "CANCELAR",
         reverseButtons: true,
			html: mess,
			customClass: {}
		};
		return body;
	}

	BuildWarningSuccess(mess: string, titleStr: string = "", timerInt: number = 4000) {
		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			icon: "warning",
			timer: timerInt,
			heightAuto: false,
			showConfirmButton: false,
			showCancelButton: false,
			html: mess,
			customClass: {}
		};
		return body;
	}

	BuildWarning(mess: string, titleStr: string = "") {
		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			icon: "warning",
			heightAuto: false,
			showCancelButton: false,
			confirmButtonText: "ENTENDIDO",
			reverseButtons: true,
			html: mess,
			customClass: {}
		};
		return body;
	}

	BuildQuestion(mess: string, titleStr: string = "") {
		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			icon: "question",
			heightAuto: false,
			showCancelButton: true,
			cancelButtonText: "CANCELAR",
			confirmButtonText: "CONFIRMAR",
			reverseButtons: true,
			html: mess,
			customClass: {}
		};
		return body;
	}

	BuildError(mess: string, titleStr: string = "Oops!") {
		const timerSec = mess.split(/[ ,]+/).length * 3.5 * 100;
		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			icon: "warning",
			heightAuto: false,
			showConfirmButton: true,
			showCloseButton: false,
			showCancelButton: false,
			html: mess,
			customClass: {}
		};
		return body;
	}

	buildToast(mess: string, titleStr: string = "", timerInt: number = 4000) {
		// const htmlexample = "<h3 class=\"txt-smb txt-w full-w tal\"> Nuevo Mensaje </h3>" +
		// 	"<p class=\"txt-mid txt-w full-w tal\">Desaparece a los 4 segundos. (en app.component.ts)</p>";

		let body: SweetAlertOptions;
		body = {
			title: titleStr,
			html: mess,
			icon: "warning",
			customClass: {container: "toast"},
			toast: true,
			timer: timerInt,
			position: "top-end",
			showConfirmButton: false,
			showCancelButton: false
		};
		return body;
	}
}
