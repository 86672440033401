import { Component, OnDestroy, OnInit } from "@angular/core";
import { NotificationsModel } from "../../../shared/models/notifications.model";
import { Store } from "@ngrx/store";
import { AppState } from "../../../shared/redux/general.reducers";
import * as GeneralAction from "../../../shared/redux/general.actions";
import * as GameAction from "../../../games/redux/games.actions";
import * as BonoAction from "../../../bonos/redux/bonos.actions";
import { MyControlModel } from "../../../shared/models/control.model";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FilterCatalogComponent } from "../../../games/components/filter-catalog/filter-catalog.component";
import { WAddCoinComponent } from "../../../cajero/components/w-add-coin/w-add-coin.component";
import { Subscription } from "rxjs";
import { WFBonosComponent } from "../../../bonos/components/w-f-bonos/w-f-bonos.component";
import { WNewTicketComponent } from "../../../ayuda/components/w-new-ticket/w-new-ticket.component";
import { FilterModel } from "../../../shared/models/filter.model";
import { WFCtaMovComponent } from "../../../cuenta/components/w-f-cta-mov/w-f-cta-mov.component";
import * as CuentaAction from "../../../cuenta/redux/cuenta.actions";
import { WFCtaGamesComponent } from "../../../cuenta/components/w-f-cta-games/w-f-cta-games.component";
import { WFCtaCryptoComponent } from "../../../cuenta/components/w-f-cta-crypto/w-f-cta-crypto.component";
import { WFCtaCryptoTxComponent } from "../../../cuenta/components/w-f-cta-crypto-tx/w-f-cta-crypto-tx.component";
import { WNewLinkComponent } from "src/app/afiliados/components/campaigns/w-new-link/w-new-link.component";
import * as AfiliadosActions from "../../../afiliados/redux/afiliados.actions";
import { WFTraficoComponent } from "src/app/afiliados/components/trafico/w-f-trafico/w-f-trafico.component";
import { WFComisionesComponent } from "src/app/afiliados/components/comisiones/batch-comisiones/w-f-comisiones/w-f-comisiones.component";

@Component({
	selector: "app-widget-control",
	templateUrl: "./widget-control.component.html",
	styleUrls: ["./widget-control.component.css"]
})
export class WidgetControlComponent implements OnInit, OnDestroy {

	myMenu: MyControlModel;

	// Notifications

	notifications: NotificationsModel[];
	rutaActiva = "";
	subscription = new Subscription();
	filterHistorialBonos: FilterModel;
	filterMovimientos: FilterModel;
	filterActJuego: FilterModel;
	filterIntercambios: FilterModel;
	filterTransacciones: FilterModel;
	filtercatalogo: { name: string, moneda_id: number };
	filterTrafico: FilterModel;
	filterBatchComisiones: FilterModel;
	vistaIntercambiosSwitch: number;
	afiliadosMode: boolean = true;
	afiliadosComisionesBatchActiveTab: number = 1;
	mostrarExchanger:boolean = true;

	constructor(
		private router: Router,
		private store: Store<AppState>,
		private modalService: NgbModal
	) {

	}


	ngOnInit(): void {
		this.subscription.add(this.store.subscribe((state) => {
			this.afiliadosMode = state.general.afiliadosMode;
			this.myMenu = state.general.myMenu;
			this.notifications = state.general.notificaciones;
			this.filterHistorialBonos = state.bonos.bonosHistorial.filter;
			this.filterMovimientos = state.cuenta.movimientosHistorial.filter;
			this.filterTrafico = state.afiliado.trafico.filter;
			this.filterBatchComisiones = state.afiliado.comisiones_batch.filter;
			this.filterActJuego = state.cuenta.juegosHistorial.filter;
			this.filterIntercambios = state.cuenta.intercambioHistorial.filter;
			this.filterTransacciones = state.cuenta.transaccionesCryptoHistorial.filter;
			this.filtercatalogo = state.games.filterGames;
			this.vistaIntercambiosSwitch = state.cuenta.switchVistaCrypto;
			this.afiliadosComisionesBatchActiveTab = state.afiliado.comisiones_active_tab;
		}))

		this.subscription.add(this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.rutaActiva = event.url;
				if(this.rutaActiva.includes('/cajero/exchanger')){
					this.mostrarExchanger = false;
				}else{
					this.mostrarExchanger = true;
				}
			}
		}));
      this.initData();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initData() {
		this.getNotifications();
	}

   // notifyShow:boolean = notificacionesShown;
	notifNum:number = 1;


	// - - - - - - - - - - - - - - - - - - - - ON TOGGLE COMPLETE MENU ()
	onToggleCompleteMenu(event, tipo) {

		event.stopPropagation();
		let nuevoMenu = {
			menu: true,
			catalog: true,
			exchanger: true,
			back: true,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: false,
			notificaciones: true,
			show_notificaciones: false,
			sub_menu_activo: this.myMenu.sub_menu_activo,
			sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
			cuenta_verificada: true
		};
		switch (tipo) {
         case "inicio":
            this.router.navigate(["/inicio/jugador"]);
            break;
			case "back":
				if (!this.myMenu.show_back_component) {
					nuevoMenu = {
						menu: true,
						catalog: true,
						exchanger: false,
						back: true,
						show_catalog_component: false,
						show_exchager_component: false,
						show_back_component: true,
						show_menu_component: false,
						notificaciones: true,
						show_notificaciones: false,
						sub_menu_activo: this.myMenu.sub_menu_activo,
						sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
						cuenta_verificada: true
					};
				}

				this.store.dispatch(GeneralAction.setMyMenu({
					menu: nuevoMenu,
					eventEmiterComponent: this.constructor.name
				}));
				break;
			case "exchanger":
				if (!this.myMenu.show_exchager_component) {
					nuevoMenu = {
						menu: true,
						catalog: true,
						exchanger: true,
						back: false,
						show_catalog_component: false,
						show_exchager_component: true,
						show_back_component: false,
						show_menu_component: false,
						notificaciones: true,
						show_notificaciones: false,
						sub_menu_activo: this.myMenu.sub_menu_activo,
						sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
						cuenta_verificada: true
					};
				}

				this.store.dispatch(GeneralAction.setMyMenu({
					menu: nuevoMenu,
					eventEmiterComponent: this.constructor.name
				}));
				break;
			case "catalog":
				if (!this.myMenu.show_catalog_component) {
					nuevoMenu = {
						menu: true,
						catalog: true,
						exchanger: false,
						back: false,
						show_catalog_component: true,
						show_exchager_component: false,
						show_back_component: false,
						show_menu_component: false,
						notificaciones: true,
						show_notificaciones: false,
						sub_menu_activo: this.myMenu.sub_menu_activo,
						sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
						cuenta_verificada:true
					};
				}

				this.store.dispatch(GeneralAction.setMyMenu({
					menu: nuevoMenu,
					eventEmiterComponent: this.constructor.name
				}));
				break;
			case "menu":
				if (!this.myMenu.show_menu_component) {
					nuevoMenu = {
						menu: true,
						catalog: true,
						exchanger: false,
						back: false,
						show_catalog_component: false,
						show_exchager_component: false,
						show_back_component: false,
						show_menu_component: true,
						notificaciones: true,
						show_notificaciones: false,
						sub_menu_activo: this.myMenu.sub_menu_activo,
						sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
						cuenta_verificada:true
					};
				}

				this.store.dispatch(GeneralAction.setMyMenu({
					menu: nuevoMenu,
					eventEmiterComponent: this.constructor.name
				}));
				break;
			case "notificaciones":
				if (!this.myMenu.show_notificaciones) {
					nuevoMenu = {
						menu: true,
						catalog: true,
						exchanger: true,
						back: true,
						show_catalog_component: false,
						show_exchager_component: false,
						show_back_component: false,
						show_menu_component: false,
						notificaciones: true,
						show_notificaciones: true,
						sub_menu_activo: this.myMenu.sub_menu_activo,
						sub_sub_menu_activo: this.myMenu.sub_sub_menu_activo,
						cuenta_verificada:true
					};
				}

				this.store.dispatch(GeneralAction.setMyMenu({
					menu: nuevoMenu,
					eventEmiterComponent: this.constructor.name
				}));
				break;
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON FILTER ()
	onFilter(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(FilterCatalogComponent);
		modalRef.result.then();

	}

	onClearFiltersCatalogo(event) {
		event.stopPropagation();
		this.store.dispatch(GameAction.setFilterGames({name: '', moneda_id: null}));
	}

	onFilterHistorialBonos(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WFBonosComponent);
		modalRef.result.then();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON FILTER ()
	onClearFiltersMovimientos(event) {
		event.stopPropagation();
		let nuevoFilter = new FilterModel();
		this.store.dispatch(CuentaAction.getMovimientos({ filter: nuevoFilter }));
	}

	onFilterHistorialMovimientos(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WFCtaMovComponent);
		modalRef.result.then();
	}

	onClearFiltersHistorialBonos(event) {
		event.stopPropagation();
		let nuevoFilter = new FilterModel();
		this.store.dispatch(BonoAction.getBonosHistorial({ filter: nuevoFilter }));
	}

	onClearFiltersHistorialJuegos(event) {
		event.stopPropagation();
		let nuevoFilter = new FilterModel();
		this.store.dispatch(CuentaAction.getHistorialJuego({ filter: nuevoFilter }));
	}

	onFilterHistorialHistorialJuegos(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WFCtaGamesComponent);
		modalRef.result.then();
	}

	onClearFiltersHistorialIntercambiosCrypto(event) {
		event.stopPropagation();
		let nuevoFilter = new FilterModel();
		this.store.dispatch(CuentaAction.getHistorialIntercambio({ filter: nuevoFilter }));
	}

	onFilterHistorialIntercambiosCrypto(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WFCtaCryptoComponent);
		modalRef.result.then();
	}

	onClearFiltersHistorialTransaccionesCrypto(event) {
		event.stopPropagation();
		let nuevoFilter = new FilterModel();
		this.store.dispatch(CuentaAction.getHistorialTransaccionesCrypto({ filter: nuevoFilter }));
	}

	onFilterHistorialTransaccionesCrypto(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WFCtaCryptoTxComponent);
		modalRef.result.then();
	}

	onFilterControlTrafico(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WFTraficoComponent);
		modalRef.result.then();
	}

	onClearFilterControlTrafico(event) {
		event.stopPropagation();
		let nuevoFilter = new FilterModel(1000);
		this.store.dispatch(AfiliadosActions.afiliadosGetTrafico({ filter: nuevoFilter }));
	}

	onFilterBatchComisiones(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WFComisionesComponent);
		modalRef.result.then();
	}

	onClearFilterBatchComisiones(event) {
		event.stopPropagation();
		let nuevoFilter = new FilterModel(1000);
		this.store.dispatch(AfiliadosActions.afiliadosGetBatchComisiones({ filter: nuevoFilter }));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ADD ()
	onAdd(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WAddCoinComponent);
		modalRef.result.then();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ADD TICKET()
	onAddTicket(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WNewTicketComponent);
		modalRef.result.then();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ADD CAMPAÑA()
	onAddCampanna(event) {
		event.stopPropagation();
		const modalRef = this.modalService.open(WNewLinkComponent);
		modalRef.result.then();
	}

	readNotifs() {
		let counter = 0;
		for (const notif of this.notifications) {
			if (!notif.leido) {
				counter++;
			}
		}
		return counter;
	}

	// API CALLS

	getNotifications() {
		this.store.dispatch(GeneralAction.getNotificaciones());
	}

	rutasConFiltro = [
		'/cajero/wallet',
		'/bonos/historial',
		'/cuenta/movimientos',
		'/cuenta/juegos',
		'/ayuda/tickets',
		'/cuenta/movimientos_cripto',
		'/afiliados/campannas',
		'/afiliados/trafico',
		'/afiliados/comisiones'
	];

	ordenandoMenuFiltrosYBack(menu) {
		let mostrar = false;
		if (!this.afiliadosMode) {
			switch (menu) {
				case 'back':
					if (this.myMenu.back &&
						!this.rutasConFiltro.find(x => x === this.rutaActiva)
					) {
						mostrar = true;
					}
					break;
				case 'catalogo':
					if (this.filtercatalogo && this.filtercatalogo.name == '' && this.filtercatalogo.moneda_id == null) {
						if (this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'catalogo_clear':
					if (this.filtercatalogo && this.filtercatalogo.name != '' || this.filtercatalogo.moneda_id != null) {
						if (this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'empty':
					if (!this.myMenu.back && !this.myMenu.show_catalog_component &&
						!this.rutasConFiltro.find(x => x === this.rutaActiva)
					) {
						mostrar = true;
					}
					break;
				case 'bono_historial':
					if (this.filterHistorialBonos.filter.length == 0) {
						if (!this.myMenu.show_catalog_component && this.rutaActiva == '/bonos/historial') {
							mostrar = true;
						}
					}
					break;
				case 'bono_historial_clear':
					if (this.filterHistorialBonos.filter.length != 0) {
						if (!this.myMenu.show_catalog_component && this.rutaActiva == '/bonos/historial') {
							mostrar = true;
						}
					}
					break;
				case 'cajero_wallet':
					if (this.rutaActiva == '/cajero/wallet' && !this.myMenu.show_catalog_component) {
						mostrar = true;
					}
					break;
				case 'movimientos':
					if (this.filterMovimientos.filter.length == 0) {
						if (this.rutaActiva == '/cuenta/movimientos' && !this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'movimientos_clear':
					if (this.filterMovimientos.filter.length != 0) {
						if (this.rutaActiva == '/cuenta/movimientos' && !this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'act_juego':
					if (this.filterActJuego.filter.length == 0) {
						if (this.rutaActiva == '/cuenta/juegos' && !this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'act_juego_clear':
					if (this.filterActJuego.filter.length != 0) {
						if (this.rutaActiva == '/cuenta/juegos' && !this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'intercambios':
					if (this.filterIntercambios.filter.length == 0 && this.vistaIntercambiosSwitch == 0) {
						if (this.rutaActiva == '/cuenta/movimientos_cripto' && !this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'intercambios_clear':
					if (this.filterIntercambios.filter.length != 0 && this.vistaIntercambiosSwitch == 0) {
						if (this.rutaActiva == '/cuenta/movimientos_cripto' && !this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'transacciones':
					if (this.filterTransacciones.filter.length == 0 && this.vistaIntercambiosSwitch == 1) {
						if (this.rutaActiva == '/cuenta/movimientos_cripto' && !this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'transacciones_clear':
					if (this.filterTransacciones.filter.length != 0 && this.vistaIntercambiosSwitch == 1) {
						if (this.rutaActiva == '/cuenta/movimientos_cripto' && !this.myMenu.show_catalog_component) {
							mostrar = true;
						}
					}
					break;
				case 'ayuda_ticket':
					if (this.rutaActiva == '/ayuda/tickets' && !this.myMenu.show_catalog_component) {
						mostrar = true;
					}
					break;
			}
		} else {
			switch (menu) {
				case 'empty':
					if (!this.rutasConFiltro.find(x => x === this.rutaActiva) ||
					 (this.rutaActiva == '/afiliados/comisiones' && this.afiliadosComisionesBatchActiveTab == 1)) {
						mostrar = true;
					}
					break;
				case 'campannas':
					if (this.rutaActiva == '/afiliados/campannas') {
						mostrar = true;
					}
					break;
				case 'control_trafico':
					if (this.filterTrafico.filter.length == 0) {
						if (this.rutaActiva == '/afiliados/trafico') {
							mostrar = true;
						}
					}
					break;
				case 'control_trafico_clear':
					if (this.filterTrafico.filter.length != 0) {
						if (this.rutaActiva == '/afiliados/trafico') {
							mostrar = true;
						}
					}
					break;
				case 'batch_comisiones':
					if (this.afiliadosComisionesBatchActiveTab == 2) {
						if (this.rutaActiva == '/afiliados/comisiones') {
							mostrar = true;
						}
					}
					break;
			}

		}

		return mostrar;

	}

	verSiRutaContiene(texto){
		return this.rutaActiva.includes(texto);
	}

}
