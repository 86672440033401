<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TAB 0 - TRANSACCIONES -->
<ng-container>


	<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
	<div class="cta-container-2 hidden-landscape">
		<div class="col col-3 full-h">
			<table class="tbl2 tbl2-lg tbl2-striped striped-alt tbl-vert tbl2-head">
				<tr>
					<th>{{ 'MOVIMIENTO' | translate }}</th>
				</tr>
				<tr>
					<th class="th-2x">{{ 'HASH' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'FECHA' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'TIPO_DE' | translate }}<br>{{ 'MOVIMIENTO' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'MONEDA' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'IMPORTE' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'ESTADO' | translate }}</th>
				</tr>
			</table>
		</div>
		<div class="col col-9 full-h of-x2">
			<div class="tbl2 tbl2-lg tbl2-striped tbl-vert faux-tbl-vert bg-g1" #divContenerdor *ngIf="transaccionesCrypto.length > 0"
				 infiniteScroll [horizontal]="true" [infiniteScrollDistance]="0.1" [infiniteScrollUpDistance]="0.1"
				 [infiniteScrollThrottle]="50"
             [alwaysCallback]="true" (scrolled)="onScroll()" (scrolledUp)="onScrollUp()" [scrollWindow]="false">
             <!-- EMPTY -->
				<div class="col tbl2-col-empty tac">
					<div class="td">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="cb"></div>
				</div>
				<!-- / EMPTY -->
				<!-- DATA -->
				<div class="col  tac">
					<div class="td">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="cb"></div>
				</div>
				<div class="col tbl2-col-n tac" *ngFor="let tx of transaccionesCrypto; let i = index;">
					<div class="td">{{tx.id}}</div>
					<div class="td td-2x">
						<span class="txt-sm wordBreak">{{tx.hash}}</span>
					</div>
					<div class="td">
						<span>{{tx.fecha.split(" ")[0]}} <br>
                     <span class="t-sm wordBreak">{{tx.fecha.split(" ")[1]}}</span></span>
					</div>
					<div class="td">
						<span class="txt-sm wordBreak">{{tx.concepto}}</span>
					</div>
					<div class="td">{{tx.moneda.toUpperCase()}}</div>
					<div class="td">{{tx.importe | number:"1.2-8"}}</div>
					<div class="td">{{tx.estado}}</div>
					<div class="cb"></div>
				</div>
				<!-- / DATA -->
			</div>
			<div class="flex-center full" *ngIf="transaccionesCrypto.length < 1">
				<div class="bg-g1 p-2">{{ 'NO_HAY_MOVIMIENTOS_LISTADO' | translate }}</div>
			</div>
		</div>
	</div>
	<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
</ng-container>
<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TAB 0 - TRANSACCIONES -->
