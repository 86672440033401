import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import { AfiliadoModel } from "src/app/shared/models/afiliado.model";
import * as GeneralAction from "../../../shared/redux/general.actions";

declare function adjustSize(): any;

@Component({
	selector: "app-afiliados",
	templateUrl: "./afiliados.component.html",
	styleUrls: ["./afiliados.component.css"]
})
export class AfiliadosComponent implements OnInit, AfterViewInit {

	afiliado: AfiliadoModel = null;
	afiliadosMode: boolean = true;

	constructor(
		private store: Store<AppState>
	) { }

	ngOnInit(): void {
		this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
			this.afiliado = state.afiliado.afiliado;
		});

		if(!this.afiliadosMode){
			this.store.dispatch(GeneralAction.setAfiliadosMode({ afiliadosMode: true }));
		}
	}

	ngAfterViewInit() {
		adjustSize();
	}
}
