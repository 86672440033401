import {Component, OnDestroy, OnInit} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {IdiomaModel} from "../models/idioma.model";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../redux/general.reducers";
import * as GeneralAction from "../redux/general.actions";

@Component({
	selector: "app-w-languages",
	templateUrl: "./w-languages.component.html",
	styleUrls: ["./w-languages.component.css"]
})
export class WLanguagesComponent implements OnInit, OnDestroy {

	langs: IdiomaModel[];
	langSelected: IdiomaModel;
	subscription = new Subscription();


	constructor(
		public activeModalLang: NgbActiveModal,
		public store: Store<AppState>,
	) {
	}

	ngOnInit(): void {
		this.subscription.add(this.store.subscribe((state) => {
			this.langs = state.general.langs;
			this.langSelected = state.general.selectedLang;
		}));

		this.store.dispatch(GeneralAction.getLangs());
	}

	ngOnDestroy(): void {
		if(this.subscription){
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - NO ACTION ()
	noAction(event) {
		event.stopPropagation();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.activeModalLang.close();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SAVE ()
	onSave(event) {
		event.stopPropagation();
		this.activeModalLang.close();
		this.store.dispatch(GeneralAction.changeLang({lang: this.langSelected}));
		const data = {
			idioma_id: this.langSelected.id
		};
		this.store.dispatch(GeneralAction.setUserLang({params: data}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET LANG ()
	onSetLang(event, i) {
		event.stopPropagation();
		this.langSelected = this.langs[i];
	}

}
