import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

import {fixAndroid} from "../../../shared/utils/general.util";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {HttpEventType} from "@angular/common/http";
import {FileUploadService} from "../../../shared/services/file-upload.service";
import * as GeneralAction from "../../../shared/redux/general.actions";
import {Actions, ofType} from "@ngrx/effects";
import {Subscription} from "rxjs";
import {TicketsModel} from "../../../shared/models/tickets.model";
import Swal from "sweetalert2";
import {NotificationService} from "../../../shared/services/notification.service";
import {TranslateService} from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-w-new-msg",
	templateUrl: "./w-new-msg.component.html",
	styleUrls: ["./w-new-msg.component.css"]
})
export class WNewMsgComponent implements OnInit, OnDestroy {

	notasForm: FormGroup;
	progress = 0;
	uploadError = false;
	barStatus: string;
	subscription = new Subscription();
	@Input("ticket") ticket: TicketsModel;

	wActive: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private fileUploadService: FileUploadService,
		private formBuilder: FormBuilder,
		private store: Store<AppState>,
		private actions$: Actions,
		private notification: NotificationService,
		private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		this.initForm();
		this.subscription.add(this.actions$
			.pipe(ofType(GeneralAction.uploadFilesCompletado))
			.subscribe(response => {
				switch (response.data.type) {
					case HttpEventType.Sent:
						this.uploadError = false;
						this.progress = 0;
						break;
					case HttpEventType.ResponseHeader:
						break;
					case HttpEventType.UploadProgress:
						this.progress = Math.round(response.data.loaded / response.data.total * 100);
						if (this.progress !== 100) {
							this.barStatus = String(this.progress) + "%";
						}
						if (this.progress === 100) {
							this.barStatus = this.translateService.instant("ESPERE_POR_FAVOR");
						}
						break;
					case HttpEventType.Response:
						this.barStatus = this.translateService.instant("COMPLETADO");
						this.progress = 0;
						this.notasForm.controls.archivo_temporal_id.setValue(response.data.body.object.id);
						setTimeout(() => this.barStatus = null, 1000);
						return;
				}

			}));
		setTimeout(() => {
			this.wActive = true;
		}, 0);
		setTimeout(() => {
			fixAndroid("w-cover-new_msg");
		}, 250);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initForm() {
		this.notasForm = this.formBuilder.group(
			{
				mensaje: new FormControl(null, [Validators.required, Validators.minLength(1)]),
				archivo_temporal_id: new FormControl(null),
				ticket_id: new FormControl(this.ticket.id, [Validators.required])
			}
		);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SEND MSG ()
	onSendMsg(event) {
		event.stopPropagation();
		if (this.notasForm.valid) {
			const params = {
				ticket_id: this.notasForm.get("ticket_id").value,
				archivo_temporal_id: this.notasForm.get("archivo_temporal_id").value,
				nota: this.notasForm.get("mensaje").value,
			};
			this.store.dispatch(GeneralAction.addNotaTicket({params: params}));
			this.onCloseW();
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON UPLOAD FILE ()
	handle(event: any) {
		// alert("gentoo");
		if (!event.target.files[0]) {
			return;
		}
		const inpF = event.target as HTMLInputElement;


		if ((inpF.files[0] as any)) {
			const input = event.target;
			const file = input.files[0];
			const fileName = (inpF.files[0] as any).name;

			const sizeByte = file.size * 1;
			const result = sizeByte / 1024;
			const sizeKiloByte = parseInt(result + "", 10);

			if (sizeKiloByte > 1024) {
				Swal.fire(this.notification.BuildError(this.translateService.instant("TAMANNO_MAXIMO_SUPERA_MAXIMO", {value: 1}), this.translateService.instant("OPSS"))).then(() => {
				});
				return;
			}

			this.uploadImageFile(file, fileName);
		}
	}

	uploadImageFile(file, filename) {
		const params = {
			model: "TicketNota",
			file: file,
			filename: filename
		};
		this.store.dispatch(GeneralAction.uploadFiles({params: params}));
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - FIRE FILE ()
	fireFile(event) {
		event.stopPropagation();
		var thisE = event.target as HTMLButtonElement;
		var thisF = thisE.closest("div").querySelector("input") as HTMLInputElement;
		thisF.click();
		console.log(thisF);
	}

}
