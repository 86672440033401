import {AfterViewInit, Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {NotificationService} from "../../../shared/services/notification.service";
import {UtilityCurrencyModel} from "../../../shared/models/utility-currency.model";
import * as IMask from "imask";
import Swal from "sweetalert2";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {Actions, ofType} from "@ngrx/effects";
import * as CajeroAction from "../../redux/cajero.actions";
import {Subscription} from "rxjs";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";

import {fixAndroid} from '../../../shared/utils/general.util';
import {TranslateService} from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-exchanger",
	templateUrl: "./exchanger.component.html",
	styleUrls: ["./exchanger.component.css"]
})
export class ExchangerComponent implements OnInit, AfterViewInit {

	walletCurrency: WalletGeneralModel[];
	walletCurrencyUntouched: WalletGeneralModel[];
	utilityCurrency: UtilityCurrencyModel[];

	selectedOrigenCrypto: WalletGeneralModel;
	selectedDestinyCrypto: WalletGeneralModel;

	origenCryptoValue: any;
	destinyCryptoValue: any;
	destinyCryptoUtility: UtilityCurrencyModel;

	numberIMask = IMask.createMask({
		mask: Number,
		scale: 8,
		signed: false,
		thousandsSeparator: ",",
		radix: "."
	});

	numberIMaskTwo = IMask.createMask({
		mask: Number,
		scale: 8,
		signed: false,
		thousandsSeparator: ",",
		radix: "."
	});
	subscription = new Subscription();

	constructor(
		private route: ActivatedRoute,
		private notification: NotificationService,
		private store: Store<AppState>,
		private actions$: Actions,
		private translateService: TranslateService,
	) {
	}

	ngOnInit(): void {
		this.subscription.add(this.store.subscribe((state) => {
				this.walletCurrencyUntouched = state.cajero.wallets;
				this.walletCurrency = this.walletCurrencyUntouched.filter(x => x.balance_real > 0);
			})
		)
		this.destinyCryptoValue = "0";
		this.getWalletCurrency();
		this.onGetWallets();
      this.listeningActions();
      //
      setTimeout(() => { fixAndroid('exchanger'); }, 10);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	listeningActions() {
		this.subscription.add(this.actions$
			.pipe(ofType(CajeroAction.getUtilidadesPorMonedaCompletado))
			.subscribe(response => {
				const resp = response;
				this.utilityCurrency = resp.data.data;
				this.destinyCryptoUtility = this.utilityCurrency[0];
				if (this.destinyCryptoUtility) {
					this.selectedDestinyCrypto = this.walletCurrencyUntouched.find(x => x.moneda.id === this.destinyCryptoUtility.moneda_destino_id);
				}
				if (this.origenCryptoValue) {
					this.changeMonto();
				}
			})
		)

		this.subscription.add(this.actions$
			.pipe(ofType(CajeroAction.postExchangerCompletado))
			.subscribe(response => {
				const resp = response;
				Swal.fire(this.notification.BuildSuccess(resp.data.message, this.translateService.instant("INTERCAMBIO_SOLICITADO"))).then();
				this.origenCryptoValue = null;
				this.destinyCryptoValue = "0";
				const walletFinal = resp.data.wallets.find(x => x.id === this.selectedOrigenCrypto.id);
				this.selectedOrigenCrypto = walletFinal.balance_real > 0 ? walletFinal : this.walletCurrency[0];
				this.changeMonto();
			})
		)
	}

	ngAfterViewInit() {
		adjustSize();
	}


	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	selectCurrencyOrigen(event) {
		if (event) {
			event.stopPropagation();
		}
		this.selectedOrigenCrypto = this.walletCurrency.find(x => x.id === Number(event.target.value));
		const param = {
			id: this.selectedOrigenCrypto.id
		};
		this.getCurrencyUtilities(param);
	}

	selectCurrencyDestiny(event) {
		if (event) {
			event.stopPropagation();
		}
		this.destinyCryptoUtility = this.utilityCurrency.find(x => x.id === Number(event.target.value));
		if (this.destinyCryptoUtility) {
			this.selectedDestinyCrypto = this.walletCurrencyUntouched.find(x => x.moneda.id === this.destinyCryptoUtility.moneda_destino_id);
		}
		this.changeMonto();
	}

	calcUSD(base, multi) {
		multi = Number(multi);
		return base * multi;
	}

	changeMonto() {
		if (!this.origenCryptoValue) {
			this.destinyCryptoValue = "0";
			return;
		}
		if (Number(this.origenCryptoValue) > this.selectedOrigenCrypto.balance_real) {
			this.origenCryptoValue = String(this.selectedOrigenCrypto.balance_real);
		}
		this.destinyCryptoValue = String(Number(this.origenCryptoValue) * this.destinyCryptoUtility.precio_jugador);
	}

	onCancelExchanger() {
		// CANCEL
	}

	exchangeCrypto() {
		const param = {
			data: {
				monto_origen: Number(this.origenCryptoValue),
				tarifa: this.destinyCryptoUtility.precio_jugador,
				exchanger: this.destinyCryptoUtility.proveedor,
				moneda_origen: this.selectedOrigenCrypto.moneda.id,
				moneda_destino: this.destinyCryptoUtility.moneda_destino_id
			}
		};
		this.postCurrencyExchange(param);
	}

	onGetWallets() {
		if (this.walletCurrency.length > 0) {

			if (this.route.snapshot.queryParams.crypto) {
				this.selectedOrigenCrypto = this.walletCurrency.find(x => x.id === this.route.snapshot.queryParams.crypto * 1);
			}
			this.selectedOrigenCrypto = !this.selectedOrigenCrypto ? this.walletCurrency[0] : this.selectedOrigenCrypto;
			const param = {
				id: this.selectedOrigenCrypto.id
			};
			this.getCurrencyUtilities(param);
		}
	}

	getWalletCurrency() {
		this.store.dispatch(CajeroAction.getWallets());
	}

	getCurrencyUtilities(param) {
		this.store.dispatch(CajeroAction.getUtilidadesPorMoneda(param));
	}


	postCurrencyExchange(param) {
		this.store.dispatch(CajeroAction.postExchanger({param: param.data}));
	}

}
