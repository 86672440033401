import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BonosModel} from "../../../shared/models/bonos.model";
import {Router} from "@angular/router";
import {WDepositoComponent} from "../w-deposito/w-deposito.component";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import {MonedaModel} from "src/app/shared/models/wallet-currency.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as BonoAction from "../../../bonos/redux/bonos.actions";

@Component({
	selector: "app-w-pre-deposito",
	templateUrl: "./w-pre-deposito.component.html",
	styleUrls: ["./w-pre-deposito.component.css"]
})
export class WPreDepositoComponent implements OnInit {

	@Input() bono: BonosModel;
	@Input() balance: WalletGeneralModel;
	@Input() process: string;
	@Input() selectedCoin: MonedaModel;
	@Input() wallet: WalletGeneralModel;

	constructor(
		public activeModal: NgbActiveModal,
		private router: Router,
		private modalService: NgbModal,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW(event) {
		event.stopPropagation();
		this.activeModal.close();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATE ()
	onActivate(event) {
		event.stopPropagation();
		const param = {
			tipo: "ULTIMO_DEPOSITO",
			data: {
				bono_jugador_apto_id: this.bono.id
			}
		};
		this.activeModal.close(true);
		this.store.dispatch(BonoAction.activarBonos({param: param}));

	}


	getHashData(event) {
		this.openDeposit("ACTIVATED_BONO");

	}

	openDeposit(process) {
		this.activeModal.close();
		const modalRef = this.modalService.open(WDepositoComponent);
		modalRef.componentInstance.selectedWalletCurrency = this.selectedCoin;
		modalRef.componentInstance.bono = this.bono;
		modalRef.componentInstance.process = process;
	}

}
