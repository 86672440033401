<section class="wider tabbed" id="retiros">
    <div class="section-header">
        <div class="main-logo hidden-landscape" routerLink="/inicio/jugador">
            <img src="../../../../assets/images/bitwild-logo-w.svg" alt="BitWild Casino">
        </div>

        <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
        <div class="hidden-landscape">
            <div class="section-title">
                <div class="txt-snor pr-1">{{'RETIROS' |translate}}</div>
                <img src="../../../../assets/images/icon-m-retiro.svg" class="section-icon">
            </div>
        </div>
        <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
    </div>

    <div class="full-h port-rel">

        <ul class="tabs">
            <li (click)="onSetTab(0)" [class.active]="selectedTab==0" class="tac">{{'JUGADOR' |translate}}</li>
            <li (click)="onSetTab(1)" [class.active]="selectedTab==1" class="tac">{{'AFILIADO' |translate}}</li>
        </ul>

        <!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TAB 0 - JUGADOR -->
        <ng-container *ngIf="selectedTab==0">
            <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
            <div class="full-h px-1 of-y2 hidden-landscape">
                <div class="col col-3 full-h">
                    <table class="tbl2 tbl2-lg tbl2-striped striped-alt tbl-vert tbl2-head">
                        <tr class="">
                            <th>{{'MOV' |translate}}.</th>
                        </tr>
                        <tr>
                            <th>{{'FECHA_SOLICITUD' |translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'METODO_RETIRO' |translate}}</th>
                        </tr>
                        <tr>
                            <th class="th-2x">{{'CUENTA' |translate}}</th>
                        </tr>
                        <tr>
                            <th class="th-2x">{{'FECHA_ESTIMADA_PAGO' |translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'MONEDA' |translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'IMPORTE' |translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'ESTADO' |translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'CANCELAR' |translate}}</th>
                        </tr>
                    </table>
                </div>
                <div class="col col-9 full-h of-x2">
                    <div class="tbl2 tbl2-lg tbl2-striped tbl-vert faux-tbl-vert bg-g1"
                         *ngIf="retiroSolicitudesJugador.length > 0">
                        <!-- EMPTY -->
                        <div class="col tbl2-col-empty tac">
                            <div class="td">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td td-2x">&nbsp;</div>
                            <div class="td td-2x">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td td-icon td-icon-7th">&nbsp;</div>
                            <div class="cb"></div>
                        </div>
                        <!-- / EMPTY -->
                        <!-- DATA -->
                        <div class="col tbl2-col-n tac"
                             *ngFor="let retiro of retiroSolicitudesJugador; let i = index;">
                            <div class="td">{{retiro.id}}</div>
                            <div class="td">
                        <span>{{retiro.fecha.split(" ")[0]}} <br>
								<span class="t-sm wordBreak">{{retiro.fecha.split(" ")[1]}}</span></span>
                            </div>
                            <div class="td">{{retiro.metodo_retiro}}</div>
                            <div class="td td-2x">
                                <span class="txt-sm wordBreak">{{retiro.hash}}</span>
                            </div>
                            <div class="td td-2x">
                        <span>
                        {{retiro.fecha_pago.split(" ")[0]}}<br>
								<span class="txt-ssm">{{retiro.fecha_pago.split(" ")[1]}}</span></span>
                            </div>
                            <div class="td">{{retiro.moneda.siglas.toUpperCase()}}</div>
                            <div class="td">{{retiro.monto | number: "1.2-8"}}</div>
                            <div class="td">{{retiro.estado}}</div>
                            <div class="td td-icon td-icon-7th" (click)="onCancelRetiro(retiro)">
                                <img src="../../../../assets/images/icon-banned.svg">
                            </div>
                            <div class="cb"></div>
                        </div>
                        <!-- / DATA -->
                    </div>

                    <div class="flex-center full" *ngIf="retiroSolicitudesJugador.length < 1">
                        <div class="bg-g1 p-2">{{'NO_HAY_OPERACIONES_LISTADAS' |translate}}.</div>
                    </div>
                </div>
            </div>
            <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

        </ng-container>
        <!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / TAB 0 - JUGADOR -->

        <!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TAB 1 - AFILIADO -->
        <ng-container *ngIf="selectedTab==1">

            <!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
            <div class="full-h px-1 of-y2 hidden-landscape">
                <div class="col col-3 full-h">
                    <table class="tbl2 tbl2-lg tbl2-striped striped-alt tbl-vert tbl2-head">
                        <tr class="">
                            <th>Mov.</th>
                        </tr>
                        <tr>
                            <th>Fecha Solicitud</th>
                        </tr>
                        <tr>
                            <th>Método Retiro</th>
                        </tr>
                        <tr>
                            <th class="th-2x">Cuenta</th>
                        </tr>
                        <tr>
                            <th class="th-2x">Fecha Estimada de Pago</th>
                        </tr>
                        <tr>
                            <th>Moneda</th>
                        </tr>
                        <tr>
                            <th>Importe</th>
                        </tr>
                        <tr>
                            <th>Estado</th>
                        </tr>
                        <tr>
                            <th>Cancelar</th>
                        </tr>
                    </table>
                </div>
                <div class="col col-9 full-h of-x2">
                    <div class="tbl2 tbl2-lg tbl2-striped tbl-vert faux-tbl-vert bg-g1"
                         *ngIf="retiroSolicitudesAfiliado.length > 0">
                        <!-- EMPTY -->
                        <div class="col tbl2-col-empty tac">
                            <div class="td">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td td-2x">&nbsp;</div>
                            <div class="td td-2x">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="td">&nbsp;</div>
                            <div class="cb"></div>
                        </div>
                        <!-- EMPTY -->
                        <!-- DATA -->
                        <div class="col tbl2-col-n tac"
                             *ngFor="let retiro of retiroSolicitudesAfiliado; let i = index;">
                            <div class="td">{{retiro.id}}</div>
                            <div class="td">
                        <span>{{retiro.fecha.split(" ")[0]}}<br>
                        <span class="txt-ssm">{{retiro.fecha.split(" ")[1]}}</span></span>
                            </div>
                            <div class="td">{{retiro.metodo_retiro}}</div>
                            <div class="td td-2x">
                                <span class="txt-ssm wordBreak">{{retiro.hash}}</span>
                            </div>
                            <div class="td td-2x">
                        <span>
                        {{retiro.fecha_pago.split(" ")[0]}}<br>
                           <span class="">{{retiro.fecha_pago.split(" ")[1]}}</span>
                           </span>
                            </div>
                            <div class="td">{{retiro.moneda.siglas.toUpperCase()}}</div>
                            <div class="td">{{retiro.monto | number: "1.2-8"}}</div>
                            <div class="td">{{retiro.estado}}</div>
                            <div class="td td-icon td-icon-7th" (click)="onCancelRetiro(retiro)">
                                <img src="../../../../assets/images/icon-banned.svg">
                            </div>
                            <div class="cb"></div>
                        </div>
                        <!-- / DATA -->
                    </div>

                    <div class="flex-center full" *ngIf="retiroSolicitudesAfiliado.length < 1">
                        <div class="bg-g1 p-2">No hay retiros listados.</div>
                    </div>
                </div>
            </div>
            <!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->

        </ng-container>
        <!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / TAB 1 - AFILIADO -->

    </div>

</section>
