import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {GamesModel} from "../../../shared/models/games.model";
import {SessionServices} from "../../../shared/services/session.services";
import {ListenerGameService} from "../../services/listener-game.service";
import {Router} from "@angular/router";
import { MonedaModel } from "src/app/shared/models/wallet-currency.model";
import * as GamesAction from "../../redux/games.actions";
import * as GeneralAction from "../../../shared/redux/general.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";

declare function adjustSize(): any;

@Component({
	selector: "app-game-info",
	templateUrl: "./game-info.component.html",
	styleUrls: ["./game-info.component.css"]
})
export class GameInfoComponent implements OnInit {

	@Input() game: GamesModel;
	@Input() cryptoCurrency: MonedaModel[];

	constructor(
		public activeModal2: NgbActiveModal,
		private sessionService: SessionServices,
		private listenerGameService: ListenerGameService,
		private router: Router,
		private store:Store<AppState>
	) {
	}

	ngOnInit(): void {
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - NO CLICK ()
	noClick(event) {
		event.stopPropagation();
		event.preventDefault();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW(event) {
		event.stopPropagation();
		this.activeModal2.close();
		adjustSize();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GO PLAY ()
	onGoPlay() {
		this.store.dispatch(GamesAction.setJuegoSeleccionado({game: this.game}));
		let nuevoMenu = {
			menu: true,
			catalog: true,
			exchanger: false,
			back: false,
			show_catalog_component: false,
			show_exchager_component: false,
			show_back_component: false,
			show_menu_component: false,
			notificaciones: true,
			show_notificaciones: false,
			sub_menu_activo: 'catalogo',
			sub_sub_menu_activo: 'playground',
			cuenta_verificada: this.sessionService.getPlayer().estado_cuenta.toUpperCase() === "ACTIVA" ? true : false
		};
		this.store.dispatch(GeneralAction.setMyMenu({menu: nuevoMenu, eventEmiterComponent: this.constructor.name}));
		this.router.navigate(["/games/playground"], {queryParams: {gameID: this.game.id}}).then();
		this.activeModal2.close(true);
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	itLoggedIn() {
		return this.sessionService.isLoggedIn();
	}

}
