
<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
<div class="tab-container-afiliados-0 hidden-landscape">
	<div class="tab-container-afiliados-0 px-15">
		<div class="afiliados-balances-container full of-y2">
			<ng-container *ngIf="allBalances.length < 1">
				<div class="empty-balance bg-g1 p-2 tac">{{'NO_HAY_BALANCES' | translate}}.</div>
			</ng-container>

			<ng-container *ngIf="allBalances.length > 0">
				<!-- BALANCE CARD -->
				<div class="balance-card" *ngFor="let balance of allBalances; let i = index;"
					[class.selected]="balanceAtivo && balanceAtivo.moneda_id == balance.moneda_id" (click)="onSelectBalance(i)">
					<div class="flex-col mb-2">
						<div class="col-4">
							<img class="coin"
								src="../../../../assets/images/crypto/{{ balance.moneda_siglas.toLowerCase()}}.svg">
						</div>
						<div class="col-8 flex-y pl-05 wordBreak">{{ balance.moneda_nombre }}</div>
					</div>
					<div class="txt-nor mb-05">
						<div class="fl txt-c2">{{ balance.moneda_siglas.toUpperCase() }}</div>
						<div class="fr">{{ balance.balance | number: "1.2-8"}}</div>
						<div class="cb"></div>
					</div>
					<div class="txt-ssm">
						<div class="fl txt-c4">{{ 'USD' }}</div>
						<div class="fr txt-g9">{{balance.balance_usd | number: "1.2-8"}}</div>
						<div class="cb"></div>
					</div>
				</div>
				<!-- / BALANCE CARD -->
			</ng-container>
		</div>
	</div>
	<div class="px-15 pt-1">
		<div class="col col-6 pr-07">
			<button class="btn2 btn2-lg btn2-4th bg-k5 full-w" type="button" [disabled]="balanceAtivo == null" (click)="onWithdraw()">
            {{'RETIRAR' | translate}}</button>
		</div>
		<div class="col col-6 pl-07">
			<button class="btn2 btn2-lg btn2-1st bg-k5 full-w txt-mx2 px-0" type="button" [disabled]="balanceAtivo == null" (click)="onAddBalance()">
            {{'TRANSFERIR_A_JUEGO' | translate}}</button>
		</div>
		<div class="cb"></div>
	</div>
</div>
<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
