import {Component, OnInit} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {WCallRequestComponent} from "../../../shared/w-call-request/w-call-request.component";
import {SessionServices} from "../../../shared/services/session.services";

declare function adjustSize(): any;

@Component({
	selector: "app-w-help",
	templateUrl: "./w-help.component.html",
	styleUrls: ["./w-help.component.css"]
})
export class WHelpComponent implements OnInit {

	constructor(
		private router: Router,
		private modalService: NgbModal,
		private sessionService: SessionServices,
		public activeModal2: NgbActiveModal
	) {
	}

	ngOnInit(): void {
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW(event) {
		event.stopPropagation();
		this.activeModal2.close();
		adjustSize();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON OPEN TICKETS ()
	onOpenTickets(event) {
		event.stopPropagation();
		this.activeModal2.close();
		this.router.navigate(["/ayuda/tickets"]);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CALL REQUEST ()
	onCallRequest(event) {
		event.stopPropagation();
		this.activeModal2.close();
		const modalRef = this.modalService.open(WCallRequestComponent);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GO CHAT ()
	goChat(event) {
		event.stopPropagation();
		this.activeModal2.close();
		//....
	}

	isLogIn() {
		return !!this.sessionService.getPlayer();
	}

}
