import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {SessionServices} from "../services/session.services";

@Injectable({
	providedIn: "root"
})
export class AdminGuard implements CanActivate {

	constructor(private sessionService: SessionServices, private router: Router) {
	}

	canActivate() {
		return this.canLoad();
	}

	canLoad() {
		if (!this.sessionService.getPlayer()) {
			this.router.navigate(["/inicio/visitante"]).then();
		}
		return !!this.sessionService.getPlayer();
	}

}
