import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {JugadorModel} from "../../../../shared/models/user.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ResponseModel} from "../../../../shared/models/response.model";
import {CuentaService} from "../../../services/cuenta.service";
import * as CuentaAction from "../../../redux/cuenta.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/redux/general.reducers";

@Component({
	selector: "app-tab-datos-password",
	templateUrl: "./tab-datos-password.component.html",
	styleUrls: ["./tab-datos-password.component.css"]
})
export class TabDatosPasswordComponent implements OnInit, OnDestroy {

	@Input() account: JugadorModel;

	accountForm: FormGroup;

	edit: boolean;

	constructor(
		private cuentaService: CuentaService,
		private formBuilder: FormBuilder,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.edit = false;

		this.initForm();
	}

	ngOnDestroy(): void {
	}

	initForm() {
		this.accountForm = this.formBuilder.group(
			{
				password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
				passwordNew: new FormControl(null, [Validators.required, Validators.minLength(6)]),
				passwordNewConfirmation: new FormControl(null, [Validators.required])
			}, {
				validator: [
					this.MustMatch("passwordNew", "passwordNewConfirmation"),
					this.ValidPassword("passwordNew")
				]
			}
		);
		this.accountForm.disable();
	}

	private MustMatch(controlName: string, matchingControlName: string) {
		return (formGroup: FormGroup) => {
			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];
			if (matchingControl.errors && !matchingControl.errors.mustMatch) {
				return;
			}
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({mustMatch: true});
			} else {
				matchingControl.setErrors(null);
			}
		};
	}

	private ValidPassword(controlName: string) {
		return (formGroup: FormGroup) => {
			const control = formGroup.controls[controlName];
			if (control.errors && !control.errors.validPassword) {
				return;
			}
			if (/[A-Z]/.test(control.value) === false ||
				/[a-z]/.test(control.value) === false ||
				/\d/.test(control.value) === false ||
				/\W/.test(control.value) === false) {
				control.setErrors({validPassword: true});
			} else {
				control.setErrors(null);
			}
		};
	}

	inputTouched(formName: string) {
		return this.accountForm.controls[formName].invalid && (this.accountForm.controls[formName].dirty || this.accountForm.controls[formName].touched);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON EDIT ()
	onEdit() {
		this.edit = true;
		this.accountForm.enable();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SAVE ()
	disableRegister() {
		const controlOne = this.accountForm.controls.password;
		const controlThree = this.accountForm.controls.passwordNew;
		const controlFour = this.accountForm.controls.passwordNewConfirmation;
		if (!controlOne.valid || !controlThree.valid || !controlFour.valid) {
			return true;
		}
		return controlOne.value === null || controlThree.value === null || controlFour.value === null;
	}

	onSave() {
		if (this.accountForm.valid) {
			const accountModel = this.accountForm.value;
			const param = {
				data: {
					old_password: accountModel.password,
					password: accountModel.passwordNew,
					password_confirmation: accountModel.passwordNewConfirmation
				}
			};
			this.patchUpdatePassword(param.data);
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DISCARD ()
	onDiscard() {
		this.accountForm.reset();
		this.accountForm.disable();
		this.edit = false;
	}

	patchUpdatePassword(param) {
		this.store.dispatch(CuentaAction.patchJugadorPasswords({params:param}));
		this.initForm();
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON EDIT ACCORDION ()
	onEditAccordion(thisElement) {
		var accrdn = thisElement.closest(".formSection") as HTMLDivElement;
		var inps = accrdn.getElementsByTagName('input');
		var sels = accrdn.getElementsByTagName('select');
		if (inps.length > 0) {
			for (var i = 0; i < inps.length; i++) {
				inps[i].disabled = false;
			}
		}
		if (sels.length > 0) {
			for (var j = 0; j < sels.length; j++) {
				sels[j].disabled = false;
			}
		}
		var edts = accrdn.getElementsByClassName('edit');
		for (var k = 0; k < edts.length; k++) {
			edts[k].classList.toggle('active')
		}
		this.onEdit();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - DISABLE ACCORDION ()
	disableAccordion(accrdn) {
		var inps = accrdn.getElementsByTagName('input');
		var sels = accrdn.getElementsByTagName('select');
		if (inps.length > 0) {
			for (var i = 0; i < inps.length; i++) {
				inps[i].disabled = true;
			}
		}
		if (sels.length > 0) {
			for (var j = 0; j < sels.length; j++) {
				sels[j].disabled = true;
			}
		}
		var edts = accrdn.getElementsByClassName('edit');
		for (var k = 0; k < edts.length; k++) {
			edts[k].classList.toggle('active')
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ACCORDION ()
	onCancelAccordion(thisElement) {
		var accrdn = thisElement.closest(".formSection") as HTMLDivElement;
		this.disableAccordion(accrdn);

		this.onDiscard();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SAVE ACCORDION ()
	onSaveAccordion(thisElement) {
		var accrdn = thisElement.closest(".formSection") as HTMLDivElement;
		this.disableAccordion(accrdn);
		this.onSave();
	}

}
