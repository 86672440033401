import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import * as IMask from "imask";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";

import {fixAndroid} from "../../../shared/utils/general.util";

@Component({
	selector: "app-w-retiro",
	templateUrl: "./w-retiro.component.html",
	styleUrls: ["./w-retiro.component.css"]
})
export class WRetiroComponent implements OnInit, OnDestroy {

	montoRetirar: string;
	walletHash: string;

	@Input() selectedWalletCurrency: WalletGeneralModel;

	numberIMask = IMask.createMask({
		mask: Number,
		scale: 8,
		signed: false,
		thousandsSeparator: ",",
		radix: "."
	});

	wActive: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.wActive = true;
		}, 0);
		setTimeout(() => {
			fixAndroid("wallet-withdraw-cover");
		}, 250);
	}

	ngOnDestroy(): void {

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON COPY HASH ()
	onPasteHash(event) {
		event.stopPropagation();
		navigator.clipboard.readText().then(s => this.walletHash = s);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON WITHDRAW ()
	onWithdraw(event) {
		event.stopPropagation();

		const param = {
			data: {
				hash: this.walletHash,
				moneda_id: this.selectedWalletCurrency.moneda.id,
				monto: Number(this.montoRetirar),
				tipo: "JUGADOR"
			}
		};
		this.retirarCurrencyWallet(param);
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	changeMonto() {
		if (Number(this.montoRetirar) > this.selectedWalletCurrency.balance_total) {
			this.montoRetirar = String(this.selectedWalletCurrency.balance_total);
		}
	}

	retirarCurrencyWallet(param) {
		this.store.dispatch(CajeroAction.addSolicitudRetiro({param: param.data}));
		this.activeModal.close(true);
	}

}

