import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WHomegameInfoComponent} from "./w-homegame-info/w-homegame-info.component";
import {RegisterComponent} from "../../../authetication/components/register/register.component";
import {LoginComponent} from "../../../authetication/components/login/login.component";
import {GamesModel} from "../../../shared/models/games.model";
import {GameCategoryModel} from "../../../shared/models/game-category,model";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
// import {WTerminosCondicionesComponent} from "../../../shared/w-terminos-condiciones/w-terminos-condiciones.component";
import {WConversionCoinComponent} from "../w-conversion-coin/w-conversion-coin.component";
import {BonosVisitanteModel} from "../../../shared/models/bonos-visitante.model";
import {ActivatedRoute, Router} from "@angular/router";
import Swal from "sweetalert2";
import {HomeService} from "../../services/home.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {SessionServices} from "../../../shared/services/session.services";
import {TorneoModel} from "../../../shared/models/torneo.model";
import {MonedasSiglasLocal} from "../../../shared/utils/general.util";
import * as AutheticationAction from "../../../authetication/redux/authentication.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import * as GameAction from "../../../games/redux/games.actions";
import * as cajeroAction from "../../../cajero/redux/cajero.actions";
import * as bonosAction from "../../../bonos/redux/bonos.actions";
import * as GeneralAction from "../../../shared/redux/general.actions";
import {MonedaModel} from "src/app/shared/models/wallet-currency.model";
import {Subscription} from "rxjs";
import {Actions, ofType} from "@ngrx/effects";
import * as AuthAction from "../../../authetication/redux/authentication.actions";
import { WLanguagesComponent } from "../../../shared/w-languages/w-languages.component";
import {IdiomaModel} from "../../../shared/models/idioma.model";

@Component({
	selector: "app-home-visitor",
	templateUrl: "./home-visitor.component.html",
	styleUrls: ["./home-visitor.component.css"]
})
export class HomeVisitorComponent implements OnInit, AfterViewInit, OnDestroy {

	urlSafe: SafeResourceUrl;

	coinsA = MonedasSiglasLocal;

	iH = window.innerHeight;

	noImagenesACargar: number = 20;
	@ViewChild('divContenerdor') divContenerdor: any;
	games: GamesModel[];
	gamesCategory: GameCategoryModel[];
	cryptoCurrency: MonedaModel[];
	categoriaSeleccionada: GameCategoryModel;
	filteredGames: GamesModel[];
	juegosAMostrar: GamesModel[];
	bonos: BonosVisitanteModel[];

	torneo: TorneoModel;

	counterOn: any;
	countDD: number;
	countHH: number;
	countMM: number;
	countSS: number;
	subscription = new Subscription();
	selectedLang: IdiomaModel;


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET SLIDES ()
	sT: any;
	afiliadosMode: boolean = false;

	constructor(
		private modalService: NgbModal,
        private modalServiceLang: NgbModal,
		private notification: NotificationService,
		private router: Router,
		private route: ActivatedRoute,
		private homeService: HomeService,
		private sanitizer: DomSanitizer,
		private sessionService: SessionServices,
		private store: Store<AppState>,
		private actions$: Actions,
	) {

	}

	ngOnInit(): void {
		this.subscription.add(this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
			this.selectedLang = state.general.selectedLang;
		}));

		this.store.dispatch(GeneralAction.setAfiliadosMode({afiliadosMode: false}));

		this.sT = "";
		//const eBody = document.body as HTMLBodyElement;
		//eBody.className = "";
		document.getElementById('main-container').classList.add('unboxed');
		document.getElementById("turn").classList.add("noTurn");


		this.subscription.add(this.actions$
			.pipe(ofType(AuthAction.activacionCuentaJugadorCompletado))
			.subscribe(response => {
				Swal.fire(this.notification.BuildSuccess(response.data.message)).then(() => {
					if (!this.sessionService.getPlayer()) {
						this.onLogIn();
					}
				});
			}));


		this.initData();

		// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! RUTA EN SERVIDOR LOCAL
		this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("http://localhost:8888/-plain_html/terminos_y_condiciones.html");
	}

	ngAfterViewInit() {
		this.setSlides();
		//
		this.checkLoadingStatus();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		//const eBody = document.body as HTMLBodyElement;
		//eBody.className = "bodyPortrait";
		document.getElementById("turn").classList.remove("noTurn");
		document.getElementById('main-container').classList.remove('unboxed');
		if (this.sT) {
			clearInterval(this.sT);
		}
		if (this.counterOn) {
			clearInterval(this.counterOn);
		}
	}


	listeningActions() {
		this.subscription.add(this.store.select((state) => state).subscribe(state => {
				this.games = state.games.games.value;
				this.gamesCategory = state.games.clasificacionesVisuales.value;
				this.categoriaSeleccionada = !this.categoriaSeleccionada ? this.gamesCategory[0] : this.categoriaSeleccionada;
				this.filteredGames = this.games.filter(x => x.clasificacion_visual_juego_ids.find(y => y === this.categoriaSeleccionada.id));
				this.juegosAMostrar = this.filteredGames.slice(0, this.noImagenesACargar);
				this.cryptoCurrency = state.cajero.cryptoMonedas.value;
				this.bonos = state.bonos.bonosHome.value;
				this.torneo = state.general.torneoActivo.value;
				if (this.torneo) {
					this.setTorneoTimer();
				}
			})
		)
	}

	initData() {
		this.listeningActions();

		this.getCryptoCurrency();

		this.getBonosVisitantes();

		this.getCatAndGames();

		if (this.gamesCategory.length > 0) {
			this.onSetCat(this.gamesCategory[0]);
		}

		this.getCurrentTorneo();

		if (this.torneo) {
			this.setTorneoTimer();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET CAT ()
	onSetCat(cat: GameCategoryModel) {
		if (this.divContenerdor) {
			this.divContenerdor.nativeElement.scrollTop = 0;
			this.divContenerdor.nativeElement.scrollLeft = 0;
		}
		this.categoriaSeleccionada = cat;
		this.filteredGames = this.games.filter(x => x.clasificacion_visual_juego_ids.find(y => y === cat.id));
		this.juegosAMostrar = this.filteredGames.slice(0, this.noImagenesACargar);
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET SLIDES ()
	setSlides() {
		const slideS = document.getElementsByClassName("home-slide");
		const slidesOrder = [];
		//
		if (slideS.length > 0) {
			// set
			for (let i = 0; i < slideS.length; i++) {
				slideS[i].classList.add("hs-" + i);
				slidesOrder.push(i);
			}
			// start
			this.sT = setInterval(x => {
				slidesOrder.push(slidesOrder.shift());
				for (let j = 0; j < slideS.length; j++) {
					slideS[j].className = "home-slide hs-" + slidesOrder[j];
				}
			}, 3500);
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON LOGIN ()
	onLogIn() {
		if (this.itsLogged()) {
			this.router.navigate(["bonos/mis_bonos"]).then();
			return;
		}
		const modalRef = this.modalService.open(LoginComponent);
		modalRef.result.then(
			(resp: any) => {
				if (resp === "GoToRegister") {
					this.onGoRegister();
				}
			});
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GO REGISTER ()
	onGoRegister() {
		const modalRef = this.modalService.open(RegisterComponent);
		modalRef.result.then(
			(resp: any) => {
				if (resp === "GoToLogin") {
					this.onLogIn();
				}
			});
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON FILTER CAT ()

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOOGLE RANK */
	onToggleRank(event, id) {
		event.stopPropagation();
		const tcE = document.getElementById("tcE-" + id) as HTMLDivElement;
		tcE.classList.toggle("active");
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON GO AFIL */
	onGoAfil() {
		if (this.itsLogged()) {
			this.router.navigate(["/afiliados/inicio"]).then();
		} else {
			this.router.navigate(["/afiliados/visitante"]).then();
			window.scroll(0, 0);
		}
	}


	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	fixCatName(name) {
		return name.replace(/\s?$/, "");
	}

	changeImg(event, cat: string) {
		switch (cat.replace(/\s?$/, "").toLocaleUpperCase()) {
			case "Favoritos".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-favorite.svg";
				break;
			case "Destacados".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-feature.svg";
				break;
			case "Slots".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-slot.svg";
				break;
			case "Slots Clásicos".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-slot2.svg";
				break;
			case "Mesas".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-table.svg";
				break;
			case "Especiales".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-special.svg";
				break;
			case "En Vivo".toLocaleUpperCase():
				event.target.src = "../../../../assets/images/icon-cat-live.svg";
				break;
			default:
				event.target.src = "../../../../assets/images/icon-cat-slot.svg";
		}
	}

   goChat() {}

   /*
	goToHelp() {
		const modalRef = this.modalService.open(WHelpComponent);
		// modalRef.result.then();
   }

	goToTermConditions() {
		const modalRef = this.modalService.open(WTerminosCondicionesComponent);
		modalRef.result.then();
	}

	goToPrivacy() {
	}
   */
   onGoMrWW() {}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW GAME INFO ()
	onShowGameInfo(game) {
		const modalRef = this.modalService.open(WHomegameInfoComponent);
		modalRef.componentInstance.game = game;
		modalRef.componentInstance.cryptoCurrency = this.cryptoCurrency;
		modalRef.result.then();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATE BONO ()

	onActivateBono(bono: BonosVisitanteModel) {
		if (this.itsLogged()) {
			this.router.navigate(["bonos/mis_bonos"]).then();
			return;
		}
		const tempCryptoCurrency = [];
		for (const moneda of bono.monedas) {
			tempCryptoCurrency.push(this.cryptoCurrency.find(x => x.id === moneda.id));
		}
		const modalRef = this.modalService.open(WConversionCoinComponent);
		modalRef.componentInstance.cryptoCurrency = tempCryptoCurrency;
		modalRef.componentInstance.bono = bono;
		modalRef.componentInstance.process = "REGISTER_BONO";
	}

	onUserMenu() {
		console.log(7);
		this.router.navigate(["/inicio/jugador"]).then();
	}

	confirmacionCuenta() {
		const api_token = this.route.snapshot.queryParams.api_token;
		if (api_token) {
			const params = {
				api_token: api_token
			}
			this.store.dispatch(AutheticationAction.activacionCuentaJugador({params: params}));
		}
	}

	itsLogged() {
		return this.sessionService.isLoggedIn();
	}

	getPremioPlace(place) {
		return this.torneo.premios.find(x => Number(x.numero) === place);
	}

	logOutAction() {
		this.store.dispatch(AutheticationAction.logout());
	}

	chekeandoAfiliados() {
		// Afiliados
		const codigo = this.route.snapshot.queryParams.cod;
		if (codigo) {
			this.onGoRegister();
			const param = {
				data: {
					campana_codigo: codigo
				}
			};
			this.homeService.saveVisita(param).subscribe();
		}
	}

	dhm(ms) {
		const days = Math.floor(ms / (24 * 60 * 60 * 1000));
		const daysms = ms % (24 * 60 * 60 * 1000);
		const hours = Math.floor((daysms) / (60 * 60 * 1000));
		const hoursms = ms % (60 * 60 * 1000);
		const minutes = Math.floor((hoursms) / (60 * 1000));
		const minutesms = ms % (60 * 1000);
		const sec = Math.floor((minutesms) / (1000));

		this.countDD = Number(days);
		this.countHH = Number(hours);
		this.countMM = Number(minutes);
		this.countSS = Number(sec);
		// return days + ":" + hours + ":" + minutes + ":" + sec;
	}

	setTorneoTimer() {
		const today = new Date();
		let finalDay;
		if (this.torneo.estado === "ACTIVO") {
			finalDay = new Date(this.torneo.fecha_fin);
		} else {
			finalDay = new Date(this.torneo.fecha_inicio);
		}
		// @ts-ignore
		const diff = finalDay - today;
		this.dhm(diff);
		this.counterOnFunc();
	}

	counterOnFunc() {
		if (this.counterOn) {
			clearInterval(this.counterOn);
		}
		this.counterOn = setInterval(() => {
			this.countSS--;
			if (this.countSS < 0) {
				this.countSS = 59;
				this.countMM--;
				if (this.countMM < 0) {
					this.countMM = 59;
					this.countHH--;
					if (this.countHH < 1) {
						this.countHH = 23;
						this.countDD--;
					}
				}
			}
		}, 1000);
	}


	// New API Calls

	getCatAndGames() {
		let endURL = "";
		const player = this.sessionService.getPlayer();
		if (player) {
			endURL = "?jugador_id=" + player.id;
		}
		this.store.dispatch(GameAction.clasificacionesVisualesJuegos({endUrl: endURL}));
	}

	getCryptoCurrency() {
		this.store.dispatch(cajeroAction.getCryptoMonedas());
	}

	getBonosVisitantes() {
		this.store.dispatch(bonosAction.getBonosHome());
	}

	getCurrentTorneo() {
		this.store.dispatch(GeneralAction.getTorneoActivo());
	}

	checkLoadingStatus() {
		if (document.readyState === "complete") {
			this.chekeandoAfiliados();
			this.confirmacionCuenta();
		} else {
			document.onreadystatechange = () => {
				if (document.readyState === "complete") {
					this.chekeandoAfiliados();
					this.confirmacionCuenta();
				}
			};
		}
	}


	onScroll() {
		if (this.filteredGames.length > this.noImagenesACargar) {
			this.noImagenesACargar += 20;
			this.juegosAMostrar = this.filteredGames.slice(0, this.noImagenesACargar);
		}
	}


   onSelectLanguage() {
      const modalRefLang = this.modalServiceLang.open(WLanguagesComponent);
   }

}

