import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {apiRoutes} from "../../api-routing";

@Injectable({
	providedIn: "root"
})
export class NavigationService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	dummyPost(credential: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_login);
		const response = this.httpClient.post(url, credential);
		return response.pipe(map((data: any) => data));
	}


}
