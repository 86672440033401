import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WCampaignInfoComponent } from "./w-campaign-info/w-campaign-info.component";
import { FilterModel } from "../../../shared/models/filter.model";
import { FormGroup } from "@angular/forms";
import { CampaignModel } from "../../../shared/models/campaign.model";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import * as AfiliadosActions from '../../../afiliados/redux/afiliados.actions';

@Component({
	selector: "app-campaigns",
	templateUrl: "./campaigns.component.html",
	styleUrls: ["./campaigns.component.css"]
})
export class CampaignsComponent implements OnInit {

	campaigns: CampaignModel[];

	searchForm: FormGroup;
	filter: FilterModel;
	
	baseUrl = `${window.location.origin}/playground-guest?cod=`;

	constructor(
		private modalService: NgbModal,
		private store: Store<AppState>
	) { }

	ngOnInit(): void {
		this.initFilter();
		this.store.select((state) => state).subscribe(state => {
			this.campaigns = state.afiliado.campannas.value;
		});

		this.store.dispatch(AfiliadosActions.afiliadosGetCampanna({ 'filter': this.filter }));
	}

	initFilter() {
		this.filter = new FilterModel(1000);
	}

	onShowInfo(campaign: CampaignModel) {
		const modalRef = this.modalService.open(WCampaignInfoComponent);
		modalRef.componentInstance.campaign = campaign;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON COPY HASH ()
	onCopyHash(event, code) {
		event.stopPropagation();
		const selBox = document.createElement("textarea");

		selBox.style.position = "fixed";
		selBox.style.left = "0";
		selBox.style.top = "0";
		selBox.style.opacity = "0";
		selBox.value = `${this.baseUrl}${code}`;

		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();

		document.execCommand("copy");
		document.body.removeChild(selBox);
	}

	onFacebookShare(code) {
		const facebook = "https://www.facebook.com/sharer/sharer.php?u=";
		window.open(facebook + this.baseUrl + code);
	}

	onTwitterShare(code) {
		const twitter = "https://twitter.com/home?status=";
		window.open(twitter + this.baseUrl + code);
	}

}
