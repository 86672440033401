import { createAction, props } from '@ngrx/store';
import { AfiliadoModelCompleteObject } from 'src/app/shared/models/afiliado.model';
import { BalanceAfiliadosModel } from 'src/app/shared/models/balance-afiliados.model';
import { FilterModel } from 'src/app/shared/models/filter.model';
import { ResumenPeriodoAfiliadosModel } from 'src/app/shared/models/resumen-periodo-afiliados.model';



export const afiliadosCreateAccount = createAction('[Afiliados] Create Account', props<{ 'usuario': string }>());
export const afiliadosCreateAccountCompletado = createAction('[Afiliados] Create Account Completado', props<{ object: AfiliadoModelCompleteObject }>());

export const afiliadosGetAccountAuthCompletado = createAction('[Afiliados] Get Account Auth Completado', props<AfiliadoModelCompleteObject>());

export const afiliadosGetResumenPeriodoActual = createAction('[Afiliados] Get Resumen Periodo Actual');
export const afiliadosGetResumenPeriodoActualCompletado = createAction('[Afiliados] Get Resumen Periodo Actual Completado', props<{ object: ResumenPeriodoAfiliadosModel }>());

export const afiliadosCreateCampanna = createAction('[Afiliados] Create Campanna', props<{ 'nombre': string }>());
export const afiliadosCreateCampannaCompletado = createAction('[Afiliados] Create Campanna Completado', props<{ data: any }>());
export const afiliadosGetCampanna = createAction('[Afiliados] Get Campanna', props<{ 'filter': FilterModel }>());
export const afiliadosGetCampannaCompletado = createAction('[Afiliados] Get Campanna Completado', props<{ data: any }>());

export const afiliadosGetTrafico = createAction('[Afiliados] Get Trafico', props<{ 'filter': FilterModel }>());
export const afiliadosGetTraficoCompletado = createAction('[Afiliados] Get Trafico Completado', props<{ data: any }>());

export const afiliadosGetPeriodoActualComisiones = createAction('[Afiliados] Get Periodo Actual Comisiones');
export const afiliadosGetPeriodoActualComisionesCompletado = createAction('[Afiliados] Get Periodo Actual Comisiones Completado', props<{ data: any }>());

export const afiliadosGetBatchComisiones = createAction('[Afiliados] Get Batch Comisiones', props<{ 'filter': FilterModel }>());
export const afiliadosGetBatchComisionesCompletado = createAction('[Afiliados] Get Batch Comisiones Completado', props<{ data: any }>());

export const afiliadosSetComisionesActiveTab = createAction('[Afiliados] Set Comisiones Active Tab', props<{ activeTab: number }>());

export const afiliadosGetBalances = createAction('[Afiliados] Get Balances');
export const afiliadosGetBalancesCompletado = createAction('[Afiliados] Get Balances Completado', props<{ data: { data: BalanceAfiliadosModel[] } }>());

export const afiliadosAddRetiro = createAction('[Afiliados] Add Retiro', props<{
    retiroData: {
        afiliado_id: number,
        hash: string,
        monto: number,
        moneda_id: number,
        tipo: string,
        accion: string
    }
}>());
export const afiliadosAddRetiroCompletado = createAction('[Afiliados] Add Retiro Completado', props<{ data: any }>());

export const afiliadosSetBlanceActive = createAction('[Afiliados] Set Balance Active', props<{ activeBalance: BalanceAfiliadosModel }>());

export const afiliadosLogoutCompletado = createAction('[Afiliados] Logout Completado');
