import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BonosJugadorAptoModel, BonosModel} from "../../../shared/models/bonos.model";
import {Router} from "@angular/router";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {Subscription} from "rxjs";
import * as BonoAction from "../../../bonos/redux/bonos.actions";



@Component({
	selector: "app-w-activate-gratis-devolucion",
	templateUrl: "./w-activate-gratis-devolucion.component.html",
	styleUrls: ["./w-activate-gratis-devolucion.component.css"]
})
export class WActivateGratisDevolucionComponent implements OnInit, OnDestroy {

	@Input() bono: BonosJugadorAptoModel;
	balance: WalletGeneralModel;
	subscription = new Subscription();

	constructor(
		public activeModal: NgbActiveModal,
		private router: Router,
		private store: Store<AppState>,
	) {
	}

	ngOnInit(): void {
		this.subscription.add(
			this.store.subscribe(state => {
				this.balance = state.cajero.balance;
			})
		)
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW(event) {
		event.stopPropagation();
		this.activeModal.close();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW(event);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATE ()
	onActivate(event) {
		event.stopPropagation();
		const tipo = "GRATIS";
		const param = {
			tipo,
			data: {
				bono_jugador_apto_id: this.bono.id
			}
		};
		this.postActivateBono(param);
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	postActivateBono(param) {
		this.activeModal.close(true);
		this.store.dispatch(BonoAction.activarBonos({param: param}));
	}

}
