<div class="w-cover coverB" id="w-deposit-1-cover" [class.active]="wActive==true" *ngIf="hashKey" (swipedown)="onCloseW()">
   <!-- -->

	<div class="window" id="wallet-deposit-1">
		<!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
		<div class="hidden-landscape deposit1-container">
			<div class="btn-window-close" (click)="onCloseW()">
				<div class="btn-window-close-bar"></div>
			</div>
			<p class="w-title-cover txt-sm txt-w tac">{{'DEPOSITAR_EN' |translate}}: <span class="txt-c2">{{hashKey.moneda.siglas.toUpperCase()}}</span></p>

			<div class="px-1 full-h flex-y2">

				<div class="bg-g1 p-15 mb-1">
					<div class="col col-6-1">
						<div class="coin-name">
							<img class="" src="../../../../assets/images/crypto/{{hashKey.moneda.siglas.toLowerCase()}}.svg">
							<span class="txt-lg txt-w pl-1">{{hashKey.moneda.nombre}}</span>
						</div>
					</div>
					<div class="col col-4-1 pl-05">
						<img [src]="hashKey.codigo_qr_url" class="qr-code b-10-w"
							alt="código qr">
					</div>
					<div class="cb mb-2"></div>

					<p class="txt-gc mb-05">{{'DEPOSITE_EN_ESTA_DIRECCION' |translate}}:</p>
					<div class="col col-btn2-11 pr-1">
						<div class="faux-ta2-nor full-w txt-w tac">
							<span class="word-wrap">{{hashKey.hash}}</span>
						</div>
					</div>
					<div class="col col-btn2-1">
						<button type="button" (click)="onCopyHash($event)" class="btn2 btn2-4th btn2-lg btn2-lg-icon">
							<img src="../../../../assets/images/icon-copy.svg">
						</button>
					</div>
					<div class="cb"></div>
				</div>

				<div class="bg-g1 p-15">
					<p class="txt-gc mb-05">{{'BONO_ASOCIADO' |translate}}:</p>
					<div class="sel2 faux-sel2 mb-1">
						<input class="inp2 inp2-nor full-w mb-03" placeholder="" id="" [value]="nombreBono">
						<select class="sel2 sel2-nor full-w" (change)="selectBono($event)"
								[disabled]="hashKey.bonos.length === 0">
							<option [value]="null" [selected]="!bono">{{'SIN_BONO_ASOCIADO' |translate}}</option>
							<option [value]="b.id" [selected]="bono && (bono.id == b.bono.id || bono.id == b.id)"
									*ngFor="let b of hashKey.bonos">{{b.nombre}}</option>
						</select>
					</div>
					<div class="col col-55-1 txt-sm txt-g9">{{'DEPOSITO_MINIMO' |translate}}:</div>
					<div class="col col-15-1 txt-c2"> {{hashKey.moneda.siglas.toUpperCase()}} </div>
					<div class="col col-3-1 txt-nor tar"> {{!getBonoDetail() ? (selectedWalletCurrency.minimo_solicitud | number: "1.2-8") : getBonoDetail().deposito_minimo | number: "1.2-8"}}</div>
					<div class="cb mb-05"></div>
					<div class="col col-55-1 txt-sm txt-g9">{{'EQUIV_A' |translate}}:</div>
					<div class="col col-15-1 txt-g9"> USD </div>
					<div class="col col-3-1 txt-nor tar"> {{!getBonoDetail() ? ((wallet.moneda.minimo_solicitud * wallet.cambio_moneda_base) | number: "1.2-2") : getBonoDetail().deposito_minimo_equivalente | number: "1.2-2"}}</div>
					<div class="cb"></div>
				</div>

			</div>
			<div class="w-actions px-2 pb-1">
            <button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCloseW()">{{'CERRAR' |translate}}</button>
			</div>

		</div>
		<!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->
	</div>


</div>
