import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {CircuitoJugadorModel, CircuitoModel, NodoJugadorModel, NodoModel} from "../../../shared/models/circuitos.model";

declare function adjustSize(): any;

@Component({
	selector: "app-w-circuito-node-msg",
	templateUrl: "./w-circuito-node-msg.component.html",
	styleUrls: ["./w-circuito-node-msg.component.css"]
})
export class WCircuitoNodeMsgComponent implements OnInit {

	wActive: boolean = false;
	@Input("position") position: number;

	circuito: CircuitoModel;
	nodoSeleccionado: NodoModel;

	constructor(
		public activeModal: NgbActiveModal,
		public store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.store.select((state) => state).subscribe(state => {
			this.circuito = state.general.circuitoActivo.value.circuito;
			this.nodoSeleccionado = this.circuito.nodos.find((x) => x.posicion === this.position);
		});
		setTimeout(() => {
			this.wActive = true;
		}, 0);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close(false);
			adjustSize();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

}
