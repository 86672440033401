import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import { PeriodoCommissionsModel } from "../../../shared/models/periodo-commissions.model";
import * as AfiliadosActions from '../../../afiliados/redux/afiliados.actions';
import { BatchCommissionsModel } from "src/app/shared/models/batch-commissions.model";
import { ExistFieldInFilter, ExtracFieldValueFromFilter, FilterModel } from "src/app/shared/models/filter.model";
import { Subscription } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { CreateComisionesBatchDefaultFilter } from "../../redux/afiliados.reducers";
import { GetNombreMesesEspannol } from "src/app/shared/utils/general.util";

@Component({
   selector: "app-comisiones",
   templateUrl: "./comisiones.component.html",
   styleUrls: ["./comisiones.component.css"]
})
export class ComisionesComponent implements OnInit, OnDestroy {
   selectedTab: number;
   subTable: number = -1;
   accordion: number = -1;

   allCommissions: PeriodoCommissionsModel[];

   allBatchCommissions: BatchCommissionsModel[];

   filter: FilterModel;

   subscription = new Subscription();

   constructor(
      private store: Store<AppState>,
      private actions$: Actions
   ) { }

   ngOnInit(): void {
      this.allCommissions = [];
      this.allBatchCommissions = [];
      this.selectedTab = 1;

      this.store.select((state) => state).subscribe(state => {
         this.allCommissions = state.afiliado.comisiones_periodo_actual;
         this.filter = state.afiliado.comisiones_batch.filter;
         this.allBatchCommissions = state.afiliado.comisiones_batch.value;
         this.selectedTab = state.afiliado.comisiones_active_tab;
      });

      this.getPeriodoActualComisiones();
      this.getBatchComisiones();
      this.onSetTab(1);
   }

   getPeriodoActualComisiones() {
      this.store.dispatch(AfiliadosActions.afiliadosGetPeriodoActualComisiones());
   }

   getBatchComisiones() {
      this.store.dispatch(AfiliadosActions.afiliadosGetBatchComisiones({ 'filter': CreateComisionesBatchDefaultFilter() }));
   }

   ngOnDestroy() {
      if (this.subscription) {
         this.subscription.unsubscribe();
      }
   }

   // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SET TAB ()
   onSetTab(tab) {
      if (tab != this.selectedTab) {
         this.store.dispatch(AfiliadosActions.afiliadosSetComisionesActiveTab({ activeTab: tab }));
      }
   }

   // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOGGLE SUBTABLE ()
   onToggleSubtable(i) {
      this.subTable = this.subTable == i ? -1 : i;
   }

   // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ACCORDION CONTENT HEIGTH ()
   accContentH() {
      var tic = document.getElementById('accContainer') as HTMLDivElement;
      if (tic) {
         let accordionMaxH = tic.offsetHeight - 220;
         let root = document.documentElement;
         root.style.setProperty('--acc2ContH2', accordionMaxH + "px");
      }
   }

   // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON TOGGLE ACCORDION ()
   onToggleAccordion(event, accord) {
      event.stopPropagation();
      this.accordion = this.accordion == accord ? -1 : accord;
      //
      var aacCont = document.getElementById('accContainer') as HTMLDivElement;
      var act = document.getElementById('bAcc-' + accord) as HTMLDivElement;
      setTimeout(() => {
         var scrTop = act.offsetTop;
         aacCont.scrollTop = scrTop;
      }, 250);
   }

   liseningSetComisionesActiveTab() {
      this.subscription.add(this.actions$
         .pipe(ofType(AfiliadosActions.afiliadosSetComisionesActiveTab))
         .subscribe(data => {
            if (data.activeTab == 2) {
               setTimeout(() => {
                  this.accContentH();
               }, 250);
            }
         }));
   }

   getPeriodo() {
      let periodo = '';
      if (this.filter && ExistFieldInFilter('mes', this.filter)) {
         periodo += GetNombreMesesEspannol(ExtracFieldValueFromFilter('mes', this.filter));
      }

      if (this.filter && ExistFieldInFilter('anno', this.filter)) {
         periodo += ` ${ExtracFieldValueFromFilter('anno', this.filter)}`;
      }

      return periodo;
   }

   // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SHOW TOOLTIP ()
	showTooltip(event, t) {
		event.stopPropagation();
		const tt = event.target as HTMLDivElement;
		tt.classList.add("active");
		setTimeout(() => {
			tt.classList.remove("active");
		}, t * 1000);
	}
}


// document.getElementById('bAcc-2').offsetTop