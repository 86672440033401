import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CuentaRoutes} from "./cuenta.routing";
import {CuentaService} from "./services/cuenta.service";
import {SharedModule} from "../shared/shared.module";
import {CtaCryptoComponent} from "./components/cta-crypto/cta-crypto.component";
import {CuentaComponent} from "./components/cuenta/cuenta.component";
import {CtaDatosComponent} from "./components/cta-datos/cta-datos.component";
import {WFCtaCryptoTxComponent} from "./components/w-f-cta-crypto-tx/w-f-cta-crypto-tx.component";
import {WFCtaCryptoComponent} from "./components/w-f-cta-crypto/w-f-cta-crypto.component";
import {WFCtaMovComponent} from "./components/w-f-cta-mov/w-f-cta-mov.component";
import {CtaGamesComponent} from "./components/cta-games/cta-games.component";
import {CtaMovComponent} from "./components/cta-mov/cta-mov.component";
import {WFCtaGamesComponent} from "./components/w-f-cta-games/w-f-cta-games.component";
import {TabDatosCryptoComponent} from "./components/cta-datos/tab-datos-crypto/tab-datos-crypto.component";
import {TabDatosFiatComponent} from "./components/cta-datos/tab-datos-fiat/tab-datos-fiat.component";
import {TabDatosContactoComponent} from "./components/cta-datos/tab-datos-contacto/tab-datos-contacto.component";
import {TabDatosDireccionComponent} from "./components/cta-datos/tab-datos-direccion/tab-datos-direccion.component";
import {TabDatosPasswordComponent} from "./components/cta-datos/tab-datos-password/tab-datos-password.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {CryptoIntercambiosComponent} from "./components/cta-crypto/crypto-intercambios/crypto-intercambios.component";
import {CryptoTransaccionesComponent} from "./components/cta-crypto/crypto-transacciones/crypto-transacciones.component";
import {StoreModule} from "@ngrx/store";
import * as fromCuentaReducer from "../cuenta/redux/cuenta.reducers";
import {EffectsModule} from "@ngrx/effects";
import {CuentaEffects} from "./redux/cuenta.effects";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		RouterModule.forChild(CuentaRoutes),
		SharedModule,
		InfiniteScrollModule,
		StoreModule.forFeature("cuenta", fromCuentaReducer.reducer),
		EffectsModule.forFeature([CuentaEffects]),
		TranslateModule
	],
	providers: [
		NgbActiveModal,
		CuentaService
	],
	declarations: [
		CtaCryptoComponent,
		CtaDatosComponent,
		CtaGamesComponent,
		CtaMovComponent,
		CuentaComponent,
		WFCtaCryptoComponent,
		WFCtaCryptoTxComponent,
		WFCtaGamesComponent,
		WFCtaMovComponent,
		TabDatosCryptoComponent,
		TabDatosFiatComponent,
		TabDatosContactoComponent,
		TabDatosDireccionComponent,
		TabDatosPasswordComponent,
		CryptoIntercambiosComponent,
		CryptoTransaccionesComponent
	],
	exports: []
})
export class CuentaModule {
}
