<input type="text" autofocus="autofocus" style="display:none" />

<div class="w-cover coverB" id="w-afiliado-new-account-cover" [class.active]="wActive==true" (swipedown)="onCancelPort()">

	<div class="window" id="afiliado-new-account">

		<form [formGroup]="registerForm" (ngSubmit)="onCreateAfiliado($event)">
         <!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
         <div class="hidden-landscape">
            <div class="btn-window-close" (click)="onCancelPort()">
               <div class="btn-window-close-bar"></div>
            </div>
            <p class="w-title-cover txt-sm txt-w tac mb-2">{{'CREA_TU_CUENTA' | translate}}</p>
            <div class="px-5">
               <p class="txt-gc mb-3">{{'CREA_TU_CUENTA_AFILIADO_GENERA_GANACIAS' |translate}}.</p>
               <p class="txt-sm txt-g8 mb-03">{{'ELIGE_ALIAS' |translate}}:</p>
			   <input type="text" class="inp2 inp2-nor inp2-1st full-w mb-1"
				[style.border-color]="inputTouched('username') ? 'red' : ''" 
				formControlName="username" placeholder="{{'MI_ALIAS' | translate}}">
            </div>
            <div class="btn2-actions px-2 pb-2">
					<div class="col col-6 pr-07">
                  <button type="button" class="btn2 btn2-lg btn2-g full-w" (click)="onCancelPort()">{{'CANCELAR' |translate}}</button>
               </div>
               <div class="col col-6 pl-07">
                  <button type="button" class="btn2 btn2-lg btn2-1st full-w" (click)="onCreateAfiliado($event)" [disabled]="registerForm.invalid">{{'CONTINUAR' |translate}}</button>
               </div>
               <div class="cb"></div>
				</div>
         </div>
         <!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->

		</form>
	</div>

</div>
