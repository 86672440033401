import {Component, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../services/notification.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import * as countriesJSON from "../../../assets/json/countries.json";
import * as timeZonesJSON from "../../../assets/json/timezones.json";
import {CountryModel} from "../models/country.model";
import {Store} from "@ngrx/store";
import {AppState} from "../redux/general.reducers";
import * as GeneralAction from "../../shared/redux/general.actions";
import {Router} from "@angular/router";

import {fixAndroid} from '../../shared/utils/general.util';

declare function adjustSize(): any;

@Component({
	selector: "app-w-call-request",
	templateUrl: "./w-call-request.component.html",
	styleUrls: ["./w-call-request.component.css"]
})
export class WCallRequestComponent implements OnInit {

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	wActive: boolean = false;

	callForm: FormGroup;

	countries: CountryModel[];
	timeZones: { nombre: string }[];
	paisSeleccionado: string = "País";

	constructor(
		public activeModal: NgbActiveModal,
		private notification: NotificationService,
		private store: Store<AppState>,
		private router: Router
	) {
	}

	ngOnInit(): void {
		this.countries = countriesJSON.countries;
		this.timeZones = timeZonesJSON.timezones;
		this.initForm();
		setTimeout(() => {
			this.wActive = true;
		}, 1);
		setTimeout(() => {
			fixAndroid('ayuda-call-cover');
		}, 250);
	}

	initForm() {
		this.callForm = new FormGroup({
			fecha: new FormControl(null, Validators.required),
			hora: new FormControl(8, Validators.required),
			minutos: new FormControl(0, Validators.required),
			zona_horaria: new FormControl(null, Validators.required),
			meridiano: new FormControl("AM", Validators.required),
			telefono: new FormGroup({
				pais: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(4)]),
				area: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(4)]),
				numero: new FormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
			}),
		});
	}

	onChangePais(event) {
		let pais = this.countries.find(x => x.codigo_telefonico == event.target.value);
		if (pais) {
			this.paisSeleccionado = pais.codigo_telefonico + " " + pais.tx_iso2;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SET DATE W ()
	setDate(event) {
		const dateInp = event.target as HTMLInputElement;
		const dateVal = dateInp.value;
		const txtInp = dateInp.closest(".date").querySelector(".date-txt") as HTMLInputElement;
		txtInp.value = dateVal;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close(false);
			adjustSize();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON REQUEST CALL ()
	onRequestCall(event) {
		event.stopPropagation();
		if (this.callForm.valid) {
			const model = this.callForm.value;
			const param = {
				data: {
					fecha: model.fecha + " " + model.hora + ":" + model.minutos + " " + model.meridiano,
					tel: model.telefono.pais + " " + model.telefono.area + " " + model.telefono.numero,
					asunto: "Solicitud de llamada",
					categoria_id: 7,
					zona_horaria: model.zona_horaria,
					contenido: "Fecha: " + model.fecha + " Numero: " + model.telefono.pais + " " + model.telefono.area + " " + model.telefono.numero + ""
				}
			};
			this.store.dispatch(GeneralAction.addTickets({params: param.data}));
			this.router.navigate(["/ayuda/tickets"]).then();
			// this.activeModal.close();
			this.onCloseW();
		} else {
			this.validateAllFormFields(this.callForm);
		}
	}

	validateAllFormFields(formGroup: FormGroup) {         // {1}
		Object.keys(formGroup.controls).forEach(field => {  // {2}
			const control = formGroup.get(field);             // {3}
			if (control instanceof FormControl) {             // {4}
				control.markAsTouched({onlySelf: true});
			} else if (control instanceof FormGroup) {        // {5}
				this.validateAllFormFields(control);            // {6}
			}
		});
	}

}
