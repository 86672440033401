import {ModuleWithProviders, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BillingAddressDirective} from "./directives/billing-address.directive";
import {FocusOnShowDirective} from "./directives/FocusOnShowDirective.directive";
import {AlphabeticDirective} from "./directives/alphabetic.directive";
import {DecimalDirective} from "./directives/decimal.directive";
import {NumericDirective} from "./directives/numeric.directive";
import {AlphanumericDirective} from "./directives/alphanumeric.directive";
import {RouterModule} from "@angular/router";
import {SanitizerPipe} from "./pipes/sanitizer.pipe";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
	imports: [
		ReactiveFormsModule,
		FormsModule,
		RouterModule,
		TranslateModule
	],
	providers: [],
	declarations: [
		BillingAddressDirective,
		FocusOnShowDirective,
		AlphabeticDirective,
		NumericDirective,
		AlphanumericDirective,
		DecimalDirective,
		SanitizerPipe
	],
	exports: [
		BillingAddressDirective,
		FocusOnShowDirective,
		AlphabeticDirective,
		NumericDirective,
		AlphanumericDirective,
		DecimalDirective,
		SanitizerPipe
	],
	entryComponents: []
})


export class SharedModule {
	static forRoot(): ModuleWithProviders<unknown> {
		return {
			ngModule: SharedModule
		};
	}
}


