import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {BonosJugadorAptoModel} from "../../../../shared/models/bonos.model";
import {WRulesComponent} from "../../w-rules/w-rules.component";
import {WActivateGratisDevolucionComponent} from "../../w-activate-gratis-devolucion/w-activate-gratis-devolucion.component";
import {WActivateBonoDevolucionComponent} from "../../w-activate-bono-devolucion/w-activate-bono-devolucion.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WConversionCoinComponent} from "../../../../home/components/w-conversion-coin/w-conversion-coin.component";
import {WalletGeneralModel} from "../../../../shared/models/wallet-general.model";
import Swal from "sweetalert2";
import {NotificationService} from "../../../../shared/services/notification.service";
import {MonedaModel} from "src/app/shared/models/wallet-currency.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/redux/general.reducers";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-disponibles-bonos",
	templateUrl: "./disponibles-bonos.component.html",
	styleUrls: ["./disponibles-bonos.component.css"]
})
export class DisponiblesBonosComponent implements OnInit, OnDestroy {

	allBonos: BonosJugadorAptoModel[];
	walletCurrency: WalletGeneralModel[];
	cryptoCurrency: MonedaModel[];
	balance: WalletGeneralModel;
	subscription = new Subscription();

	constructor(
		private modalService: NgbModal,
		private notificationService: NotificationService,
		private store: Store<AppState>,
		private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		this.subscription.add(this.store.subscribe(state => {
				this.allBonos = state.bonos.bonosJugadorAptos;
				this.walletCurrency = state.cajero.wallets;
				this.balance = state.cajero.balance;
				this.cryptoCurrency = state.cajero.cryptoMonedas.value;
			})
		);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW RULES ()
	onShowRules(bono: BonosJugadorAptoModel) {
		const modalRef = this.modalService.open(WRulesComponent);
		modalRef.componentInstance.bono = bono;
		modalRef.componentInstance.tipo = "BONOJUGADORAPTO";
		modalRef.result.then();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATE GRATIS  ()
	onActivate(bono: BonosJugadorAptoModel) {
		if (bono.tipo_bono.id === 1) {
			this.onPendiente(bono);
		} else if (bono.tipo_bono.id === 3) {
			const modalRef = this.modalService.open(WActivateBonoDevolucionComponent);
			modalRef.componentInstance.bono = bono;
			modalRef.result.then();
		} else {
			if (bono.monedas.find(x => x.siglas.toUpperCase() === this.balance.moneda.siglas.toUpperCase())) {
				const modalRef = this.modalService.open(WActivateGratisDevolucionComponent);
				modalRef.componentInstance.bono = bono;
				modalRef.result.then();
			} else {
				Swal.fire(this.notificationService.BuildWarning(this.translateService.instant("MONEDA_BALANCE_ACTUAL_NO_USABLE_BONO"))).then();
			}
		}
	}

	onPendiente(bono: BonosJugadorAptoModel) {
		const modalRef = this.modalService.open(WConversionCoinComponent);
		const tempCryptoCurrency = [];
		for (const moneda of bono.monedas) {
			tempCryptoCurrency.push(this.cryptoCurrency.find(x => x.id === moneda.id));
		}
		modalRef.componentInstance.cryptoCurrency = tempCryptoCurrency;
		modalRef.componentInstance.bono = bono;
		modalRef.componentInstance.process = "ACTIVATED_BONO";
		modalRef.result.then();
	}

}
