import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GamesRoutes} from "./games.routing";
import {GamesService} from "./services/games.service";
import {SharedModule} from "../shared/shared.module";
import {CatalogComponent} from "./components/catalog/catalog.component";
import {FilterCatalogComponent} from "./components/filter-catalog/filter-catalog.component";
import {GameInfoComponent} from "./components/game-info/game-info.component";
import {PlaygroundComponent} from "./components/playground/playground.component";
import {BackComponent} from "./components/back/back.component";
import {WBackComponent} from "./components/w-back/w-back.component";
import {ClickOutsideModule} from "ng-click-outside";
import {PusherService} from "./services/pusher.service";
import {WExchangerComponent} from "./components/w-exchanger/w-exchanger.component";
import {IMaskModule} from "angular-imask";
import {StoreModule} from "@ngrx/store";
import * as fromGamesReducer from "./redux/games.reducers";
import {EffectsModule} from "@ngrx/effects";
import {GamesEffects} from "./redux/games.effects";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		RouterModule.forChild(GamesRoutes),
		SharedModule,
		IMaskModule,
		ClickOutsideModule,
		StoreModule.forFeature("games", fromGamesReducer.reducer),
		EffectsModule.forFeature([GamesEffects]),
		TranslateModule
	],
	providers: [
		NgbActiveModal,
		GamesService,
		PusherService
	],
	declarations: [
		CatalogComponent,
		FilterCatalogComponent,
		GameInfoComponent,
		PlaygroundComponent,
		BackComponent,
		WBackComponent,
		WExchangerComponent
	],
	entryComponents: [],
	exports: [
		CatalogComponent,
		FilterCatalogComponent,
		GameInfoComponent,
		WBackComponent,
		WExchangerComponent
	]
})
export class GamesModule {
}
