import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FilterModel} from "../../../../shared/models/filter.model";
import {HistorialIntercambioModel} from "../../../../shared/models/historial-intercambio.model";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "src/app/shared/redux/general.reducers";
import * as CajeroAction from "../../../../cajero/redux/cajero.actions";
import {Actions, ofType} from "@ngrx/effects";
import * as CuentaAction from "../../../redux/cuenta.actions";

@Component({
	selector: "app-crypto-intercambios",
	templateUrl: "./crypto-intercambios.component.html",
	styleUrls: ["./crypto-intercambios.component.css"]
})
export class CryptoIntercambiosComponent implements OnInit, OnDestroy {

	intercambiosCrypto: HistorialIntercambioModel[];
	filter: FilterModel;
	subscription = new Subscription();
	recargar: boolean = true;
	@ViewChild('divContenerdor') divContenerdor: any;

	constructor(
		private store: Store<AppState>,
		private actions$: Actions,
	) {
	}

	ngOnInit(): void {
		this.filter = new FilterModel();
		this.intercambiosCrypto = [];

		this.subscription.add(
			this.store.subscribe((state) => {
				this.filter = state.cuenta.intercambioHistorial.filter;
				this.intercambiosCrypto = state.cuenta.intercambioHistorial.value;
			})
		)
		this.subscription = this.actions$
			.pipe(ofType(CuentaAction.getHistorialIntercambioCompletado))
			.subscribe(response => {
				this.recargar = true;
				if (this.divContenerdor) {
					if (this.filter.amount_last_page != 0) {
						setTimeout(
							() => {
								this.divContenerdor.nativeElement.scrollLeft = this.divContenerdor.nativeElement.scrollLeft + 200;
							}, 350
						);
					} else {
						this.divContenerdor.nativeElement.scrollTop = 20;
						this.divContenerdor.nativeElement.scrollLeft = 20;
					}
				}
			});
		this.initData();


	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initData() {
		this.getCryptoCurrency();
		this.getCryptoIntercambio();
	}

	onScroll() {
		if (this.filter.last_page != this.filter.page) {
			this.getCryptoIntercambio("scroll+");
		}
	}

	onScrollUp() {
		if (this.filter.page != 1) {
			this.getCryptoIntercambio("scroll-");
		}
	}

	getCryptoIntercambio(tipo = "primero") {
		let nuevoFiltro = new FilterModel();
		if (tipo != "primero") {
			nuevoFiltro = {...this.filter};
		}
		if (tipo == "scroll+") {
			nuevoFiltro.page++;
		}
		if (tipo == "scroll-") {
			nuevoFiltro.page--;
		}
		if (this.filter.amount_last_page != 0 && this.filter.page > 2) {
			nuevoFiltro.page--;
		}
		if (this.recargar) {
			this.recargar = false;
			this.store.dispatch(CuentaAction.getHistorialIntercambio({filter: nuevoFiltro}));
		}

	}

	getCryptoCurrency() {
		this.store.dispatch(CajeroAction.getCryptoMonedas())
	}

}
