import {createAction, props} from "@ngrx/store";
import {MyControlModel} from "../models/control.model";
import {CircuitoJugadorModel} from "../models/circuitos.model";
import {NotificationsModel} from "../models/notifications.model";
import {FilterModel} from "../models/filter.model";
import {IdiomaModel} from "../models/idioma.model";


export const showLoader = createAction('[General] Show Loader', props<{ httpRequestLoader: boolean, tipo: string }>());
export const hideLoader = createAction('[General] Hide Loader', props<{ httpRequestLoader: boolean, tipo: string }>());
export const hideLoaderOnPeticionesColgadas = createAction('[General] Hide Loader en Peticiones colgadas');
export const localDataLoad = createAction('[General] Local Data Load', props<{ dataName: string }>());
export const torneoActivoAuthCompletado = createAction('[General] Torneo Activo Completado', props<{ data: any }>());
export const getTorneoActivo = createAction('[General] Get Torneo Activo');
export const getTorneoActivoCompletado = createAction('[General] Torneo Activo Completado', props<{ data: any }>());
export const getCircuitoActivo = createAction('[General] Get Circuito Activo');
export const getCircuitoActivoCompletado = createAction('[General] Get Circuito Activo Completado', props<{ data: CircuitoJugadorModel }>());
export const generalLogoutCompletado = createAction('[General] Logout Completado');
export const setMyMenu = createAction('[General] Set My Menu', props<{ menu: MyControlModel, eventEmiterComponent: string }>());

export const getNotificaciones = createAction('[General] Get Notificaciones');
export const getNotificacionesCompletado = createAction('[General] Get Notificaciones Completado', props<{ data: any }>());

export const getEstadosPaises = createAction('[General] Get Estados por Pais', props<{ params: any }>());
export const getEstadosPaisesCompletado = createAction('[General]  Get Estados por Pais Completado', props<{ data: any }>());

export const marcarNotificacionComoLeido = createAction('[General] Marcar Notificacion Como Leido', props<{ id: number }>());
export const marcarNotificacionComoLeidoCompletado = createAction('[General] Marcar Notificacion Como Leido Completado', props<{ data: any }>());

export const eliminarNotificacion = createAction('[General] Eliminar Notificacion', props<{ id: number }>());
export const eliminarNotificacionCompletado = createAction('[General] Eliminar Notificacion Completado', props<{ data: any }>());


export const getTickets = createAction('[General] Get Tickets', props<{ filter: FilterModel }>());
export const getTicketsCompletado = createAction('[General] Get Tickets Completado', props<{ data: any }>());
export const addTickets = createAction('[General] Add Tickets', props<{ params: any }>());
export const addTicketsCompletado = createAction('[General] Add Tickets Completado', props<{ data: any }>());

export const addNotaTicket = createAction('[General] Add Nota al Tickets', props<{ params: any }>());
export const addNotaTicketCompletado = createAction('[General] Add Nota al Tickets Completado', props<{ data: any }>());

export const uploadFiles = createAction('[General] Upload Files', props<{ params: any }>());
export const uploadFilesCompletado = createAction('[General] Upload Files Completado', props<{ data: any }>());


export const getLangs = createAction('[General] Get Langs');
export const getLangsCompletado = createAction('[General] Get Langs Completado', props<{ data: any }>());
export const changeLang = createAction('[General] Change Langs', props<{ lang: IdiomaModel }>());
export const setUserLang = createAction('[General] Set user Lang', props<{ params: any }>());
export const setUserLangCompletado = createAction('[General] Set user Lang Completado', props<{ data: any }>());


export const setAfiliadosMode = createAction('[General] Set Afiliados Mode', props<{ afiliadosMode: boolean }>());


export const pusherEventNuevaNotificacion = createAction('[General] Pusher Event Nueva Notificacion', props<NotificationsModel>());

export const showCircuitoActivo = createAction('[General] Show Circuito activo');
export const hideCircuitoActivo = createAction('[General] Hide Circuito activo');


