import {Component} from "@angular/core";

declare function adjustSize(): any;

@Component({
	selector: "app-w-deposito-bono",
	templateUrl: "./w-deposito-bono.component.html",
	styleUrls: ["./w-deposito-bono.component.css"]
})
export class WDepositoBonoComponent {
//
// 	hide: boolean;
//
// 	hashKey: HashCurrencyModel;
//
// 	@Input() selectedWalletCurrency: WalletCurrencyModel;
//
// 	protected ngUnsubscribe: Subject<any> = new Subject<any>();
//
// 	constructor(
// 		public activeModal: NgbActiveModal,
// 		public sessionService: SessionServices,
// 		private spinner: NgxSpinnerService,
// 		private store: Store<fromReducer.State>,
// 		private actionsSubject$: ActionsSubject,
// 		private notification: NotificationService,
// 	) {
// 	}
//
// 	ngOnInit(): void {
// 		// this.bono = JSON.parse(sessionStorage.getItem("bonoHashData"));
//
//
// 		const param = {
// 			data: {
// 				moneda_id: this.selectedWalletCurrency.moneda.id
// 			}
// 		};
// 		this.store.dispatch(new cajeroAction.CreateHashCurrency(param));
//
// 		this.triggers();
// 	}
//
// 	ngOnDestroy(): void {
// 		this.ngUnsubscribe.next(false);
// 	}
//
// 	triggers() {
// 		this.actionsSubject$
// 			.pipe(takeUntil(this.ngUnsubscribe))
// 			.pipe(ofType(cajeroAction.CajeroActionTypes.CreateHashCurrencyCompleted))
// 			.subscribe(response => {
// 				environment.showLoader = false;
// 				const resp: ResponseModel = (response as any).payload;
// 				this.hashKey = resp.data;
// 			});
// 		this.actionsSubject$
// 			.pipe(takeUntil(this.ngUnsubscribe))
// 			.pipe(ofType(cajeroAction.CajeroActionTypes.CreateHashCurrencyHandleError))
// 			.subscribe(error => {
// 				environment.showLoader = false;
// 				const err = (error as any).payload;
// 				this.activeModal.close();
//
// 			});
//
// 		this.actionsSubject$
// 			.pipe(takeUntil(this.ngUnsubscribe))
// 			.pipe(ofType(bonosAction.BonosActionTypes.DeleteBonoDepositoCompleted))
// 			.subscribe(response => {
// 				environment.showLoader = false;
// 				const resp: ResponseModel = (response as any).payload;
// 				Swal.fire(this.notification.BuildSuccess(resp.message, "Bono Cancelado!")).then(
// 					() => {
// 						this.hide = true;
// 					}
// 				);
// 			});
// 		this.actionsSubject$
// 			.pipe(takeUntil(this.ngUnsubscribe))
// 			.pipe(ofType(bonosAction.BonosActionTypes.DeleteBonoDepositoHandleError))
// 			.subscribe(error => {
// 				environment.showLoader = false;
// 				const err = (error as any).payload;
//
// 			});
// 	}
//
// 	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
// 	onCloseW(event) {
// 		event.stopPropagation();
//       this.activeModal.close(this.hide);
//       adjustSize();
// 	}
//
// 	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON COPY HASH ()
// 	onCopyHash(event) {
// 		event.stopPropagation();
// 		const selBox = document.createElement("textarea");
//
// 		selBox.style.position = "fixed";
// 		selBox.style.left = "0";
// 		selBox.style.top = "0";
// 		selBox.style.opacity = "0";
// 		selBox.value = this.hashKey.hash;
//
// 		document.body.appendChild(selBox);
// 		selBox.focus();
// 		selBox.select();
//
// 		document.execCommand("copy");
// 		document.body.removeChild(selBox);
// 	}
//
// 	changeImg(event, newImg) {
// 		event.target.src = newImg;
// 	}
//
// 	onClearBono() {
// 		const param = {
// 			bonoID: this.selectedWalletCurrency.bono_jugador_deposito.bono_id,
// 			monedaID: this.selectedWalletCurrency.bono_jugador_deposito.moneda_id
// 		};
// 		this.store.dispatch(new bonosAction.DeleteBonoDeposito(param));
// 	}

}
