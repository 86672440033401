import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {apiRoutes} from "../../api-routing";
import {FilterModel} from "../../shared/models/filter.model";

@Injectable({
	providedIn: "root"
})
export class BonosService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	getAllBonos(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_bono_bono_jugador_aptos_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getAllBonosActivos(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_bono_bono_jugadores_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getCurrentBalance(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugadores_balance_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	postActivateBono(param: any): Observable<any> {
		let url;
		switch (param.tipo) {
			case "DEPOSITO":
				url = this.buildURL(apiRoutes.r_frontend_bono_bono_jugadores_bono_jugador_depositos);
				break;
			case "ULTIMO_DEPOSITO":
				url = this.buildURL(apiRoutes.r_frontend_bono_bono_jugadores_activacion_bono_ultimo_deposito);
				break;
			case "GRATIS":
				url = this.buildURL(apiRoutes.r_frontend_bono_bono_jugadores_activacion_bono_post);
				break;
			case "DEVOLUCION":
				url = this.buildURL(apiRoutes.r_frontend_bono_bono_jugadores_tomar_bono_devolucion_post);
				break;
			default:
				throwError("ERROR");
				break;
		}
		const response = this.httpClient.post(url, param.data);
		return response.pipe(map((data: any) => data));
	}

	patchDeactivateBono(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_bono_bono_jugadores_cancelacion.replace("{id}", param.id));
		const response = this.httpClient.patch(url, param);
		return response.pipe(map((data: any) => data));
	}

	getAllCurrency(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_monedas_get + "?filter=[[\"crypto\",\"=\",\"1\"]]");
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getBonosHistorial(filter: FilterModel): Observable<any> {
		const url = this.buildURL(this.formatFilters(filter, apiRoutes.r_frontend_bono_bono_jugadores_filter_historial));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getLastDeposit(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_movimientos_deposito_get + (!param.moneda_id ? "" : "?moneda_id=" + param.moneda_id));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	deleteBonoDeposito(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_bono_bono_jugador_delete_moneda_bono.replace("{bono_id}", param.bonoID).replace("{moneda_id}", param.monedaID));
		const response = this.httpClient.delete(url, param);
		return response.pipe(map((data: any) => data));
	}

	private formatFilters(flt: FilterModel, url) {
		url += `?paginate=` + flt.paginate + `&ordenado_por=created_at&direction=DESC&page=` + flt.page;
		let filter = ``;
		if (flt.filter.filter.length !== 0) {
			filter += `&filter=[`;
			let first = true;
			flt.filter.forEach(value => {
				if (first) {
					filter += `["${value.atribute}"`;
				} else {
					filter += `,["${value.atribute}"`;
				}
				filter += `,"${value.operator}"`;
				if (value.value === true) {
					filter += `,true]`;
				} else if (value.value === false) {
					filter += `,false]`;
				} else {
					filter += encodeURI(`,"${value.value}"]`);
				}
				first = false;
			});
			filter += `]`;

		}
		return url + filter;
	}


}
