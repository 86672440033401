import {createAction, props} from '@ngrx/store';
import {FilterModel} from "../../shared/models/filter.model";


 export const getMovimientos = createAction('[Cuenta] Get Movimientos', props<{ filter: FilterModel }>());
 export const getMovimientosCompletado = createAction('[Cuenta] Get Movimientos Completado', props<{ data: any }>());

 export const getConceptos = createAction('[Cuenta] Get Conceptos');
 export const getConceptosCompletado = createAction('[Cuenta] Get Conceptos Completado', props<{ data: any }>());

export const getHistorialJuego = createAction('[Cuenta] Get Historial de Juego', props<{ filter: FilterModel }>());
export const getHistorialJuegoCompletado = createAction('[Cuenta] Get Historial de Juego Completado', props<{ data: any }>());


export const getHistorialIntercambio = createAction('[Cuenta] Get Historial de Intercambio', props<{ filter: FilterModel }>());
export const getHistorialIntercambioCompletado = createAction('[Cuenta] Get Historial de Intercambio Completado', props<{ data: any }>());


export const getHistorialTransaccionesCrypto = createAction('[Cuenta] Get Historial de Transacciones Crypto', props<{ filter: FilterModel }>());
export const getHistorialTransaccionesCryptoCompletado = createAction('[Cuenta] Get Historial de Transacciones Crypto Completado', props<{ data: any }>());

export const patchJugadorDataCrypto = createAction('[Cuenta] Guardar Datos Cryptos Jugador', props<{ params: any }>());
export const patchJugadorDataCryptoCompletado = createAction('[Cuenta] Guardar Datos Cryptos Jugador Completado', props<{ data: any }>());

export const patchJugadorPasswords = createAction('[Cuenta] Resetar Passwords Jugador', props<{ params: any }>());
export const patchJugadorPasswordsCompletado = createAction('[Cuenta] Resetar Passwords Jugador Completado', props<{ data: any }>());

export const patchJugadorFiat = createAction('[Cuenta] Actualizar datos Fiat Jugador', props<{ params: any }>());
export const patchJugadorFiatCompletado = createAction('[Cuenta] Actualizar datos Fiat Jugador Completado', props<{ data: any }>());

export const changeSwitchVistaCrypto = createAction('[Cuenta] Cambiar el switch de la vista Crypto', props<{ position: number }>());

