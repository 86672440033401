import {Component, OnDestroy, OnInit} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WTerminosCondicionesComponent} from "../../../shared/w-terminos-condiciones/w-terminos-condiciones.component";
import {WLanguagesComponent} from "../../../shared/w-languages/w-languages.component";
import {IdiomaModel} from "../../../shared/models/idioma.model";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {AppState} from "../../../shared/redux/general.reducers";

@Component({
	selector: "app-footer-jugador",
	templateUrl: "./footer-jugador.component.html",
	styleUrls: ["./footer-jugador.component.css"]
})
export class FooterJugadorComponent implements OnInit, OnDestroy {

	selectedLang: IdiomaModel;
	subscription = new Subscription();

	constructor(
		private modalService: NgbModal,
		private modalServiceLang: NgbModal,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.subscription.add(this.store.subscribe((state) => {
			this.selectedLang = state.general.selectedLang;
		}));
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	goChat() {
	}

	goToTermConditions() {
		const modalRef = this.modalService.open(WTerminosCondicionesComponent);
		modalRef.result.then();
	}

	goToPrivacy() {
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT LANGUAGE ()
	onSelectLanguage() {
		const modalRefLang = this.modalServiceLang.open(WLanguagesComponent);
	}

}
