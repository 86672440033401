import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SessionServices} from "../../../shared/services/session.services";
import {ResponseModel} from "../../../shared/models/response.model";
import {HashCurrencyModel} from "../../../shared/models/hash-currency.model";
import {BonosJugadorAptoModel, DetallesBonoModel} from "../../../shared/models/bonos.model";
import {Router} from "@angular/router";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import {CajeroService} from "../../services/cajero.service";
import {BonosService} from "../../../bonos/services/bonos.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {Actions, ofType} from "@ngrx/effects";
import * as BonoAction from "../../../bonos/redux/bonos.actions";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";
import {MonedaModel} from "../../../shared/models/wallet-currency.model";
import {Subscription} from "rxjs";

import { fixAndroid } from '../../../shared/utils/general.util';
import {TranslateService} from "@ngx-translate/core";
declare function adjustSize(): any;

@Component({
	selector: "app-w-deposito",
	templateUrl: "./w-deposito.component.html",
	styleUrls: ["./w-deposito.component.css"]
})
export class WDepositoComponent implements OnInit, OnDestroy {

	hashKey: HashCurrencyModel;
	wallet: WalletGeneralModel;
	nombreBono: string = "Sin bono asociado";

	@Input() selectedWalletCurrency: MonedaModel;
	@Input() bono: BonosJugadorAptoModel;
	@Input() bonoID: number;
	@Input() process: string;
   subscription = new Subscription();

   wActive: boolean = false;

	constructor(
		private cajeroService: CajeroService,
		private bonosService: BonosService,
		public activeModal: NgbActiveModal,
		private router: Router,
		public sessionService: SessionServices,
		private store: Store<AppState>,
		private actions$: Actions,
		private translateService: TranslateService,
	) {
	}

	ngOnInit(): void {
		this.subscription.add(this.store.select((state) => state).subscribe(state => {
			this.wallet = state.cajero.wallets.find(x => x.moneda.id === this.selectedWalletCurrency.id);
			this.hashKey = state.cajero.jugadorHash
		}))
		this.listeningActions();
		const param = {
			moneda_id: this.selectedWalletCurrency.id
		};
      this.createCurrencyHash(param);
      // No se cuanto tiempo poner aquí
      setTimeout(() => { this.wActive = true; }, 0);
      setTimeout(() => { fixAndroid('w-deposit-1-cover'); }, 250);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	listeningActions() {
		this.subscription.add(this.actions$
			.pipe(ofType(CajeroAction.getHashDepositoCompletado))
			.subscribe(response => {
				if (this.bono && !this.bono.bono) {
					this.bono = this.hashKey.bonos.find(x => x.bono.id === this.bono.id);
				}
				if (this.bono && this.bono.bono) {
					this.bono = this.hashKey.bonos.find(x => x.id === this.bono.id);
				}
				this.nombreBono = this.bono ? this.bono.bono.nombre : this.translateService.instant("SIN_BONO_ASOCIADO");
				if (this.bono) {
					this.onActivateAndGo(null);
				}
			}));
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
      this.wActive = false;
      setTimeout(() => {
         if (this.process === "REGISTER_BONO") {
            console.log(5);
            this.router.navigate(["/inicio/jugador"]).then();
         }
         this.activeModal.close();
         adjustSize();
      }, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON COPY HASH ()
	onCopyHash(event) {
		event.stopPropagation();
		const selBox = document.createElement("textarea");

		selBox.style.position = "fixed";
		selBox.style.left = "0";
		selBox.style.top = "0";
		selBox.style.opacity = "0";
		selBox.value = this.hashKey.hash;

		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();

		document.execCommand("copy");
		document.body.removeChild(selBox);
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON ACTIVATE AND GO ()
	onActivateAndGo(event) {
		if (event) {
			event.stopPropagation();
		}
		const param = {
			tipo: "DEPOSITO",
			data: {
				bono_jugador_apto_id: this.bono.id,
				moneda_id: this.selectedWalletCurrency.id
			}
		};

		this.postActivateBono(param);
	}

	selectBono(event) {
		if (event) {
			event.stopPropagation();
		}
		const bonoID = event.target.value;
		if (bonoID === "null") {
			if (this.bono) {
				this.nombreBono = this.translateService.instant("SIN_BONO_ASOCIADO");
				const param = {
					bonoID: this.bono.bono.id,
					monedaID: this.hashKey.moneda.id,
				};
				this.deleteBonoDeposito(param);
			}
		} else {
			this.bono = this.hashKey.bonos.find(x => x.id === Number(bonoID));
			this.nombreBono = this.bono.nombre;
			this.onActivateAndGo(event);
		}
	}

	getBonoDetail(): DetallesBonoModel {
		if (!this.bono) {
			return null;
		}
		return this.bono.detalles.find(x => x.siglas.toUpperCase() === this.hashKey.moneda.siglas.toUpperCase());
	}

	createCurrencyHash(param) {
		this.store.dispatch(CajeroAction.getHashDeposito(param));
	}

	postActivateBono(param) {
		this.store.dispatch(BonoAction.setBonoParaDeposito(param))
	}

	deleteBonoDeposito(param) {
		this.bonosService.deleteBonoDeposito(param).subscribe(
			(response) => {
				const resp: ResponseModel = response;
				this.bono = null;
			}
		);
	}

}

// $Test.123
