import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {apiRoutes} from "../../api-routing";

@Injectable({
	providedIn: "root"
})
export class AdminServices {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	login(credential: any): Observable<any> {
		// alert(this.buildURL(apiRoutes.r_frontend_login));
		const url = this.buildURL(apiRoutes.r_frontend_login);
		const response = this.httpClient.post(url, credential);
		return response.pipe(map((data: any) => data));
	}

	logout(credential: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_logout);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getUserData(): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_obtener_usuario_logueado);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	// getUserBalance(param): Observable<any> {
	// 	const url = this.buildURL(`/v1/cliente/jugadores/balance`);
	// 	const response = this.httpClient.get(url);
	// 	return response.pipe(map((data: any) => data));
	// }
	//
	// getStaticData(param): Observable<any> {
	// 	const url = this.buildURL(`/v1/cliente/modelos/all`);
	// 	const response = this.httpClient.get(url);
	// 	return response.pipe(map((data: any) => data));
	// }
}
