import {createAction, props} from '@ngrx/store';
import {Credenciales, JugadorModel} from "../../shared/models/user.model";
import {AuthRefreshTokenResult} from './authentication.reducers';
import {RegisterData} from "../../shared/models/user.model";
import {MonedaModel} from "../../shared/models/wallet-currency.model";
import {BonosVisitanteModel} from "../../shared/models/bonos-visitante.model";

export const login = createAction('[Authentication] Login', props<{ credenciales: Credenciales }>());
export const loginFacebook = createAction('[Authentication] Login Facebook', props<{ token: string, email: string }>());
export const loginCompletado = createAction('[Authentication] Login Completado', props<{ data: any }>());
export const obtenerUsuarioLogueado = createAction('[Authentication] Obtener Usuario Logueado');
export const obtenerUsuarioLogueadoCompletado = createAction('[Authentication] Obtener Usuario LogueadoCompletado', props<{ data: any }>());
export const obtenerUsuarioLogueadoFromPusher = createAction('[Authentication] Obtener Usuario Logueado From Pusher', props<{ player: JugadorModel }>());
export const logout = createAction('[Authentication] Logout');
export const logoutCompletado = createAction('[Authentication] Logout Completado');
export const refreshToken = createAction('[Authentication Session Service] Refresh Token');
export const refreshTokenCompletado = createAction('[Authentication Session Service] Refresh Token Completado', props<AuthRefreshTokenResult>());
export const refreshTokenError = createAction('[Authentication Session Service] Refresh Token Error', props<{error:any}>());
export const register = createAction('[Authentication] Register', props<{userdata:RegisterData}>());
export const registerCompletado = createAction('[Authentication] Register Completado', props<{ data: any }>());
export const setRegistroConBonoYMoneda = createAction('[Authentication] Set Registro con Bono y Moneda', props<{ bono: BonosVisitanteModel, monedaSel:MonedaModel, proceso:string }>());
export const clearRegistroConBonoYMoneda = createAction('[Authentication] Clear Registro con Bono y Moneda');
export const onJugadorDataCryptoUpdate = createAction('[Authentication] Guardar datos nuevos del Jugador Crypto', props<{ data: any }>());
export const activacionCuentaJugador = createAction('[Authentication] Activacion Cuenta Jugador', props<{ params: any }>());
export const activacionCuentaJugadorCompletado = createAction('[Authentication] Activacion Cuenta Jugador Completado', props<{ data: any }>());


export const terminar_state = createAction('[Authentication] Terminar State', props<{ error: any }>());

