import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class ListenerGameService {

	changeGameSubject: Subject<any> = new Subject<any>();
	closeExchangerSubject: Subject<any> = new Subject<any>();
	closeMenuSubject: Subject<any> = new Subject<any>();

	constructor() {
	}

	emitGameSubject(data) {
		this.changeGameSubject.next(data);
	}

	emitCloseMenuSubject(data) {
		this.closeMenuSubject.next(data);
	}

	emitCloseExchangerSubject(data) {
		this.closeExchangerSubject.next(data);
	}
}
