import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
	selector: "[appAlphanumericDirective]"
})
export class AlphanumericDirective {

	private regex: RegExp = new RegExp(/[a-zA-Z0-9\s]$/g);
	private specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight", "Del", "Delete", ".", ","];
	private specialLetters: Array<string> = ["á", "é", "í", "ó", "ú", "ý", "Á", "É", "Í", "Ó", "Ú", "Ý"];

	constructor(
		private el: ElementRef
	) {
	}


	@HostListener("keydown", ["$event"]) onKeyDown(event) {
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}
		if (this.specialLetters.indexOf(event.key) !== -1) {
			return;
		}
		if (!event.key.match(this.regex)) {
			event.preventDefault();
		}
	}

}
