<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
<div class="tab-container-afiliados-0 hidden-landscape">
   <p class="txt-ssm tac mb-1"><span class="txt-ga">{{'PERIODO' | translate}}: </span>{{ getPeriodoActual() }}</p>
   <div class="px-1">
      <table class="tbl2 tbl2-md tbl2-striped tbl-vert bg-g1">
         <tr class="tbl2-head1">
            <th class="col-45-1 tac">{{'NOMBRE_CAMPANNA' |translate}}</th>
            <th class="col-2-1 tac">{{'TIPO_TRATO' |translate}}</th>
            <th class="col-2-1 tac">{{'REGISTROS' |translate}}</th>
            <th class="tac">{{'FTD' |translate}}</th>
         </tr>
      </table>
   </div>

   <div class="tab-container-afiliados-0 of-y2 bg-1 px-1">
      <table class="tbl2 tbl2-noHead tbl2-lg tbl2-striped tbl-vert bg-g1">
         <tr class="tbl2-body1" *ngFor="let traffic of allTraffic; let i = index;">
            <td class="col-45-1"><span class="txt-sm">{{traffic.nombre}}</span></td>
            <td class="col-2-1 tac"><span class="txt-sm">{{ traffic.trato.tipo_trato.nombre }}</span></td>
            <td class="col-2-1 tac">{{ traffic.valores.registros }}</td>
            <td class="tac">{{ traffic.valores.ftd }}</td>
         </tr>
      </table>
   </div>
</div>
<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
