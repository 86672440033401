import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import * as authAction from './authentication.actions';
import {AdminServices} from "../../shared/services/admin.service";
import {AuthenticationService} from "../services/authentication.service";
import {SessionServices} from 'src/app/shared/services/session.services';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/shared/redux/general.reducers';
import * as generalAction from "../../shared/redux/general.actions";
import * as GamesActions from "../../games/redux/games.actions";
import * as CajeroActions from "../../cajero/redux/cajero.actions";
import * as BonosActions from '../../bonos/redux/bonos.actions';
import * as AfiliadosActions from '../../afiliados/redux/afiliados.actions';
import {HomeService} from "../../home/services/home.service";

@Injectable()
export class AuthenticationEffects {

	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.login),
			exhaustMap(action =>
				this.adminService.login(action.credenciales).pipe(
					map(data => authAction.loginCompletado({data})),
					catchError(error => of(authAction.terminar_state({error})))
				)
			)
		)
	);

	loginComplete$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.loginCompletado),
			tap((action) => this.store.dispatch(generalAction.showLoader({
				httpRequestLoader: true,
				tipo: action.type
			}))),
			exhaustMap(action =>
				this.authService.getUserDataFirstTime(null).pipe(
					map(data => {
						this.store.dispatch(generalAction.hideLoader({
							httpRequestLoader: true,
							tipo: action.type
						}));
						this.store.dispatch(generalAction.changeLang({lang: data.user_data.jugador.idioma}));
						return authAction.obtenerUsuarioLogueadoCompletado({data});
					}),
					catchError(error => of(authAction.terminar_state({error})))
				)
			)
		)
	);

	loginFacebook$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.loginFacebook),
			exhaustMap(action =>
				this.authService.postLoginFacebook({token: action.token, email: action.email}).pipe(
					map(data => authAction.loginCompletado({data})),
					catchError(error => of(authAction.terminar_state({error})))
				)
			)
		)
	);

	usuarioLogueado$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.obtenerUsuarioLogueado),
			tap((action) => this.store.dispatch(generalAction.showLoader({
				httpRequestLoader: true,
				tipo: action.type
			}))),
			exhaustMap(action =>
				this.authService.getUserDataFirstTime(null).pipe(
					map(data => {
						this.store.dispatch(generalAction.hideLoader({
							httpRequestLoader: true,
							tipo: action.type
						}));
						return authAction.obtenerUsuarioLogueadoCompletado({data})
					}),
					catchError(error => of(authAction.terminar_state({error})))
				)
			)
		)
	);

	logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.logout),
			tap((action) => this.store.dispatch(generalAction.showLoader({
				httpRequestLoader: true,
				tipo: action.type
			}))),
			exhaustMap(action =>
				this.adminService.logout(null).pipe(
					map(data => {
						this.store.dispatch(generalAction.hideLoader({
							httpRequestLoader: true,
							tipo: authAction.logout.name
						}));
						return authAction.logoutCompletado()
					}),
					tap(data => {
						this.store.dispatch(generalAction.generalLogoutCompletado());
						this.store.dispatch(GamesActions.gamesLogoutCompletado());
						this.store.dispatch(CajeroActions.cajeroLogoutCompletado());
						this.store.dispatch(BonosActions.bonosLogoutCompletado());
						this.store.dispatch(AfiliadosActions.afiliadosLogoutCompletado());
						this.store.dispatch(generalAction.hideLoader({httpRequestLoader: true,
							tipo: action.type}));
					}),
					catchError(error => of(authAction.terminar_state({error})))
				)
			)
		)
	);

	refreshToken$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.refreshToken),
			exhaustMap(action =>
				this.sessionService.refreshToken().pipe(
					map(data => authAction.refreshTokenCompletado(data)),
					catchError(error => {
						return of(authAction.refreshTokenError({error}))
					})
				)
			)
		)
	);


	register$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.register),
			exhaustMap(action => {
					return this.authService.postRegister(action.userdata).pipe(
						map(data => authAction.registerCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	activacionCuenta$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authAction.activacionCuentaJugador),
			exhaustMap(action => {
					return this.homeService.confirmarActivacionDeCuenta(action.params).pipe(
						map(data => authAction.activacionCuentaJugadorCompletado({data})),
						catchError(error => of(authAction.terminar_state({error})))
					)
				}
			)
		)
	);

	constructor(
		private actions$: Actions,
		private adminService: AdminServices,
		private sessionService: SessionServices,
		private authService: AuthenticationService,
		private homeService: HomeService,
		private store: Store<AppState>
	) {
	}
}
