import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators, AbstractControl} from "@angular/forms";
import {NotificationService} from "../../../shared/services/notification.service";
import {FacebookLoginProvider, SocialAuthService} from "angularx-social-login";
import {RegisterData} from "../../../shared/models/user.model";
import {WTerminosCondicionesComponent} from "../../../shared/w-terminos-condiciones/w-terminos-condiciones.component";
import {ActivatedRoute, Router} from "@angular/router";
import {WDepositoComponent} from "../../../cajero/components/w-deposito/w-deposito.component";
import Swal from "sweetalert2";
import * as AuthAction from "../../redux/authentication.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/redux/general.reducers";
import {Actions, ofType} from "@ngrx/effects";
import {WalletGeneralModel} from "../../../shared/models/wallet-general.model";
import {BonosVisitanteModel} from "../../../shared/models/bonos-visitante.model";
import {Subscription} from "rxjs";

import {fixAndroid} from '../../../shared/utils/general.util';
import {TranslateService} from "@ngx-translate/core";

declare function adjustSize(): any;

@Component({
	selector: "app-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.css"]
})
export class RegisterComponent implements OnInit, OnDestroy {

	registerForm: FormGroup;
	@Input() selectedCoin: WalletGeneralModel;
	@Input() bono: BonosVisitanteModel;
	@Input() process: string;
	registerCompletadoSubscription: Subscription = null;
	obtenerUsuarioLogueadoCompletadoSubs: Subscription = null;

	codigoCampanna;

	emailRegex = /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-zA-Z]{2,9}$/;
	afiliadosMode: boolean = false;

	wActive: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private notification: NotificationService,
		public activeModal: NgbActiveModal,
		private router: Router,
		private authService: SocialAuthService,
		private store: Store<AppState>,
		private actions$: Actions,
		private translateService: TranslateService,
	) {

	}

	ngOnInit(): void {

		this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
		});

		this.listeningActions();
		this.initForm();
		this.codigoCampanna = this.route.snapshot.queryParams.cod;
		//
		setTimeout(() => {
			this.wActive = true;
		}, 0);
		setTimeout(() => {
			fixAndroid('w-cover-register');
		}, 250);
	}

	ngOnDestroy(): void {
		if (this.registerCompletadoSubscription) {
			this.registerCompletadoSubscription.unsubscribe();
		}

		if (this.obtenerUsuarioLogueadoCompletadoSubs) {
			this.obtenerUsuarioLogueadoCompletadoSubs.unsubscribe();
		}
	}

	listeningActions() {

		this.registerCompletadoSubscription = this.actions$
			.pipe(ofType(AuthAction.registerCompletado))
			.subscribe(response => {
				this.store.dispatch(AuthAction.obtenerUsuarioLogueado());

				this.registerCompletadoSubscription.unsubscribe();
			});

		//Escuchando la llegada del obtener usuario logueado
		this.obtenerUsuarioLogueadoCompletadoSubs = this.actions$
			.pipe(ofType(AuthAction.obtenerUsuarioLogueadoCompletado))
			.subscribe(response => {
				const resp = response;
				this.onCloseW();

				if (this.afiliadosMode) {
					this.router.navigate(["/afiliados/inicio"]).then();
				} else {
					console.log(4);
					this.router.navigate(["/inicio/jugador"]).then();
				}

				if (!this.process || this.process === "AFILIADOS_VISITANTES") {
					Swal.fire(this.notification.BuildSuccess(this.translateService.instant("REGISTRADO_SATISFACTORIAMENTE"), this.translateService.instant("REGISTRO_COMPLETO"))).then(() => {
					});
				} else {
					this.openDeposit();
				}

				this.obtenerUsuarioLogueadoCompletadoSubs.unsubscribe();
			});
	}


	initForm() {
		this.registerForm = this.formBuilder.group(
			{
				username: new FormControl(null, [Validators.required, Validators.minLength(3)]),
				password: new FormControl(null, [Validators.required,
					Validators.minLength(6),
					this.validPasswordMayuscula(),
					this.validPasswordMinuscula(),
					this.validPasswordNumero(),
					this.validPasswordCaracterRaro()
				]),
				passwordConfirmation: new FormControl(null, [Validators.required]),
				email: new FormControl(null, [Validators.required, Validators.pattern(this.emailRegex)]),
				termsAndConditions: new FormControl(false, [Validators.requiredTrue])
			}, {
				validator: [
					this.MustMatch("password", "passwordConfirmation")
				]
			}
		);
	}

	private MustMatch(controlName: string, matchingControlName: string) {
		return (formGroup: FormGroup) => {
			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];
			if (matchingControl.errors && !matchingControl.errors.mustMatch) {
				return;
			}
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({mustMatch: true});
			} else {
				matchingControl.setErrors(null);
			}
		};
	}

	checkForValidPasword(validationName) {
		if (this.registerForm && this.registerForm.controls['password'].touched
			&& this.registerForm.controls['password'].hasError(validationName)) {
			return true;
		}
		return false;
	}

	disableRegister() {
		const controlOne = this.registerForm.controls.username;
		const controlTwo = this.registerForm.controls.password;
		const controlThree = this.registerForm.controls.passwordConfirmation;
		const controlFour = this.registerForm.controls.email;
		const controlFive = this.registerForm.controls.termsAndConditions;
		if (!controlOne.valid || !controlTwo.valid || !controlThree.valid || !controlFour.valid || !controlFive.valid) {
			return true;
		}
		return controlOne.value === null || controlTwo.value === null || controlFour.value === null || controlFive.value === null;
	}

	inputTouched(formName: string) {
		return this.registerForm.controls[formName].invalid && (this.registerForm.controls[formName].dirty || this.registerForm.controls[formName].touched);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - NO CLICK ()
	noClick(event) {
		event.stopPropagation();
		event.preventDefault();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	goToLogin(event) {
		event.stopPropagation();
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close("GoToLogin");
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON REGISTER ()
	onRegister(event) {
		event.stopPropagation();
		if (this.registerForm.valid) {
			const registerModel = this.registerForm.value;
			let param = new RegisterData();
			param.crypto = true;
			param.email = registerModel.email;
			param.moneda_base_id = 18;
			param.moneda_id = this.selectedCoin ? this.selectedCoin.id : null;
			param.password = registerModel.password;
			param.password_confirmation = registerModel.passwordConfirmation;
			param.terminos = registerModel.termsAndConditions;
			param.username = registerModel.username;
			param.quiere_afiliado = this.process === "AFILIADOS_VISITANTES";
			param.campana_codigo = this.codigoCampanna ? this.codigoCampanna : null

			this.store.dispatch(AuthAction.register({userdata: param}));
			//this.store.dispatch(AuthAction.clearRegistroConBonoYMoneda());
		}

	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON REGISTER FB ()
	onRegisterFB(event) {
		event.stopPropagation();
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
			(userData) => {
				this.registerForm.controls.email.setValue(userData.email);
				if(userData.email) {
					this.registerForm.controls.username.setValue(userData.email.split("@")[0]);
				}
			}
		);

	}

	openDeposit() {
		const modalRef = this.modalService.open(WDepositoComponent);
		modalRef.componentInstance.selectedWalletCurrency = this.selectedCoin;
		modalRef.componentInstance.bono = this.bono;
		modalRef.componentInstance.process = this.process;
		modalRef.result.then();
	}

	private validPasswordMayuscula() {
		return (control: AbstractControl) => {
			if (/[A-Z]/.test(control.value) === false)
				return {validPasswordMayuscula: true};
			return null;
		}
	}

	private validPasswordMinuscula() {
		return (control: AbstractControl) => {
			if (/[a-z]/.test(control.value) === false)
				return {validPasswordMinuscula: true};
			return null;
		}
	}

	private validPasswordNumero() {
		return (control: AbstractControl) => {
			if (/\d/.test(control.value) === false)
				return {validPasswordNumero: true};
			return null;
		}
	}

	private validPasswordCaracterRaro() {
		return (control: AbstractControl) => {
			if (/\W/.test(control.value) === false)
				return {validPasswordCaracterRaro: true};
			return null;
		}
	}

	goToTermConditions() {
		const modalRef = this.modalService.open(WTerminosCondicionesComponent);
		modalRef.result.then();
	}
}
