import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import {WTerminosCondicionesComponent} from "../../../shared/w-terminos-condiciones/w-terminos-condiciones.component";
import {RegisterComponent} from "../../../authetication/components/register/register.component";
import {LoginComponent} from "../../../authetication/components/login/login.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MonedasSiglasLocal} from "../../../shared/utils/general.util";
import * as GeneralAction from "../../../shared/redux/general.actions";
import {Store} from "@ngrx/store";
import {AppState} from "src/app/shared/redux/general.reducers";
import * as GameAction from "../../../games/redux/games.actions";
import * as cajeroAction from "../../../cajero/redux/cajero.actions";
import {SessionServices} from "src/app/shared/services/session.services";
import * as AutheticationAction from "../../../authetication/redux/authentication.actions";
import {Router} from "@angular/router";
import {JugadorModel,} from "src/app/shared/models/user.model";
import {WLanguagesComponent} from "../../../shared/w-languages/w-languages.component";
import {IdiomaModel} from "../../../shared/models/idioma.model";

@Component({
	selector: "app-home-afiliados-visitor",
	templateUrl: "./home-afiliados-visitor.component.html",
	styleUrls: ["./home-afiliados-visitor.component.css"]
})
export class HomeAfiliadosVisitorComponent implements OnInit, AfterViewInit, OnDestroy {

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	coinsA = MonedasSiglasLocal;

	// ss1:number;
	st1: number;
	vidMutted = true;
	afiliadosMode: boolean = true;
	usuarioLogueado: JugadorModel = null;
	selectedLang: IdiomaModel;

	constructor(
		private modalService: NgbModal,
		private modalServiceLang: NgbModal,
		private router: Router,
		private sessionService: SessionServices,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		this.store.select((state) => state).subscribe(state => {
			this.afiliadosMode = state.general.afiliadosMode;
			this.usuarioLogueado = state.auth.player;
			this.selectedLang = state.general.selectedLang;
		});

		this.store.dispatch(GeneralAction.setAfiliadosMode({afiliadosMode: true}));

		document.getElementById("turn").classList.add("noTurn");
		// this.ss1=0;
		this.st1 = 0;
		this.vidMutted = true;
		//
		let bg = document.getElementsByClassName("fullscreen-bg")[0] as HTMLDivElement;
		bg.classList.add("afiliado");
		document.getElementById("main-container").classList.add("unboxed");

		this.initData();
	}

	initData() {
		this.getCryptoCurrency();
		this.getCatAndGames();
		this.getCurrentTorneo();
	}

	getCatAndGames() {
		this.store.dispatch(GameAction.clasificacionesVisualesJuegos({endUrl: ""}));
	}

	getCryptoCurrency() {
		this.store.dispatch(cajeroAction.getCryptoMonedas());
	}

	getCurrentTorneo() {
		this.store.dispatch(GeneralAction.getTorneoActivo());
	}

	ngAfterViewInit() {
		/*
		this.avustaVideos();
		*/
	}

	ngOnDestroy() {
		document.getElementById("turn").classList.remove("noTurn");
		//
		let bg = document.getElementsByClassName("fullscreen-bg")[0] as HTMLDivElement;
		bg.classList.remove("afiliado");
		document.getElementById("main-container").classList.remove("unboxed");
	}

	itsLogged() {
		return this.sessionService.isLoggedIn();
	}

	logOutAction() {
		this.store.dispatch(AutheticationAction.logout());
	}


	onIrALobby() {
		if (this.usuarioLogueado.afiliado) {
			this.router.navigate(["/afiliados/inicio"]).then();
		} else {
			console.log(6);
			this.router.navigate(["/inicio/jugador"]).then();
		}
	}

	/*
	avustaVideos() {
		var vid = document.getElementsByClassName('tutorial-video');
		var vid0 = vid[0] as HTMLVideoElement;
		var vidW = vid0.offsetWidth;
		for ( var i=0; i < vid.length; i++ ){
		   var vidI = vid[i] as HTMLVideoElement;
		   vidI.style.height= vidW+'px';
		}
		var vidT = document.querySelector('.video-title') as HTMLDivElement;
		var vidTH = vidT.offsetHeight + 10;
		var vcH = (vidW + vidTH) + 'px';
		var vc = document.querySelector('.ha-tutorial-container') as HTMLDivElement;
		vc.style.height = vcH;
	}
	*/

	/*
	  onSs1Prev() {
		 var total = document.getElementsByClassName('ha-slide').length - 1;
		 this.ss1--;
		 this.ss1 = this.ss1 < 0 ? total : this.ss1;
		 document.getElementById('ha-slider1-content').className="pos"+this.ss1;
	  }
	  onSs1Next() {
	   var total = document.getElementsByClassName('ha-slide').length - 1;
		 this.ss1++;
		 this.ss1 = this.ss1 > total ? 0 : this.ss1;
		 document.getElementById('ha-slider1-content').className="pos"+this.ss1;
	  }
	*/
	onSt1Prev() {
		let total = document.getElementsByClassName("ha-tutorial").length - 1;
		this.st1--;
		this.st1 = this.st1 < 0 ? total : this.st1;
		document.getElementById("ha-tutorial-content").className = "pos" + this.st1;
		let vids = document.getElementsByClassName("tutorial-video");
		for (let i = 0; i < vids.length; i++) {
			let currV = vids[i] as HTMLVideoElement;
			currV.pause();
		}
	}

	onSt1Next() {
		let total = document.getElementsByClassName("ha-tutorial").length - 1;
		this.st1++;
		this.st1 = this.st1 > total ? 0 : this.st1;
		document.getElementById("ha-tutorial-content").className = "pos" + this.st1;
		let vids = document.getElementsByClassName("tutorial-video");
		for (let i = 0; i < vids.length; i++) {
			let currV = vids[i] as HTMLVideoElement;
			currV.pause();
		}
	}

	onLogIn() {
		const modalRef = this.modalService.open(LoginComponent);
		modalRef.result.then(
			(resp: any) => {
				if (resp === "GoToRegister") {
					this.onRegister();
				}
			});
	}

	onRegister() {
		const modalRef = this.modalService.open(RegisterComponent);
		modalRef.componentInstance.process = "AFILIADOS_VISITANTES";
		modalRef.result.then(
			(resp: any) => {
				if (resp === "GoToLogin") {
					this.onLogIn();
				}
			});
	}

	goChat() {
	}

	goToTermConditions() {
		const modalRef = this.modalService.open(WTerminosCondicionesComponent);
		modalRef.result.then();
	}

	goToPrivacy() {
	}

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  UNMUTE VIDEO ()
	unmuteVideo() {
		// this.vidMutted = !this.vidMutted;

		const vid = document.getElementById("ha-main-video") as HTMLVideoElement;

		if (vid.ended) {
			vid.play().then();
		} else {
			this.vidMutted = this.vidMutted !== true;
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SELECT LANGUAGE ()
	onSelectLanguage() {
		const modalRefLang = this.modalServiceLang.open(WLanguagesComponent);
	}

}
