import { FilterModel } from "../models/filter.model";

export class GeneralUtil {
	static redondearNumero(numero: number, lugaresDespuesDeLaComa: number): number {
		let factor = 1;
		for (let i = 0; i < lugaresDespuesDeLaComa; i++) {
			factor *= 10;
		}
		return Math.round(numero * factor) / factor;
	}


	static isTokenAlive(token: any): boolean {
		const time = token.expires_in * 1000;
		const currentMillis = new Date().getTime();
		return time > (currentMillis - token.millis);
	}

	static setWithExpiry(value, ttl = 300000) {
		const now = new Date();

		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			value: value,
			expire: now.getTime() + ttl,
		};
		return item;
	}

	static setWithFilter(value, filter: FilterModel = new FilterModel()) {
		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			value: value,
			filter: filter,
		};
		return item;
	}

	static getWithExpiry(key) {
		const itemStr = localStorage.getItem(key);
		// if the item doesn't exist, return null
		if (!itemStr) {
			return null;
		}
		const item = JSON.parse(itemStr);
		const now = new Date();
		// compare the expiry time of the item with the current time
		if (now.getTime() > item.expiry) {
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.removeItem(key);
			return null;
		}
		return item.value;
	}
}


export const MonedasSiglasLocal = ["btc", "eth", "ltc", "bch", "xrp", "ada", "doge", "etc", "neo", "rep", "usdt", "xin", "bnb", "bsv", "eurs", "usdc"];

export function fixAndroid(coverE) {
	var wcover = document.getElementById(coverE) as HTMLDivElement;
	if(wcover) {
		var h = wcover.offsetHeight;
		wcover.style.height = h + 'px';
	}
	return null;
}

export function SubMenuActivo(rutaActiva: string): string {
	let subMenuActivo = '';
	switch (rutaActiva) {
		case "/afiliados/campannas":
			subMenuActivo = "campannas";
			break;
		case "/afiliados/trafico":
			subMenuActivo = "trafico";
			break;
		case "/afiliados/comisiones":
			subMenuActivo = "comisiones";
			break;
		case "/afiliados/balances_retiros":
			subMenuActivo = "balances_retiros";
			break;
		case "/cajero/wallet":
			subMenuActivo = "wallet";
			break;
		case "/cajero/exchanger":
			subMenuActivo = "exchanger";
			break;
		case "/cajero/verificacion":
			subMenuActivo = "verificacion";
			break;
		case "/cajero/retiros":
			subMenuActivo = "retiros";
			break;
		case "/bonos/mis_bonos":
			subMenuActivo = "mis_bonos";
			break;
		case "/bonos/historial":
			subMenuActivo = "historial";
			break;
		case "/cuenta/datos":
			subMenuActivo = "datos";
			break;
		case "/cuenta/movimientos":
			subMenuActivo = "movimientos";
			break;
		case "/cuenta/juegos":
			subMenuActivo = "juegos";
			break;
		case "/cuenta/movimientos_cripto":
			subMenuActivo = "movimientos_cripto";
			break;
	}

	return subMenuActivo;
}

//Recibe el mes como un string que representa el número del mismo empezando por
// ¨01¨ - Enero ... ¨12¨ - Diciembre y retorne el Nombre en Español del mes.
export function GetNombreMesesEspannol(mes: string): string {
	let nombreEspannol = '';

	switch (mes) {
		case "01":
			nombreEspannol = "Enero";
			break;
		case "02":
			nombreEspannol = "Febrero";
			break;
		case "03":
			nombreEspannol = "Marzo";
			break;
		case "04":
			nombreEspannol = "Abril";
			break;
		case "05":
			nombreEspannol = "Mayo";
			break;
		case "06":
			nombreEspannol = "Junio";
			break;
		case "07":
			nombreEspannol = "Julio";
			break;
		case "08":
			nombreEspannol = "Agosto";
			break;
		case "09":
			nombreEspannol = "Septiembre";
			break;
		case "10":
			nombreEspannol = "Octubre";
			break;
		case "11":
			nombreEspannol = "Noviembre";
			break;
		case "12":
			nombreEspannol = "Diciembre";
			break;
	}

	return nombreEspannol;
}
