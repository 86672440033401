<div class="w-cover">

	<div class="window pt-1" id="bono-activate-1">

		<div class="w-title mb-1">{{'ACTIVAR_BONO_DEPOSITO' |translate}}
			<!-- <span *ngIf="tipoActivacion == 'pre0'" class="txt-g9">( pre-depósito )</span> -->
		</div>

		<div class="px-2">
			<div class="col col-1"></div>

			<div class="col col-10">
				<!-- <div class="faux-inp faux-inp-nor tac mb-05"><span class="txt-c4">Bono: </span> {{bono.nombre}}
            </div> -->
				<div class="faux-inp faux-inp-nor mb-05">{{bono.nombre}}
				</div>
			</div>
			<div class="cb mb-1"></div>

			<p class="txt-gc taj mb-05">{{'BONO_SE_PUEDE_ASOCIAR_MONEDAS'|translate}}:</p>
			<div class="b-1-g5 mb-05">
				<div class="faux-inp faux-inp-sm of-y b-2-t br-0">
					<div class="txt-sm cols-4">
						<p *ngFor="let item of bono.monedas; let i = index;" class="ff-cnd"><span class="txt-c2">
								{{item.siglas.toUpperCase()}} -
							</span> {{item.nombre}}</p>
					</div>
				</div>
			</div>

			<p *ngIf="tipoActivacion == 'pre0'" class="txt-sssm txt-gc taj">{{'MSGS_BONO_AFECTARA_BALANCE_MONEDA_PROXIMO_DEPOSITO' |translate}}.</p>

			<div *ngIf="tipoActivacion == 'pre1'">
				<p class="txt-c4-l taj mb-1">¿{{'COMO_DESEAS_ACTIVAR_BONO' |translate}}?</p>

				<div class="col col-4">
					<div class="pos-rel ff-cnd">
						<div [class.checked]="modeActivation==0" class="rad rad-4th">
							{{'CON_PROXIMO_DEPOSITO'|translate}}
							<input (click)="onActivationMode($event,0)" class="" id="mode0" name="next" type="radio"
								   value="">
						</div>
					</div>
				</div>
				<div class="col col-8 pl-1">
					<div class="pos-rel ff-cnd">
						<div [class.checked]="modeActivation==1" class="rad rad-4th">
							{{'CON_ULTIMO_DEPOSITO'|translate}}<span class="txt-gc"> : {{lastDeposit.fecha}} </span> <span
							class="txt-gc"> : </span> <span class="txt-c2"> {{lastDeposit.moneda.toUpperCase()}}
							</span> <span class="txt-gc"> {{lastDeposit.importe | number: "1.2-8"}} </span>
							<input (click)="onActivationMode($event,1)" class="" id="mode1" name="next" type="radio"
								   value="">
						</div>
					</div>
				</div>
				<div class="cb"></div>

			</div>


		</div>

		<div class="w-actions px-3">

			<div class="col col-3 pr-05">
				<button (click)="onCancel($event)" class="btn btn-nor btn-rnd btn-7th btn-icon full-w" type="button">
					{{'CANCELAR' |translate}}
					<img src="../../../../assets/images/icon-b-close7-c.svg">
				</button>
			</div>
			<div class="col col-6"></div>
			<div class="col col-3 pl-05">

				<button (click)="onActivateAndGo($event)" *ngIf="tipoActivacion == 'pre0'"
						class="btn btn-nor btn-rnd btn-1st btn-icon full-w" type="button">
					{{'CONTINUAR' |translate}}
					<img src="../../../../assets/images/icon-b-play-c.svg">
				</button>

				<button (click)="onActivate($event)" *ngIf="tipoActivacion == 'pre1'"
						class="btn btn-nor btn-rnd btn-1st btn-icon full-w" type="button">
					{{'CONTINUAR' |translate}}
					<img src="../../../../assets/images/icon-b-play-c.svg">
				</button>

			</div>

			<div class="cb"></div>
		</div>

		<button (click)="onCloseW($event)" class="btn btn-w-close" type="button">
			<img src="../../../../assets/images/icon-b-close.svg">
		</button>
	</div>

</div>
