import {Routes} from "@angular/router";
import {HomeVisitorComponent} from "./components/home-visitor/home-visitor.component";
import {HomeJugadorComponent} from "./components/home-jugador/home-jugador.component";
import {JugadorGuard} from "./guards/jugador.guard";

export const HomeRoutes: Routes = [
	{
		path: "",
		redirectTo: "visitante",
		pathMatch: "full"
	},
	// - - - - - - - - - - - - - HI - HOME VISITOR
	{
		path: "visitante",
		component: HomeVisitorComponent
	},
	// - - - - - - - - - - - - - / HI - HOME VISITOR
	{
		path: "jugador",
		component: HomeJugadorComponent,
		canActivate: [JugadorGuard]
	},
];
