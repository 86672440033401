import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {apiRoutes} from "../../api-routing";

@Injectable({
	providedIn: "root"
})
export class HomeService {
	private api: string = environment.apiUrl;

	constructor(private httpClient: HttpClient) {
	}

	buildURL(serviceUrl: string): string {
		return `${environment.protocol}${this.api + serviceUrl}`;
	}

	getBonosVisitantes(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_bono_visitantes + "?orderBy=created_at&direction=ASC&take=5");
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getMonedasHomeExchanger(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_monedas_exchanger_visitantes);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getMonedasExchangerJugador(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_monedas_exchanger_visitantes);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getHomeAfiliadosData(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_afiliados_home_tabla);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	confirmarActivacionDeCuenta(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_activacion);
		const response = this.httpClient.patch(url, param);
		return response.pipe(map((data: any) => data));
	}

	obtenerLoginDatos(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_obtener_usuario_logueado);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	obtenerPaises(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_paises_get);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	obtenerEstadosPorPais(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_paises_estados_get.replace("{id}", param.id));
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getCurrentTorneo(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_torneos_get_torneo);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	getCurrencyExchange(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_currency_exchange);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	saveVisita(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_visitante_campanna_post);
		const response = this.httpClient.post(url, param.data);
		return response.pipe(map((data: any) => data));
	}

	getLangs(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_idiomas);
		const response = this.httpClient.get(url);
		return response.pipe(map((data: any) => data));
	}

	setUserLang(param: any): Observable<any> {
		const url = this.buildURL(apiRoutes.r_frontend_jugadores_cambio_idioma);
		const response = this.httpClient.patch(url, param);
		return response.pipe(map((data: any) => data));
	}


}
