<div class="flex-y-alt full-h">
    <div class="verif-txt">
        <ng-container *ngIf="!dataCompleted">
            <h1 class="txt-reg mb-1">{{'COMPLETAR_MIS_DATOS' |translate}}</h1>
            <p>{{'COMPLETAR_INFO_MIS_DATOS_DESCRIPCION_1' |translate}} <b>“{{'COMPLETAR_INFO_MIS_DATOS_DESCRIPCION_2' |translate}}”</b> {{'COMPLETAR_INFO_MIS_DATOS_DESCRIPCION_3' |translate}}.</p>
        </ng-container>
        <ng-container *ngIf="dataCompleted">
            <h1 class="txt-reg mb-1">{{'DATOS_VERIFICADOS' |translate}}</h1>
            <p>{{'VERIFICAR_DATOS_FORMULARIO_1' |translate}} <b>&ldquo;{{'VERIFICAR_DATOS_FORMULARIO_2' |translate}}&rdquo;</b> .</p>
        </ng-container>
    </div>

    <button class="btn2 btn2-lg btn2-1st bg-k7" routerLink="/cuenta/datos" type="button">
        &emsp;{{'IR_A_MIS_DATOS' |translate}}&emsp;
    </button>

    <div class="cb"></div>
</div>
