import {Component, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

declare function adjustSize(): any;

// @Pipe({name: 'safe'})

@Component({
	selector: "app-w-terminos-condiciones",
	templateUrl: "./w-terminos-condiciones.component.html",
	styleUrls: ["./w-terminos-condiciones.component.css"]
})
export class WTerminosCondicionesComponent implements OnInit {

   urlSafe: SafeResourceUrl;

   wActive:boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private sanitizer: DomSanitizer
	) {
	}

	ngOnInit(): void {
      setTimeout(() => { this.wActive = true; }, 0);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
      this.wActive = false;
      setTimeout(() => {
         this.activeModal.close();
         adjustSize();
      }, 250);
	}

}
