import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {BonosModel} from "../../../../shared/models/bonos.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WRulesComponent} from "../../w-rules/w-rules.component";
import Swal from "sweetalert2";
import {NotificationService} from "../../../../shared/services/notification.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/redux/general.reducers";
import {Subscription} from "rxjs";
import * as BonoAction from "../../../redux/bonos.actions";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-activos-bonos",
	templateUrl: "./activos-bonos.component.html",
	styleUrls: ["./activos-bonos.component.css"]
})
export class ActivosBonosComponent implements OnInit, OnDestroy {

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	subscription = new Subscription();
	allBonosActivos: BonosModel[];

	constructor(
		private modalService: NgbModal,
		private notificationService: NotificationService,
		private store: Store<AppState>,
		private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		this.subscription.add(this.store.subscribe(state => {
				this.allBonosActivos = state.bonos.bonosActivos;
			})
		);
	}
	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW RULES ()
	onShowRules(bono: BonosModel) {
		const modalRef = this.modalService.open(WRulesComponent);
		modalRef.componentInstance.bono = bono;
		modalRef.componentInstance.tipo = "BONOJUGADOR";
		modalRef.result.then();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW RULES ()
	onCancelBono(bono: BonosModel) {
		const message = "";
		Swal.fire(this.notificationService.BuildQuestion(message, this.translateService.instant("CANCELAR_BONO"))).then(
			(result) => {
				if (result.value) {
					const param = {
						id: bono.id
					};
					this.patchDeactivateBono(param);
				}
			}
		);
	}

	patchDeactivateBono(param) {
		this.store.dispatch(BonoAction.desactivarBonos({param:param}))
	}


}
