
import {Action, createReducer, on} from "@ngrx/store";
import * as GamesActions from "../../games/redux/games.actions";
import {GeneralUtil} from "../../shared/utils/general.util";
import {GameCategoryModel} from "../../shared/models/game-category,model";
import {DataWithExpireTime} from 'src/app/shared/redux/general.reducers';
import {GamesModel} from "../../shared/models/games.model";


export class GameState {
	games: DataWithExpireTime<GamesModel>;
	masJugados: DataWithExpireTime<GamesModel>;
	clasificacionesVisuales: DataWithExpireTime<GameCategoryModel>;
	categoriaSeleccionada: GameCategoryModel;
	filterGames: { name: string, moneda_id: number };
	juegoSeleccionado: GamesModel;

	constructor() {
		this.games = {value: [], expire: 0};
		this.masJugados = {value: [], expire: 0};
		this.clasificacionesVisuales = {value: [], expire: 0};
		this.categoriaSeleccionada = null;
		this.filterGames = {name: '', moneda_id: null}
		this.juegoSeleccionado = null
	}
}

const GamesReducer = createReducer(
	new GameState(),
	on(GamesActions.juegosAuthCompletado, (state, {data}) => {
		let nuevosJuegos = [];
		for (var i = 0; i < state.games.value.length; i++) {
			let nuevosGame = {...state.games.value[i]}
			let encontrado = data.favoritos.find((x) => x === state.games.value[i].id)

			if (encontrado) {
				nuevosGame.favorito = true;
			}
			nuevosJuegos.push(nuevosGame);
		}

		return {
			...state,
			masJugados: GeneralUtil.setWithExpiry(data.mas_jugados),
			games: {value: nuevosJuegos, expire: state.games.expire}
		}
	}),
	on(GamesActions.clasificacionesVisualesJuegosCompletado, (state, {data}) => ({
		...state,
		games: GeneralUtil.setWithExpiry(data.data.juegos, 3600000),/* 60 minutos */
		clasificacionesVisuales: GeneralUtil.setWithExpiry(data.data.clasificaciones_visuales, 3600000),/* 60 minutos */
		juegoSeleccionado: data.data.juegos.find(x => x.id === 5),

	})),
	on(GamesActions.setCategoriaSeleccionada, (state, {cat}) => ({
		...state,
		categoriaSeleccionada: cat,
	})),
	on(GamesActions.gamesLogoutCompletado, (state) => {
		return {
			...new GameState(), clasificacionesVisuales: state.clasificacionesVisuales,
			games: state.games
		};
	}),
	on(GamesActions.setFilterGames, (state, {name, moneda_id}) => ({
		...state,
		filterGames: {name: name, moneda_id: moneda_id},
	})),
	on(GamesActions.clearFilterGames, (state) => ({
		...state,
		filterGames: {name: '', moneda_id: null},
	})),
	on(GamesActions.setJuegoSeleccionado, (state, {game}) => ({
		...state,
		juegoSeleccionado: game,
	})),
	on(GamesActions.getSoloClasificacionesCompletado, (state, {data}) => ({
		...state,
		clasificacionesVisuales: GeneralUtil.setWithExpiry(data.data, 3600000),/* 60 minutos */
	})),
	on(GamesActions.putLikeFavoriteCompletado, (state, {data}) => {
		let nuevosGames = [];
		for (let i = 0; i < state.games.value.length; i++) {
			let nuevosGame = {...state.games.value[i]}
			if (state.games.value[i].id == data.juego_id) {
				if(data.operacion == "suma") {
					nuevosGame.favorito = true;
				}else{
					nuevosGame.favorito = false;
				}
			}
			nuevosGames.push(nuevosGame);
		}

		let nuevosGamesMasJugados = [];
		for (let i = 0; i < state.masJugados.value.length; i++) {
			let nuevoGameMasJugados = {...state.masJugados.value[i]}
			if (state.masJugados.value[i].id == data.juego_id) {
				if(data.operacion == "suma") {
					nuevoGameMasJugados.favorito = true;
				}else{
					nuevoGameMasJugados.favorito = false;
				}
			}
			nuevosGamesMasJugados.push(nuevoGameMasJugados);
		}
		return {
			...state,
			games: {value: nuevosGames, expire: state.games.expire},
			masJugados: {value: nuevosGamesMasJugados, expire: state.masJugados.expire}
		};
	}),
	on(GamesActions.getAllImagenesCompletado, (state, {data}) => {
		let nuevosGames = [];
		for (let i = 0; i < state.games.value.length; i++) {
			let nuevosGame = {...state.games.value[i]}
			nuevosGame.imagen_logo_base64 = data.data[nuevosGame.id];
			nuevosGames.push(nuevosGame);
		}
		return {
			...state,
			games: {value: nuevosGames, expire: state.games.expire},
		};
	}),
);

export function reducer(state: GameState | undefined, action: Action) {
	return GamesReducer(state, action);
}
