<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
<div class="flex-y-alt full-h pb-15 hidden-landscape">
	<div class="verif-txt w-tooltip">
		<h1 class="txt-reg mb-1">{{'MIS_TARJETAS' |translate}}</h1>
		<p>{{'FOTOS_TARJETAS' |translate}}.</p>
		<!-- TOOTLTIP2 -->
		<button type="button" class="tooltip2" (click)="onToggleTooltip2($event,'cards1')"><img
			src="../../../../../assets/images/icon-question.svg"></button>
		<div class="tooltip2-contentainer RU" id="cards1"
			 (click)="onToggleTooltip2($event,'cards1')">
			<div class="tooltip2-content">
				<span class="txt-sm">{{'TARJETA_DESCRIPCION'|translate}}.</span>
			</div>
		</div>
		<!-- / TOOTLTIP2 -->
	</div>

	<div class="verification-area noScrlBr">
		<!-- OJO !!!!!
		Hay que considerar si puede haber más de un documento -->

		<!--      <div class="doc-container"> &lt;!&ndash; Si no hay otra foto &ndash;&gt;-->
		<!--         <img [src]="this.selectedCard.url" *ngIf="this.selectedCard">-->
		<!--         <img class="doc" src="../../../../../assets/images/docs/card.png" *ngIf="!this.selectedCard">-->
		<!--      </div>-->

		<ng-container *ngFor="let item of selectedDocuments; let i = index;">
			<div class="doc-container" (click)="onShow($event, item)">
				<img *ngIf="dibujarImagen(item, 'imagen')" class="doc" [src]="this.item.url">
				<img *ngIf="dibujarImagen(item, 'pdf')" class="doc" src="../../../../../assets/images/docs/pdf.jpg">
				<button type="button" class="btn2 btn2-7th btn2-sm btn2-sm-icon bg-k7 mr-1" (click)="onDeleteDoc($event, item)">
					<img src="../../../../assets/images/icon-b-trash.svg">
				</button>
			</div>
		</ng-container>

	</div>

	<div class="file-upload p-1">
		<div class="col col-icon-sm">
			<input type="file" class="inp2 inp2-sm inputfile" id="file-inp" name="file-inp" (change)="handle($event)">
			<button type="button" class="btn2 btn2-1st btn2-sm btn2-sm-icon mr-1" (click)="fireFile($event)">
				<img src="../../../../assets/images/icon-file-upload.svg">
			</button>
		</div>
		<div class="col col-icon-sm-r">
			<input type="text" class="inp2 inp2-sm full-w file-inp-txt" id="file-inp-txt" readonly
				   placeholder="{{'SELECCIONE_DOCUMENTO' |translate}}">
		</div>
		<div class="cb"></div>
		<!-- PROGRESS -->
		<div class="progress-c">
			<ng-container *ngIf="progress > 0">
				<div class="progress-container">
					<div class="progress progress-t0">
						<div class="progress-bar" role="progressbar" [style.width]="progress+'%'"
							 attr.aria-valuenow="{{progress}}" aria-valuemin="0"
							 aria-valuemax="100">
						</div>
					</div>
				</div>
			</ng-container>
		</div>
		<!-- / PROGRESS -->
	</div>

</div>
<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
