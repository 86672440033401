<div class="w-cover">

    <div class="window" id="pre-deposit">
        <!-- - - - - - - - - - - - - - - - - - PORTRAIT -->
        <div class="hidden-landscape full-h">
            <div class="btn-window-close" (click)="onCloseW($event)">
                <div class="btn-window-close-bar"></div>
            </div>
            <p class="w-title-cover txt-sm txt-w tac mb-1">&nbsp;</p>
            <div class="px-2 pb-2">
                <p class="w-title-cover txt-sm txt-gc tac mb-1">{{'PUEDE_ACTIVAR_ESTE_BONO_CON_DEPOSITO_1' |translate}} <span class='txt-w'> {{'PUEDE_ACTIVAR_ESTE_BONO_CON_DEPOSITO_2' |translate}}</span>
                    :</p>

                <!-- FAUX INP -->
                <div class="faux-inp2 fi-hash txt-nor tac mb-1">
                    <span class="txt-c4">{{'FECHA' |translate}}: </span>
                    <span class="ilb pl-1"> {{ balance.deposito_usable_bono.fecha }} </span>
                </div>
                <!-- FAUX INP -->
                <!-- / FAUX INP -->
                <div class="faux-inp2 fi-hash txt-nor tac mb-3">
                    <span class="txt-c4">{{'MONTO' |translate}}: </span> <span class="ilb txt-c2 pl-1">
						{{ balance.moneda.siglas.toUpperCase() }} </span>
                    {{balance.deposito_usable_bono.importe | number:'1.4-8'}}
                </div>
                <!-- / FAUX INP -->

                <button (click)="onActivate($event)" class="btn2 btn2-lg btn2-2nd full-w mb-4" type="button">
                    {{'ACTIVAR_CON_DEPOSITO' |translate}}
                </button>
                <p class="txt-sm txt-gc mb-05 tac">{{'PUEDES_REALIAR_UN' | translate}}:</p>
                <button (click)="getHashData($event)" class="btn2 btn2-lg btn2-1st btn2-lg-icon full-w" type="button">
                    {{'NUEVO_DEPOSITO' |translate}}
                    <img src="../../../../assets/images/icon-b-play-c.svg">
                </button>

            </div>
        </div>
        <!-- - - - - - - - - - - - - - - - - - / PORTRAIT -->
    </div>

</div>
