import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GamesModel} from "../../../../shared/models/games.model";
import {WConversionCoinComponent} from "../../w-conversion-coin/w-conversion-coin.component";
import {SessionServices} from "../../../../shared/services/session.services";
import {Router} from "@angular/router";
import {MonedaModel} from "src/app/shared/models/wallet-currency.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/redux/general.reducers";

declare function adjustSize(): any;

@Component({
	selector: "app-w-homegame-info",
	templateUrl: "./w-homegame-info.component.html",
	styleUrls: ["./w-homegame-info.component.css"]
})
export class WHomegameInfoComponent implements OnInit {

	@Input() game: GamesModel;
	@Input() cryptoCurrency: MonedaModel[];

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	wActive: boolean = false;

	constructor(
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		private router: Router,
		private sessionService: SessionServices,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.wActive = true;
		}, 0);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - NO CLICK ()
	noClick(event) {
		event.stopPropagation();
		event.preventDefault();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

	onPlay(event) {
		if (this.sessionService.isLoggedIn()) {
			this.router.navigate(["/games/playground"], {queryParams: {gameID: this.game.id}}).then();
			this.wActive = false;
			setTimeout(() => {
				this.activeModal.close();
				return;
			}, 250);

		}
		event.stopPropagation();
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			const tempCryptoCurrency = [];
			for (const moneda of this.game.moneda_ids) {
				const temp = this.cryptoCurrency.find(x => x.id === moneda);
				if (temp) {
					tempCryptoCurrency.push(temp);
				}
			}
			const modalRef = this.modalService.open(WConversionCoinComponent);
			modalRef.componentInstance.cryptoCurrency = tempCryptoCurrency;
			modalRef.componentInstance.process = "SHOW_HASH_ONLY_REGISTER";
			adjustSize();
		}, 250);
	}

	changeImg(event, newImg) {
		event.target.src = newImg;
	}
}
