<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TAB 0 - INTERCAMBIOS -->
<ng-container>


	<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
	<div class="cta-container-2 hidden-landscape">
		<div class="col col-3 full-h">
			<table class="tbl2 tbl2-lg tbl2-striped striped-alt tbl-vert tbl2-head">
				<tr>
					<th>{{ 'MOVIMIENTO' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'FECHA' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'MONEDA_DE' | translate }}<br>{{ 'ORIGEN' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'MONTO_DE' | translate }}<br>{{ 'ORIGEN' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'MONEDA_FINAL' | translate }}</th>
				</tr>
				<tr>
					<th>{{ 'MONTO_FINAL' | translate }}</th>
				</tr>
				<tr>
					<th class="th-2x">{{ 'ESTADO' | translate }}</th>
				</tr>
			</table>
		</div>
		<div class="col col-9 full-h of-x2">
			<div class="tbl2 tbl2-lg tbl2-striped tbl-vert faux-tbl-vert bg-g1" #divContenerdor
				*ngIf="intercambiosCrypto.length > 0" infiniteScroll [horizontal]="true" [infiniteScrollDistance]="0.1"
				[infiniteScrollUpDistance]="0.1" [infiniteScrollThrottle]="50" [alwaysCallback]="true"
				(scrolled)="onScroll()" (scrolledUp)="onScrollUp()" [scrollWindow]="false">
				<!-- EMPTY -->
				<div class="col tbl2-col-empty tac">
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="cb"></div>
				</div>
				<!-- EMPTY -->
				<!-- DATA -->
				<div class="col  tac">
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td">&nbsp;</div>
					<div class="td td-2x">&nbsp;</div>
					<div class="cb"></div>
				</div>
				<div class="col tbl2-col-n tac" *ngFor="let intercambio of intercambiosCrypto; let i = index;">
					<div class="td">{{intercambio.id}}</div>
					<div class="td">
						<span>{{intercambio.fecha_procesamiento.split(" ")[0]}} <br>
							<span class="t-sm wordBreak">{{intercambio.fecha_procesamiento.split(" ")[1]}}</span></span>
					</div>
					<div class="td">{{intercambio.moneda_origen.toUpperCase()}}</div>
					<div class="td">{{intercambio.monto_origen | number: "1.2-8"}}</div>
					<div class="td">{{intercambio.moneda_destino.toUpperCase()}}</div>
					<div class="td">{{intercambio.monto_destino | number: "1.2-8"}}</div>
					<div class="td td-2x">{{intercambio.estado}}</div>
					<div class="cb"></div>
				</div>
				<!-- / DATA -->
			</div>
			<div class="flex-center full" *ngIf="intercambiosCrypto.length < 1">
				<div class="bg-g1 p-2">{{ 'NO_HAY_MOVIMIENTOS_LISTADO' | translate }}.</div>
			</div>
		</div>

	</div>
	<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
</ng-container>
<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TAB 0 - INTERCAMBIOS -->