import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup } from "@angular/forms";
import { ExistFieldInFilter, ExtracFieldValueFromFilter, FilterModel } from "src/app/shared/models/filter.model";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/redux/general.reducers";
import { Actions, ofType } from "@ngrx/effects";
import * as AfiliadosActions from '../../../../../afiliados/redux/afiliados.actions';
import { fixAndroid } from "src/app/shared/utils/general.util";
import { CreateComisionesBatchDefaultFilter } from "src/app/afiliados/redux/afiliados.reducers";
declare function adjustSize(): any;

@Component({
	selector: "app-w-f-comisiones",
	templateUrl: "./w-f-comisiones.component.html",
	styleUrls: ["./w-f-comisiones.component.css"]
})
export class WFComisionesComponent implements OnInit {


	searchForm: FormGroup;
	filter: FilterModel;
	subscription = new Subscription();
	wActive: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private store: Store<AppState>,
		private actions$: Actions
	) { }

	ngOnInit(): void {
		this.initForm();
		this.filter = CreateComisionesBatchDefaultFilter();
		this.store.select((state) => state).subscribe(state => {
			this.filter = state.afiliado.comisiones_batch.filter;
			this.updateFormFromStore();
		});

		this.initForm();
		this.liseningGetBatchComisionesCompletado();
		this.updateFormFromStore();
		setTimeout(() => { this.wActive = true; }, 0);
		setTimeout(() => { fixAndroid('filtro-comisiones-cover'); }, 250);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CLOSE W ()
	onCloseW() {
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close();
			adjustSize();
		}, 250);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON CANCEL ()
	onCancel(event) {
		event.stopPropagation();
		this.onCloseW();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON FILTER ()
	onFilter(event) {
		event.stopPropagation();
		this.wActive = false;
		setTimeout(() => {
			this.activeModal.close(true);
			this.onSearchForm();
		}, 250);
	}

	initForm() {
		this.searchForm = new FormGroup({
			mes: new FormControl('null'),
			anno: new FormControl('null'),
		});
	}

	updateFormFromStore() {
		if (this.searchForm && this.filter && ExistFieldInFilter('mes', this.filter) && ExistFieldInFilter('anno', this.filter)) {
			this.searchForm.setValue({
				mes: ExtracFieldValueFromFilter('mes', this.filter),
				anno: ExtracFieldValueFromFilter('anno', this.filter)
			});
		}
	}

	liseningGetBatchComisionesCompletado() {
		this.subscription.add(this.actions$
			.pipe(ofType(AfiliadosActions.afiliadosGetBatchComisionesCompletado))
			.subscribe(response => {
				this.activeModal.close(true);
			}));
	}

	onSearchForm() {
		let nuevofilter = new FilterModel(1000);
		const searchModel = this.searchForm.value;

		let mes = ExtracFieldValueFromFilter('mes', this.filter);
		let anno = ExtracFieldValueFromFilter('anno', this.filter);

		if (searchModel.mes && searchModel.mes !== "null") {
			mes = searchModel.mes;
		}

		if (searchModel.anno && searchModel.anno !== "null") {
			anno = searchModel.anno
		}
		nuevofilter.filter.push({
			atribute: "mes",
			operator: "like",
			value: mes
		});

		nuevofilter.filter.push({
			atribute: "anno",
			operator: "like",
			value: anno
		});

		this.store.dispatch(AfiliadosActions.afiliadosGetBatchComisiones({ 'filter': nuevofilter }));
	}

}
