<!-- - - - - - - - - - - - - - - - - - - PORTRAIT -->
<div class="tab-container-afiliados-0 hidden-landscape">
    <div class="tab-container-afiliados-0 pos-rel px-15">

        <!-- - - - - - - - - - - - - - - - - - - ACTUAL -->
        <ng-container *ngIf="selectedTab==1">
            <div class="full pos-rel of-y2">
                <!-- -->
                <div class="col col-3">
                    <table class="tbl2 tbl2-md tbl2-striped tbl-vert">
                        <tr>
                            <th>{{'JUGADOR' | translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'FECHA_FTD' | translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'FECHA_INICIO_JUEGO' | translate}}</th>
                        </tr>
                        <tr>
                            <th class="th-2x">{{'CAMPANNA' | translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'TRATO' | translate}}</th>
                        </tr>
                        <tr>
                            <th class="th-2x">{{'MONEDAS' | translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'MONEDA_FTD' | translate}}</th>
                        </tr>
                        <tr>
                            <th>{{'MONEDA_APUESTA' | translate}}</th>
                        </tr>

                        <tr class="titlespacer">&nbsp;</tr>
                        <tr class="titlespacer">&nbsp;</tr>

                        <tr class="tr-c1">
                            <th>{{'MONEDA_JUEGO' |translate}}</th>
                        </tr>
                        <tr class="tr-c1">
                            <th>{{'MONTO' | translate}}</th>
                        </tr>
                        <tr class="tr-c1">
                            <th>{{'EQUIV_USD'|translate }}</th>
                        </tr>

                        <tr class="titlespacer">&nbsp;</tr>
                        <tr class="titlespacer">&nbsp;</tr>

                        <tr class="tr-c2">
                            <th>{{'MONEDA_JUEGO' |translate}}</th>
                        </tr>
                        <tr class="tr-c2">
                            <th>{{'MONTO' | translate}}</th>
                        </tr>
                        <tr class="tr-c2">
                            <th>{{'EQUIV_USD'|translate }}</th>
                        </tr>
                        <tr>
                            <th>&nbsp;</th>
                        </tr>

                    </table>
                </div>
                <div class="col col-9 of-x2 bg-g1">
                    <div class="tbl2 tbl2-md tbl2-striped tbl-vert tbl2-noHead">
                        <div class="flex-x-c">

                            <ng-container *ngFor="let item of allCommissions; let i = index;">
                                <!-- DATA -->
                                <div class="tbl2-col-n tac" [class.active]="subTable == i">
                                    <div class="td curr-point" (click)="onToggleSubtable(i)">
                                        <div class="accord-indicator">
                                            <img class="icon-full"
                                                 src="../../../../../assets/images/icon-indicator.svg">
                                        </div>
                                    </div>
                                    <div class="td">{{ item.fecha_ftd }}</div>
                                    <div class="td">{{ item.fecha_inicio_wagered }}</div>
                                    <div class="td td-2x">
                                        <span class="txt-ssm">{{ item.campanna }}</span>
                                    </div>
                                    <div class="td">
                                        <span class="txt-ssm">{{ item.trato }}</span>
                                    </div>
                                    <div class="td td-2x">
                                        <span class="ff-cnd txt-sssm">{{ item.monedas_deposito }}</span>
                                    </div>
                                    <div class="td">{{ item.moneda_ftd }}</div>
                                    <div class="td">{{ item.moneda_wagering }}</div>

                                    <div class="titlespacer">&nbsp;</div>
                                    <div class="titlespacer">&nbsp;</div>

                                    <div class="tr-c1">
                                        <div class="td">-</div>
                                        <div class="td">-</div>
                                        <div class="td td-">{{ item.cpa_usd }}</div>
                                    </div>

                                    <div class="titlespacer">&nbsp;</div>
                                    <div class="titlespacer">&nbsp;</div>

                                    <div class="tr-c2">
                                        <div class="td">-</div>
                                        <div class="td">-</div>
                                        <div class="td td-">{{ item.rev_usd }}</div>
                                    </div>

                                    <div class="td">&nbsp;</div>

                                </div>
                                <!-- ACCORDION V -->
                                <div class="col accordion2-vertical bg-gct">
                                    <!-- SUB DATA -->
                                    <div class="tbl2-col-n tac"
                                         *ngFor="let pagoJugador of item.jugadores; let j = index;">
                                        <div class="td">{{ pagoJugador.jugador }}</div>
                                        <div class="td">{{ pagoJugador.fecha_ftd }}</div>
                                        <div class="td">{{ pagoJugador.fecha_inicio_wagered }}</div>
                                        <div class="td td-2x">
                                            <span class="txt-ssm">{{ pagoJugador.campanna }}</span>
                                        </div>
                                        <div class="td">
                                            <span class="txt-ssm">{{ pagoJugador.trato }}</span>
                                        </div>
                                        <div class="td td-2x">
                                            <span class="ff-cnd txt-sssm">{{ pagoJugador.monedas_deposito }}</span>
                                        </div>
                                        <div class="td">{{ pagoJugador.moneda_ftd }}</div>
                                        <div class="td">{{ pagoJugador.moneda_wagering }}</div>

                                        <div class="titlespacer">&nbsp;</div>
                                        <div class="titlespacer">&nbsp;</div>

                                        <div class="td">{{ pagoJugador.moneda_wagering }}</div>
                                        <div class="td">{{ pagoJugador.cpa_moneda }}</div>
                                        <div class="td">{{ pagoJugador.cpa_usd }}</div>

                                        <div class="titlespacer">&nbsp;</div>
                                        <div class="titlespacer">&nbsp;</div>

                                        <div class="td">{{ pagoJugador.moneda_wagering }}</div>
                                        <div class="td">{{ pagoJugador.rev_moneda }}</div>
                                        <div class="td">{{ pagoJugador.rev_usd }}</div>
                                        <div class="td">
                                            <div *ngIf="pagoJugador.causas != ''" class="tooltip tt-table tt-c2 tt-ct"
                                                 (click)="showTooltip($event,3.5)">
                                                <p class="txt-sssm txt-w ff-cnd">
                                                    &nbsp;
                                                </p>
                                                <span class="tt-content">{{ pagoJugador.causas }}.</span>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- / SUB DATA -->
                                </div>
                                <!-- ACCORDION V -->
                                <!-- / DATA -->
                            </ng-container>

                            <!-- EMPTY -->
                            <div class="col tbl2-col-empty tac">
                                <div class="td">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="td td-2x">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="td td-2x">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="titlespacer">&nbsp;</div>
                                <div class="titlespacer">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="titlespacer">&nbsp;</div>
                                <div class="titlespacer">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="td">&nbsp;</div>
                                <div class="cb"></div>
                            </div>
                            <!-- -->
                        </div>
                    </div>
                </div>
                <!-- -->
                <div class="tbl2-title-row title-1 txt-c1">{{'COMISION_CPA' | translate}}</div>
                <!-- -->
                <div class="tbl2-title-row title-2 txt-c2">{{'COMISION_REV_SHARE' | translate}}</div>
                <!-- -->
                <div class="cb"></div>
            </div>
        </ng-container>
        <!-- - - - - - - - - - - - - - - - - - - / ACTUAL -->

        <!-- - - - - - - - - - - - - - - - - - - BATCH -->
        <ng-container *ngIf="selectedTab==2">
            <div class="bg-g1 txt-nor tac p-05 mb-02">
                <span class=" txt-g6">{{'PERIODO' |translate}}: </span> {{ getPeriodo() }}
            </div>
            <div class="tab-container-afiliados-3 pos-rel of-y2" id="accContainer">

                <div class="accordion2 mb-05" *ngFor="let item of allBatchCommissions; let i = index;" id="bAcc-{{i}}"
                     [class.active]="accordion==i">
                    <div class="accordion2-header" (click)="onToggleAccordion($event,i)">
                        <span class="txt-xnor">{{ item.nombre_campanna }}</span>
                        <div class="accordion2-indicator"><img src="../../../../assets/images/icon-indicator.svg">
                        </div>
                    </div>
                    <div class="px-1 pt-05 pb-1" (click)="onToggleAccordion($event,i)">
                        <div class="txt-sm mb-1">
                            <span class="txt-c2" *ngIf="item.estado_pago_id == 1">{{ item.estado_solicitud }}</span>
                            <span class="txt-c1" *ngIf="item.estado_pago_id == 2">{{ item.estado_solicitud }}</span>
                            <span class="txt-c7-l" *ngIf="item.estado_pago_id == 3">{{ item.estado_solicitud }}</span>
                            <span class="txt-gc" *ngIf="item.estado_pago_id == 4">{{ item.estado_solicitud }}</span>
                        </div>
                        <div class="col col-4-1">&nbsp;</div>
                        <div class="col col-6-1">
                            <div class="fl txt-g9">{{'TOTAL_EQUIV_USD'|translate}}:</div>
                            <div class="fr">{{ item.total_equivalente }}</div>
                        </div>
                        <div class="cb"></div>
                    </div>

                    <div class="accordion2-content acc2cntnt-lg">
                        <table class="tbl2 tbl2-md tbl2-striped">
                            <tr class="tbl2-head1">
                                <th class="col-2-1 tac">{{'MONEDA' |translate}}</th>
                                <th class="col-2-1 tac">{{'TRATO_CPA' |translate}}</th>
                                <th class="col-2-1 tac">{{'TRATO_REV_SHARE' | translate}}</th>
                                <th class="col-2-1 tac">{{'TOTAL'|translate}}</th>
                                <th class="tac">{{'EQUIV_USD' |translate}}</th>
                            </tr>
                        </table>
                        <div class="of-y2" id="comTableContainer">
                            <table class="tbl2 tbl2-noHead tbl2-md tbl2-striped tbl-vert bg-g1">
                                <tr class="tbl2-body1" *ngFor="let desgloseMoneda of item.monedas; let j = index;">
                                    <td class="col-2-1 tac"><span class="txt-sm">{{ desgloseMoneda.moneda }}</span></td>
                                    <td class="col-2-1 tac"><span class="txt-sm">{{ desgloseMoneda.monto_cpa }}</span>
                                    </td>
                                    <td class="col-2-1 tac">{{ desgloseMoneda.monto_rev_share }}</td>
                                    <td class="col-2-1 tac">{{ desgloseMoneda.total }}</td>
                                    <td class="tac">{{ desgloseMoneda.equivalente_moneda_base }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>
        <!-- - - - - - - - - - - - - - - - - - - / BATCH -->

    </div>
    <div class="port-rel">
        <ul class="tabs">
            <li (click)="onSetTab(1)" [class.active]="selectedTab==1"
                class="col-6 tac">{{'PERIODO_ACTUAL' | translate}}</li>
            <li (click)="onSetTab(2)" [class.active]="selectedTab==2" class="col-6 tac">{{'BATCH' |translate}}</li>
        </ul>
    </div>
</div>
<!-- - - - - - - - - - - - - - - - - - - / PORTRAIT -->
