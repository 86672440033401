<!-- - - - - - - - - - - - - - - - HOME FOOTER -->
<footer id="home-footer" class="pt-1">

    <div class="p-2">

        <div class="col col-6 pr-1 pt-02">
            <img src="../../../../assets/images/bitwild-logo-w.svg">
        </div>
        <div class="col col-6">
            <ul class="footer-social tar">
                <li><a href="#" target="_blank"><img src="../../../../assets/images/icon-social-fb.svg"></a>
                </li>
                <li><a href="#" target="_blank"><img src="../../../../assets/images/icon-social-ig.svg"></a>
                </li>
            </ul>
        </div>

        <div class="cb mb-2"></div>
        <ul class="menu-footer ff-cnd">
            <li (click)="goChat()">{{'CHAT' |translate}}</li>
            <li (click)="goToTermConditions()">{{'TERMINOS_CONDICIONES' |translate}}</li>
            <li (click)="goToPrivacy()">{{'POLITICA_PRIVACIDAD' |translate}}</li>
        </ul>

        <div class="pt-2">
            <img src="../../../../assets/images/proveedores.png" alt="Listado de nuestros proveedores"
                 class="providers">
        </div>

        <div class="cb mb-2"></div>
        <div class="fl">
            <button class="btn-lang-sm txt-x1" (click)="onSelectLanguage()">
                <img src="../../../../assets/images/icon-lang.svg" class="icon" alt="icon">
                {{selectedLang ? selectedLang.siglas.toUpperCase() :""}}
            </button>
        </div>

        <div class="fr">
            <p class="fr txt-sssm txt-g6 tar">{{'DERECHOS_RESERVADOS' |translate}}<.<br>v.1.0</p>
        </div>
        <div class="cb"></div>
    </div>

</footer>
<!-- - - - - - - - - - - - - - - - / HOME FOOTER -->
