import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WRulesComponent} from "../w-rules/w-rules.component";
import {Subscription} from "rxjs";
import {FilterModel} from "../../../shared/models/filter.model";
import {WindowsService} from "../../../nav/services/windows.service";
import {BonosService} from "../../services/bonos.service";
import {Store} from "@ngrx/store";
import {AppState} from "src/app/shared/redux/general.reducers";
import * as CajeroAction from "../../../cajero/redux/cajero.actions";
import * as GeneralAction from "../../../shared/redux/general.actions";
import * as BonoAction from "../../../bonos/redux/bonos.actions";
import {BonosModel} from "../../../shared/models/bonos.model";
import {Actions, ofType} from "@ngrx/effects";

declare function adjustSize(): any;

@Component({
	selector: "app-bonos-hist",
	templateUrl: "./bonos-hist.component.html",
	styleUrls: ["./bonos-hist.component.css"]
})
export class BonosHistComponent implements OnInit, OnDestroy, AfterViewInit {

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	arrayOne(n: number): any[] {
		if (n > 0) {
			return Array(n);
		}
	}

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	@ViewChild('divContenerdor') divContenerdor: any;
	bonosHistorialJugador: BonosModel[] = [];
	subscription = new Subscription();
	filter: FilterModel;
	recargar: boolean = true;

	constructor(
		private modalService: NgbModal,
		private bonosService: BonosService,
		private windowsService: WindowsService,
		private store: Store<AppState>,
		private actions$: Actions,
	) {
	}

	ngOnInit(): void {
		this.bonosHistorialJugador = [];
		this.filter = new FilterModel();
		this.subscription.add(this.store.select((state) => state).subscribe(state => {
			this.filter = state.bonos.bonosHistorial.filter;
			this.bonosHistorialJugador = state.bonos.bonosHistorial.value;
		}));
		this.store.dispatch(GeneralAction.setAfiliadosMode({afiliadosMode: false}));

		this.subscription = this.actions$
			.pipe(ofType(BonoAction.getBonosHistorialCompletado))
			.subscribe(response => {
				this.recargar = true;
			});
		this.initData();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	ngAfterViewInit() {
		adjustSize();
	}

	initData() {
		this.getCryptoCurrency();
		this.getBonosHistorial("primero");
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON SHOW RULES ()
	onShowRules(bono: BonosModel) {
		const modalRef = this.modalService.open(WRulesComponent);
		modalRef.componentInstance.bono = bono;
		modalRef.componentInstance.tipo = "BONOJUGADOR";
		modalRef.result.then();
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - CLEAR FILTERS ()

	// New API Calls
	getBonosHistorial(tipo = "primero") {
		let nuevoFiltro = new FilterModel();
		if (tipo != "primero") {
			nuevoFiltro = {...this.filter};
		}
		if (tipo == "scroll+") {
			nuevoFiltro.page++;
		}
		if (tipo == "scroll-") {
			nuevoFiltro.page--;
		}
		if (this.recargar) {
			this.recargar = false;
			this.store.dispatch(BonoAction.getBonosHistorial({filter: nuevoFiltro}));
			if (this.divContenerdor) {
				this.divContenerdor.nativeElement.scrollTop = 0;
				this.divContenerdor.nativeElement.scrollLeft = 0;
			}
		}

	}

	onScroll() {
		if (this.filter.last_page != this.filter.page) {
			this.getBonosHistorial("scroll+");
		}
	}

	onScrollUp() {
		if (this.filter.page != 1) {
			this.getBonosHistorial("scroll-");
		}
	}

	getCryptoCurrency() {
		this.store.dispatch(CajeroAction.getCryptoMonedas())
	}

}
