<div (click)="onCloseW($event)" class="w-cover">

	<div class="window pt-2 px-1" id="game-info" (click)="noClick($event)">
		<p class="info-title mb-15 pl-15">
      <span class="fav-indicator mr-05 " [class.active]="game.favorito"><img
		  src="../../../../assets/images/icon-favorite-lg.svg"></span>
			{{game.nombre_publico}}</p>

		<div class="px-1">
			<div class="col col-5 px-05">
				<img class="game-play" [src]="game.imagen_juego">
			</div>

			<div class="col col-7">
				<p class="info-detail"><span
					class="ff-cnd info-label">{{'CATEGORIA_JUEGO' |translate}}:</span> {{game.categoria_juego.nombre}}</p>
				<p class="info-detail"><span class="ff-cnd info-label">{{'LINEAS' |translate}}:</span> {{game.lineas}}</p>
				<p class="info-detail"><span class="ff-cnd info-label">{{'MIN_EQUIV_A' |translate}}:</span> <span
					class="txt-c4"> USD </span>
					{{game.apuesta_minima | number:"1.2-8"}}</p>
				<p class="info-detail"><span class="ff-cnd info-label">{{'MAX_EQUIV_A' |translate}}:</span> <span
					class="txt-c4"> USD </span>
					{{game.apuesta_maxima | number:"1.2-8"}}</p>
				<p class="info-detail"><span class="ff-cnd info-label">{{'JACKPOT_EQUIV_A' |translate}}:</span> <span
               class="txt-c4"> {{!game.jackpot ? "" : "USD"}} </span>
					{{!game.jackpot ? "n/a" : game.jackpot | number:"1.2-8"}}</p>
			</div>
			<div class="cb"></div>
		</div>

		<div class="cb mb-07"></div>

		<div class="accepted-coins-container of-y pl-1">
			<ul class="accepted-coins">
				<ng-container *ngFor="let curr of cryptoCurrency; let i = index;">
					<li class="coin" *ngIf="game.moneda_ids.includes(curr.id)">
						<img src="../../../../assets/images/crypto/{{ curr.siglas.toLowerCase() }}.svg"
							 (error)="changeImg($event, curr.imagen)">
						{{ curr.siglas.toUpperCase() }}
					</li>
				</ng-container>
			</ul>
			<div class="cb"></div>
		</div>

		<div class="w-actions px-3">
			<div class="col col-6">
			</div>
			<div class="col col-6 tar">
				<button (click)="onGoPlay()" class="btn2 btn2-nor btn-w1 btn2-1st" type="button">
					{{'JUGAR' |translate}}
					<!-- <img src="../../../../assets/images/icon-b-play-c.svg"> -->
				</button>
			</div>
			<div class="cb"></div>
		</div>

		<button (click)="onCloseW($event)" class="btn btn-w-close" type="button">
			<img src="../../../../assets/images/icon-b-close.svg">
		</button>
	</div>

</div>
